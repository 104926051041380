var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "add-schedule-pop-up" },
        [
          _c("div", { staticClass: "add-schedule-pop-up__step" }, [
            _vm._v(
              _vm._s(
                _vm.parseTranslate(
                  _vm.pricesTranslate.stepOf.text,
                  _vm.step,
                  "2"
                )
              )
            ),
          ]),
          _vm._v(" "),
          _vm.step == 1
            ? _c("pricing-schedule", {
                attrs: {
                  translations: _vm.scheduleTranslate,
                  tour: _vm.tour,
                  "tour-lang": _vm.tourLang,
                  "improve-options-translate": _vm.improveOptionsTranslate,
                  step: 2,
                  "schedule-pricing": _vm.schedule,
                },
                on: {
                  save: _vm.saveSchedule,
                  close: _vm.close,
                  back: _vm.close,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.step == 2
            ? _c("price-option", {
                attrs: {
                  "price-option": _vm.priceOption,
                  translations: _vm.pricesTranslate,
                  tour: _vm.tour,
                  step: 2,
                },
                on: {
                  back: function ($event) {
                    _vm.step = 1
                  },
                  close: _vm.close,
                  save: _vm.save,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("no-members", {
        attrs: {
          translations: _vm.pricesTranslate,
          "no-members": _vm.noMembers,
          slug: _vm.tour.slug,
        },
        on: { close: _vm.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }