<template>
  <div class="radio-button" :class="setClass">
    <input
      :id="id"
      class="custom-radio"
      type="radio"
      :name="radioName"
      :value="value"
      :checked="checked"
    >
    <label class="custom-radio-label" :for="id">{{ name }}</label>
  </div>
</template>

<script>
import '@/assets/css/radio-button.scss'
export default {
  name: 'Checkbox',
  props: {
    radioName: String,
    name: String,
    id: String,
    value: String,
    // Radio type
    default: Boolean,
    checked: Boolean
  },
  computed: {
    setClass () {
      const typeCheckbox = this.default ? 'default' : ''
      return 'radio-button_' + typeCheckbox
    }
  }
}
</script>
