var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nuxt-link",
    { staticClass: "back-link", attrs: { to: _vm.fullLink } },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "5",
            height: "8",
            viewBox: "0 0 5 8",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [_c("path", { attrs: { d: "M4 1L1 4L4 7", stroke: "currentColor" } })]
      ),
      _vm._v(" "),
      !_vm.product
        ? _c("span", { staticClass: "back-link__text" }, [
            _vm._v(_vm._s(_vm.commonTranslate.linkBackProductList?.text)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.product
        ? _c("span", { staticClass: "back-link__text" }, [
            _vm._v(_vm._s(_vm.commonTranslate.linkBackProductContent?.text)),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }