var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M3.71871 3.875H16.28C20.0591 3.875 23.125 7.0723 23.125 10.9921V17.6977C23.125 19.2225 21.9318 20.4714 20.4545 20.4714H19.8889C19.4908 21.9952 18.1484 23.125 16.5509 23.125C14.9533 23.125 13.6111 21.9952 13.2128 20.4714H10.7872C10.3889 21.9952 9.04672 23.125 7.44914 23.125C5.81867 23.125 4.45397 21.9483 4.0876 20.3768C2.2509 19.9674 0.875 18.2684 0.875 16.2456V6.82897C0.875 5.20463 2.1459 3.875 3.71871 3.875ZM2.69222 6.82897V10.6606H6.95259V5.75566H3.71871C3.15753 5.75566 2.69222 6.2325 2.69222 6.82897ZM8.76981 10.6606H13.0302V5.75566H8.76981V10.6606ZM16.28 5.75566H14.8474V10.6606H16.3094C17.6276 10.6606 18.9263 11.0258 20.0647 11.7159C20.0647 11.7159 20.0647 11.7159 20.0647 11.7159L21.3078 12.4693V10.9921C21.3078 8.09998 19.0477 5.75566 16.28 5.75566ZM14.9092 19.531C14.9092 20.4804 15.6505 21.2441 16.5509 21.2441C17.4515 21.2441 18.1927 20.4804 18.1927 19.531C18.1927 18.5816 17.4514 17.8177 16.5509 17.8177C15.6505 17.8177 14.9092 18.5816 14.9092 19.531ZM7.44914 21.2441C8.34953 21.2441 9.09076 20.4804 9.09076 19.531C9.09076 18.5816 8.34951 17.8177 7.44914 17.8177C6.54855 17.8177 5.80732 18.5816 5.80732 19.531C5.80732 20.4804 6.54854 21.2441 7.44914 21.2441ZM7.44914 15.937C9.04671 15.937 10.3889 17.0666 10.7872 18.5906H13.2128C13.6111 17.0666 14.9533 15.937 16.5509 15.937C18.1484 15.937 19.4908 17.0666 19.8889 18.5906H20.4545C20.9202 18.5906 21.3078 18.1946 21.3078 17.6977V14.649L19.1462 13.3386C19.1462 13.3386 19.1462 13.3386 19.1462 13.3386C18.2855 12.817 17.3046 12.5415 16.3094 12.5415H2.69222V16.2456C2.69222 17.2519 3.30268 18.1092 4.15332 18.442C4.59744 16.994 5.90377 15.937 7.44914 15.937Z",
          fill: "currentColor",
          stroke: "currentColor",
          "stroke-width": "0.25",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }