<template>
  <div class="v-pagination">
    <button class="v-pagination__button" :disabled="disablePrev" @click="prevPage">
      <IconArrowLeft class="v-pagination__icon" />{{ translates.previousButton.text }}
    </button>
    <span class="v-pagination__page">{{ value }}</span>
    <button class="v-pagination__button" :disabled="disableNext" @click="nextPage">
      {{ translates.nextButton.text }} <IconArrowLeft class="v-pagination__icon v-pagination__icon--next" />
    </button>
  </div>
</template>

<script>
import IconArrowLeft from '@/components/icons/ArrowLeft.vue'

export default {
  name: 'VPagination',
  components: { IconArrowLeft },
  props: {
    value: { type: Number, default: 1 },
    length: { type: Number, default: 0 },
    pageSize: { type: Number, default: 5 },
    disabled: { type: Boolean, default: false },
    translates: { type: Object, default: () => ({}) }
  },

  computed: {
    pagesCount () {
      return Math.ceil(this.length / this.pageSize)
    },

    disableNext () {
      return this.value === this.pagesCount || this.disabled
    },

    disablePrev () {
      return this.value === 1 || this.disabled
    }
  },

  methods: {
    nextPage () {
      this.$emit('input', this.value + 1)
    },

    prevPage () {
      this.$emit('input', this.value - 1)
    }
  }
}
</script>

<style lang="scss">
.v-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  &__button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    color: $dark-blue;
    font-size: 16px;

    &:disabled {
      opacity: 0.5;
    }
  }

  &__page {
    background-color: #E7E7E7;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: $dark-blue;
    margin: 0 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  &__icon {
    width: 8px;
    height: 8px;
    margin-right: 4px;

    &--next {
      margin-right: 0;
      margin-left: 4px;
      transform: rotate(180deg);
    }
  }
}
</style>
