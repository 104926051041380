<template>
  <VDialog
    v-model="modelActive"
    :title="parseTranslate(translations.bookingCancelledTitle.text, count)"
    dialog-classes="availability-confirm"
  >
    <p class="availability-confirm__subtitle">{{ translations.bookingCancelledSubtitle.text }}</p>
    <p class="availability-confirm__bookings">
      {{ translations.bookingsCancelledTotal.text }} <span class="availability-confirm__bookings-count">{{ count }}</span>
    </p>
    <p class="availability-confirm__reason-title">
      {{ translations.giveCancellationReason.text }}
    </p>
    <VField class="availability-confirm__field">
      <VSelect
        v-model="$v.selectedReason.$model"
        :error="$v.selectedReason.$error"
        :items="formattedReasons"
        :translates="reasonsTranslates"
      />
    </VField>
    <VField v-if="isCustomReason" class="availability-confirm__field">
      <VTextarea
        v-model="$v.reasonText.$model"
        :error="$v.reasonText.$error"
        :placeholder="translations.cancellationReasonDescription.text"
        :max-length="250"
        :rows="5"
      />
    </VField>
    <VField class="availability-confirm__field" :error="$v.confirmDelete.$error" :error-message="'Required'">
      <VCheckbox v-model="$v.confirmDelete.$model" :label="parseTranslate(translations.acceptUnderstandCanceling.text, count)" />
    </VField>

    <div class="availability-confirm__footer">
      <VButton base @click="close">{{ translations.backButton.text }}</VButton>
      <VButton outline @click="confirm">{{ translations.cancelBookingsButton.text }}</VButton>
    </div>
  </VDialog>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'

import VDialog from '@/components/common/VDialog.vue'
import VField from '@/components/controls/VField.vue'
import VCheckbox from '@/components/controls/VCheckbox.vue'
import VSelect from '@/components/controls/VSelect.vue'
import VTextarea from '@/components/controls/VTextarea.vue'
import VButton from '@/components/controls/VButton.vue'

import { parseTranslate } from '@/utils/translations'

const REASON_NOT_LISTED_ID = 15

export default {
  name: 'AvailabilityConfirm',
  components: { VDialog, VField, VCheckbox, VSelect, VTextarea, VButton },

  props: {
    isVisible: { type: Boolean, default: false },
    count: { type: Number, default: 0 },
    translations: { type: Object, required: true },
    cancellationReasons: { type: Array, default: () => [] }
  },

  data() {
    return {
      selectedReason: '',
      reasonText: '',
      confirmDelete: false
    }
  },

  computed: {
    modelActive: {
      get() {
        return this.isVisible
      },
      set(value) {
        this.$emit('update:isVisible', value)
      }
    },

    isCustomReason() {
      const selectedReason = this.filteredReasons.find(reason => reason.id === this.selectedReason)
      return selectedReason?.is_additional_text_required
    },

    filteredReasons() {
      return this.cancellationReasons.filter(reason => reason.is_suitable_for_mass_cancellation)
    },

    formattedReasons() {
      return this.filteredReasons.map(reason => ({ ...reason, name: reason.id }))
    },

    reasonsTranslates() {
      return this.filteredReasons.reduce((translations, { id, name }) => {
        return {
          ...translations,
          [id]: name
        }
      }, {})
    }
  },

  validations() {
    return {
      confirmDelete: { required: value => value },
      selectedReason: { required },
      reasonText: { required: requiredIf(() => this.isCustomReason) }
    }
  },

  watch: {
    selectedReason() {
      if (!this.isCustomReason) {
        this.reasonText = ''
      }
    },

    modelActive() {
      if (this.modelActive) {
        this.selectedReason = ''
        this.reasonText = ''
        this.confirmDelete = false
        this.$v.$reset()
      }
    }
  },

  methods: {
    parseTranslate,

    confirm() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.$emit('confirm', { cancellationReason: this.selectedReason, cancellationReasonText: this.reasonText })
    },

    close() {
      this.modelActive = false
    }
  }
}
</script>

<style lang="scss">
.availability-confirm {
  width: 839px;

  .selection__expand {
    max-height: 150px;
  }

  &__subtitle {
    margin-top: 18px;
  }

  &__bookings {
    font-weight: 600;
    margin-top: 28px;
  }

  &__bookings-count {
    display: inline-block;
    margin-left: 18px;
  }

  &__reason-title {
    margin: 30px 0;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 60px;
  }

  &__field {
    max-width: 620px;
    &:not(:last-child) {
      margin-bottom: 18px;
    }
  }
}
</style>
