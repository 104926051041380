export function emailValidations(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export function passwordValidations(value) {
  const containsUppercase = /[A-Z]/.test(value)
  const containsLowercase = /[a-z]/.test(value)
  const containsNumber = /[0-9]/.test(value)
  const containsSpecial = /[#?!@$%^&*-]/.test(value)
  return containsUppercase && containsLowercase && containsNumber && containsSpecial
}

export function website(value) {
  if (!value) {
    return true
  }

  return (
    /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?$/.test(value) ||
    /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?$/.test(value)
  )
}

export function checkDateEnd(start, end) {
  if (end != null && start != null && new Date(end) < new Date(start)) {
    return false
  }
  return true
}

export function dateIsInTheInterval(date, start, end) {
  return new Date(date) >= new Date(start) && new Date(date) <= new Date(end)
}

export function maxFileSize(maxSize) {
  return value => (value?.size ? value?.size < maxSize : true)
}

export function checkFileFormat(accessFileTypes) {
  return value => (value?.type ? accessFileTypes.includes(value?.type) : true)
}

export const checkBic = value => (value !== '' ? /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value) : true)
