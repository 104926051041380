<template>
  <div class="your-information-form">
    <VInput
      v-model="$v.fullName.$model"
      :error="$v.fullName.$error"
      class="your-information-form__field"
      :label="translations.placeholderTitle1.text"
      :placeholder="translations.placeholderText1.text"
    />
    <VInput
      v-model="$v.publicName.$model"
      :error="$v.publicName.$error"
      class="your-information-form__field"
      :label="translations.placeholderTitle2.text"
      :sub-label="translations.placeholderSubtitle2.text"
      :placeholder="translations.placeholderText2.text"
      :max-length="24"
    />
    <VInput
      v-model="$v.tagline.$model"
      :error="$v.tagline.$error"
      class="your-information-form__field"
      :label="translations.placeholderTitle3.text"
      :sub-label="translations.placeholderSubtitle3.text"
      :placeholder="translations.placeholderText3.text"
      :max-length="100"
    />
    <VTextarea
      v-model="$v.bio.$model"
      :error="$v.bio.$error"
      class="your-information-form__field"
      rows="3"
      :label="translations.placeholderTitle4.text"
      :sub-label="translations.placeholderSubtitle4.text"
      :placeholder="translations.placeholderText4.text"
      :max-length="500"
    />
    <VInput
      v-model="$v.website.$model"
      :error="$v.website.$error"
      class="your-information-form__field"
      :label="translations.placeholderTitle5.text"
      :optional="translations.placeholderTitleInfo5.text"
      :placeholder="translations.placeholderText5.text"
    />

    <VBtn class="your-information-form__button" :loading="loading" base :name="saveBtnText" @click="save" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { website } from '@/utils/validations'

import VInput from '@/components/controls/VInput.vue'
import VTextarea from '@/components/controls/VTextarea.vue'
import VBtn from '@/components/controls/VBtn.vue'

export default {
  name: 'YourInformationForm',
  components: { VInput, VTextarea, VBtn },
  props: {
    yourInfo: { type: Object, default: () => ({}) },
    translations: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
    saveBtnText: { type: String, default: '' }
  },

  data() {
    return {
      fullName: '',
      publicName: '',
      tagline: '',
      bio: '',
      website: ''
    }
  },

  validations() {
    return {
      fullName: { required },
      publicName: { required },
      tagline: { required },
      bio: { required },
      website: { website }
    }
  },

  mounted() {
    if (this.yourInfo) {
      const {
        seller_full: sellerFull,
        company_name: companyName,
        seller_tagline: salledTagline,
        bio,
        company_site_link: website
      } = this.yourInfo
      this.fullName = sellerFull
      this.publicName = companyName
      this.tagline = salledTagline
      this.bio = bio
      this.website = website
    }
  },

  methods: {
    save() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.$emit('save', {
        fullName: this.fullName,
        publicName: this.publicName,
        tagline: this.tagline,
        bio: this.bio,
        website: this.website
      })
    }
  }
}
</script>

<style lang="scss">
.your-information-form {
  max-width: 720px;

  &__field {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__button {
    margin-top: 72px;
  }
}
</style>
