var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "categories-selector" },
    [
      _c("VRadioGroup", {
        attrs: { name: "selectedCategoryId" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ on, attrs }) {
              return _vm._l(_vm.categories, function (category) {
                return _c(
                  "div",
                  {
                    key: category.id,
                    staticClass: "categories-selector__category",
                  },
                  [
                    _c(
                      "VRadioButton",
                      _vm._g(
                        _vm._b(
                          { attrs: { value: category.id } },
                          "VRadioButton",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "categories-selector__category-content",
                          },
                          [
                            _c("img", {
                              attrs: { src: `${category.image}`, alt: "" },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "categories-selector__category-header",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "categories-selector__category-name",
                                  },
                                  [_vm._v(_vm._s(category.name))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "categories-selector__category-description",
                                  },
                                  [_vm._v(_vm._s(category.description))]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              })
            },
          },
        ]),
        model: {
          value: _vm.selectedCategoryId,
          callback: function ($$v) {
            _vm.selectedCategoryId = $$v
          },
          expression: "selectedCategoryId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }