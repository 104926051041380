<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-improve-options">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close" />
      </div>
      <div class="pop-up-improve-options__content">
        <div class="title">
          {{ translations.title.text }}
        </div>
        <div class="description">
          {{ translations.subtitle.text }}
        </div>
        <VTextarea v-model="message" :max-length="500" rows="3" />
      </div>
      <div class="pop-up-improve-options__nav">
        <v-btn outline :name="translations.backBtn.text" @click="closeWindow" />
        <v-btn base :name="translations.sendBtn.text" :loading="loading" loading-name="Saved" @click="sendFeedback()" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VBtn from '@/components/controls/VBtn.vue'
import VTextarea from '@/components/controls/VTextarea.vue'
import notificationService from '@/services/notification'

export default {
  components: { VBtn, VTextarea },
  props: {
    isActive: { type: Boolean, default: false },
    translations: { type: Object, default: () => ({}) }
  },
  data: () => ({
    message: '',
    submitResponse: '',
    loading: false
  }),
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    closeWindow() {
      const active = this.isActive
      this.$emit('close', active)
    },
    async sendFeedback() {
      await this.$axios
        .$post(
          `/api/v1/send-feedback/?name=${this.userInfo.contact_first_name}&question=${this.message}&email=${this.userInfo.email}`
        )
        .then(() => {
          notificationService.success({ title: this.translations.sendingFeedbackSuccessful.text })
          this.closeWindow()
        })
        .catch(() => {
          notificationService.error({ title: this.translations.errorOccurredWhileSending.text })
        })
    }
  }
}
</script>

<style lang="scss">
.pop-up-improve-options {
  background: $white;
  position: relative;
  padding: 35px 31px 19px 31px;
  width: 600px;
  height: 567px;
  align-items: center;
  justify-content: center;
  &__content {
    margin-bottom: 34px;
    .title {
      &::before {
        content: '';
        position: absolute;
        text-align: -webkit-center;
        top: 95px;
        left: 0;
        right: 0;
        margin: auto;
        width: 80px;
        height: 80px;
        background-repeat: no-repeat;
        background-size: 80px;
        background-image: url('~/assets/img/svg/clock.svg');
      }
      padding-top: 175px;
      font-weight: bold;
      margin-bottom: 23px;
      text-align: center;
    }
    .description {
      text-align: center;
      margin-bottom: 15px;
      color: #999999;
    }
  }
  &__nav {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #ececec;
    padding-top: 23px;
    .v-btn {
      &_outline {
        border: 2px solid $primary;
        padding: 13px 30px;
        margin-right: 20px;
      }
    }
  }
}
</style>
