<template>
  <div class="categories-selector">
    <VRadioGroup v-model="selectedCategoryId" name="selectedCategoryId">
      <template #default="{ on, attrs }">
        <div v-for="category in categories" :key="category.id" class="categories-selector__category">
          <VRadioButton :value="category.id" v-bind="attrs" v-on="on">
            <div class="categories-selector__category-content">
              <img :src="`${category.image}`" alt="" />

              <div class="categories-selector__category-header">
                <div class="categories-selector__category-name">{{ category.name }}</div>
                <div class="categories-selector__category-description">{{ category.description }}</div>
              </div>
            </div>
          </VRadioButton>

          <!-- <div v-if="selectedCategoryId === category.id" class="categories-selector__category-tags">
            <p class="categories-selector__tags-title">{{ translations.tagsTitle.text }}</p>
            <CategoriesFormTags
              v-model="selectedTags" :error="$v.selectedTags.$error"
              :subcategories="category.subcategories" :translations="translations" />
          </div> -->
        </div>
      </template>
    </VRadioGroup>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import VRadioButton from '@/components/controls/VRadioButton.vue';
import VRadioGroup from '@/components/controls/VRadioGroup.vue';
import CategoriesFormTags from '@/components/AccountSetup/CategoriesFormTags.vue';

export default {
  name: 'CategoriesSelector',
  components: { VRadioButton, VRadioGroup, CategoriesFormTags },

  props: {
    categories: { type: Array, default: () => [] },
    translations: { type: Object, default: () => ({}) },
    categoryId: { type: Number, default: null },
    tags: { type: Array, default: () => [] },
    subcategories: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },

  data() {
    return {
      selectedCategoryId: null,
      selectedTags: [],
    };
  },

  computed: {
    selectedCategory() {
      return this.getCategoryById(this.selectedCategoryId);
    },

    selectedSubcategories() {
      if (!this.selectedCategory || !this.selectedTags.length) {
        return [];
      }

      return this.selectedCategory.subcategories.filter((subcategory) => {
        return subcategory.tags.some((tag) => this.selectedTags.find((selectedTag) => selectedTag.id === tag.id));
      });
    },

    saveData() {
      return {
        categoryId: this.selectedCategoryId,
        subcategories: this.selectedSubcategories,
        tags: this.selectedTags,
      };
    },
  },

  validations() {
    return {
      // selectedTags: { required },
    };
  },

  watch: {
    selectedCategoryId(newCategory, prevCategory) {
      if (!prevCategory) {
        return;
      }
      // this.selectedTags = [];
      // this.$v.selectedTags.$reset();
    },

    saveData() {
      this.$emit('update', this.saveData);
    },

    categories() {
      this.initializeFilledData();
    },
  },

  mounted() {
    this.initializeFilledData();
  },

  methods: {
    getCategoryById(id) {
      return this.categories.find((category) => category.id === id);
    },

    initializeFilledData() {
      // if (!this.categoryId || !this.subcategories.length || !this.tags.length) return;
      if (!this.categoryId) return;
      this.selectedCategoryId = this.categoryId;
      const category = this.categories.find((x) => x.id === this.categoryId);
      if (!category) return;
      const tagIds = new Set(this.tags);
      // for (const sub of category.subcategories) {
      //   for (const tag of sub.tags) {
      //     if (tagIds.has(tag.id)) {
      //       this.selectedTags.push(tag);
      //     }
      //   }
      // }
      this.save();
    },

    save() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.$emit('save', {
        category: this.selectedCategoryId,
        subcategories: this.selectedSubcategories,
        tags: this.selectedTags,
      });
    },
  },
};
</script>

<style lang="scss">
.categories-selector {
  &__category {
    border: 1px solid #ebebeb;
    border-radius: 14px;
    padding: 24px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__category-content {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__category-name {
    margin-bottom: 8px;
    font-weight: 700;
  }

  &__category-tags {
    padding-left: 126px;
    padding-top: 16px;
  }

  &__tags-title {
    font-weight: 700;
    margin-bottom: 9px;
  }
}
</style>
