var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShow
    ? _c("div", { staticClass: "wrapper" }, [
        _c(
          "div",
          { staticClass: "info-pop-up" },
          [
            _c(
              "div",
              {
                staticClass: "pop-up__close",
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/svg/close.svg"),
                    alt: "close",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "info-pop-up__title" }, [
              _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "info-pop-up__description" },
              _vm._l(_vm.texts, function (item, index) {
                return _c("div", { key: index }, [
                  _c("p", [_vm._v(_vm._s(item))]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("v-btn", {
              attrs: { base: "", name: "Ok" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }