<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.08062 21.9999H4.03225C3.49326 21.9999 2.97635 21.7892 2.59523 21.4141C2.21411 21.039 2 20.5303 2 19.9999V12.9999C2 12.4695 2.21411 11.9608 2.59523 11.5857C2.97635 11.2107 3.49326 10.9999 4.03225 10.9999H7.08062M14.1935 8.99996V4.99998C14.1935 4.20434 13.8723 3.44128 13.3006 2.87867C12.729 2.31607 11.9536 2 11.1451 2L7.08062 10.9999V21.9999H18.5425C19.0326 22.0053 19.5082 21.8363 19.8816 21.5239C20.2551 21.2115 20.5012 20.7768 20.5747 20.2999L21.977 11.2999C22.0212 11.0133 22.0016 10.7206 21.9194 10.4422C21.8373 10.1638 21.6946 9.90624 21.5013 9.68747C21.308 9.46869 21.0687 9.29389 20.7999 9.17517C20.5311 9.05646 20.2393 8.99667 19.9447 8.99996H14.1935Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'TourCancellation'
}
</script>
