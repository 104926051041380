<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-review-report">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close">
      </div>
      <div class="pop-up-review-report__content">
        <div class="title">
          {{ reportTranslate.title.text }}
        </div>
        <div class="description">
          {{ reportTranslate.subtitle.text }}
        </div>
        <textarea
          v-model.trim="$v.form.text.$model"
          class="textarea"
          :class="{ 'input-error': $v.form.text.$error }"
        />
      </div>
      <div class="pop-up-review-report__nav">
        <div class="back" @click="closeWindow()">
          <Btn outline :name="reportTranslate.backBtn.text" />
        </div>
        <div class="report" @click="reviewReport()">
          <Btn base :name="reportTranslate.sendBtn.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Btn from '@/components/controls/Btn.vue'
export default {
  components: {
    Btn
  },
  props: ['isActive', 'reportTranslate', 'tourId', 'tourName', 'customerText'],
  data: () => ({
    form: {
      text: ''
    }
  }),
  validations: {
    form: {
      text: { required }
    }
  },
  methods: {
    closeWindow () {
      const active = this.isActive
      this.$emit('close', active)
    },
    reviewReport () {
      this.$v.form.$touch()
      if (!this.$v.$invalid) {
        const mail_send_report_body = new FormData()
        mail_send_report_body.append(
          'subject',
          `New tour reviews - ${this.tourName}`
        )
        mail_send_report_body.append('to', 'request@vidodoguide.com')
        mail_send_report_body.append('from', 'noreply@vidodoguide.com')
        mail_send_report_body.append(
          'message',
          `Tour id - ${this.tourId}
                                                        \nTour name - ${this.tourName}
                                                        \nCustomer review - ${this.customerText}
                                                        \nTour owner's explanation - ${this.form.text}`
        )
        this.$axios
          .$post('/api/v1/send-mail/', mail_send_report_body, {})
          .catch((error) => {
            console.log(error.response)
          })
        this.$emit('submit')
        this.closeWindow()
      }
    }
  }
}
</script>

<style lang="scss">
.pop-up-review-report {
  background: $white;
  position: relative;
  padding: 35px;
  width: 600px;
  align-items: center;
  justify-content: center;
  &__content {
    margin-bottom: 34px;
    .title {
      &::before {
        content: '';
        position: absolute;
        text-align: -webkit-center;
        top: 45px;
        left: 0;
        right: 0;
        margin: auto;
        width: 80px;
        height: 80px;
        background-repeat: no-repeat;
        background-size: 80px;
        background-image: url('~/assets/img/svg/warning-black.svg');
      }
      padding-top: 135px;
      font-weight: bold;
      margin-bottom: 23px;
      text-align: center;
    }
    .description {
      text-align: center;
      margin-bottom: 15px;
      color: #999999;
    }
    .textarea {
      padding: 17px;
      width: 520px;
      height: 100px;
      resize: none;
      font-size: 18px;
      line-height: 21px;
      border: 1px solid #ebebeb;
      border-radius: 8px;
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ececec;
    padding-top: 23px;
    width: 520px;
    .btn {
      &_outline {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
      &_base {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
    }
  }
}
</style>
