<template>
  <VDialog
    v-model="modelVisible"
    size="large-plus"
    class="provide-missing-information"
    :title="translations.provideMissingInformation.text"
  >
    <p class="provide-missing-information__content">
      {{ translations.performanceLinkDescription.text }}
    </p>
    <VButton class="provide-missing-information__button" base :to="localePath({ name: 'performance' })" target="_blank">{{
      translations.performanceLink.text
    }}</VButton>
  </VDialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VButton from '@/components/controls/VButton.vue'

export default {
  name: 'ProvideMissingInformation',
  components: { VDialog, VButton },

  props: {
    isActive: { type: Boolean, default: false },
    translations: { type: Object, default: () => ({}) }
  },

  computed: {
    modelVisible: {
      get() {
        return this.isActive
      },
      set(visible) {
        if (this.loading) {
          return
        }
        this.$emit('update:isActive', visible)
      }
    }
  }
}
</script>

<style lang="scss">
.provide-missing-information {
  &__content {
    margin-top: 20px;
  }

  &__button {
    margin-left: auto;
    margin-top: 30px;
    width: fit-content;
    padding: 0 20px;
  }
}
</style>
