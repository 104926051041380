<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.364 18.938H5.187C4.764 18.938 4.417 19.274 4.417 19.7 4.417 20.126 4.764 20.462 5.187 20.462H19.364C19.787 20.462 20.134 20.126 20.134 19.7 20.134 19.274 19.787 18.938 19.364 18.938ZM11.753 15.004C11.897 15.119 12.088 15.182 12.275 15.182 12.458 15.182 12.651 15.124 12.799 15.002L16.938 11.695C17.093 11.571 17.186 11.396 17.186 11.205 17.186 11.015 17.093 10.84 16.938 10.716 16.643 10.48 16.186 10.48 15.891 10.716L13.045 12.99V3.566C13.045 3.148 12.647 2.901 12.275 2.901 11.903 2.901 11.505 3.148 11.505 3.566V12.99L8.66 10.716C8.364 10.48 7.907 10.48 7.612 10.716 7.457 10.84 7.365 11.015 7.365 11.205 7.365 11.396 7.457 11.571 7.612 11.695L11.753 15.004Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width=".5"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconDownload'
}
</script>
