var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "address-form" },
    [
      _c(
        "VField",
        {
          staticClass: "address-form__field",
          attrs: {
            label: _vm.translations.placeholderTitle1.text,
            error: _vm.$v.localAddress.country.$error,
          },
        },
        [
          _c(
            "client-only",
            [
              _c("VSelectCountry", {
                attrs: {
                  country: _vm.localAddress.country,
                  error: _vm.$v.localAddress.country.$error,
                  "country-name": "",
                  "top-country": "ES",
                  usei18n: false,
                  placeholder: _vm.translations.placeholderText1.text,
                  "disable-placeholder": "",
                },
                model: {
                  value: _vm.$v.localAddress.country.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.localAddress.country, "$model", $$v)
                  },
                  expression: "$v.localAddress.country.$model",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "VField",
        {
          staticClass: "address-form__field",
          attrs: {
            label: _vm.translations.placeholderTitle2.text,
            error: _vm.$v.localAddress.city.$error,
          },
        },
        [
          _c("VInput", {
            attrs: {
              error: _vm.$v.localAddress.city.$error,
              placeholder: _vm.translations.placeholderText2.text,
            },
            model: {
              value: _vm.$v.localAddress.city.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.localAddress.city, "$model", $$v)
              },
              expression: "$v.localAddress.city.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "VField",
        {
          staticClass: "address-form__field",
          attrs: {
            label: _vm.translations.placeholderTitle3.text,
            error: _vm.$v.localAddress.zip.$error,
          },
        },
        [
          _c("VInput", {
            attrs: {
              error: _vm.$v.localAddress.zip.$error,
              onkeypress: "return event.charCode >= 48 && event.charCode <= 57",
              onpaste: "return false;",
              placeholder: _vm.translations.placeholderText3.text,
            },
            model: {
              value: _vm.$v.localAddress.zip.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.localAddress.zip, "$model", $$v)
              },
              expression: "$v.localAddress.zip.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "VField",
        {
          staticClass: "address-form__field",
          attrs: {
            label: _vm.translations.placeholderTitle4.text,
            error: _vm.$v.localAddress.address.$error,
          },
        },
        [
          _c("VInput", {
            attrs: {
              error: _vm.$v.localAddress.address.$error,
              placeholder: _vm.translations.placeholderText4_1.text,
            },
            model: {
              value: _vm.$v.localAddress.address.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.localAddress.address, "$model", $$v)
              },
              expression: "$v.localAddress.address.$model",
            },
          }),
          _vm._v(" "),
          _c("VInput", {
            attrs: { placeholder: _vm.translations.placeholderText4_2.text },
            model: {
              value: _vm.localAddress.secondAddress,
              callback: function ($$v) {
                _vm.$set(_vm.localAddress, "secondAddress", $$v)
              },
              expression: "localAddress.secondAddress",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("VBtn", {
        staticClass: "address-form__button",
        attrs: { loading: _vm.loading, base: "", name: _vm.saveBtnText },
        on: { click: _vm.save },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }