function createCounter() {
  let count = 0
  return () => {
    ++count
    return count
  }
}

const nextCount = createCounter()

const SUCCESS = 'success'
const ERROR = 'error'

class NotificationService {
  constructor() {
    this.subscribers = {}
  }

  addEventListener(event, callback, { once } = {}) {
    if (!this.subscribers[event]) {
      this.subscribers[event] = []
    }

    this.subscribers[event].push({ cb: callback, once })
  }

  removeEventListener(event, callback) {
    this.subscribers[event] = this.subscribers[event].filter(subscriber => subscriber.cb !== callback)
  }

  dispatchEvent(event, item) {
    this.subscribers[event]?.forEach(subscriber => {
      subscriber.cb(item)

      if (subscriber.once) {
        this.removeEventListener(event, subscriber.cb)
      }
    })
  }

  push({ title, text, timeout = 4000, type = SUCCESS }) {
    const id = nextCount()
    const item = { title, text, type, id }
    this.dispatchEvent('created', item)

    if (timeout) {
      setTimeout(() => {
        this.remove(item)
      }, timeout)
    }

    return item
  }

  remove(item) {
    this.dispatchEvent('removed', item)
  }

  success({ title, text }, timeout) {
    return this.push({ title, text, timeout, type: SUCCESS })
  }

  error({ title, text }, timeout) {
    return this.push({ title, text, timeout, type: ERROR })
  }
}

export default new NotificationService()
