<template>
  <input
    v-model="proxyValue"
    class="input"
    :class="[setClass, { 'input--error': error }]"
    :type="type"
    :placeholder="placeholder"
    :readonly="readOnly"
    :max="max"
    :min="min"
    v-bind="$attrs"
    @input="$emit('input', $event.target.value)"
  >
</template>

<script>

export default {
  name: 'InputFields',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: [String, Number],
    placeholder: String,
    // Input type
    default: Boolean,
    disabledInput: Boolean,
    readOnly: Boolean,
    searchInput: Boolean,
    fullWidth: Boolean,
    max:String,
    min:String,
    error: { type: Boolean, default: false }
  },
  computed: {
    setClass () {
      const typeInput = this.default
        ? 'default'
        : this.disabledInput
          ? 'disabledInput'
          : this.searchInput
            ? 'searchInput'
            : ''
      return 'input_' + typeInput
    },
    proxyValue: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue), this.$emit('focus', newValue)
      },
      setBlur (newValue) {
        newValue = false
        this.$emit('blur.native', newValue)
      }
    }
  }
}
</script>

<style lang="scss">
.input {
  position: relative;
  font-family: 'Cabin', sans-serif;
  display: flex;
  align-items: center;
  width: 128px;
  height: 48px;
  padding: 0 16px;
  border: 1px solid $border;
  border-radius: 4px;
  font-size: 16px;
  line-height: 19px;
  &::placeholder {
    color: $light-gray-three;
  }
  &_disabledInput {
    cursor: no-drop;
    background: #f0f0f0;
  }
  &_searchInput {
    padding-right: 45px;
    width: 715px;
    height: 48px;
    font-size: 17px;
    line-height: 27px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: white;
    background-image: url('~/assets/img/svg/search.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 20px;
  }

  &--error {
    border-color: $red;
    background-color: $red-light;
  }
}
</style>
