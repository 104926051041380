<template>
  <div v-if="noMembers" class="wrapper">
    <div class="no-members">
      <div class="no-members__title">
        {{ translations.contentNoMembers.text }}
      </div>
      <div class="no-members__description">
        {{ translations.noMembersDescription1.text }}
        <nuxt-link :to="localePath(`/product/${slug}/booking-details/pricing-type`)">
          {{ translations.noMembersDescription2.text }}
        </nuxt-link>
        {{ translations.noMembersDescription3.text }} <br />
      </div>
      <div class="no-members__description nav-description">
        {{ translations.noMembersDescription4.text }}
      </div>
      <v-btn class="no-members__button" base :name="translations.closeBtn.text" @click="$emit('close')" />
    </div>
  </div>
</template>

<script>
import VBtn from '@/components/controls/VBtn.vue'
export default {
  components: {
    VBtn
  },
  props: {
    translations: { type: Object, default: () => ({}) },
    noMembers: { type: Boolean, default: false },
    slug: { type: String, default: '' }
  }
}
</script>

<style lang="scss">
.no-members {
  padding: 25px 15px;
  background: $white;
  width: 400px;
  border-radius: 20px;
  &__title {
    font-size: 22px;
    margin-bottom: 15px;
    font-weight: bold;
    text-align: center;
  }
  &__description {
    a {
      color: $light-blue-two;
    }
    &.nav-description {
      margin-top: 10px;
      font-weight: bold;
    }
  }
  &__button {
    margin-top: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
    .btn {
      min-width: 100px;
    }
  }
}
</style>
