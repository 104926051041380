<template>
  <div class="v-notification-item" :class="{ 'v-notification-item--error': isError }">
    <div class="v-notification-item__picture">
      <img v-if="isError" width="40" height="40" src="@/assets/img/svg/cancel.svg" alt="alert error" />
      <img v-else width="40" height="40" src="@/assets/img/svg/sent_by.svg" alt="alert success" />
    </div>
    <div class="v-notification-item__description">
      <div class="v-notification-item__title">
        {{ title }}
      </div>
      <div class="v-notification-item__text">
        {{ text }}
      </div>
    </div>
    <button class="v-notification-item__button-close" @click="close"><BaseIcon icon-name="Close" /></button>
  </div>
</template>

<script>
import BaseIcon from '@/components/common/BaseIcon.vue'

export default {
  name: 'VNotificationItem',
  components: { BaseIcon },
  props: {
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    type: { type: String, default: '' }
  },

  computed: {
    isError() {
      return this.type === 'error'
    }
  },

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.v-notification-item {
  padding: 14px 10px;
  width: max-content;
  max-width: 300px;
  border-radius: 12px;
  background: $white;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.25));
  display: flex;
  align-items: center;
  gap: 12px;
  border: 2px solid $green-light;

  &--error {
    border-color: $red;
  }

  &__picture {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    margin-bottom: 2px;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  &__button-close {
    align-self: flex-start;
    border: none;
    background: none;
    color: #babdc9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
