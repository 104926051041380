<template>
  <div class="information-about-ticket">
    <h1 class="main__title information-about-ticket__title">
      {{ translates.title.text }}
    </h1>
    <h3 class="information-about-ticket__subtitle main__subtitle">
      {{ translates.subtitle.text }}
    </h3>

    <label class="information-about-ticket__label">
      {{ translates.attractionsQuestion.text }}
    </label>
    <VRadioGroup
      v-model="hasAttractions"
      name="hasAttractions"
      class="information-about-ticket__radio-group"
    >
      <template #default="{ on, attrs }">
        <VRadioButton :label="translates.yesText.text" :value="true" v-bind="attrs" v-on="on" />
        <VRadioButton :label="translates.noText.text" :value="false" v-bind="attrs" v-on="on" />
      </template>
    </VRadioGroup>

    <template v-if="hasAttractions">
      <label class="information-about-ticket__label">
        {{ translates.contentAttractionsText1.text }}
        <p class="information-about-ticket__label-description">
          {{ translates.contentAttractionsText2.text }}
        </p>
      </label>
      <p v-if="$v.localAttractionList.$error" class="information-about-ticket__error-text">
        {{ translates.attractionsError.text }}
      </p>
      <div class="information-about-ticket__attraction-card-list">
        <Card
          v-for="(attraction, index) in localAttractionList"
          :key="attraction.id"
          v-bind="attraction"
          :index="index"
          :total-count="localAttractionList.length"
          :translates="translates"
          class="information-about-ticket__attraction-card"
          @edit="editAttraction(attraction)"
          @remove="removeAttraction(attraction)"
          @move-up="moveUp(attraction)"
          @move-down="moveDown(attraction)"
        />
      </div>

      <VButton
        class="information-about-ticket__attraction-create"
        text
        @click="openCreateModal"
      >
        {{ translates.attractionsAdd.text }}
      </VButton>
    </template>

    <div class="information-about-ticket__footer">
      <VButton
        class="information-about-ticket__save"
        base
        @click="saveAllAttractions"
      >
        {{ translates.saveBtn.text }}
      </VButton>
    </div>

    <CreateModal
      v-model="showCreateModal"
      :attraction="activeAttraction"
      :translates="translates"
      @update:isActive="closeCreateModal"
      @save="saveAttraction"
    />
    <VConfirmDialog
      ref="deleteDialog"
      :title="translates.deleteAttractionTitle.text"
      :text="translates.deleteAttractionSubtitle.text"
      :confirm-text="translates.yesText.text"
      :cancel-text="translates.noText.text"
    />
  </div>
</template>

<script>
import VRadioButton from '@/components/controls/VRadioButton.vue'
import VRadioGroup from '@/components/controls/VRadioGroup.vue'
import VButton from '@/components/controls/VButton.vue'
import CreateModal from '@/components/Attractions/CreateModal.vue'
import Card from '@/components/Attractions/Card.vue'
import VConfirmDialog from '@/components/VConfirmDialog.vue'

import {
  camelCaseKeysToSnakeCase,
  snakeCaseKeysToCamelCase
} from '@/utils/formatters'

export default {
  name: 'ProductInformationAboutTicket',
  components: {
    VRadioButton,
    VRadioGroup,
    CreateModal,
    Card,
    VButton,
    VConfirmDialog
  },

  props: {
    tour: { type: Object, required: true },
    translates: { type: Object, required: true }
  },

  data () {
    return {
      hasAttractions: false,
      showCreateModal: false,
      activeAttraction: null,
      localAttractionList: []
    }
  },

  validations () {
    return {
      localAttractionList: { minLength: list => list.length > 0 }
    }
  },

  mounted () {
    if (this.tour.get_tour_attraction?.length) {
      this.hasAttractions = true
      this.localAttractionList = this.tour.get_tour_attraction.map(
        (attraction) => {
          return {
            ...snakeCaseKeysToCamelCase(attraction)
          }
        }
      )
    }
  },

  methods: {
    moveUp (attraction) {
      const index = this.localAttractionList.findIndex(attractionItem => attractionItem.id === attraction.id)

      if (index === 0) {
        return
      }
      this.localAttractionList[index] = this.localAttractionList[index - 1]
      this.localAttractionList[index - 1] = attraction
      this.localAttractionList = [...this.localAttractionList]
    },

    moveDown (attraction) {
      const index = this.localAttractionList.findIndex(attractionItem => attractionItem.id === attraction.id)

      if (index === this.localAttractionList.length - 1) {
        return
      }
      this.localAttractionList[index] = this.localAttractionList[index + 1]
      this.localAttractionList[index + 1] = attraction
      this.localAttractionList = [...this.localAttractionList]
    },

    openCreateModal () {
      this.showCreateModal = true
    },

    closeCreateModal () {
      this.showCreateModal = false
      this.activeAttraction = null
    },

    saveAttraction (attraction) {
      if (this.activeAttraction) {
        this.localAttractionList = this.localAttractionList.map(
          (attractionItem) => {
            return attractionItem.id === attraction.id
              ? attraction
              : attractionItem
          }
        )
      } else {
        this.localAttractionList.push(attraction)
        this.$v.localAttractionList.$touch()
      }
      this.showCreateModal = false
      this.activeAttraction = null
    },

    editAttraction (attraction) {
      this.showCreateModal = true
      this.activeAttraction = attraction
    },

    async removeAttraction (attraction) {
      const needDelete = await this.$refs.deleteDialog.open()

      if (needDelete) {
        this.localAttractionList = this.localAttractionList.filter(
          attractionItem => attraction.id !== attractionItem.id
        )
        this.$v.localAttractionList.$touch()
      }
    },

    postAttractions (attraction) {
      return this.$axios.post('api/v1/add-tour-attraction/',
        { attraction },
        {
          params: { tour_id: this.tour.id }
        }
      )
    },

    saveAllAttractions () {
      this.$v.$touch()
      const formattedAttractions = camelCaseKeysToSnakeCase(this.localAttractionList)

      if (!this.hasAttractions) {
        this.$emit('save', [])
      } else if (!this.$v.localAttractionList.$invalid) {
        this.$emit('save', formattedAttractions)
      }
    }
  }
}
</script>

<style lang="scss">
.information-about-ticket {
  &__title {
    margin-bottom: 11px;
  }

  &__subtitle {
    max-width: 546px;
    line-height: 21px !important;
    font-weight: 500;
    margin-bottom: 54px;
  }

  &__radio-group {
    margin-bottom: 36px;
  }

  &__attraction-card-list {
    margin-top: 38px;
    width: 100%;
  }

  &__attraction-card {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__attraction-create {
    margin-top: 28px;
    font-size: 18px;
    font-weight: 600;
  }

  &__label {
    display: block;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 12px;
  }

  &__label-description {
    margin-top: 6px;
    font-weight: 500;
  }

  &__footer {
    display: flex;
    padding-top: 64px;
  }

  &__save {
    height: 48px;
    padding: 0 20px;
  }

  &__error-text {
    color: $red;
    font-size: 18px;
    font-weight: 500;
  }
}
</style>
