var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pricing-change-modal-first-page" }, [
    _c("div", { staticClass: "pricing-change-modal-first-page__title" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.isPerson
              ? _vm.translations.popupPersonFirstPageTitle.text
              : _vm.translations.popupGroupFirstPageTitle.text
          ) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pricing-change-modal-first-page__subtitle" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.isPerson
              ? _vm.translations.popupPersonFirstPageSubtitle.text
              : _vm.translations.popupGroupFirstPageSubtitle.text
          ) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pricing-change-modal-first-page__content" }, [
      _c("div", { staticClass: "pricing-change-modal-first-page__item" }, [
        _c("div", { staticClass: "dot dot_orange" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pricing-change-modal-first-page__item--description" },
          [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.isPerson
                    ? _vm.translations.popupPersonFirstPageItem1.text
                    : _vm.translations.popupGroupFirstPageItem1.text
                ) +
                "\n      "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pricing-change-modal-first-page__item" }, [
        _c("div", { staticClass: "dot dot_orange" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pricing-change-modal-first-page__item--description" },
          [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.isPerson
                    ? _vm.translations.popupPersonFirstPageItem2.text
                    : _vm.translations.popupGroupFirstPageItem2.text
                ) +
                "\n      "
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.isSpacialOfferNow
      ? _c("div", { staticClass: "pricing-change-modal-first-page__error" }, [
          _vm._v(
            "\n    " + _vm._s(_vm.translations.errorPricesChange.text) + "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "change-pricing-type__nav" },
      [
        _c("v-btn", {
          attrs: { outline: "", name: _vm.translations.popupNavCancel.text },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nav__pagination row" },
          _vm._l(_vm.pageCount, function (index) {
            return _c("div", { key: `pageNum${index}` }, [
              _c("div", {
                staticClass: "dot dot_page",
                class: { dot_page_active: index - 1 == _vm.currentPageIndex },
              }),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("v-btn", {
          staticClass: "nav__next",
          attrs: {
            base: "",
            name: _vm.translations.popupNavNext.text,
            disabled: _vm.isSpacialOfferNow,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("next")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }