<template>
  <div>
    <div class="translations__content">
      <div class="translate-your-product">
        <div class="translate-your-product__title">
          {{ translations.title.text }}
        </div>
        <div class="translate-your-product__subtitle">
          {{ translations.subtitle.text }}
        </div>
        <div class="translate-your-product__description">
          {{ translations.description.text }}
        </div>
        <v-btn base :name="translations.startBtn.text"  @click="translateSelect = !translateSelect"/>
      </div>
      <div class="info">
        <div class="info__icon">
          <img src="@/assets/img/svg/translation-planet.svg" alt="translation-planet">
        </div>
        <div class="info__title">
          {{ translations.infotypeTitle.text }}
        </div>
        <div class="info__description">
          {{ translations.infotypeDescription.text }}
        </div>
      </div>
    </div>
    <translateSelect
      :is-active="translateSelect"
      :select-language-translate="selectTranslations"
      :tour="tour"
      @close="translateSelect = !translateSelect"
    />
  </div>
</template>

<script>
import VBtn from '@/components/controls/VBtn.vue'
import translateSelect from '@/components/pop-up/translateSelect.vue'
export default {
  components: {
    VBtn,
    translateSelect
  },
  props: {
    tour: { type: Object, default: () => ({}) },
    translations: { type: Object, default: () => ({}) },
    selectTranslations: { type: Object, default: () => ({}) }
  },
  data: () => ({
    translateSelect: false
  })
}
</script>

<style lang="scss">
.translations {
  &__content {
    display: flex;
    align-items: center;
    .translate-your-product {
      margin-right: 57px;
      width: 580px;
      &__title {
        margin-bottom: 8px;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
      }
      &__subtitle {
        margin-bottom: 16px;
      }
      &__description {
        margin-bottom: 50px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
      }
      .start-translating {
        width: 200px;
      }
    }
    .info {
      padding-left: 15px;
      padding-top: 18px;
      border: 2px solid $orange;
      border-radius: 8px;
      width: 300px;
      height: 230px;
      &__icon {
        margin-bottom: 11px;
      }
      &__title {
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
}
</style>