<template>
  <div class="container">
    <div class="container__holder">
      <div class="container__title">{{ translations.informationDisplayedOnVoucher.text }}</div>
      <div class="container__text">
        {{ translations.importantInformation.text }}
        <span class="pre-wrap">{{ description }}</span>
        <div
          v-if="isDescriptionLarge"
          class="text-collapse-toggle-button"
          @click="isCollapsedDescription = !isCollapsedDescription"
        >
          {{ isCollapsedDescription ? 'Show all' : 'Show less' }}
          <div class="text-collapse-toggle-button__show-icon">
            <img v-if="isCollapsedDescription" src="@/assets/img/svg/show-all-icon.svg" alt="Show all" />
            <img v-else src="@/assets/img/svg/show-less-icon.svg" alt="Show less" />
          </div>
        </div>
      </div>
      <div class="container__text">
        {{ translations.leavingInformation.text }}
        <span class="pre-wrap">{{ leaving }}</span>
        <div
          v-if="isLeavingLarge"
          class="text-collapse-toggle-button"
          @click="isCollapsedLeaving = !isCollapsedLeaving"
        >
          {{ isCollapsedLeaving ? 'Show all' : 'Show less' }}
          <div class="text-collapse-toggle-button__show-icon">
            <img v-if="isCollapsedLeaving" src="@/assets/img/svg/show-all-icon.svg" alt="Show all" />
            <img v-else src="@/assets/img/svg/show-less-icon.svg" alt="Show less" />
          </div>
        </div>
      </div>
    </div>
    <nuxt-link :to="localePath(`/product/${tour.slug}/tickets/information-on-voucher`)" class="container__edit">
      <VBtn outline :name="translations.editBtn.text" />
    </nuxt-link>
  </div>
</template>

<script>
import VBtn from '@/components/controls/Btn.vue'

const MAX_LENGTH = 150

export default {
  components: { VBtn },
  props: {
    tour: { type: Object, default: () => {} },
    translations: {type: Object, default: () => ({}) }
  },
  data: () => ({
    isCollapsedDescription: true,
    isCollapsedLeaving: true
  }),
  computed: {
    isDescriptionLarge() {
      return this.tour.tour_important_info?.length >= MAX_LENGTH
    },
    isLeavingLarge() {
      return this.tour.tour_leaving?.length >= MAX_LENGTH
    },
    description() {
      if (!this.isDescriptionLarge) return this.tour.tour_important_info

      return this.isCollapsedDescription
        ? this.tour.tour_important_info.substring(0, MAX_LENGTH) + '...'
        : this.tour.tour_important_info
    },
    leaving() {
      if (!this.isLeavingLarge) return this.tour.tour_leaving
      return this.isCollapsedLeaving ? this.tour.tour_leaving.substring(0, MAX_LENGTH) + '...' : this.tour.tour_leaving
    }
  }
}
</script>
