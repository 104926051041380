<template>
  <label class="v-checkbox" :class="className">
    <input v-model="checkedModel" type="checkbox" class="v-checkbox__input" :disabled="disabled" :value="value" />
    <slot v-bind="{ active: checked, value }">
      <span class="v-checkbox__mark" />
      <slot name="label">
        <span class="v-checkbox__label">{{ label }}</span>
      </slot>
    </slot>
  </label>
</template>

<script>
const valueType = [Boolean, String, Object, Number, Array]

export default {
  name: 'VCheckbox',
  model: {
    prop: 'checked',
    event: 'update:checked'
  },

  props: {
    checked: { type: valueType, default: false },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: null },
    value: { type: valueType, default: null },
    className: { type: String, default: '' }
  },

  computed: {
    checkedModel: {
      get() {
        return this.checked
      },
      set(value) {
        this.$emit('update:checked', value)
      }
    }
  }
}
</script>

<style lang="scss">
.v-checkbox {
  $block: &;
  $mark-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.blue-border {
    .v-checkbox__mark {
      border: 1px solid $light-blue-two;
    }
  }

  &__mark {
    position: relative;
    display: inline-block;
    height: $mark-size;
    width: $mark-size;
    border: 1px solid $gray;
    border-radius: 6px;
    background: $white;
    vertical-align: middle;
    overflow: hidden;
    margin-right: 10px;

    &:before {
      content: '';
      display: block;
      visibility: hidden;
      width: 100%;
      height: 100%;
      background-color: $primary;
      background-image: url('~/assets/img/svg/check-mark.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 12px 7px;
    }
  }

  &__input {
    position: absolute;
    height: 1px;
    width: 1px;
    padding: 0;
    margin: -1px;
    clip: rect(0 0 0 0);

    &:checked ~ #{$block}__mark {
      &:before {
        visibility: visible;
      }
    }

    &:disabled {
      ~ #{$block}__mark {
        opacity: 0.5;
      }
    }
  }
}
</style>
