<template>
  <VDialog v-model="modelVisible" class="popup-cancel-booking" :title="title" size="large">
    <div class="popup-cancel-booking__content">
      <div class="popup-cancel-booking__field">
        {{ content }}
      </div>
    </div>

    <template #footer>
      <div class="popup-cancel-booking__footer">
        <VButton outline @click="cancel">{{ cancelText }}</VButton>
        <VButton base @click="save">{{ saveText }}</VButton>
      </div>
    </template>
  </VDialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VButton from '@/components/controls/VButton.vue'

export default {
  name: 'ProductInfo',
  components: { VDialog, VButton },

  model: {
    prop: 'visible',
    event: 'update:visible'
  },

  props: {
    visible: { type: Boolean, default: false },
    title: {type: String, default: ''},
    content: {type: String, default: ''},
    cancelText: {type: String, default: ''},
    saveText: {type: String, default: ''}
  },

  data() {
    return {
    }
  },

  computed: {
    modelVisible: {
      get() {
        return this.visible
      },
      set(visible) {
        this.$emit('update:visible', visible)
      }
    }
  },
  methods: {
    cancel() {
      this.modelVisible = false
      return true
    },
    save() {
      this.$emit('save', {})
    }
  }
}
</script>

<style lang="scss">
.popup-cancel-booking {
  &__content {
    max-width: 617px;
    padding-top: 30px;
  }

  &__input {
    margin: 0;
  }

  &__select {
    .selection__preview {
      width: 100%;
    }
  }

  &__label {
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    margin-bottom: 15px;
    display: block;
  }

  &__field {
    margin-bottom: 30px;
  }

  &__note {
    font-size: 14px;
    line-height: 16px;
    color: #999999;
    font-weight: 600;
    margin-bottom: 30px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}
</style>
