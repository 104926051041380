var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showPickUp
    ? _c("div", { staticClass: "preview__section pick-up" }, [
        _c("div", { staticClass: "preview__title" }, [
          _vm._v(
            "\n    " + _vm._s(_vm.translations.textPickupPoint.text) + "\n  "
          ),
        ]),
        _vm._v(" "),
        _vm.isPickUpOrMeet && _vm.isTravelServices
          ? _c("div", { staticClass: "preview__text" }, [
              _vm._v(
                "You can head directly to the meeting point, or request pickup."
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.hasOwnPickup
          ? _c("div", { staticClass: "preview__text" }, [
              _vm._v(
                "Pickup is included. The activity operator will contact you to agree on a pickup address."
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.pickUpDescription
          ? _c("div", { staticClass: "preview__text" }, [
              _vm._v(_vm._s(_vm.pickUpDescription)),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }