<template>
  <div class="selection" :class="setClass" @mouseleave="isOpen = false">
    <div
      class="selection__preview"
      :class="{ selection__preview_open: isOpen, 'selection__preview--error': error }"
      @click="isOpen = !isOpen"
    >
      <div v-if="defaultName" class="name">
        {{ translates ? translates[defaultName] : defaultName }}
      </div>
      <div v-else class="name selection__placeholder">
        {{ placeholder }}
      </div>
      <div class="arrow">
        <img src="@/assets/img/svg/drop-down-arrow.svg" alt="drop">
      </div>
    </div>
    <div v-if="isOpen && !disabled" class="selection__expand">
      <div
        v-for="(item, index) in items"
        :key="`item-${index}`"
        class="selection__item"
        :class="{ item_active: item.is_active }"
        @click="activeByName(item, items)"
      >
        {{ translates ? translates[item.name] : item.name }}
      </div>
    </div>
    <div v-if="isOpen && pickup" class="selection__expand">
      <div
        v-for="(item, index) in items"
        :key="`item-${index}`"
        class="selection__item"
        :class="{ item_active: item.is_active }"
        @click="activeByPickup(index, item, items)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/selection.scss'

export default {
  name: 'Selection',
  props: {
    type: String,
    defaultName: [String, Number],
    description: String,
    // Button type
    default: Boolean,
    items: Array,
    disabled: Boolean,
    pickup: Boolean,
    translates: { type: Object, default: null },
    placeholder: { type: String, default: '' },
    error: { type: Boolean, default: false }
  },
  data () {
    return {
      isOpen: false,
      transition: 'selectionAnimation'
    }
  },
  computed: {
    setClass () {
      const typeBtn = this.default
        ? 'default'
        : this.disabled
          ? 'disabled'
          : this.pickup
            ? 'pickup'
            : ''
      return 'selection_' + typeBtn
    }
  },
  methods: {
    activeByName (i, items) {
      let newName = this.defaultName
      for (const k in items) {
        items[k].is_active = false
      }
      i.is_active = true
      newName = i.name
      this.$emit('name', newName)
      this.isOpen = false
    },
    activeByPickup (index, i, items) {
      let newName = this.defaultName
      for (const k in items) {
        items[k].is_active = false
      }
      i.is_active = true
      newName = i.name
      this.$emit('pickupDistance', newName, index)
      this.isOpen = false
    }
  }
}
</script>
