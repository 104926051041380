var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "booking-process" }, [
    _vm.loadingPage
      ? _c(
          "div",
          [
            _c("local-loader"),
            _vm._v(" "),
            _c("div", { staticClass: "booking-process__loading-title" }, [
              _vm._v(_vm._s(_vm.translations.loading.text)),
            ]),
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "div",
              { staticClass: "booking-process__item" },
              [
                _c("BackLink"),
                _vm._v(" "),
                _c("div", { staticClass: "booking-process__title" }, [
                  _vm._v(_vm._s(_vm.translations.title.text)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "booking-process__content-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.translations.contentCutoff.text) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "booking-process__subtitle" }, [
                  _c(
                    "div",
                    { staticClass: "booking-process__subtitle--text row" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.translations.cutoffDescription.text) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "booking-process__subtitle--learn-more",
                      on: {
                        click: function ($event) {
                          return _vm.learnMoreAboutBooking()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.translations.learnMore.text) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("v-select", {
                  class: { "invalid-select": _vm.$v.timesName.$error },
                  attrs: {
                    items: _vm.times,
                    placeholder: _vm.translations.selectTime.text,
                  },
                  on: { input: _vm.changePeriodName },
                  model: {
                    value: _vm.$v.timesName.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.timesName, "$model", $$v)
                    },
                    expression: "$v.timesName.$model",
                  },
                }),
                _vm._v(" "),
                _vm.$v.timesName.$error
                  ? _c("div", { staticClass: "validation-error" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.translations.requiredTime.text) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.times[0].is_active
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "booking-process__title-input" },
                        [_vm._v(_vm._s(_vm.translations.setCutOffTime.text))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "booking-process__cut-off-time" },
                        [
                          _c("date-picker", {
                            staticClass:
                              "booking-process__cut-off-time--custom-detepicker",
                            class: {
                              "input-error": _vm.$v.oneSetDedLine.$error,
                            },
                            attrs: {
                              type: "time",
                              "time-picker-options": _vm.timePickerOptions,
                              format: _vm.timeFormat,
                              "value-type": "format",
                              "show-second": false,
                              placeholder: `0`,
                              editable: false,
                            },
                            model: {
                              value: _vm.$v.oneSetDedLine.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.oneSetDedLine, "$model", $$v)
                              },
                              expression: "$v.oneSetDedLine.$model",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-select", {
                            staticClass: "booking-process__cut-off-time--day",
                            attrs: { items: _vm.localizedDaysCutOff },
                            model: {
                              value: _vm.$v.cutOffDay.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.cutOffDay, "$model", $$v)
                              },
                              expression: "$v.cutOffDay.$model",
                            },
                          }),
                          _vm._v(" "),
                          _vm.$v.oneSetDedLine.$error
                            ? _c("div", { staticClass: "validation-error" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.translations.deadlineError.text
                                    ) +
                                    "\n          "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "booking-process__title-input" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.translations.contentDeadline.text) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input-fields", {
                          class: { "input-error": _vm.$v.deadline.$error },
                          attrs: {
                            onkeypress:
                              "return event.charCode >= 48 && event.charCode <= 57",
                            onpaste: "return false;",
                          },
                          on: { input: _vm.checkHoursInAdvance },
                          nativeOn: {
                            blur: function ($event) {
                              return _vm.checkHoursInAdvance.apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.$v.deadline.$model,
                            callback: function ($$v) {
                              _vm.$set(_vm.$v.deadline, "$model", $$v)
                            },
                            expression: "$v.deadline.$model",
                          },
                        }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "booking-process__subtitle booking-process__subtitle--text",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.translations.cutOffNote.text) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.$v.deadline.$error && _vm.times[1].is_active
                          ? _c("div", { staticClass: "validation-error" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.translations.deadlineError.text) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "booking-process__item" },
              [
                _c("div", { staticClass: "booking-process__content-title" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.translations.contentConfirmationMethod.text) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "booking-process__subtitle" }, [
                  _c(
                    "div",
                    { staticClass: "booking-process__subtitle--text row" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.translations.confirmationMethodDescription.text
                          ) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "booking-process__subtitle--learn-more",
                      on: {
                        click: function ($event) {
                          return _vm.learnMoreAboutConfirmation()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.translations.learnMore.text) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("v-select", {
                  class: { "invalid-select": _vm.$v.confirmationsName.$error },
                  attrs: {
                    items: _vm.confirmations,
                    placeholder: _vm.translations.descriptionsMethod.text,
                  },
                  on: { input: _vm.changeConfirmationsName },
                  model: {
                    value: _vm.$v.confirmationsName.$model,
                    callback: function ($$v) {
                      _vm.$set(_vm.$v.confirmationsName, "$model", $$v)
                    },
                    expression: "$v.confirmationsName.$model",
                  },
                }),
                _vm._v(" "),
                _vm.$v.confirmationsName.$error
                  ? _c("div", { staticClass: "validation-error" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.translations.requiredMethod.text) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isInstantConfirmation
                  ? _c(
                      "div",
                      {
                        staticClass: "booking-process__notifications",
                        on: { click: _vm.newNotifications },
                      },
                      [
                        _c("Checkbox", {
                          attrs: {
                            id: "email-notification",
                            default: "",
                            name: _vm.translations.notificationCheckbox.text,
                            checked: _vm.notifications,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm.isManualConfirmation
                  ? _c("error-banner", {
                      staticClass: "booking-process__info-banner",
                      attrs: {
                        errors: [
                          _vm.translations.manualConfirmationErrorDescription
                            .text,
                        ],
                      },
                    })
                  : _vm.isInstantThenManualConfirmation
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          { staticClass: "booking-process__title-input" },
                          [
                            _vm._v(
                              _vm._s(_vm.translations.cutOffTimeTitle.text)
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "booking-process__times-manual" },
                          [
                            _c("input-fields", {
                              class: {
                                "input-error": _vm.$v.manualTime.$error,
                              },
                              attrs: {
                                onkeypress:
                                  "return event.charCode >= 48 && event.charCode <= 57",
                                onpaste: "return false;",
                              },
                              model: {
                                value: _vm.$v.manualTime.$model,
                                callback: function ($$v) {
                                  _vm.$set(_vm.$v.manualTime, "$model", $$v)
                                },
                                expression: "$v.manualTime.$model",
                              },
                            }),
                            _vm._v(" "),
                            _c("v-select", {
                              attrs: { items: _vm.timesManual },
                              model: {
                                value: _vm.timesNameManual,
                                callback: function ($$v) {
                                  _vm.timesNameManual = $$v
                                },
                                expression: "timesNameManual",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.$v.manualTime.$error
                          ? _c("div", { staticClass: "validation-error" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.parseTranslate(
                                      _vm.translations.manualConfirmationError
                                        .text,
                                      _vm.getCutOffTime,
                                      "1",
                                      _vm.getTimesManualMaxValue
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("error-banner", {
                          staticClass: "booking-process__info-banner",
                          attrs: {
                            errors: [
                              _vm.translations
                                .manualConfirmationErrorDescription2.text,
                            ],
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-btn", {
              attrs: {
                base: "",
                name: _vm.translations.saveBtn.text,
                loading: _vm.loading,
                "loading-name": _vm.translations.bthSaved.text,
              },
              on: {
                click: function ($event) {
                  return _vm.saveProcess()
                },
              },
            }),
            _vm._v(" "),
            _vm.bookingLearnMore
              ? _c("bookingLearnMore", {
                  attrs: {
                    "is-active": _vm.bookingLearnMore,
                    "cutoff-translate": _vm.bookingProcessCutoffPageFields,
                  },
                  on: {
                    close: function ($event) {
                      _vm.bookingLearnMore = !_vm.bookingLearnMore
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.confirmationLearnMore
              ? _c("confirmationLearnMore", {
                  attrs: {
                    "is-active": _vm.confirmationLearnMore,
                    "confirmation-translate":
                      _vm.bookingProcessConfirmationPageFields,
                  },
                  on: {
                    close: function ($event) {
                      _vm.confirmationLearnMore = !_vm.confirmationLearnMore
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }