<template>
  <VDialog v-model="modelVisible">
    <div class="edit-option-pop-up">
      <AddAttributes
        :attributes="attributes"
        :options-product-details="options"
        :translations="translations"
        :tour="tour"
        :text-btn-save="translations.saveBtn.text"
        @save="save"
        @close="close"
        @back="close"
      />
    </div>
  </VDialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue';
import AddAttributes from '@/components/price/AddAttributes.vue';

export default {
  components: { VDialog, AddAttributes },
  props: {
    isActive: { type: Boolean, default: false },
    tour: { type: Object, default: () => ({}) },
    tourLang: { type: String, default: '' },
    optionId: { type: Number, default: 0 },
    editOptionInfo: { type: Object, default: () => ({}) },
    translations: { type: Object, default: () => ({}) },
  },
  data: () => ({
    attributes: {
      optionName: '',
      hideLanguages: true,
      durationName: '',
      optionPickupIncluded: true,
      tourLangs: [],
    },
    options: [],
  }),
  async fetch() {
    this.attributes.optionName = this.editOptionInfo.name;
    this.attributes.durationName = this.translations.durationItem1.text;
    this.attributes.optionPickupIncluded = this.editOptionInfo.is_pick_up_include_in_price;
    this.attributes.tourLangs = this.tour.languages;
    for (let i = 0; i < this.attributes.tourLangs.length; i++) {
      this.attributes.tourLangs[i].is_active = false;
    }
    for (let ol = 0; ol < this.editOptionInfo.languages.length; ol++) {
      for (let i = 0; i < this.attributes.tourLangs.length; i++) {
        if (this.editOptionInfo.languages[ol] === this.attributes.tourLangs[i].id) {
          this.attributes.tourLangs[i].is_active = true;
          break;
        }
      }
    }
    this.attributes.hideLanguages = this.attributes.tourLangs.every((lang) => lang.is_active);

    const productDetails = await this.$axios.$get(
      `/api/v1/get-option-additional-info-types/?language=${this.tourLang}`
    );
    this.options = productDetails.map((productDetail) => {
      const detailInOption = this.editOptionInfo.additional_info.find(
        (option) => option.info_type.id === productDetail.id
      );
      return {
        id: productDetail.id,
        title: productDetail.title,
        is_active: Boolean(detailInOption),
        name: detailInOption ? detailInOption.name : '',
        description: detailInOption ? detailInOption.description : '',
        details_id: detailInOption ? detailInOption.id : null,
        name_error: false,
        description_error: false,
      };
    });
  },
  computed: {
    modelVisible: {
      get() {
        return this.isActive;
      },
      set(visible) {
        this.$emit('update:isActive', visible);
      },
    },
  },
  methods: {
    close() {
      this.modelVisible = false;
    },

    deleteOptionAdditionalInfo(id) {
      return this.$axios.$post(`/api/v1/act-option-additional-info/?info_id=${id}&is_delete=true`);
    },

    addOptionAdditionalInfo({ optionId, typeId, name, description }) {
      const data = {
        option_id: optionId,
        type_id: typeId,
        name,
        description,
      };

      return this.$axios.$post(`/api/v1/act-option-additional-info/`, null, { params: data });
    },

    addOption() {
      const activeOptionLang = this.attributes.tourLangs
        .filter((lang) => lang.is_active)
        .map((lang) => lang.id)
        .join(',');

      return this.$axios.$post(`/api/v1/add-tour-schedule-option/`, null, {
        params: {
          option_id: this.optionId,
          name: this.attributes.optionName,
          tour_id: this.tour.id,
          languages: activeOptionLang,
          language: this.tour.input_language.abbreviation.toLowerCase(),
          is_pick_up_included: this.attributes.optionPickupIncluded,
        },
      });
    },

    async save(attributes) {
      this.$emit('loading');
      this.attributes = attributes;
      const newOption = await this.addOption();

      for (let i = 0; i < this.options.length; i++) {
        const localOption = this.options[i];
        const optionInSaved = this.editOptionInfo.additional_info.find(
          (option) => option.info_type.id === localOption.id
        );

        const newAdditionalOption = {
          optionId: newOption.id,
          typeId: localOption.id,
          name:
            localOption.id === 4
              ? `${this.attributes.durationTime?.toString()} ${this.attributes.durationName}`
              : localOption.name,
          description: localOption.description,
        };
        const isUpdated =
          optionInSaved &&
          (newAdditionalOption.description !== optionInSaved.description ||
            newAdditionalOption.name !== optionInSaved.name);

        if (optionInSaved && (!localOption.is_active || isUpdated)) {
          await this.deleteOptionAdditionalInfo(localOption.details_id);
        }

        if (localOption.is_active && (isUpdated || !optionInSaved)) {
          await this.addOptionAdditionalInfo(newAdditionalOption);
        }
      }
      this.$router.go(this.localePath('/'));
    },
  },
};
</script>

<style lang="scss">
.edit-option-pop-up {
  position: relative;
  background: #fff;
  width: 800px;
}
</style>
