var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "pop-up-performance-details" }, [
          _c("div", { staticClass: "head-row" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "head-row__title" }, [
                _vm._v(_vm._s(_vm.translations.yourProductNeedsAttention.text)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "pop-up__close",
                  on: { click: _vm.closeWindow },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/svg/close.svg"),
                      alt: "close",
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "head-row__subtitle" }, [
              _vm._v(_vm._s(_vm.translations.provideMissingInformation.text)),
            ]),
          ]),
          _vm._v(" "),
          _vm.isShowPayoutMethod
            ? _c("div", { staticClass: "item-performance-details" }, [
                _c("div", { staticClass: "item-performance-details__name" }, [
                  _vm._v(_vm._s(_vm.translations.selectPayoutMethod.text)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-performance-details__details" },
                  [
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to: _vm.localePath(`/finance/payout-methods`),
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.translations.details.text))]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isShowCompanyInfo
            ? _c("div", { staticClass: "item-performance-details" }, [
                _c("div", { staticClass: "item-performance-details__name" }, [
                  _vm._v(
                    _vm._s(_vm.translations.provideInformationAboutCompany.text)
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "item-performance-details__details" },
                  [
                    _c(
                      "nuxt-link",
                      {
                        attrs: {
                          to: _vm.localePath(
                            _vm.isSellerCompany
                              ? "/account/business-information"
                              : "/account/public-information"
                          ),
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.translations.details.text))]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.applyTour || true
            ? _c(
                "div",
                _vm._l(_vm.missingDetailsList, function (detailLink, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "item-performance-details" },
                    [
                      _c(
                        "div",
                        { staticClass: "item-performance-details__name" },
                        [_vm._v(_vm._s(detailLink.text))]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "item-performance-details__details" },
                        [
                          _c(
                            "nuxt-link",
                            {
                              attrs: { to: detailLink.link, target: "_blank" },
                            },
                            [_vm._v("Details")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }