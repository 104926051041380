var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ticket-mobile-preview" }, [
    _c("div", { staticClass: "ticket-mobile-preview__header" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/mobile-header.jpg"), alt: "" },
      }),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.translations.bookingInformation.text))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ticket-mobile-preview__content" }, [
      _c("div", { staticClass: "ticket-mobile-preview__main" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__booking-code" }, [
          _vm._v("VIG68028109"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__tour-name" }, [
          _vm._v(_vm._s(_vm.tour.name)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__info" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/svg/tour-date-gray.svg"),
              alt: "tour-date",
            },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "ticket-mobile-preview__info-text" }, [
            _vm._v(_vm._s(_vm.formattedDate)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__info" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/svg/tour-time-gray.svg"),
              alt: "tour-start-time",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "ticket-mobile-preview__info-text" }, [
            _vm._v(_vm._s(_vm.tourStarting)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__info" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/svg/tour-duration-gray.svg"),
              alt: "tour-duration",
            },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "ticket-mobile-preview__info-text" }, [
            _vm._v(
              _vm._s(_vm.translations.duration.text) +
                ": " +
                _vm._s(_vm.durationTitle)
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.language
          ? _c("div", { staticClass: "ticket-mobile-preview__info" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/tour-language-gray.svg"),
                  alt: "tour-language",
                },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "ticket-mobile-preview__info-text" }, [
                _vm._v(_vm._s(_vm.language)),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__info" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/svg/tour-amount-gray.svg"),
              alt: "tour-members-amount",
            },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "ticket-mobile-preview__info-text" }, [
            _vm._v("1 " + _vm._s(_vm.memberAgeCategory)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__info" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/img/svg/tour-buyer-gray.svg"),
              alt: "tour-buyer",
            },
          }),
          _vm._v(" "),
          _c("p", { staticClass: "ticket-mobile-preview__info-text" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.translations.mobileBuyer.text) +
                "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__calendar-button" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.addToCalendarButton.text) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _vm.reservationSystem
          ? _c("p", { staticClass: "ticket-mobile-preview__reference" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.translations.activityProviderReference.text) +
                  "\n        1bsadcdihjsdoa-ajxjskdj-sdadjcjsi-7236rnskdna\n      "
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ticket-mobile-preview__warning" }, [
        _c("img", {
          attrs: { src: require("@/assets/img/svg/info.svg"), alt: "info" },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "ticket-mobile-preview__warning-text" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.mobileInfoType.text) +
              "\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ticket-mobile-preview__warning" }, [
        _c("img", {
          attrs: { src: require("@/assets/img/svg/info.svg"), alt: "info" },
        }),
        _vm._v(" "),
        _c("p", { staticClass: "ticket-mobile-preview__warning-text" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.textRememberPassport.text) +
              "\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ticket-mobile-preview__options" }, [
        _c("div", { staticClass: "ticket-mobile-preview__options-item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.mobilePage1.text) +
              "\n        "
          ),
          _c("img", {
            staticClass: "arrow",
            attrs: {
              src: require("@/assets/img/svg/arrow-right.svg"),
              alt: "arrow-right",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__options-item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.mobilePage4.text) +
              "\n        "
          ),
          _c("img", {
            staticClass: "arrow",
            attrs: {
              src: require("@/assets/img/svg/arrow-right.svg"),
              alt: "arrow-right",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__options-item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.mobilePage5.text) +
              "\n        "
          ),
          _c("img", {
            staticClass: "arrow",
            attrs: {
              src: require("@/assets/img/svg/arrow-right.svg"),
              alt: "arrow-right",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__options-item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.mobilePage6.text) +
              "\n        "
          ),
          _c("img", {
            staticClass: "arrow",
            attrs: {
              src: require("@/assets/img/svg/arrow-right.svg"),
              alt: "arrow-right",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__options-item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.mobilePage7.text) +
              "\n        "
          ),
          _c("img", {
            staticClass: "arrow",
            attrs: {
              src: require("@/assets/img/svg/arrow-right.svg"),
              alt: "arrow-right",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__options-item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.mobilePage8.text) +
              "\n        "
          ),
          _c("img", {
            staticClass: "arrow",
            attrs: {
              src: require("@/assets/img/svg/arrow-right.svg"),
              alt: "arrow-right",
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__options-item" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.mobilePage2.text) +
              "\n        "
          ),
          _c("img", {
            staticClass: "arrow",
            attrs: {
              src: require("@/assets/img/svg/arrow-right.svg"),
              alt: "arrow-right",
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ticket-mobile-preview__booking" }, [
        _c("div", { staticClass: "ticket-mobile-preview__title" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.mobileManagement.text) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "ticket-mobile-preview__text" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.cancellationText.text) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "ticket-mobile-preview__booking-item" }, [
            _c("img", {
              staticClass: "image",
              attrs: {
                src: require("@/assets/img/svg/transfer.svg"),
                alt: "transfer",
              },
            }),
            _vm._v(
              "\n          " +
                _vm._s(_vm.translations.mobileOptions1.text) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ticket-mobile-preview__booking-item" }, [
            _c("img", {
              staticClass: "image",
              attrs: {
                src: require("@/assets/img/svg/cancel-trashbox.svg"),
                alt: "cancel-trashbox",
              },
            }),
            _vm._v(
              "\n          " +
                _vm._s(_vm.translations.mobileOptions2.text) +
                "\n        "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ticket-mobile-preview__operator" }, [
        _c("div", { staticClass: "ticket-mobile-preview__title" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.activityPperator.text) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__text" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.activityProvidedBy.text) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__title" }, [
          _vm._v(_vm._s(_vm.companyName)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__text" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.contactActivityOperator.text) +
              "\n      "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__contacts" }, [
          _c("div", { staticClass: "ticket-mobile-preview__contacts-item" }, [
            _c("img", {
              staticClass: "image",
              attrs: { src: require("@/assets/img/svg/voucher-phone.svg") },
            }),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "ticket-mobile-preview__contacts-text" },
              [_vm._v(_vm._s(_vm.translations.contactCall.text))]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ticket-mobile-preview__contacts-item" }, [
            _c("img", {
              staticClass: "image",
              attrs: { src: require("@/assets/img/svg/message.svg") },
            }),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "ticket-mobile-preview__contacts-text" },
              [_vm._v(_vm._s(_vm.translations.contactMessage.text))]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ticket-mobile-preview__text" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.visitHelpCenter.text) +
              "\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "ticket-mobile-preview__booking-item" }, [
          _c("img", {
            staticClass: "image",
            attrs: {
              src: require("@/assets/img/svg/transfer.svg"),
              alt: "transfer",
            },
          }),
          _vm._v(
            "\n        " + _vm._s(_vm.translations.helpCenter.text) + "\n      "
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ticket-mobile-preview__qr-code" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/svg/tour-qrcode.svg"),
          alt: "tour-qrcode",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "ticket-mobile-preview__qr-code__expand" }, [
        _c("img", {
          attrs: { src: require("@/assets/img/svg/expand.svg"), alt: "" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }