<template>
  <VDialog v-model="modelActive" :title="translations.historyTableTitle.text" dialog-classes="booking-history">
    <VLoader v-if="loading" />
    <VTable :items="formattedHistory" :headers="headers" class="booking-history__table"> </VTable>
    <div class="booking-history__footer">
      <VButton outline @click="close">{{ translations.buttonCancel.text }}</VButton>
      <VButton v-if="showRefundButton" base @click="refund">{{ translations.buttonRefund.text }}</VButton>
    </div>
  </VDialog>
</template>

<script>
import VTable from '@/components/common/VTable.vue';
import VDialog from '@/components/common/VDialog.vue';
import VButton from '@/components/controls/VButton.vue';
import VLoader from '@/components/Loader.vue';

import { format } from '@/utils/date';
import { BOOKING_STATUS_AMENDED, BOOKING_STATUS_AMENDMENT_REQUEST } from '@/constants/bookingStatus';

const ACTOR_TYPE_SELLER = 'seller';

export default {
  name: 'BookingsHistory',
  components: { VTable, VDialog, VButton, VLoader },
  props: {
    isVisible: { type: Boolean, default: false },
    id: { type: [String, Number], default: '' },
    translations: { type: Object, required: true },
    statuses: { type: Object, required: true },
    showRefundButton: { type: Boolean, default: false },
  },

  data() {
    return {
      historyData: [],
      loading: false,
    };
  },

  computed: {
    modelActive: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.$emit('update:isVisible', value);
      },
    },

    formattedHistory() {
      return this.historyData.map((action) => {
        const status = this.getStatus(action)?.toLowerCase() || '';
        const statusFormatted = status.charAt(0).toUpperCase() + status.slice(1);
        return {
          ...action,
          dateTime: format(action.created_at, 'dd.MM.yyyy HH:mm'),
          status: statusFormatted || action.new_status,
          creator:
            action.actor_type === ACTOR_TYPE_SELLER
              ? this.translations.actorTypeSupplier.text
              : this.translations.actorTypeCustomer.text,
        };
      });
    },

    headers() {
      return [
        { text: this.translations.historyTableDate.text, value: 'dateTime', width: '107px' },
        { text: this.translations.historyTableStatus.text, value: 'status', width: '102px' },
        { text: this.translations.historyTableCreator.text, value: 'creator' },
        { text: this.translations.historyTableReference.text, value: 'confirmation_number' },
        { text: this.translations.historyTableAction.text, value: 'text', width: '50%' },
      ];
    },
  },

  watch: {
    id: {
      immediate: true,
      handler() {
        if (this.id) {
          this.loadHistory();
        } else {
          this.historyData = [];
        }
      },
    },
  },

  methods: {
    getStatus(historyItem) {
      const amendmentStatuses = [BOOKING_STATUS_AMENDED, BOOKING_STATUS_AMENDMENT_REQUEST];
      const rejectActions = ['pickup_changing_rejected', 'reschedule_rejected'];

      if (amendmentStatuses.includes(historyItem.new_amendment_status)) {
        if (rejectActions.includes(historyItem.action_type)) {
          return 'Rejected amendment';
        }
        return this.statuses[historyItem.new_amendment_status]?.translate;
      }

      return this.statuses[historyItem.new_status]?.translate;
    },

    loadHistory() {
      this.loading = true;
      const language = this.$cookies.get('i18n_redirected');

      this.$axios
        .$get('/api/v1/order_product_action/', {
          params: {
            order_product: this.id,
            language,
          },
        })
        .then((history) => {
          this.historyData = history;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.modelActive = false;
    },

    refund() {
      this.$emit('refund');
    },
  },
};
</script>

<style lang="scss">
.booking-history {
  max-width: 1025px;
  width: 100%;

  &__table {
    .v-table__item {
      font-size: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
      vertical-align: top;
    }

    .v-table__items:not(:last-child) {
      border: none;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 38px;
  }
}
</style>
