<template>
  <div class="booking-process">
    <div v-if="loadingPage">
      <local-loader />
      <div class="booking-process__loading-title">{{ translations.loading.text }}</div>
    </div>
    <div v-else>
      <div class="booking-process__item">
        <BackLink />
        <div class="booking-process__title">{{ translations.title.text }}</div>
        <div class="booking-process__content-title">
          {{ translations.contentCutoff.text }}
        </div>
        <div class="booking-process__subtitle">
          <div class="booking-process__subtitle--text row">
            {{ translations.cutoffDescription.text }}
          </div>
          <div class="booking-process__subtitle--learn-more" @click="learnMoreAboutBooking()">
            {{ translations.learnMore.text }}
          </div>
        </div>
        <v-select
          v-model="$v.timesName.$model"
          :items="times"
          :class="{ 'invalid-select': $v.timesName.$error }"
          :placeholder="translations.selectTime.text"
          @input="changePeriodName"
        />
        <div v-if="$v.timesName.$error" class="validation-error">
          {{ translations.requiredTime.text }}
        </div>

        <div v-if="times[0].is_active">
          <div class="booking-process__title-input">{{ translations.setCutOffTime.text }}</div>
          <div class="booking-process__cut-off-time">
            <date-picker
              v-model="$v.oneSetDedLine.$model"
              type="time"
              :time-picker-options="timePickerOptions"
              :format="timeFormat"
              value-type="format"
              :show-second="false"
              class="booking-process__cut-off-time--custom-detepicker"
              :class="{ 'input-error': $v.oneSetDedLine.$error }"
              :placeholder="`0`"
              :editable="false"
            />

            <v-select v-model="$v.cutOffDay.$model" :items="localizedDaysCutOff" class="booking-process__cut-off-time--day" />
            <div v-if="$v.oneSetDedLine.$error" class="validation-error">
              {{ translations.deadlineError.text }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="booking-process__title-input">
            {{ translations.contentDeadline.text }}
          </div>

          <input-fields
            v-model="$v.deadline.$model"
            @input="checkHoursInAdvance"
            @blur.native="checkHoursInAdvance"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            onpaste="return false;"
            :class="{ 'input-error': $v.deadline.$error }"
          />

          <br />
          <div class="booking-process__subtitle booking-process__subtitle--text">
            {{ translations.cutOffNote.text }}
          </div>

          <div v-if="$v.deadline.$error && times[1].is_active" class="validation-error">
            {{ translations.deadlineError.text }}
          </div>
        </div>
      </div>
      <div class="booking-process__item">
        <div class="booking-process__content-title">
          {{ translations.contentConfirmationMethod.text }}
        </div>
        <div class="booking-process__subtitle">
          <div class="booking-process__subtitle--text row">
            {{ translations.confirmationMethodDescription.text }}
          </div>
          <div class="booking-process__subtitle--learn-more" @click="learnMoreAboutConfirmation()">
            {{ translations.learnMore.text }}
          </div>
        </div>

        <v-select
          v-model="$v.confirmationsName.$model"
          :items="confirmations"
          :class="{ 'invalid-select': $v.confirmationsName.$error }"
          :placeholder="translations.descriptionsMethod.text"
          @input="changeConfirmationsName"
        />
        <div v-if="$v.confirmationsName.$error" class="validation-error">
          {{ translations.requiredMethod.text }}
        </div>

        <div v-if="isInstantConfirmation" class="booking-process__notifications" @click="newNotifications">
          <Checkbox
            id="email-notification"
            default
            :name="translations.notificationCheckbox.text"
            :checked="notifications"
          />
        </div>
        <error-banner
          v-else-if="isManualConfirmation"
          :errors="[translations.manualConfirmationErrorDescription.text]"
          class="booking-process__info-banner"
        />
        <div v-else-if="isInstantThenManualConfirmation">
          <div class="booking-process__title-input">{{ translations.cutOffTimeTitle.text }}</div>
          <div class="booking-process__times-manual">
            <input-fields
              v-model="$v.manualTime.$model"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              onpaste="return false;"
              :class="{ 'input-error': $v.manualTime.$error }"
            />
            <v-select v-model="timesNameManual" :items="timesManual" />
          </div>
          <div v-if="$v.manualTime.$error" class="validation-error">
            {{ parseTranslate(translations.manualConfirmationError.text, getCutOffTime, '1', getTimesManualMaxValue) }}
          </div>
          <error-banner
            :errors="[translations.manualConfirmationErrorDescription2.text]"
            class="booking-process__info-banner"
          />
        </div>
      </div>
      <v-btn
        base
        :name="translations.saveBtn.text"
        :loading="loading"
        :loading-name="translations.bthSaved.text"
        @click="saveProcess()"
      />

      <bookingLearnMore
        v-if="bookingLearnMore"
        :is-active="bookingLearnMore"
        :cutoff-translate="bookingProcessCutoffPageFields"
        @close="bookingLearnMore = !bookingLearnMore"
      />
      <confirmationLearnMore
        v-if="confirmationLearnMore"
        :is-active="confirmationLearnMore"
        :confirmation-translate="bookingProcessConfirmationPageFields"
        @close="confirmationLearnMore = !confirmationLearnMore"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { required, requiredIf, integer, minValue, maxValue } from 'vuelidate/lib/validators';
import { parseTranslate } from '@/utils/translations';
import 'vue2-datepicker/index.css';
import VBtn from '@/components/controls/VBtn.vue';
import VSelect from '@/components/controls/VSelect.vue';
import LocalLoader from '@/components/common/LocalLoader';
import Checkbox from '@/components/controls/Checkbox.vue';
import InputFields from '@/components/controls/Input-fields.vue';
import bookingLearnMore from '@/components/pop-up/bookingLearnMore.vue';
import confirmationLearnMore from '@/components/pop-up/confirmationLearnMore.vue';
import ErrorBanner from '@/components/ErrorBanner.vue';
import BackLink from '@/components/Product/BackLink.vue';

import { formatTime } from '@/utils/utils';


const testTime = /^\d{2}:\d{2}\s?(?:[A|P]M)?/;
const isValidTime = (skipCallback) => (value) => {
  if (skipCallback()) return true;
  return testTime.test(value);
}

export default {
  name: 'BookingProcessPage',
  components: {
    LocalLoader,
    VBtn,
    VSelect,
    ErrorBanner,
    Checkbox,
    InputFields,
    bookingLearnMore,
    confirmationLearnMore,
    DatePicker,
    BackLink,
  },
  layout: 'accountHeader',
  props: {
    isBuild: { type: Boolean, default: false },
    product: { type: String, default: '' },
    translations: { type: Object, default: () => ({}) },
    lang: { type: String, default: '' },
  },
  data: () => ({
    loadingPage: false,
    tour: {},
    deadline: null,
    oneSetDedLine: null,
    cutOffDay: 'On day of travel',
    daysCutOff: [
      { is_active: true, name: 'cutOnDay' },
      { is_active: false, name: 'cut1day' },
      { is_active: false, name: 'cut2day' },
    ],
    manualTime: '',
    notifications: null,
    times: [],
    timesManual: [],
    timesName: '',
    timesNameManual: '',
    confirmations: [],
    confirmationsName: '',
    timePickerOptions: {
      start: '00:00',
      step: '00:30',
      end: '23:30',
    },
    bookingLearnMore: false,
    confirmationLearnMore: false,
    bookingProcessConfirmationPageFields: [],
    bookingProcessCutoffPageFields: [],
    loading: false,
  }),
  async fetch() {
    this.loadingPage = true;
    this.tour = await this.$axios.$get(`/api/v1/tour/${this.product}?is_input_language=true`);
    this.times = [
      {
        is_active: false,
        name: this.translations.cutoffSelection1.text,
      },
      {
        is_active: false,
        name: this.translations.cutoffSelection2.text,
      },
    ];
    this.timesManual = [
      {
        is_active: false,
        name: 'hour(s)',
      },
      {
        is_active: false,
        name: 'minute(s)',
      },
    ];
    this.confirmations = [
      {
        is_active: false,
        name: this.translations.confirmationMethodSelection1.text,
      },
      {
        is_active: false,
        name: this.translations.confirmationMethodSelection2.text,
      },
      {
        is_active: false,
        name: this.translations.confirmationMethodSelection3.text,
      },
    ];

    this.tour.time_before_manual = this.tour.time_before_manual?.toString();
    if (this.tour.time_before_manual_type === 'min') {
      this.timesNameManual = 'minute(s)';
      this.timesManual[1].is_active = true;
    } else {
      this.timesNameManual = 'hour(s)';
      this.timesManual[0].is_active = true;
    }

    if (this.tour.time_before_manual !== '0') {
      this.manualTime = this.tour.time_before_manual;
    }
    this.notifications = this.tour.new_booking_notifications;
    if (this.tour.booking_deadline_type) {
      this.timesName = this.tour.booking_deadline_type;
      if (this.tour.booking_deadline_type === 'one_set_time') {
        this.timesName = this.translations.cutoffSelection1.text;
        this.times[0].is_active = true;
        this.deadline = null;
        const formattedTime = formatTime({ time: this.tour.booking_deadline_time.substring(0, 5), lang: this.lang });
        this.oneSetDedLine = formattedTime;
        this.cutOffDay =
          this.tour.booking_deadline_delta_days === 2
            ? this.translations.bookingDeadlineTwoDays.text
            : this.tour.booking_deadline_delta_days === 1
            ? this.translations.bookingDeadlineDeltaOneDay.text
            : this.translations.bookingDeadlineDeltaOnDay.text;
      } else if (this.tour.booking_deadline_type === 'relative_to_start_time') {
        this.timesName = this.translations.cutoffSelection2.text;
        this.times[1].is_active = true;
        this.oneSetDedLine = null;
        this.deadline = this.tour.booking_deadline_delta_hours;
      }
    } else {
      this.timesName = '';
      this.times[0].is_active = true;
    }
    if (!this.tour.booking_confirmation_method) {
      this.confirmationsName = '';
    } else {
      this.confirmationsName = this.tour.booking_confirmation_method;
      if (this.tour.booking_confirmation_method === 'instant') {
        this.confirmationsName = this.translations.confirmationMethodSelection1.text;
        this.confirmations[0].is_active = true;
      } else if (this.tour.booking_confirmation_method === 'instant_then_manual') {
        this.confirmationsName = this.translations.confirmationMethodSelection2.text;
        this.confirmations[1].is_active = true;
      } else if (this.tour.booking_confirmation_method === 'manual') {
        this.confirmationsName = this.translations.confirmationMethodSelection3.text;
        this.confirmations[2].is_active = true;
      }
    }
    this.loadingPage = false;
  },
  validations() {
    return {
      oneSetDedLine: { required: isValidTime(() => !this.times[0].is_active) },
      cutOffDay: { required: requiredIf(() => this.times[0].is_active) },
      timesName: { required },
      confirmationsName: { required },
      deadline: {
        required: requiredIf(() => this.times[1].is_active),
        integer,
        minValue: minValue(1),
        maxValue: maxValue(1000),
      },
      manualTime: {
        required: requiredIf(() => this.isInstantThenManualConfirmation),
        integer,
        minValue: minValue(1),
        maxValue: maxValue(this.getTimesManualMaxValue),
      },
    };
  },
  computed: {
    localizedDaysCutOff() {
      return this.daysCutOff.map((item) => {
        const key = item.name;
        const name = this.translations[key]?.text ?? key;
        return {...item, name};
      });
    },    
    isManualConfirmation() {
      return this.confirmationsName === this.translations.confirmationMethodSelection3.text;
    },
    isInstantThenManualConfirmation() {
      return this.confirmationsName === this.translations.confirmationMethodSelection2.text;
    },
    isInstantConfirmation() {
      return this.confirmationsName === this.translations.confirmationMethodSelection1.text;
    },
    getTimesManualMaxValue() {
      return this.timesManual[0].is_active ? 48 : 60;
    },
    getCutOffTime() {
      return this.timesManual[0].is_active ? 'hours' : 'minutes';
    },
    timeFormat() {
      return formatTime({ lang: this.lang });
    },
  },
  methods: {
    parseTranslate,
    changePeriodName(defaultName) {
      this.timesName = defaultName;
    },

    checkHoursInAdvance(value) {
      let minHours = 48;
      // если установлено ручное подтверждение - требуем не менее 48 часов на подтверждение
      if (
        value instanceof FocusEvent &&
        this.confirmations[2].name == this.$v.confirmationsName.$model &&
        this.$v.deadline.$model < minHours
      )
        return (this.$v.deadline.$model = minHours);

      if (value.length > 1 && this.confirmations[2].name == this.$v.confirmationsName.$model && value < minHours)
        this.$v.deadline.$model = minHours;

      return false;
    },

    changeConfirmationsName(defaultName) {
      let minHours = 48;
      // если мы переключаемся в ручное подтверждение, требуем не менее 48 часов на подтверждение
      if (this.confirmations[2].name == defaultName && this.$v.deadline.$model < minHours)
        this.$v.deadline.$model = minHours;

      this.confirmationsName = defaultName;
    },
    newNotifications() {
      if (document.getElementById('email-notification').checked) {
        this.notifications = true;
      } else {
        this.notifications = false;
      }
    },
    async saveProcess() {
      let that = this;
      try {
        let error = false;
        this.$v.$touch();
        if (this.timesName !== '' && this.confirmationsName !== '' && !this.$v.$invalid) {
          if (this.confirmations[1].is_active || this.confirmations[2].is_active) {
            this.$v.manualTime.$touch();
            if (this.$v.manualTime.$error) {
              error = true;
            }
          }
          if (this.times[1].is_active) {
            this.$v.deadline.$touch();
            if (this.$v.deadline.$error) {
              error = true;
            }
          }
          if (this.times[0].is_active) {
            this.$v.oneSetDedLine.$touch();
            if (this.$v.oneSetDedLine.$error) {
              error = true;
            }
          }
          if (!error) {
            this.loading = true;
            const editTour = new FormData();
            editTour.append(
              'booking_confirmation_method',
              this.isInstantThenManualConfirmation
                ? 'instant_then_manual'
                : this.isManualConfirmation
                ? 'manual'
                : 'instant'
            );
            editTour.append('time_before_manual', this.isManualConfirmation ? 24 : this.manualTime);
            const type = !this.isInstantThenManualConfirmation ? 'h' : this.timesManual[0].is_active ? 'h' : 'min';
            editTour.append('time_before_manual_type', type);

            const tourParams = {
              tour_id: this.tour.id,
              edit_language: this.tour.input_language.abbreviation.toLowerCase(),
              new_booking_notifications: this.notifications,
            };
            // Booking deadline type
            if (this.timesName === this.translations.cutoffSelection1.text) {
              tourParams.booking_deadline_type = 'one_set_time';
              const time = this.oneSetDedLine.split(':');
              tourParams.booking_deadline_time = time[0] + ':' + time[1];
              tourParams.booking_deadline_delta_days =
                this.cutOffDay === this.translations.bookingDeadlineDeltaOnDay.text
                  ? 0
                  : this.cutOffDay === this.translations.bookingDeadlineDeltaOneDay.text
                  ? 1
                  : 2;
            } else {
              tourParams.booking_deadline_type = 'relative_to_start_time';
              tourParams.booking_deadline_delta_hours = this.deadline;
            }

            await this.$axios.$put(`/api/v1/edit-tour/`, editTour, {
              params: tourParams,
            });
            if (this.isBuild) {
              const productStep = new FormData();
              productStep.append('booking_process', true);
              await this.$axios.$put(`api/v1/set-tour-creation-steps/${this.tour.id}`, productStep);
            }
            this.$router.push(
              this.localePath(
                this.isBuild
                  ? `/product/${this.tour.slug}/build/booking-and-tickets/information-on-voucher`
                  : `/product/${this.tour.slug}/booking-details`
              )
            );
            this.loading = false;
          }
        }
      } catch {
        return 'error';
      }
    },
    async learnMoreAboutBooking() {
      const bookingProcessCutoffPage = await this.$axios.$get(`/api/v1/pages/lk-popup-cutoff?language=${this.lang}`);
      this.bookingProcessCutoffPageFields = bookingProcessCutoffPage.fields;
      this.bookingLearnMore = !this.bookingLearnMore;
    },
    async learnMoreAboutConfirmation() {
      const bookingProcessConfirmationPage = await this.$axios.$get(
        `/api/v1/pages/lk-popup-confirmation-method?language=${this.lang}`
      );
      this.bookingProcessConfirmationPageFields = bookingProcessConfirmationPage.fields;
      this.confirmationLearnMore = !this.confirmationLearnMore;
    },
  },
};
</script>

<style lang="scss">
.booking-process {
  .v-local-loader {
    margin-top: 150px;
  }

  &__loading-title {
    text-align: center;
    margin-top: 30px;
  }

  &__item {
    padding-bottom: 34px;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 22px;
  }

  &__title {
    margin-bottom: 39px;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    word-wrap: break-word;
  }

  &__content-title {
    font-weight: bold;
    margin-bottom: 17px;
  }

  &__title-input {
    margin-top: 45px;
    font-weight: bold;
    margin-bottom: 17px;
  }

  &__subtitle {
    display: flex;
    margin-bottom: 13px;
    max-width: 750px;

    &--text {
      margin-right: 18px;
    }

    &--learn-more {
      cursor: pointer;
      color: #999999;
    }
  }

  &__notifications {
    margin-top: 14px;
  }

  &__cut-off-time {
    display: flex;

    &--custom-detepicker {
      position: relative;
      font-family: 'Cabin', sans-serif;
      display: flex;
      align-items: center;
      width: 128px;
      height: 48px;
      padding: 0 16px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      font-size: 16px;
      line-height: 19px;
      margin-right: 10px;

      .mx-input {
        border: none;
        box-shadow: initial;
        padding: 0;
        background-color: inherit;
      }
    }

    &--day {
      width: 200px;

      .selection {
        &__expand {
          width: 200px;
        }
      }
    }
  }

  &__info-banner {
    color: $orange;
    background-color: transparent;
    border: 1px solid $orange;
  }

  .error-banner {
    max-width: 720px;
  }
  .error-banner__item:before {
    display: none;
  }

  &__times-manual {
    display: flex;

    .selection__preview {
      width: 150px;
      border-radius: 0px 4px 4px 0px;
      border-left: 0px;
    }

    .input {
      width: 70px;
      border-radius: 4px 0px 0px 4px;
    }
  }
}

.selection {
  &__preview {
    width: 500px;
  }

  &__expand {
    width: 500px;
  }
}

.mx-datepicker-popup {
  .mx-time {
    width: 128px;
  }
}
</style>
