
import { SCHEDULE_TIME_TYPES } from '../constants/scheduleTypes';
import { getTime } from '@/utils/timeHelper';

// const lang = this.$cookies.get("i18n_redirected") ?? 'en';

export const getTourStarting = (tour, translates, lang) => {
  const schedules = tour.get_tour_schedule;
  const schedule = schedules[0] ?? tour.get_tour_schedule[0];
  const isSame = new Set(schedules.map(x => x.schedule_type.option)).size === 1;
  const isAnySchedule = (search) => schedule.schedule_type.option === search;

  if (!isSame) return translates.variousStartTime?.text;
  if (isAnySchedule(SCHEDULE_TIME_TYPES.coordinatedStartTimes)) {
    return translates.ticketSelectionTimeTypeCoordinated?.text;
  }
  if (isAnySchedule(SCHEDULE_TIME_TYPES.openingHours)) {
    return translates.checkOpeningHours?.text;
  }
  if (isAnySchedule(SCHEDULE_TIME_TYPES.startTimes)) {
    if (schedules.length > 1) return translates.checkStartingTime?.text;
    if (schedule.price_schedules.length > 1) return translates.checkStartingTime?.text;
    const startTimes = schedule.price_schedules[0].available_time;
    if (startTimes.length > 1) return translates.checkStartingTime?.text;
    const formattedTime = getTime(tour.start_time, lang);
    return `${translates.tourDescriptionStartTime?.text}: ${formattedTime}`;
  }
  return translates.variousStartTime?.text;
}


const durationTranslates = {
  min: {
    en: ' min', de: ' Min', es: ' min'
  },
  hour: {
    en: 'h', de: ' Std.', es: 'h'
  },
  day: {
    en: ' day', de: ' Tag', es: ' día'
  }
}

const trUnit = (unit, lang) => durationTranslates[unit]?.[lang] ?? unit;

// Функция превращающая 160 минут в 2:40h
export function normalizeRange(times, unit, lang) {
  lang = lang.toLowerCase();
  times = ("" + times).split('-');

  if (unit === 'min' && times.some(x => x >= 60)) {
    unit = 'hour'
    times = times.map((time) => {
      time = Number(time) || 0;
      const hours = Math.floor(time / 60);
      const minutes = (time % 60).toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    })
  }

  if (times.length === 1) return times[0] + ' ' + trUnit(unit, lang);
  return times.map((x) => `${x}${trUnit(unit, lang)}`).join(' - ');
}