<template>
  <div v-if="isActive" class="wrapper">
    <div class="refud-already-pop-up">
      <div class="close" @click="closeWindow">
        <img
          :id="`DelClosePopup`"
          width="20"
          height="20"
          src="@/assets/img/svg/close.svg"
          alt="close"
        >
      </div>
      <div class="refud-already-pop-up__title">
        {{ title }}
      </div>
      <div class="refud-already-pop-up__buttons">
        <div class="button" @click="closeWindow">
          <btn base :name="buttonText" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Btn: () => import('@/components/controls/Btn')
  },
  props: {
    isActive: { type: Boolean, default: false },
    title: { type: String, default: '' },
    buttonText: { type: String, default: '' }
  },
  data: () => ({}),
  methods: {
    closeWindow () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.refud-already-pop-up {
  position: relative;
  background: $white;
  padding: 52px;
  border-radius: 12px;
  &__title {
    text-align: center;
    width: 465px;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 47px;
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      padding: 13px 32px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      height: 40px;
      min-width: fit-content;
      &:first-child {
        border: 1px solid #d1d6e3;
      }
    }
    .button {
      &:first-child {
        margin-right: 16px;
      }
    }
  }
  .close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 25px;
    z-index: 1100;
    filter: brightness(0);
    img {
      width: 16px;
      height: 16px;
    }
  }
}

@media (max-width: 576px) {
  .refud-already-pop-up {
    width: 80%;
    padding: 25px 15px;
    &__title {
      padding-top: 15px;
      font-size: 22px;
      line-height: 26px;
      width: 100%;
    }
  }
}
</style>
