var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.type === "checkbox"
    ? _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.proxyValue,
                expression: "proxyValue",
              },
            ],
            staticClass: "input",
            class: [_vm.setClass, { "input--error": _vm.error }],
            attrs: {
              placeholder: _vm.placeholder,
              readonly: _vm.readOnly,
              max: _vm.max,
              min: _vm.min,
              type: "checkbox",
            },
            domProps: {
              checked: Array.isArray(_vm.proxyValue)
                ? _vm._i(_vm.proxyValue, null) > -1
                : _vm.proxyValue,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event.target.value)
              },
              change: function ($event) {
                var $$a = _vm.proxyValue,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.proxyValue = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.proxyValue = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.proxyValue = $$c
                }
              },
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      )
    : _vm.type === "radio"
    ? _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.proxyValue,
                expression: "proxyValue",
              },
            ],
            staticClass: "input",
            class: [_vm.setClass, { "input--error": _vm.error }],
            attrs: {
              placeholder: _vm.placeholder,
              readonly: _vm.readOnly,
              max: _vm.max,
              min: _vm.min,
              type: "radio",
            },
            domProps: { checked: _vm._q(_vm.proxyValue, null) },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event.target.value)
              },
              change: function ($event) {
                _vm.proxyValue = null
              },
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      )
    : _c(
        "input",
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.proxyValue,
                expression: "proxyValue",
              },
            ],
            staticClass: "input",
            class: [_vm.setClass, { "input--error": _vm.error }],
            attrs: {
              placeholder: _vm.placeholder,
              readonly: _vm.readOnly,
              max: _vm.max,
              min: _vm.min,
              type: _vm.type,
            },
            domProps: { value: _vm.proxyValue },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.proxyValue = $event.target.value
                },
                function ($event) {
                  return _vm.$emit("input", $event.target.value)
                },
              ],
            },
          },
          "input",
          _vm.$attrs,
          false
        )
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }