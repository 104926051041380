<template>
  <Portal to="popups">
    <fade-transition>
      <div v-if="visible" v-scroll-lock="visible" class="v-dialog-wrapper">
        <div class="v-dialog__overlay" @click="close"></div>
        <div :class="['v-dialog', dialogClasses, classes]">
          <slot name="document">
            <div class="v-dialog__window" role="document">
              <div v-if="title" class="v-dialog__header">
                <h1 class="v-dialog__title">{{ title }}</h1>
                <button
                  class="v-dialog__close"
                  type="button"
                  aria-label="Close"
                  @click="close"
                >
                  <BaseIcon icon-name="Close" />
                </button>
              </div>
              <div class="v-dialog__content">
                <slot />
              </div>
              <div v-if="$slots.footer" class="v-dialog__footer">
                <slot name="footer" />
              </div>
            </div>
          </slot>
        </div>
      </div>
    </fade-transition>
  </Portal>
</template>

<script>
import VScrollLock from "v-scroll-lock";
import FadeTransition from "@/components/transitions/Fade.vue";
import BaseIcon from "@/components/common/BaseIcon.vue";

export default {
  name: "VDialog",
  components: {
    FadeTransition,
    BaseIcon,
  },
  directives: {
    VScrollLock,
  },
  model: {
    prop: "visible",
    event: "update:visible",
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "normal",
      //!  extra-large - 920px, large-plus - 670px, large - 496px, normal - 456px , small - 363px, tiny - 345px
      validator: (size) =>
        ["normal", "small", "large", "large-plus", "extra-large", "tiny"].includes(size),
    },
    title: {
      type: String,
      default: null,
    },
    dialogClasses: {
      type: String,
      default: "",
    },
  },
  computed: {
    modelVisible: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      },
    },
    classes() {
      const { size } = this;
      return {
        [`v-dialog--size_${size}`]: Boolean(size),
      };
    },
  },
  methods: {
    close() {
      this.modelVisible = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
$--z-index-high: 1000;
$--darken-black: #282829;

.v-dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: $--z-index-high;
}

.v-dialog {
  $block: &;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 95%;

  &--size {
    &_small,
    &_tiny,
    &_large,
    &_large-plus,
    &_extra-large {
      width: 100%;
    }

    &_small {
      width: 363px;
    }

    &_tiny {
      width: 345px;
    }

    &_large {
      max-width: 496px;
    }

    &_large-plus {
      max-width: 670px;
    }

    &_extra-large {
      max-width: 920px;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(30, 40, 67, 0.8);
    backdrop-filter: blur(2px);
    z-index: -1;
  }

  &__window {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    width: auto;
    padding: 32px 0;
    overflow: auto;
    max-height: 100%;
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 0 35px;
  }

  &__title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    word-break: break-all;
  }

  &__content {
    padding: 0 35px;
  }

  &__footer {
    padding: 0 35px;
  }

  &__close {
    border: none;
    background: none;
    font-size: 20px;
  }
}
</style>
