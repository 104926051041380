<template>
  <div v-if="isShow" class="wrapper">
    <div class="info-pop-up">
      <div class="pop-up__close" @click="$emit('close')">
        <img src="@/assets/img/svg/close.svg" alt="close" />
      </div>
      <div class="info-pop-up__title">
        {{ title }}
      </div>
      <div class="info-pop-up__description">
        <div v-for="(item, index) in texts" :key="index">
          <p>{{ item }}</p>
        </div>
      </div>
      <v-btn base name="Ok" @click="$emit('close')" />
    </div>
  </div>
</template>

<script>
import VBtn from '@/components/controls/VBtn.vue'

export default {
  name: 'InfoPopUP',
  components: { VBtn },
  props: {
    isShow: { type: Boolean, default: false },
    title: { type: String, default: '' },
    texts: { type: Array, default: () => [] }
  }
}
</script>

<style lang="scss">
.info-pop-up {
  position: relative;
  background: $white;
  border-radius: 10px;
  padding: 40px 40px 40px 40px;
  .info-pop-up__title {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
  .info-pop-up__description {
    margin-bottom: 30px;
    p {
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
    }
  }
}
</style>
