<template>
  <div class="page-header">
    <div class="_container">
      <div class="main__title">{{ name }}</div>
      <div class="page__status">
        <div v-if="status == 'in_stock'" class="selected-status">
          <VSelect v-model="currentStatus" :items="$options.statuses" :translates="statusesTranslates" @input="setStatus()" />
        </div>
        <div v-else class="status">
          <div class="dot dot_bold" />
          <div class="name">{{ headerTranslate.pageStatus1.text }}</div>
        </div>
        <div class="code">{{ headerTranslate.pageProductCode.text }} {{ id }}</div>
        <div class="dot dot_gray" />
        <div class="tour-name">
          {{ slug }}
        </div>
        <div class="dot dot_gray" />
        <nuxt-link v-if="status !== 'in_stock'" :to="localePath(`/product/${slug}/preview`)" tag="a">
          <div class="tour-view">{{ headerTranslate.textPreview.text }}</div>
        </nuxt-link>
        <a v-else class="tour-view" :href="`https://test.vidodoguide.com/catalog/${slug}`" target="_blank">
          {{ headerTranslate.btnView.text }}
        </a>
      </div>
      <div class="page__nav">
        <div class="nav">
          <nuxt-link :to="localePath(`/product/${slug}/product-content`)" class="nav__item">
            {{ headerTranslate.pageNav1.text }}
          </nuxt-link>
          <nuxt-link :to="localePath(`/product/${slug}/schedules-and-prices`)" class="nav__item">
            {{ headerTranslate.pageNav3.text }}
          </nuxt-link>
          <nuxt-link :to="localePath(`/product/${slug}/booking-details`)" class="nav__item">
            {{ headerTranslate.pageNav2.text }}
          </nuxt-link>
          <nuxt-link :to="localePath(`/product/${slug}/tickets`)" class="nav__item">
            {{ headerTranslate.pageNav4.text }}
          </nuxt-link>
          <nuxt-link :to="localePath(`/product/${slug}/translations`)" class="nav__item">
            {{ headerTranslate.pageNav6.text }}
          </nuxt-link>
          <nuxt-link :to="localePath(`/product/${slug}/special-offers`)" class="nav__item">
            {{ headerTranslate.pageNav5.text }}
          </nuxt-link>
          <nuxt-link :to="localePath(`/product/${slug}/product-connection`)" class="nav__item">
            {{ headerTranslate.textConnection.text }}
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VSelect from '@/components/controls/VSelect.vue'
import notificationService from '@/services/notification'
const statuses = [
  { is_active: true, name: 'draft' },
  { is_active: false, name: 'in_stock' }
]
export default {
  components: { VSelect },
  props: {
    name: { type: String, default: '' },
    status: { type: String, default: '' },
    id: { type: Number, default: -1 },
    slug: { type: String, default: '' }
  },
  data() {
    return {
      currentStatus: '',
      oldStatus: ''
    }
  },
  statuses,
  computed: {
    ...mapState(['headerTranslate']),
    statusesTranslates() {
      return {
        draft: this.headerTranslate.pageStatus1.text,
        in_stock: this.headerTranslate.pageStatus3.text
      }
    }
  },
  created() {
    this.currentStatus = this.status
    this.oldStatus = this.status
  },
  methods: {
    async setStatus() {
      if (this.currentStatus !== this.oldStatus)
        await this.$axios.$put(`api/v1/tours/${this.id}/set-status/`, { status: this.currentStatus }).then(() => {
          this.oldStatus = this.currentStatus
          notificationService.success({ title: 'Status has been successfully changed' })
          this.$router.go(this.localePath('/'))
        })
    }
  }
}
</script>

<style lang="scss">
.tour-view {
  font-size: 12px;
  line-height: 14px;
  color: $blue;
  cursor: pointer;
}
</style>
