export const BOOKING_STATUS_REFUNDED = 'refunded'
export const BOOKING_STATUS_REFUND_REQUEST = 'refund_request'
export const BOOKING_STATUS_CONFIRMED = 'confirmed'
export const BOOKING_STATUS_AWAITING_CONFIRMATION = 'awaiting_confirmation'
export const BOOKING_STATUS_CANCELLED = 'cancelled'

export const BOOKING_STATUS_AMENDED = 'amended'
export const BOOKING_STATUS_NOT_AMENDED = 'not_amended'
export const BOOKING_STATUS_AMENDMENT_REQUEST = 'amendment_requested'

export const BOOKING_STATUS_REJECTED = 'rejected'
