<template>
  <div class="map-wrap">
    <div v-if="loading" class="loader" />
    <client-only>
      <l-map :options="{ scrollWheelZoom: false }" :zoom="10" :center="getCenter" @ready="mapLoaded()" @click="onClick">
        <l-tile-layer :url="url" />
        <l-circle
          v-for="(item, index) in getCircleList"
          :key="`${id}-Radius-${index}`"
          :center="[item.lat, item.lon]"
          :lat-lng="[item.lat, item.lon]"
          :radius="item.radius || 0"
          :color="circle.color"
          :fill-color="circle.fillColor"
          :fill-opacity="circle.fillOpacity"
          :opacity="circle.opacity"
          :dash-offset="circle.dashOffset"
          :marker="[item.lat, item.lon]"
        />
        <l-marker v-if="isLocationNotEmpty && circleList.length == 0" :icon="icon" :lat-lng="locationMap" />
        <l-marker
          v-for="(item, index) in getCircleList"
          :key="`${id}-RadiusPin-${index}`"
          :lat-lng="[item.lat, item.lon]"
          :icon="icon"
          :draggable="true"
          @dragend="setPoint(index)"
          @move="moveMarker($event, index)"
        />
      </l-map>
    </client-only>
  </div>
</template>

<script>
export default {
  name: 'BaseMap',
  props: {
    id: { type: String, default: 'map' },
    locationMap: { type: Array, default: () => [28.99685, -13.70702] },
    circleList: { type: Array, default: () => [] }
  },
  data() {
    return {
      loading: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      circle: {
        color: '#FF5134',
        fillColor: '#FF5134',
        fillOpacity: 0.5,
        opacity: 0.5,
        dashOffset: '2px dashed #D80027'
      },
      icon: null
    }
  },
  computed: {
    isLocationNotEmpty() {
      return this.locationMap[0] != null && this.locationMap[1] != null
    },
    getLastCircleLocation() {
      return [this.circleList[this.circleList.length - 1].lat, this.circleList[this.circleList.length - 1].lon]
    },
    getCenter() {
      return this.isLocationNotEmpty
        ? this.circleList.length > 0
          ? this.getLastCircleLocation
          : this.locationMap
        : [28.99685, -13.70702]
    },
    getCircleList() {
      return this.circleList.filter(e => e.lat != null && e.lon != null)
    }
  },
  methods: {
    mapLoaded() {
      this.loading = false
      this.icon = this.$L.icon({
        iconUrl: require('~/assets/img/svg/map-pin.svg'),
        iconSize: [25, 41], // size of the icon
        iconAnchor: [13, 35] // point of the icon which will correspond to marker's location
      })
    },
    setPoint(index) {
      this.$emit('setPoint', index)
    },
    moveMarker(event, index) {
      this.$emit('moveMarker', event, index)
    },
    onClick(event) {
      const isEmpty = (this.locationMap?.every(x => !x) ?? true) || this.circleList.length === 0;
      if (isEmpty) this.$emit('addMarker', event)
    }
  }
}
</script>
<style lang="scss">
.map {
  position: relative;
  width: 715px;
  height: 256px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 44px;
  .open {
    position: absolute;
    background-color: white;
    z-index: 440;
    padding: 3px;
    top: 10px;
    right: 20px;
    cursor: pointer;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    &:hover {
      background-color: #f4f4f4;
    }
    img {
      width: 28px;
    }
  }
  .map-wrap {
    width: 715px;
    height: 256px;
  }
}
.vue2leaflet-map {
  border-radius: 14px;
}
.map {
  position: relative;
  width: 715px;
  height: 256px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 44px;
}
.vue2leaflet-map {
  border-radius: 14px;
}
</style>
