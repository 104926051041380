var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "radio-button", class: _vm.setClass }, [
    _c("input", {
      staticClass: "custom-radio",
      attrs: { id: _vm.id, type: "radio", name: _vm.radioName },
      domProps: { value: _vm.value, checked: _vm.checked },
    }),
    _vm._v(" "),
    _c("label", { staticClass: "custom-radio-label", attrs: { for: _vm.id } }, [
      _vm._v(_vm._s(_vm.name)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }