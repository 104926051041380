<template>
  <div class="checkbox" :class="setClass">
    <input
      :id="id"
      class="custom-checkbox"
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      @change="onChange"
    >
    <label class="custom-label" :for="id">{{ name }}</label>
  </div>
</template>

<script>
import '@/assets/css/checkbox.scss'
export default {
  name: 'Checkbox',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    name: String,
    id: String,
    disable: Boolean,
    // Button type
    button: Boolean,
    default: Boolean,
    checked: Boolean,
    switcher: Boolean,
    disabled: Boolean
  },
  computed: {
    setClass () {
      const typeCheckbox = this.button
        ? 'button'
        : this.default
          ? 'default'
          : this.switcher
            ? 'switcher'
            : ''
      return 'checkbox_' + typeCheckbox
    }
  },
  methods: {
    onChange(ev) {
      this.$emit('change', ev.target.checked)
    }
  }
}
</script>
