<template>
  <div class="account-nav">
    <nuxt-link v-if="!isSellerCompany" :to="localePath(`/account/private-information`)" class="account-nav__item">
      {{ translations.accountPrivateInformation.text }}
    </nuxt-link>
    <nuxt-link v-if="!isSellerCompany" :to="localePath(`/account/public-information`)" class="account-nav__item">
      {{ translations.accountPublicInformation.text }}
    </nuxt-link>
    <nuxt-link v-if="isSellerCompany" :to="localePath(`/account/business-information`)" class="account-nav__item">
      {{ translations.accountBusinessInformation.text }}
    </nuxt-link>
    <!-- <nuxt-link v-if="!isSellerCompany" :to="localePath(`/account/supplier-type`)" class="account-nav__item">
      {{ translations.accountSupplierType.text }}
    </nuxt-link> -->
    <nuxt-link :to="localePath(`/account/license-and-insurance`)" class="account-nav__item">{{
      translations.accountLicenseAndInsurance.text
    }}</nuxt-link>
    <nuxt-link :to="localePath(`/account/connectivity`)" class="account-nav__item">{{
      translations.accountConnectivity.text
    }}</nuxt-link>
    <nuxt-link :to="localePath(`/account/profile-settings`)" class="account-nav__item">{{
      translations.accountProfileSettings.text
    }}</nuxt-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    isSellerCompany: { type: Boolean, default: false }
  },

  computed: {
    ...mapState({
      translations: 'headerTranslate'
    })
  }
}
</script>

<style lang="scss">
.account-nav {
  margin-bottom: 5px;
  padding-bottom: 3px;
  border-bottom: 1px solid #ebebeb;
  &__item {
    font-size: 14px;
    line-height: 16px;
    &:not(:last-child) {
      margin-right: 40px;
    }
    &:hover {
      color: $primary;
    }
    &.nuxt-link-active {
      position: relative;
      font-weight: bold;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: -6px;
        left: 0;
        border-bottom: 2px solid $primary;
      }
    }
  }
}
</style>
