<template>
  <div>
    <header class="header">
      <div class="_container">
        <div class="header__inner">
          <div class="nav">
            <a
              v-if="toLogin == 'anonymous'"
              :href="localePath('/login')"
              tag="div"
              class="logo"
            >
              <img src="@/assets/img/header/logo.svg" alt="logo">
            </a>
            <nuxt-link
              v-else-if="!userInfo.is_password_entered"
              :to="localePath('/')"
              tag="div"
              class="logo"
            >
              <img src="@/assets/img/header/logo.svg" alt="logo">
            </nuxt-link>
          </div>
        </div>
      </div>
    </header>
    <Nuxt class="default-wrapper" />
    <notification-view />
    <screenResolutionNotice />
  </div>
</template>

<script>
import { mapState } from 'vuex'


export default {
  components: {
    NotificationView: () => import('@/components/common/VNotificationsView'),
    screenResolutionNotice: () => import('@/components/pop-up/screenResolutionNotice.vue')
  },
  data: () => ({
    toLogin: ''
  }),
  fetch () {
    this.toLogin = this.$cookies.get('userlk')
  },
  computed: {
    ...mapState(['userInfo'])
  }
}
</script>

<style lang="scss">
.header {
  margin-bottom: 22px;
  border-bottom: 1px solid #ebebeb;
  &__inner {
    padding: 12px 0;
    padding-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nav {
      display: flex;
      align-items: center;
      .logo {
        cursor: pointer;
        margin-right: 48px;
        width: 80px;
        height: 25px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
