var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bank-details" }, [
    _c(
      "div",
      { staticClass: "_container" },
      [
        _c("VBreadcrumbs", {
          attrs: { title: _vm.translations.backStep.text },
          on: { click: _vm.backStep },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "bank-details__content" }, [
          _c(
            "div",
            { staticClass: "bank" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.translations.bankTitle.text) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bank-details__subtitle subtitle" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.translations.bankSubtitle.text) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("VRadioGroup", {
                staticClass: "bank-details__field",
                attrs: { name: "isCompany" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "VRadioButton",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  label:
                                    _vm.translations.sellerTypeIndividual.text,
                                  value: false,
                                },
                              },
                              "VRadioButton",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                        _vm._v(" "),
                        _c(
                          "VRadioButton",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  label:
                                    _vm.translations.sellerTypeBusiness.text,
                                  value: true,
                                },
                              },
                              "VRadioButton",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.isCompany,
                  callback: function ($$v) {
                    _vm.isCompany = $$v
                  },
                  expression: "isCompany",
                },
              }),
              _vm._v(" "),
              !_vm.isCompany
                ? _c(
                    "VField",
                    {
                      staticClass: "bank-details__field",
                      attrs: {
                        label: _vm.translations.labelFirstName.text,
                        error: _vm.$v.localBankForm.first_name.$error,
                        "error-message": _vm.getErrorMessage(
                          _vm.translations.labelFirstName.text
                        ),
                      },
                    },
                    [
                      _c("VInput", {
                        attrs: {
                          error: _vm.$v.localBankForm.first_name.$error,
                          "max-length": 128,
                          "hide-counter": "",
                        },
                        model: {
                          value: _vm.$v.localBankForm.first_name.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.localBankForm.first_name,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.localBankForm.first_name.$model",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isCompany
                ? _c(
                    "VField",
                    {
                      staticClass: "bank-details__field",
                      attrs: {
                        label: _vm.translations.labelLastName.text,
                        error: _vm.$v.localBankForm.last_name.$error,
                        "error-message": _vm.getErrorMessage(
                          _vm.translations.labelLastName.text
                        ),
                      },
                    },
                    [
                      _c("VInput", {
                        attrs: {
                          error: _vm.$v.localBankForm.last_name.$error,
                          "max-length": 128,
                          "hide-counter": "",
                        },
                        model: {
                          value: _vm.$v.localBankForm.last_name.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.localBankForm.last_name,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.localBankForm.last_name.$model",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isCompany
                ? _c(
                    "VField",
                    {
                      staticClass: "bank-details__field",
                      attrs: {
                        label: _vm.translations.labelCompany.text,
                        error: _vm.$v.localBankForm.company_name.$error,
                        "error-message": _vm.getErrorMessage(
                          _vm.translations.labelCompany.text
                        ),
                      },
                    },
                    [
                      _c("VInput", {
                        attrs: {
                          error: _vm.$v.localBankForm.company_name.$error,
                          "max-length": 128,
                          "hide-counter": "",
                        },
                        model: {
                          value: _vm.$v.localBankForm.company_name.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.localBankForm.company_name,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.localBankForm.company_name.$model",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "VField",
                {
                  staticClass: "bank-details__field",
                  attrs: {
                    label: _vm.translations.bankName.text,
                    error: _vm.$v.localBankForm.bank_name.$error,
                    "error-message": _vm.getErrorMessage(
                      _vm.translations.bankName.text
                    ),
                  },
                },
                [
                  _c("VInput", {
                    attrs: {
                      error: _vm.$v.localBankForm.bank_name.$error,
                      placeholder:
                        _vm.translations.inputBankNamePlaceholder.text,
                      "max-length": 128,
                      "hide-counter": "",
                    },
                    model: {
                      value: _vm.$v.localBankForm.bank_name.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.localBankForm.bank_name,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.localBankForm.bank_name.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isShowAccountNumber
                ? _c(
                    "VField",
                    {
                      staticClass: "bank-details__field",
                      attrs: {
                        label: _vm.translations.iban.text,
                        description: _vm.descriptionIban,
                        error: _vm.$v.localBankForm.iban.$error,
                        "error-message": !_vm.$v.localBankForm.iban.format
                          ? _vm.translations.errorIncorrect.text
                          : _vm.getErrorMessage(_vm.translations.iban.text),
                      },
                    },
                    [
                      _c("VInput", {
                        attrs: {
                          error: _vm.$v.localBankForm.iban.$error,
                          placeholder: `#### #### #### ### #### ##`,
                          "max-length": 50,
                          "hide-counter": "",
                        },
                        on: { input: _vm.replace_spaces },
                        model: {
                          value: _vm.$v.localBankForm.iban.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.localBankForm.iban,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.localBankForm.iban.$model",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isShowAccountNumber
                ? _c(
                    "VField",
                    {
                      staticClass: "bank-details__field",
                      attrs: {
                        label: _vm.translations.labelBicSwift.text,
                        error: _vm.$v.localBankForm.swift.$error,
                        "error-message": !_vm.$v.localBankForm.swift.format
                          ? _vm.translations.errorIncorrect.text
                          : _vm.getErrorMessage(
                              _vm.translations.labelBicSwift.text
                            ),
                      },
                    },
                    [
                      _c("VInput", {
                        attrs: {
                          error: _vm.$v.localBankForm.swift.$error,
                          placeholder: "XXXXXX*****",
                        },
                        model: {
                          value: _vm.$v.localBankForm.swift.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.localBankForm.swift,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.localBankForm.swift.$model",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isShowAccountNumber
                ? _c(
                    "VField",
                    {
                      staticClass: "bank-details__field",
                      attrs: {
                        label: _vm.translations.labelAccountNumber.text,
                        error: _vm.$v.localBankForm.account_number.$error,
                        "error-message": _vm.getErrorMessage(
                          _vm.translations.labelAccountNumber.text
                        ),
                      },
                    },
                    [
                      _c("VInput", {
                        attrs: {
                          error: _vm.$v.localBankForm.account_number.$error,
                        },
                        model: {
                          value: _vm.$v.localBankForm.account_number.$model,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.$v.localBankForm.account_number,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.localBankForm.account_number.$model",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "save", on: { click: _vm.saveStep } },
                [
                  _c("Btn", {
                    attrs: { base: "", name: _vm.translations.btnSave.text },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }