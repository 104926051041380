<template>
  <InputFields
    ref="input"
    v-model="localAddress"
    class="google-autocomplete"
    :class="{ 'google-autocomplete--error': error, 'google-autocomplete--filled': localAddress }"
    :error="error"
    v-bind="$attrs"
    @click="initAutocomplete"
  />
</template>

<script>
import InputFields from '@/components/controls/Input-fields.vue'

import { formatGoogleAddress } from '@/utils/googleMaps'

export default {
  name: 'GoogleAutocomplete',

  components: { InputFields },

  inheritAttrs: false,

  model: {
    prop: 'address',
    event: 'update:address'
  },

  props: {
    address: { type: String, default: '' },
    addressFormatted: { type: Boolean, default: false },
    options: { type: Object, default: () => ({}) },
    error: { type: Boolean, default: false }
  },

  autocompleteInstance: null,

  computed: {
    localAddress: {
      get() {
        return this.address
      },
      set(value) {
        this.$emit('update:address', value)
      }
    }
  },

  mounted() {
    this.initAutocomplete()
  },

  beforeDestroy() {
    if (google && this.$options.autocompleteInstance) {
      google.maps.event.clearInstanceListeners(this.$options.autocompleteInstance)
      this.$options.autocompleteInstance = null
    }
  },

  methods: {
    initAutocomplete() {
      const options = this.addressFormatted
        ? {
            types: this.types,
            fields: ['address_components', 'geometry']
          }
        : {}

      this.$options.autocompleteInstance = new google.maps.places.Autocomplete(this.$refs.input.$el, { ...options, ...this.options })

      google.maps.event.addListener(this.$options.autocompleteInstance, 'place_changed', () => {
        const place = this.$options.autocompleteInstance.getPlace()
        const point = {
          lat: place.geometry.location.lat(),
          lon: place.geometry.location.lng(),
          address: formatGoogleAddress(place.address_components)
        }

        this.$emit('update:point', point)
        this.$emit('update:place', place)
      })
    }
  }
}
</script>

<style lang="scss">
.google-autocomplete {
  width: 100% !important;
  border-radius: 12px !important;
  height: 46px;
  font-size: 18px;
  line-height: 21px;
  color: $text-primary;
}
</style>
