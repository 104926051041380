<template>
  <div class="slider">
    <div class="slider__content">
      <div v-if="availableFrom" class="slider__content__available-from">
        Available from {{ availableFrom }}
      </div>
      <div class="slider__picture">
        <img :id="`SliderMainPhoto-${id}`" width="1000" height="500" :src="minPhotos[MainPhotoIndex].get_image" alt="Tour picture">
        <div class="photo-button_prev">
          <v-btn :id="`SliderBackBtn-${id}`" slider class="slider__button slider__button_back" @click="prevPhoto" />
        </div>
        <div class="photo-button_next">
          <v-btn :id="`SliderNextBtn-${id}`" slider class="slider__button slider__button_next" @click="nextPhoto" />
        </div>
        <div v-if="oldPrice > 0" class="badge badge__special">
          Special offer
        </div>
      </div>
    </div>
    <div class="slider__min-photos" :style="{'margin-top': '-' +(scroll * currentPhotoIndex + lastSlideScroll) +'px'}" style="transition: all 0.8s;">
      <div v-for="(minPhoto, index) in minPhotos" :key="minPhoto.id" class="min-photo">
        <img
          :id="`SliderMinPhoto-${id}`"
          width="150"
          height="75"
          :src="minPhoto.get_image"
          alt="tour photo"
          @click="MainPhotoIndex = index"
        >
      </div>
      <div v-if="minPhotos.length > 4" class="button-down" @click="nextSlide">
        <img :id="`SliderDownBtn-${id}`" width="15" height="10" src="@/assets/img/svg/slider-btn-down.svg" alt="slide down">
      </div>
    </div>
  </div>
</template>

<script>
import VBtn from '@/components/controls/VBtn.vue'
export default {
  components: { VBtn },
  props: [
    'id',
    'minPhotos',
    'currentPhotos',
    'oldPrice',
    'availableFrom'
  ],
  data: () => ({
    scroll: 85,
    currentPhotoIndex: 0,
    MainPhotoIndex: 0,
    lastSlideScroll: 0
  }),
  methods: {
    nextSlide () {
      this.currentPhotoIndex += 1
      this.lastSlideScroll = 0
      if (this.minPhotos.length === 5) {
        if (this.scroll !== 15) {
          this.scroll = 15
        } else {
          this.scroll = 85
          this.currentPhotoIndex = 0
        }
      } else {
        if (this.currentPhotos - 4 === this.currentPhotoIndex + 1) {
          this.lastSlideScroll = 15
        }
        if (this.currentPhotoIndex >= this.currentPhotos - 4) {
          this.currentPhotoIndex = 0
        }
      }
    },
    nextPhoto () {
      if (this.MainPhotoIndex < this.currentPhotos - 1) {
        this.MainPhotoIndex += 1
      } else {
        this.MainPhotoIndex = 0
      }
    },
    prevPhoto () {
      if (this.MainPhotoIndex === 0) {
        this.MainPhotoIndex = this.currentPhotos - 1
      } else {
        this.MainPhotoIndex -= 1
      }
    }
  }
}
</script>

<style lang="scss">
.slider{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 400px;
    overflow: hidden;
    &__content{
        position: relative;
        overflow: hidden;
        width: 975px;
        height: 400px;
        border-radius: 11px;
        img{
            max-width: 1000px;
            max-height: 500px;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        .badge{
            position: absolute;
            &:not(:last-child){
                margin-right: 6px;
            }
            bottom: 16px;
            left: 16px;
            border-radius: 4px;
            box-shadow: 0px 1px 4px rgba(30, 40, 67, 0.12);
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            background: $primary;
            padding: 3px 8px;
            color: $white
        }
        &__available-from {
          z-index: 10;
          position: absolute;
          left: 16px;
          bottom: 16px;
          background-color: #fff;
          border-radius: 4px;
          font-size: 12px;
          font-weight: bold;
          padding: 3px 8px;
        }
    }
    &__picture{
        width: 100%;
        height: 100%;
    }
    &__min-photos{
        position: relative;
        overflow: hidden;
        transition: all 0.8s;
        .button-down{
            height: 21px;
            width: 100%;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            img{
                object-fit: cover;
                width: 15px;
                height: 10px;
            }
        }
        .min-photo{
            cursor: pointer;
            overflow: hidden;
            width: 150px;
            height: 75px;
            border-radius: 8px;
            img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
            &:hover{
                background: $orange;
                background-image: url('~assets/img/svg/slider-min-photo-hover.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 24px;
                opacity: 1;
                img{
                    opacity: 0.2;
                }
            }
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
    &__button{
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        &_back{
            left: 16px;
        }
        &_next{
            transform: rotateY(180deg);
            right: 16px;
        }
    }
}

@media (max-width: 1200px) {
  .slider{
      &__content{
          width: 82%;
      }
  }
}

@media (max-width: 1024px) {
  .slider{
      &__content{
          width: 81%;
      }
  }
}

</style>
