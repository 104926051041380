var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "map-wrap" },
    [
      _vm.loading ? _c("div", { staticClass: "loader" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "client-only",
        [
          _c(
            "l-map",
            {
              attrs: {
                options: { scrollWheelZoom: false },
                zoom: 10,
                center: _vm.getCenter,
              },
              on: {
                ready: function ($event) {
                  return _vm.mapLoaded()
                },
                click: _vm.onClick,
              },
            },
            [
              _c("l-tile-layer", { attrs: { url: _vm.url } }),
              _vm._v(" "),
              _vm._l(_vm.getCircleList, function (item, index) {
                return _c("l-circle", {
                  key: `${_vm.id}-Radius-${index}`,
                  attrs: {
                    center: [item.lat, item.lon],
                    "lat-lng": [item.lat, item.lon],
                    radius: item.radius || 0,
                    color: _vm.circle.color,
                    "fill-color": _vm.circle.fillColor,
                    "fill-opacity": _vm.circle.fillOpacity,
                    opacity: _vm.circle.opacity,
                    "dash-offset": _vm.circle.dashOffset,
                    marker: [item.lat, item.lon],
                  },
                })
              }),
              _vm._v(" "),
              _vm.isLocationNotEmpty && _vm.circleList.length == 0
                ? _c("l-marker", {
                    attrs: { icon: _vm.icon, "lat-lng": _vm.locationMap },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.getCircleList, function (item, index) {
                return _c("l-marker", {
                  key: `${_vm.id}-RadiusPin-${index}`,
                  attrs: {
                    "lat-lng": [item.lat, item.lon],
                    icon: _vm.icon,
                    draggable: true,
                  },
                  on: {
                    dragend: function ($event) {
                      return _vm.setPoint(index)
                    },
                    move: function ($event) {
                      return _vm.moveMarker($event, index)
                    },
                  },
                })
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }