var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: {
        title: _vm.parseTranslate(
          _vm.translations.bookingCancelledTitle.text,
          _vm.count
        ),
        "dialog-classes": "availability-confirm",
      },
      model: {
        value: _vm.modelActive,
        callback: function ($$v) {
          _vm.modelActive = $$v
        },
        expression: "modelActive",
      },
    },
    [
      _c("p", { staticClass: "availability-confirm__subtitle" }, [
        _vm._v(_vm._s(_vm.translations.bookingCancelledSubtitle.text)),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "availability-confirm__bookings" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.translations.bookingsCancelledTotal.text) + " "
        ),
        _c("span", { staticClass: "availability-confirm__bookings-count" }, [
          _vm._v(_vm._s(_vm.count)),
        ]),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "availability-confirm__reason-title" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.translations.giveCancellationReason.text) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "VField",
        { staticClass: "availability-confirm__field" },
        [
          _c("VSelect", {
            attrs: {
              error: _vm.$v.selectedReason.$error,
              items: _vm.formattedReasons,
              translates: _vm.reasonsTranslates,
            },
            model: {
              value: _vm.$v.selectedReason.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.selectedReason, "$model", $$v)
              },
              expression: "$v.selectedReason.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isCustomReason
        ? _c(
            "VField",
            { staticClass: "availability-confirm__field" },
            [
              _c("VTextarea", {
                attrs: {
                  error: _vm.$v.reasonText.$error,
                  placeholder:
                    _vm.translations.cancellationReasonDescription.text,
                  "max-length": 250,
                  rows: 5,
                },
                model: {
                  value: _vm.$v.reasonText.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.reasonText, "$model", $$v)
                  },
                  expression: "$v.reasonText.$model",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "VField",
        {
          staticClass: "availability-confirm__field",
          attrs: {
            error: _vm.$v.confirmDelete.$error,
            "error-message": "Required",
          },
        },
        [
          _c("VCheckbox", {
            attrs: {
              label: _vm.parseTranslate(
                _vm.translations.acceptUnderstandCanceling.text,
                _vm.count
              ),
            },
            model: {
              value: _vm.$v.confirmDelete.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.confirmDelete, "$model", $$v)
              },
              expression: "$v.confirmDelete.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "availability-confirm__footer" },
        [
          _c("VButton", { attrs: { base: "" }, on: { click: _vm.close } }, [
            _vm._v(_vm._s(_vm.translations.backButton.text)),
          ]),
          _vm._v(" "),
          _c(
            "VButton",
            { attrs: { outline: "" }, on: { click: _vm.confirm } },
            [_vm._v(_vm._s(_vm.translations.cancelBookingsButton.text))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }