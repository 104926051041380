<template>
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2 10.3202L2 21.9202C2 23.2015 3.29837 24.2402 4.9 24.2402L28.1 24.2402C29.7016 24.2402 31 23.2015 31 21.9202L31 10.3202C31 9.03893 29.7016 8.00023 28.1 8.00023L4.9 8.00023C3.29837 8.00023 2 9.03893 2 10.3202Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M25.7798 9C25.7798 11.6676 27 13 30 13" stroke="currentColor" stroke-width="2"/>
  <path d="M7.22016 9C7.22016 11.6676 6 13 3 13" stroke="currentColor" stroke-width="2"/>
  <path d="M25.5 23.5C25.5 20.5 27.7577 19 30 19" stroke="currentColor" stroke-width="2"/>
  <path d="M7.5 23.5C7.5 20.5 5.24228 19 3 19" stroke="currentColor" stroke-width="2"/>
  <path d="M16.1097 12.7402H17.5097V19.7402H15.9097V14.4302L14.6697 14.7702L14.2797 13.4002L16.1097 12.7402Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'HomePay'
}
</script>
