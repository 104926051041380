<template>
  <Selection v-bind="$attrs" :default-name="value" :items="items" :placeholder="placeholder" @name="updateModel" v-on="$listeners" />
</template>

<script>
import Selection from '@/components/controls/Selection.vue'

export default {
  name: 'VSelect',
  components: { Selection },
  props: {
    value: { type: [String, Number], default: '' },
    items: { type: Array, default: () => [] },
    placeholder: { type: String, default: '' },
  },

  methods: {
    updateModel (value) {
      this.$emit('input', value)
    }
  }
}
</script>
