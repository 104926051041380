<template>
  <div class="activity-icon">
    <base-icon :icon-name="iconName" class="activity-icon__icon" />
    <div class="activity-icon__description">
      <div class="activity-icon__title">
        {{ title }}
      </div>
      <div class="activity-icon__text">
        {{ description }}
      </div>
      <div class="activity-icon__text row">
        <div v-for="(lang, index) in languages" :key="`lang-${index}`">
          <div v-if="index !== languages.length - 1" class="activity-icon__language" style="margin-right: 5px;">
            {{ `${lang.language}, ` }}
          </div>
          <div v-else class="activity-icon__language">
            {{ `${lang.language}` }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseIcon from '@/components/common/BaseIcon.vue'

export default {
  name: 'ActivityIcon',
  components: { BaseIcon },
  props: {
    iconName: { type: String, default: '' },
    title: { type: String, default: '' },
    description: { type: String, default: '' },
    languages: { type: Array, default: () => [] }
  }
}
</script>
<style lang="scss">
.activity-icon {
  display: flex;
  flex-wrap: wrap;
  &__icon {
    margin-right: 10px;
    color: #00A1FF;
  }
  &__description {
    margin-top: 2px;
  }
  &__title {
    margin-bottom: 6px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }
  &__text {
    width: 188px;
    font-size: 14px;
    line-height: 140%;
    color: $light-gray;
  }
  &__suitable {
    width: 225px;
    font-size: 14px;
    line-height: 140%;
    color: $light-gray;
  }
  &__language {
    width: max-content;
    font-size: 14px;
    color: $light-gray;
  }
}
@media (max-width: 768px) {
  .activity-icon {
    margin-bottom: 16px;
    .activity-icon__icon{
      color: #565E72 !important;
    }
  }
}
</style>
