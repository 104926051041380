<template>
  <label class="v-textarea">
    <div v-if="label || $slots.label" class="v-textarea__label">
      <slot name="label">
        {{ label }}
        <span v-if="optional.length > 0" class="v-textarea__label--optional">({{ optional }})</span>
      </slot>
    </div>
    <div v-if="subLabel" class="v-textarea__sub-label">
      {{ subLabel }}
    </div>

    <div class="v-textarea__wrapper">
      <textarea
        v-model="localModel"
        :maxlength="maxLength ? maxLength : ''"
        class="v-textarea__textarea"
        :class="classes"
        v-bind="$attrs"
        :disabled="disabled"
      />
      <div v-if="maxLength" class="v-textarea__counter" :class="{ 'v-textarea__counter--error': errorLength }">
        {{ localModel.length }}/{{ maxLength }}
      </div>
    </div>
    <div v-if="error && errorMessage" class="validation-error">
      {{ errorMessage }}
    </div>
  </label>
</template>

<script>
export default {
  name: 'VTextarea',

  inheritAttrs: false,

  props: {
    value: { type: String, default: '' },
    label: { type: String, default: '' },
    subLabel: { type: String, default: '' },
    optional: { type: String, default: '' },
    maxLength: { type: Number, default: 0 },
    errorLength: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },

  computed: {
    localModel: {
      get() {
        return this.value ?? '';
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    classes() {
      return {
        'v-textarea__textarea--error': this.error,
      };
    },
  },
};
</script>

<style lang="scss">
.v-textarea {
  $this: &;

  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;

  &__label {
    margin-bottom: 5px;
    color: #1e2843;
    font-size: 18px;
    font-weight: 600;
    &--optional {
      color: #999999;
    }
  }

  &__sub-label {
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 19px;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__textarea {
    font-family: 'Cabin', sans-serif;
    font-size: inherit;
    line-height: inherit;
    font-weight: inherit;
    resize: none;
    background: none;
    outline: none;
    width: 100%;
    height: 100%;
    padding: 20px 14px;
    color: #1e2843;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    cursor: text;

    &::placeholder {
      color: #999999;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }

    &--error {
      border-color: $red;
      background-color: $red-light;
    }
  }

  &__counter {
    position: absolute;
    bottom: 6px;
    right: 12px;
    text-align: right;
    font-size: 14px;
    color: #cbcbcb;

    &--error {
      color: $red;
    }
  }

  &__error {
    color: $red;
    font-size: 18px;
    font-weight: 500;
    margin-top: 4px;
  }
}
</style>
