var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkbox", class: _vm.setClass }, [
    _c("input", {
      staticClass: "custom-checkbox",
      attrs: { id: _vm.id, type: "checkbox", disabled: _vm.disabled },
      domProps: { checked: _vm.checked },
      on: { change: _vm.onChange },
    }),
    _vm._v(" "),
    _c("label", { staticClass: "custom-label", attrs: { for: _vm.id } }, [
      _vm._v(_vm._s(_vm.name)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }