<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14341_114801)">
      <path d="M19.7098 7.21402C19.323 6.86255 18.6958 6.86255 18.3088 7.21402L15.9881 9.32209C15.6004 5.73543 12.6759 2.63817 8.59082 1.90002C5.58398 1.35674 2.4829 2.20031 0.29563 4.15665C-0.0942431 4.50542 -0.0991306 5.07522 0.284732 5.42938C0.668662 5.78355 1.29591 5.78799 1.68571 5.43922C3.40484 3.9016 5.84157 3.23857 8.20419 3.66539C11.4316 4.24857 13.737 6.70589 14.0209 9.54384L11.456 7.21383C11.0692 6.86243 10.4418 6.86237 10.0549 7.21389C9.66805 7.56536 9.66805 8.13516 10.0549 8.48663L14.1819 12.2356C14.3753 12.4113 14.6289 12.4992 14.8824 12.4992C15.1358 12.4992 15.3895 12.4113 15.5829 12.2356L19.7097 8.48681C20.0967 8.13534 20.0967 7.56548 19.7098 7.21402Z" fill="currentColor" />
      <circle cx="1.73913" cy="16.8485" r="1.73913" fill="currentColor" />
      <circle cx="8.26086" cy="16.8485" r="1.73913" fill="currentColor" />
      <circle cx="14.7826" cy="16.8485" r="1.73913" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_14341_114801">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'TourSkipLine'
}
</script>
