<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-performance-details">
      <div class="head-row">
        <div class="row">
          <div class="head-row__title">{{ translations.yourProductNeedsAttention.text }}</div>
          <div class="pop-up__close" @click="closeWindow">
            <img src="@/assets/img/svg/close.svg" alt="close" />
          </div>
        </div>
        <div class="head-row__subtitle">{{ translations.provideMissingInformation.text }}</div>
      </div>
      <!-- Payout method -->
      <div v-if="isShowPayoutMethod" class="item-performance-details">
        <div class="item-performance-details__name">{{ translations.selectPayoutMethod.text }}</div>
        <div class="item-performance-details__details">
          <nuxt-link :to="localePath(`/finance/payout-methods`)" target="_blank">{{
            translations.details.text
          }}</nuxt-link>
        </div>
      </div>
      <!-- Company info -->
      <div v-if="isShowCompanyInfo" class="item-performance-details">
        <div class="item-performance-details__name">{{ translations.provideInformationAboutCompany.text }}</div>
        <div class="item-performance-details__details">
          <nuxt-link
            :to="localePath(isSellerCompany ? '/account/business-information' : '/account/public-information')"
            target="_blank"
            >{{ translations.details.text }}</nuxt-link
          >
        </div>
      </div>
      <div v-if="!applyTour || true">
        <div v-for="(detailLink, index) in missingDetailsList" :key="index" class="item-performance-details">
          <div class="item-performance-details__name">{{ detailLink.text }}</div>
          <div class="item-performance-details__details">
            <nuxt-link :to="detailLink.link" target="_blank">Details</nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    isActive: { type: Boolean, default: false },
    tour: { type: Object, default: () => {} },
    mainInfo: { type: Object, default: () => {} },
    showSellerErrors: { type: Boolean, default: false },
    applyTour: { type: Boolean, default: false },
    translations: { type: Object, default: () => {} },
  },
  computed: {
    ...mapState(['userInfo']),
    isShowPayoutMethod() {
      return !this.mainInfo.payout_method && this.showSellerErrors;
    },
    isShowCompanyInfo() {
      return (
        !this.mainInfo.company_name ||
        !this.mainInfo.company_trading_name ||
        !this.mainInfo.office_country ||
        !this.mainInfo.office_city ||
        !this.mainInfo.office_address ||
        !this.mainInfo.office_address_code ||
        !this.mainInfo.contact_phone
      );
    },
    isShowActivitiesDetails() {
      return !this.tour.location || !this.tour.description || !this.tour.duration;
    },
    isSellerCompany() {
      return this.userInfo.is_seller_company;
    },

    missingDetailsList() {
      if (!this.tour?.slug) return [
        {
          link: this.localePath(`/performance`),
          text: this.translations.pleaseEnterProfileInformation.text,
          active: true,
        }
      ];
      const detailsList = [
        {
          link: this.localePath(`/product/${this.tour.slug}/product-content/photos-edit`),
          active: !this.tour.photo,
          text: this.translations.pleaseAddMorePhotos.text,
        },
        {
          link: this.localePath(`/product/${this.tour.slug}/product-content/meeting-and-pickup`),
          active: !this.tour.pick_up,
          text: this.translations.pleaseEnterMeetingPickup.text,
        },
        {
          link: this.localePath(`/product/${this.tour.slug}/product-content/categories-and-themes`),
          active: !this.tour.category,
          text: this.translations.pleaseEnterCategoriesThemes.text,
        },
        {
          link: this.localePath(`/product/${this.tour.slug}/product-content/makes-unique`),
          active: !this.tour.short_description,
          text: this.translations.PleaseEnterTourDescription.text,
        },
        {
          link: this.localePath(`/product/${this.tour.slug}/product-content/activities-details`),
          active: this.isShowActivitiesDetails,
          text: this.translations.pleaseEnterActivitiesDetails.text,
        },
        {
          link: this.localePath(`/product/${this.tour.slug}/product-content/information-edit`),
          active: !this.tour.contact_number,
          text: this.translations.pleaseEnterInformationFromYou.text,
        },
        {
          link: this.localePath(`/product/${this.tour.slug}/product-content/inclusions-and-exclusions`),
          active: !this.tour.includes_excludes,
          text: this.translations.pleaseEnterInclusionsExclusions.text,
        },
        {
          link: this.localePath(`/product/${this.tour.slug}/booking-details/booking-process`),
          active: !this.tour.booking_process,
          text: this.translations.pleaseCompleteBookingProcess.text,
        },
        {
          link: this.localePath(`/product/${this.tour.slug}/booking-details/pricing-type`),
          active: !this.tour.pricing_type,
          text: this.translations.pleaseEnterPricingType.text,
        },
        {
          link: this.localePath(`/product/${this.tour.slug}/schedules-and-prices`),
          active: !this.tour.schedule,
          text: this.translations.pleaseEnterSchedulePrices.text,
        },
      ].filter((link) => link.active);
      return detailsList;
    },
  },
  methods: {
    closeWindow() {
      const active = this.isActive;
      this.$emit('close', active);
    },
  },
};
</script>

<style lang="scss">
.pop-up-performance-details {
  background: $white;
  position: relative;
  width: 800px;
  padding: 34px;

  .pop-up__close {
    top: 37px;
    right: 20px;
  }

  .head-row {
    padding-bottom: 27px;
    border-bottom: 1px solid #e4e4e4;

    &__title {
      font-size: 32px;
      line-height: 38px;
    }

    &__subtitle {
      font-size: 18px;
      line-height: 21px;
      padding-top: 16px;
      width: 85%;
    }
  }

  .item-performance-details {
    display: flex;
    padding: 21px 0px;
    font-size: 16px;
    line-height: 19px;
    border-bottom: 1px solid #e4e4e4;

    &__name {
      flex: 1 1 auto;
    }

    &__details {
      flex: 0 0 auto;
      cursor: pointer;

      a {
        color: $primary;
      }
    }
  }
}
</style>
