<template>
  <div class="photo-help">
    <img class="photo-help__image" src="@/assets/img/svg/camera.svg" alt="camera" />
    <p class="photo-help__title">{{ doTitle }}</p>
    <ul class="photo-help__list">
      <li v-for="doItem in doList" :key="doItem" class="photo-help__item">
        <BaseIcon icon-name="Checkmark" class="photo-help__icon" /> {{ doItem }}
      </li>
    </ul>

    <p class="photo-help__title">{{ dontTitle }}</p>
    <ul class="photo-help__list">
      <li v-for="dontItem in dontList" :key="dontItem" class="photo-help__item">
        <BaseIcon icon-name="Close" class="photo-help__icon photo-help__icon--dont" />
        {{ dontItem }}
      </li>
    </ul>
  </div>
</template>

<script>
import BaseIcon from '@/components/common/BaseIcon.vue'

export default {
  name: 'PhotoHelp',
  components: { BaseIcon },
  props: {
    doList: { type: Array, default: () => [] },
    dontList: { type: Array, default: () => [] },
    doTitle: { type: String, default: '' },
    dontTitle: { type: String, default: '' }
  }
}
</script>

<style lang="scss">
.photo-help {
  width: 293px;
  padding: 19px 32px 34px;
  border: 1px solid #ebebeb;
  border-radius: 14px;

  &__title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  &__list {
    &:not(:last-child) {
      margin-bottom: 22px;
    }
  }

  &__item {
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: flex-start;
    gap: 3px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    color: #4cd9b7;

    &--dont {
      color: $red;
    }
  }

  &__image {
    margin-bottom: 15px;
  }
}
</style>
