<template>
  <div class="personal-details-form">
    <VField
      class="personal-details-form__field"
      :label="translations.birthLabel.text"
      :error="$v.localPersonalDetails.birthDate.$error"
      :error-message="translations.requiredError.text"
    >
      <DatePicker
        v-model="$v.localPersonalDetails.birthDate.$model"
        class="personal-details-form__date"
        :editable="false"
        :placeholder="translations.birthPlaceholder.text"
        value-type="DD-MM-YYYY"
        format="D MMMM YYYY"
      />
    </VField>

    <VField
      class="personal-details-form__field"
      :label="translations.phoneLabel.text"
      :description="translations.phoneDescription.text"
      :error="phoneError"
      :error-message="translations.phoneError.text"
    >
      <VuePhoneNumberInput
        v-model="localPersonalDetails.phone"
        :error="phoneError"
        :default-country-code="localPersonalDetails.phoneCode || ''"
        class="personal-details-form__phone"
        @update="countryChanged"
      />
    </VField>

    <VBtn class="personal-details-form__button" :loading="loading" base :name="saveBtnText" @click="save" />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import { required } from 'vuelidate/lib/validators'
import 'vue2-datepicker/index.css'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VuePhoneNumberInput from 'vue-phone-number-input'

import VField from '@/components/controls/VField.vue'
import VBtn from '@/components/controls/VBtn.vue'

export default {
  name: 'PersonalDetailsForm',
  components: { DatePicker, VuePhoneNumberInput, VField, VBtn },
  props: {
    birthDate: { type: String, default: '' },
    phone: { type: String, default: '' },
    phoneCode: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    translations: { type: Object, default: () => ({}) },
    saveBtnText: { type: String, default: '' }
  },

  data() {
    return {
      localPersonalDetails: {
        birthDate: '',
        phone: '',
        phoneCode: ''
      },
      country: {
        isValid: true
      },
      countryError: false,
      phoneError: false
    }
  },

  validations() {
    return {
      localPersonalDetails: {
        birthDate: { required }
      }
    }
  },

  mounted() {
    if (this.phone) {
      this.localPersonalDetails.phone = this.phone
    }

    if (this.birthDate) {
      this.localPersonalDetails.birthDate = this.birthDate
    }

    if (this.phoneCode) {
      this.localPersonalDetails.phoneCode = this.phoneCode
    }
  },

  methods: {
    countryChanged(country) {
      this.country = country
      this.localPersonalDetails.phoneCode = country.countryCode
      if (country.isValid !== undefined) {
        this.countryError = !country.isValid
      }
      this.phoneError = this.countryError && this.localPersonalDetails.phone !== ''
    },

    save() {
      this.$v.$touch()
      this.countryChanged(this.country)

      if (this.$v.$invalid || this.phoneError) {
        return
      }

      this.$emit('save', this.localPersonalDetails)
    }
  }
}
</script>

<style lang="scss">
.personal-details-form {
  max-width: 720px;

  &__field {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &__date {
    width: 100%;
    max-width: 250px;
    .mx-input {
      font-size: 18px;
      color: $text-primary;
      height: 48px;
      box-shadow: none;
      border: 1px solid #ebebeb;
      border-radius: 4px;

      &::placeholder {
        color: #868686;
        font-size: 18px;
      }
    }
  }

  &__phone {
    .country-selector {
      height: 48px;
      min-height: 48px;

      &__input {
        border-radius: 0;
        height: 100%;
        border-color: #ebebeb;
        color: $text-primary;
      }

      &__country-flag {
        display: none;
      }
    }

    .input-tel {
      height: 48px;
      min-height: 48px;

      &__input {
        height: 100%;
        border-color: #ebebeb;
        color: $text-primary;
        font-size: 18px;

        &::placeholder {
          color: #868686;
          font-size: 18px;
        }
      }
    }
  }

  &__button {
    margin-top: 72px;
  }
}
</style>
