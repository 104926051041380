<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-learn-more">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close">
      </div>
      <div class="pop-up-learn-more__title">
        {{ confirmationTranslate.title.text }}
      </div>
      <div class="pop-up-learn-more__subtitle">
        {{ confirmationTranslate.description.text }}
      </div>
      <div class="pop-up-learn-more__content">
        <div class="instant">
          <div class="instant__title">
            {{ confirmationTranslate.instantTitle.text }}
          </div>
          <div class="instant__description">
            {{ confirmationTranslate.instantDescription.text }}
          </div>
          <div class="instant__example">
            {{ confirmationTranslate.instantNote.text }}
            <img src="@/assets/img/instant-confirmation.jpg" alt="instant">
          </div>
        </div>
        <div class="instant-and-manual">
          <div class="instant-and-manual__title">
            {{ confirmationTranslate.instantManualTitle.text }}
          </div>
          <div class="instant-and-manual__description">
            {{ confirmationTranslate.instantManualDescription.text }}
          </div>
          <div class="instant-and-manual__example">
            <img
              src="@/assets/img/instant-and-manual-confirmation.jpg"
              alt="instant-and-manual"
            >
          </div>
        </div>
        <div class="manual">
          <div class="manual__title">
            {{ confirmationTranslate.manualTitle.text }}
          </div>
          <div class="manual__description">
            {{ confirmationTranslate.manualDescription.text }}
          </div>
          <div class="manual__example">
            {{ confirmationTranslate.manualNote.text }}
            <img
              src="@/assets/img/manual-confirmation.jpg"
              alt="one-set-time"
            >
          </div>
        </div>
      </div>
      <div class="pop-up-learn-more__nav" @click="closeWindow">
        <Btn base :name="confirmationTranslate.understoodBtn.text" />
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/controls/Btn.vue'
export default {
  components: {
    Btn
  },
  props: ['isActive', 'confirmationTranslate'],
  methods: {
    closeWindow () {
      const active = this.isActive
      this.$emit('close', active)
    }
  }
}
</script>

<style lang="scss">
.pop-up-learn-more {
  position: relative;
  background: $white;
  padding: 35px;
  width: 680px;
  &__title {
    margin-bottom: 22px;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
  &__subtitle {
    margin-bottom: 22px;
    font-size: 16px;
  }
  &__content {
    .instant {
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ececec;
      &__title {
        margin-bottom: 10px;
        font-weight: bold;
      }
      &__description {
        font-size: 16px;
      }
      &__example {
        color: #888a93;
        font-size: 16px;
        img {
          margin-left: -11px;
          width: 505px;
          height: 67px;
        }
      }
    }
    .instant-and-manual {
      padding-top: 10px;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ececec;
      &__title {
        margin-bottom: 10px;
        font-weight: bold;
      }
      &__description {
        font-size: 16px;
      }
      &__example {
        color: #888a93;
        font-size: 16px;
        img {
          margin-left: -11px;
          width: 505px;
          height: 67px;
        }
      }
    }
    .manual {
      padding-top: 10px;
      margin-bottom: 35px;
      &__title {
        margin-bottom: 10px;
        font-weight: bold;
      }
      &__description {
        font-size: 16px;
      }
      &__example {
        color: #888a93;
        font-size: 16px;
        img {
          margin-left: -11px;
          width: 505px;
          height: 67px;
        }
      }
    }
  }
  &__nav {
    text-align: right;
  }
}
</style>
