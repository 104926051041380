var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.to
    ? _c(
        "nuxt-link",
        _vm._g(
          _vm._b(
            {
              staticClass: "v-button",
              class: _vm.setClass,
              attrs: { to: _vm.to, disabled: _vm.loading || _vm.disabled },
            },
            "nuxt-link",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm.loading
            ? _c("div", { staticClass: "v-button__loader" })
            : _vm._t("default"),
        ],
        2
      )
    : _c(
        "button",
        _vm._g(
          _vm._b(
            {
              staticClass: "v-button",
              class: _vm.setClass,
              attrs: { type: _vm.type, disabled: _vm.loading || _vm.disabled },
            },
            "button",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm.loading
            ? _c("div", { staticClass: "v-button__loader" })
            : _vm._t("default"),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }