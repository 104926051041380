var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      staticClass: "popup-cancel-booking",
      attrs: { title: _vm.title, size: "large" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "popup-cancel-booking__footer" },
                [
                  _c(
                    "VButton",
                    { attrs: { outline: "" }, on: { click: _vm.cancel } },
                    [_vm._v(_vm._s(_vm.cancelText))]
                  ),
                  _vm._v(" "),
                  _c(
                    "VButton",
                    { attrs: { base: "" }, on: { click: _vm.save } },
                    [_vm._v(_vm._s(_vm.saveText))]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c("div", { staticClass: "popup-cancel-booking__content" }, [
        _c("div", { staticClass: "popup-cancel-booking__field" }, [
          _vm._v("\n      " + _vm._s(_vm.content) + "\n    "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }