<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 7 9 18l-5-5"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCheckmark'
}
</script>
