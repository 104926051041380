<template>
  <component :is="iconComponent" class="base-icon" />
</template>

<script>
export default {
  name: 'BaseIcon',

  props: {
    iconName: { type: String, required: true }
  },

  data() {
    return {
      iconComponent: null
    }
  },

  watch: {
    iconName: {
      immediate: true,
      async handler(value) {
        const icon = await import(`@/components/icons/${value}.vue`)

        if (icon?.default) {
          this.iconComponent = icon.default
        }
      }
    }
  }
}
</script>

<style lang="scss">
.base-icon {
  flex-shrink: 0;
}
</style>
