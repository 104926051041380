<template>
  <div class="notification-view">
    <transition-group name="notification-list" class="notification-view__items" tag="div">
      <NotificationItem
        v-for="notification in notificationPool"
        :key="notification.id"
        class="notification-view__item"
        :type="notification.type"
        :title="notification.title"
        :text="notification.text"
        @close="removeNotification(notification)"
      />
    </transition-group>
  </div>
</template>

<script>
import notificationService from '@/services/notification'
import NotificationItem from '@/components/common/VNotificationItem'

export default {
  name: 'NotificationView',
  components: {
    NotificationItem
  },

  data() {
    return {
      notificationPool: []
    }
  },

  mounted() {
    notificationService.addEventListener('created', this.pushNotification)
    notificationService.addEventListener('removed', this.removeNotification)
  },

  beforeDestroy() {
    notificationService.removeEventListener('created', this.pushNotification)
    notificationService.removeEventListener('removed', this.removeNotification)
  },

  methods: {
    pushNotification(item) {
      this.notificationPool = [...this.notificationPool, item]
    },

    removeNotification(item) {
      this.notificationPool = this.notificationPool.filter(notification => notification.id !== item.id)
    }
  }
}
</script>

<style lang="scss">
.notification-view {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000000000;

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    position: relative;
  }

  &__item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.notification-list-enter-active,
.notification-list-move {
  transition: all 0.6s;
}

.notification-list-leave-to {
  transition: all 0.6s;
  opacity: 0;
  transform: translateX(50%);
}

.notification-list-enter {
  opacity: 0;
  transform: translateY(50%);
}
</style>
