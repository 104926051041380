var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "v-list2", style: _vm.cssVars },
    [
      _vm._l(_vm.itemsShow, function (item, index) {
        return [
          _vm.isShowDescription(item.description)
            ? _vm._l(
                _vm.splitDescription(item.description),
                function (line, lineIndex) {
                  return _c(
                    "li",
                    {
                      key: `${index}-${lineIndex}`,
                      staticClass: "v-list__item",
                      class: _vm.itemClasses,
                    },
                    [_c("div", [_vm._v(_vm._s(line))])]
                  )
                }
              )
            : _c(
                "li",
                {
                  key: `details-${index}`,
                  staticClass: "v-list__item",
                  class: _vm.itemClasses,
                },
                [
                  _c(
                    "div",
                    {
                      style:
                        item.details != null && item.details.length > 0
                          ? "margin-bottom: 7px; margin-top: 4px;"
                          : "margin-top: 9px;",
                    },
                    [
                      _c("div", [_vm._v(_vm._s(item.address))]),
                      _vm._v(" "),
                      item.details != null && item.details.length > 0
                        ? _c("div", [_vm._v(_vm._s(item.details))])
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
        ]
      }),
      _vm._v(" "),
      _vm.items.length > _vm.showCount
        ? _c(
            "ButtonMore",
            {
              staticClass: "v-list__button-show",
              on: { click: _vm.toggleShow },
            },
            [_vm._v(_vm._s(_vm.textShow))]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }