<template>
  <button
    class="btn"
    :class="setClass"
    :type="type"
    :@click="click"
    :disabled="disabled"
  >
    <slot>
      <div v-if="loading" class="loader"></div>
      {{ loading ? loadingName :name }}
    </slot>
  </button>
</template>

<script>
import '@/assets/css/btn.scss'

export default {
  name: 'Btn',
  props: {
    type: String,
    name: String,
    click: String,
    disabled: Boolean,
    // Button type
    outline: Boolean,
    base: Boolean,
    green: Boolean,
    loading: Boolean,
    loadingName: String
  },
  computed: {
    setClass () {
      const typeBtn = this.outline
        ? 'outline'
        : this.base
          ? 'base'
          : this.green
            ? 'green'
            : ''
      return 'btn_' + typeBtn
    }
  }
}
</script>
