<template>
  <div v-if="isOpenModal" class="wrapper" @click="closeWindow">
    <div class="pop-up-screen-resolution-notice">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close">
      </div>
      <div class="pop-up-screen-resolution-notice__title">
        {{ screenResolutionNotice?.name?.text }}
      </div>
      <div class="pop-up-screen-resolution-notice__text">
        {{ screenResolutionNotice?.text?.text }}
      </div>
      <div class="pop-up-screen-resolution-notice__nav">
        <div class="continue" @click="closeWindow">
          <Btn base :name="screenResolutionNotice?.understoodBtn?.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/controls/Btn.vue'

const MIN_RESOLUTION = 1340
const STORAGE_KEY = 'ignoreUnsupportedPageWidth'
const HIDE_INTERVAL = 14 * 24 * 3600 * 1000 // 2 Week

const shouldSkipWarning = () => {
  const cookies = document.cookie.split(';').map(x => x.split('=')[0].trim())
  return cookies.includes(STORAGE_KEY)
}

const ignoreWarning = () => {
  const expireTime = Date.now() + HIDE_INTERVAL
  const expires = new Date(expireTime).toUTCString()
  const cookie = `${STORAGE_KEY}=true; expires=${expires}`
  // console.log(cookie);
  document.cookie = cookie
}

export default {
  components: {
    Btn
  },
  data: () => ({
    screenResolutionNotice: {},
    isOpenModal: false,
    lang: ''
  }),
  async fetch() {
    this.lang = this.$cookies.get('i18n_redirected')
    const data = await this.$axios.$get(`/api/v1/pages/screen-resolution-notice?language=${this.lang}`)
    this.screenResolutionNotice = data.fields
  },
  mounted() {
    if (shouldSkipWarning()) return
    const matcher = window.matchMedia(`(min-width: ${MIN_RESOLUTION}px)`)
    if (matcher.matches) return
    this.isOpenModal = true
  },
  methods: {
    closeWindow() {
      this.isOpenModal = false
      const active = this.isActive
      this.$emit('close', active)
      ignoreWarning()
    }
  }
}
</script>

<style lang="scss">
.pop-up-screen-resolution-notice {
  background: $white;
  position: relative;
  padding: 42.21px 30px 30px 37.23px;
  width: 60vw;
  color: #1E2843;
  max-width: 620px;

  &__title {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
  }

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin: 20px 0 30px 0;
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      &_outline {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }

      &_base {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        padding: 9px 17px 8px 16px;
        width: 151px;
      }
    }
  }
}

@media (max-width: 640px) {
  .pop-up-screen-resolution-notice {
    width: 80vw;

    &_base {
      padding: 10px 14px 10px 15px;
    }
  }

  .pop-up-screen-resolution-notice__nav {
    justify-content: flex-start;
  }
}
</style>
