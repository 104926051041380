var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "account-nav" },
    [
      !_vm.isSellerCompany
        ? _c(
            "nuxt-link",
            {
              staticClass: "account-nav__item",
              attrs: { to: _vm.localePath(`/account/private-information`) },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.translations.accountPrivateInformation.text) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isSellerCompany
        ? _c(
            "nuxt-link",
            {
              staticClass: "account-nav__item",
              attrs: { to: _vm.localePath(`/account/public-information`) },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.translations.accountPublicInformation.text) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isSellerCompany
        ? _c(
            "nuxt-link",
            {
              staticClass: "account-nav__item",
              attrs: { to: _vm.localePath(`/account/business-information`) },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.translations.accountBusinessInformation.text) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "nuxt-link",
        {
          staticClass: "account-nav__item",
          attrs: { to: _vm.localePath(`/account/license-and-insurance`) },
        },
        [_vm._v(_vm._s(_vm.translations.accountLicenseAndInsurance.text))]
      ),
      _vm._v(" "),
      _c(
        "nuxt-link",
        {
          staticClass: "account-nav__item",
          attrs: { to: _vm.localePath(`/account/connectivity`) },
        },
        [_vm._v(_vm._s(_vm.translations.accountConnectivity.text))]
      ),
      _vm._v(" "),
      _c(
        "nuxt-link",
        {
          staticClass: "account-nav__item",
          attrs: { to: _vm.localePath(`/account/profile-settings`) },
        },
        [_vm._v(_vm._s(_vm.translations.accountProfileSettings.text))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }