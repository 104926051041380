<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-learn-more">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close">
      </div>
      <div class="pop-up-learn-more__title">
        {{ cutoffTranslate.title.text }}
      </div>
      <div class="pop-up-learn-more__subtitle">
        {{ cutoffTranslate.description.text }}
      </div>
      <div class="pop-up-learn-more__content">
        <div class="start-time">
          <div class="start-time__title">
            {{ cutoffTranslate.relativeTitle.text }}
          </div>
          <div class="start-time__description">
            {{ cutoffTranslate.relativeDescription.text }}
          </div>
          <div class="start-time__example">
            {{ cutoffTranslate.relativeExample.text }}
            <img
              src="@/assets/img/relative-to-start-time.jpg"
              alt="relative-to-start-time"
            >
          </div>
        </div>
        <div class="one-set-time">
          <div class="one-set-time__title">
            {{ cutoffTranslate.setTimeTitle.text }}
          </div>
          <div class="one-set-time__description">
            {{ cutoffTranslate.setTimeDescription.text }}
          </div>
          <div class="one-set-time__example">
            {{ cutoffTranslate.setTimeExample.text }}
            <img src="@/assets/img/one-set-time.jpg" alt="one-set-time">
          </div>
        </div>
      </div>
      <div class="pop-up-learn-more__nav" @click="closeWindow">
        <Btn base :name="cutoffTranslate.understoodBtn.text" />
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/controls/Btn.vue'
export default {
  components: {
    Btn
  },
  props: ['isActive', 'cutoffTranslate'],
  methods: {
    closeWindow () {
      const active = this.isActive
      this.$emit('close', active)
    }
  }
}
</script>

<style lang="scss">
.pop-up-learn-more {
  position: relative;
  background: $white;
  padding: 35px;
  width: 680px;
  &__title {
    margin-bottom: 22px;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
  &__subtitle {
    margin-bottom: 22px;
    font-size: 16px;
  }
  &__content {
    .start-time {
      margin-bottom: 10px;
      &__title {
        margin-bottom: 10px;
        font-weight: bold;
      }
      &__description {
        font-size: 16px;
      }
      &__example {
        color: #888a93;
        font-size: 16px;
        img {
          margin-left: -11px;
          width: 505px;
          height: 216px;
        }
      }
    }
    .one-set-time {
      margin-bottom: 35px;
      &__title {
        margin-bottom: 10px;
        font-weight: bold;
      }
      &__description {
        font-size: 16px;
      }
      &__example {
        color: #888a93;
        font-size: 16px;
        img {
          margin-left: -11px;
          width: 505px;
          height: 216px;
        }
      }
    }
  }
  &__nav {
    text-align: right;
  }
}
</style>
