var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v-file-input" },
    [
      !_vm.previewUrl
        ? _c(
            "label",
            {
              staticClass: "v-file-input__container",
              class: {
                "v-file-input__container--error": _vm.error,
                "v-file-input__container--full": _vm.full,
              },
              attrs: { tabindex: "0" },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/add-plus.svg"),
                  width: "19",
                  height: "19",
                  alt: "Add license",
                },
              }),
              _vm._v(" "),
              _vm._t("default"),
              _vm._v(" "),
              _c(
                "input",
                _vm._b(
                  {
                    ref: "fileInput",
                    staticClass: "v-file-input__input",
                    attrs: { type: "file" },
                    on: { change: _vm.handleFileChange },
                  },
                  "input",
                  _vm.$attrs,
                  false
                )
              ),
            ],
            2
          )
        : [
            _c(
              "div",
              {
                staticClass:
                  "v-file-input__container v-file-input__container--preview",
                class: {
                  "v-file-input__container--error": _vm.error,
                  "v-file-input__container--full": _vm.full,
                },
                attrs: { tabindex: "0" },
              },
              [
                _vm._t("preview-append"),
                _vm._v(" "),
                _c("img", {
                  staticClass: "v-file-input__preview",
                  attrs: { src: _vm.previewUrl },
                }),
              ],
              2
            ),
            _vm._v(" "),
            _vm.changeText
              ? _c("label", { staticClass: "v-file-input__change" }, [
                  _vm._v("\n      + " + _vm._s(_vm.changeText) + "\n      "),
                  _c(
                    "input",
                    _vm._b(
                      {
                        ref: "fileInput",
                        staticClass: "v-file-input__input",
                        attrs: { type: "file" },
                        on: { change: _vm.handleFileChange },
                      },
                      "input",
                      _vm.$attrs,
                      false
                    )
                  ),
                ])
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }