<template>
  <VDialog
    v-model="modelVisible"
    size="large-plus"
    :title="translation.sentCode.text"
    dialog-classes="pop-up-confirm-email-code-title"
  >
    <div class="pop-up-confirm-email-code">
      <div class="pop-up-confirm-email-code__content">
        <div class="pop-up-confirm-email-code__title">
          {{ translation.sentWay.text }}
        </div>
        <VField
          :label="translation.sentEnter.text"
          class="pop-up-confirm-email-code__field"
          :style="{ marginBottom: attemptsCount >= 3 ? 27 + 'px' : '' }"
          :error="$v.code.$error"
          :error-message="getErrorMessage()"
        >
          <VInput v-model="$v.code.$model" :error="$v.code.$error" :placeholder="translation.sentYour.text" />
        </VField>
        <div v-if="attemptsCount < 3" class="pop-up-confirm-email-code__resend-code" @click="resendCode">
          {{ translation.sentResend.text }}
        </div>
      </div>

      <div class="pop-up-confirm-email-code__actions">
        <VCheckbox v-model="isTrustDevice" :label="translation.sentTrust.text" className="blue-border" />
        <v-btn
          base
          :name="translation.sendConfirm.text"
          :loading="loading"
          loading-name="confirming"
          class="pop-up-confirm-email-code__confirm"
          @click="confirmEmailCode()"
        />
      </div>
    </div>
  </VDialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import { mapState } from 'vuex';
import VDialog from '@/components/common/VDialog.vue';
import VBtn from '@/components/controls/VBtn.vue';
import VInput from '@/components/controls/VInput.vue';
import VField from '@/components/controls/VField.vue';
import VCheckbox from '@/components/controls/VCheckbox.vue';

export default {
  components: { VDialog, VBtn, VInput, VField, VCheckbox },

  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: { type: Boolean, default: false },
    email: { type: String, default: '' },
    loading: { type: Boolean, default: false },
    errorConfirmCode: { type: Boolean, default: false },
    translation: { type: Object },
  },
  data() {
    return {
      code: '',
      isTrustDevice: false,
      attemptsCount: 0,
    };
  },
  validations() {
    return {
      code: {
        required,
        validateAfterConfirm: (value, vm) => {
          if (!vm.errorConfirmCode && !value) return false;
          if (vm.errorConfirmCode && !value) return false;
          if (vm.errorConfirmCode && value) return false;
          return true;
        },
      },
    };
  },
  computed: {
    ...mapState(['userInfo']),
    modelVisible: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit('update:visible', visible);
      },
    },
    hiddenEmail() {
      return this.email.slice(0, 3) + '******' + this.email.split('@')[1];
    },
  },
  watch: {
    modelVisible() {
      if (this.modelVisible) {
        this.resetModal();
      }
    },

    errorConfirmCode(newStatus) {
      this.$v.code.$touch();
    },

    code() {
      this.$emit('resetErrorConfirmCode');
    },
  },
  methods: {
    resetModal() {
      this.$v.$reset();
    },

    confirmEmailCode() {
      this.$emit('confirmEmailCode', this.code, this.isTrustDevice);
    },

    resendCode() {
      this.attemptsCount++;
      if (this.attemptsCount <= 3) {
        this.$emit('sendEmailCode');
      }
    },

    getErrorMessage() {
      if (!this.errorConfirmCode && !this.code) return 'Code is required';
      if (this.errorConfirmCode && !this.code) return 'Please enter the code before confirming';
      if (this.errorConfirmCode && this.code) return 'Incorrect code. Please try again';
      return 'Code is required';
    },
  },
};
</script>

<style lang="scss">
.pop-up-confirm-email-code-title {
  .v-dialog__title {
    font-size: 32px;
    line-height: 37.5px;
    text-align: start;
  }
}
.pop-up-confirm-email-code {
  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &__title {
    font-size: 18px;
    line-height: normal;
  }

  &__field {
    margin: 0;
    margin-top: 20px;
    margin-bottom: 5px;

    .v-input {
      margin-top: 0px;
    }
    &:not(:first-child) {
      .v-field__info {
        margin-bottom: 0px;
      }
    }
  }

  &__actions {
    display: flex;
    gap: 0 10px;
  }

  &__confirm {
    margin-left: auto;
  }

  &__resend-code {
    cursor: pointer;
    color: $primary;
    width: max-content;
  }
}
</style>
