import { render, staticRenderFns } from "./StepBase.vue?vue&type=template&id=4436883f"
import script from "./StepBase.vue?vue&type=script&lang=js"
export * from "./StepBase.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4436883f')) {
      api.createRecord('4436883f', component.options)
    } else {
      api.reload('4436883f', component.options)
    }
    module.hot.accept("./StepBase.vue?vue&type=template&id=4436883f", function () {
      api.rerender('4436883f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/translations/StepBase.vue"
export default component.exports