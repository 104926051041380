<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-translate-select">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close">
      </div>
      <div class="pop-up-translate-select__title">
        {{ selectLanguageTranslate.title.text }}
      </div>
      <div class="pop-up-translate-select__selection">
        <selection
          default
          :default-name="languagesName"
          :items="languages"
          @name="changeLanguageName"
        />
      </div>
      <div class="pop-up-translate-select__nav">
        <div class="cancel" @click="closeWindow">
          <Btn outline :name="selectLanguageTranslate.cancelBtn.text" />
        </div>
        <div class="continue" @click="goToPage">
          <Btn base :name="selectLanguageTranslate.continueBtn.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/controls/Btn.vue'
import Selection from '@/components/controls/Selection.vue'
export default {
  components: {
    Btn,
    Selection
  },
  props: ['isActive', 'tour', 'selectLanguageTranslate'],
  data: () => ({
    languages: [
      { is_active: false, name: '', abbreviation: 'en' },
      { is_active: false, name: '', abbreviation: 'es' },
      { is_active: false, name: '', abbreviation: 'de' }
    ],
    languagesName: ''
  }),
  fetch () {
    this.languages[0].name = this.selectLanguageTranslate.selection3.text
    this.languages[1].name = this.selectLanguageTranslate.selection1.text
    this.languages[2].name = this.selectLanguageTranslate.selection2.text
    this.languagesName = this.selectLanguageTranslate.selectionDefault.text
    for (const i in this.languages) {
      if (this.tour.input_language.abbreviation.toLowerCase() === this.languages[i].abbreviation) {
        this.languages.splice(i, 1)
      }
    }
  },
  methods: {
    closeWindow () {
      const active = this.isActive
      this.$emit('close', active)
    },
    changeLanguageName (defaultName) {
      this.languagesName = defaultName
    },
    goToPage () {
      if (this.languagesName !== this.selectLanguageTranslate.selectionDefault.text && this.languagesName !== '' && this.languagesName != null) {
        let activeAbbreviation = ''
        for (const i in this.languages) {
          if (this.languages[i].is_active) {
            activeAbbreviation = this.languages[i].abbreviation
            break
          }
        }
        this.$router.push(this.localePath(`/product/${this.tour.slug}/translations/product-descriptions?lang=${activeAbbreviation}`))
      }
    }
  }
}
</script>

<style lang="scss">
.pop-up-translate-select {
  background: $white;
  position: relative;
  padding: 26px 60px 0 40px;
  width: 430px;
  &__title {
    margin-bottom: 37px;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
  &__selection {
    margin-bottom: 60px;
    .selection {
      &__preview {
        width: 320px;
      }
      &__expand {
        width: 320px;
      }
    }
  }
  &__nav {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      &_outline {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
      &_base {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
    }
  }
}
</style>
