var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "pop-up-enchanced-security" }, [
          _c("div", { staticClass: "pop-up-enchanced-security__content" }, [
            _c("div", { staticClass: "warning" }, [
              _c("img", {
                staticClass: "warning__image",
                attrs: {
                  src: require("@/assets/img/svg/warning.svg"),
                  alt: "warning",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "warning__text" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.translations.enhancedSecurityTitle.text) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "description" }, [
              _c("div", { staticClass: "description__item" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.translations.enhancedSecurityDescription.text) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "description__item" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.translations.enhancedSecurityRecommend.text) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pop-up-enchanced-security__nav" }, [
            _c(
              "div",
              { staticClass: "cancel", on: { click: _vm.closeWindow } },
              [_c("Btn", { attrs: { outline: "", name: "Maybe later" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "enable" },
              [_c("Btn", { attrs: { base: "", name: "Enable" } })],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }