<template>
  <div class="error-banner">
    <base-icon icon-name="Info"/>
    <ul>
      <li v-for="message in errors" :key="message" class="error-banner__item">
        {{ message }}
      </li>
    </ul>
  </div>
</template>

<script>
import BaseIcon from '@/components/common/BaseIcon.vue'
export default {
  name: 'ErrorBanner',
  components: { BaseIcon },
  props: {
    errors: { type: Array, default: () => [] }
  }
}
</script>

<style lang="scss">
.error-banner {
    display: flex;
    align-items: center;
    gap: 20px;
    border: 1px solid #d80007;
    background-color: #ffe2e3;
    padding: 15px 24px;
    border-radius: 4px;
    margin-top: 16px;
    color: #d80007;

    &__item {
      font-size: 18px;
      color: #1e2843;
      line-height: 27px;

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      &:before {
        content: '';
        background-color: #d80007;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
}

</style>
