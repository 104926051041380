<template>
  <div class="map">
    <span v-if="!isOpenBigMap" class="open" @click="isOpenBigMap = true"><img src="@/assets/img/svg/full.svg" alt="full-arrow"></span>
    <base-map
      :id="id"
      :location-map="locationMap"
      :circle-list="circleList"
      @setPoint="setPoint"
      @moveMarker="moveMarker"
      @addMarker="$emit('addMarker', $event)"
    />
    <big-map
      :id="id"
      :is-active="isOpenBigMap"
      :location-map="locationMap"
      :circle-list="circleList"
      @setPoint="setPoint"
      @moveMarker="moveMarker"
      @close="isOpenBigMap = false"
    />
  </div>
</template>

<script>
import BaseMap from '@/components/common/BaseMap.vue'
import BigMap from '@/components/pop-up/BigMap.vue'
export default {
  components: { BaseMap, BigMap },
  props: {
    id: { type: String, default: 'map' },
    locationMap: { type: Array, default: () => [28.99685, -13.70702] },
    circleList: { type: Array, default: () => [] }
  },
  data () {
    return {
      isOpenBigMap: false
    }
  },
  methods: {
    setPoint (index) {
      this.$emit('setPoint', index)
    },
    moveMarker (event, index) {
      this.$emit('moveMarker', event, index)
    }
  }
}
</script>
<style lang="scss">
.map{
  position: relative;
  width: 715px;
  height: 256px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 44px;
}
</style>
