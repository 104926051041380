<template>
  <div>
    <VDialog v-model="modelActive" :title="translations.acknowledgeBookingTitle.text" dialog-classes="booking-acknowledge">
      <VField
        class="booking-acknowledge__field"
        :label="translations.confirmationNumberLabel.text"
        :optional="translations.confirmationNumberOptional.text"
      >
        <VInput v-model="acknowledge" :placeholder="translations.confirmationNumberExample.text" />
      </VField>
      <div class="booking-acknowledge__footer">
        <VButton class="booking-acknowledge__button" outline @click="close">{{ translations.buttonCancel.text }}</VButton>
        <VButton class="booking-acknowledge__button" base @click="confirm">{{ translations.btnConfirm.text }}</VButton>
      </div>
    </VDialog>

    <BookingAcknowledgeSave
      v-if="showSaveDialog"
      :is-visible="showSaveDialog"
      :translations="translations"
      :acknowledge="acknowledge"
      @update:isVisible="showSaveDialog = $event"
      @confirm="handleSaveConfirm"
    />
  </div>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VButton from '@/components/controls/VButton.vue'
import VField from '@/components/controls/VField.vue'
import VInput from '@/components/controls/VInput.vue'
import BookingAcknowledgeSave from './BookingAcknowledgeSave.vue'

export default {
  name: 'BookingsAcknowledge',
  components: { VDialog, VButton, VField, VInput, BookingAcknowledgeSave },
  props: {
    isVisible: { type: Boolean, default: false },
    translations: { type: Object, required: true }
  },

  data() {
    return {
      acknowledge: '',
      showSaveDialog: false,
    }
  },

  emits: ['confirm'],

  computed: {
    modelActive: {
      get() {
        return this.isVisible
      },
      set(value) {
        this.$emit('update:isVisible', value)
      }
    }
  },

  methods: {
    close() {
      this.modelActive = false;
    },

    confirm() {
      this.showSaveDialog = true; 
    },

    handleSaveConfirm(acknowledge) {
      this.$emit('confirm', acknowledge);
      this.showSaveDialog = false; 
    }
  }
}
</script>

<style lang="scss">
.booking-acknowledge {
  width: 100%;
  max-width: 603px;

  &__field {
    margin-top: 30px;
  }

  .v-input {
    margin-top: 0 !important;

    input {
      margin-top: 0 !important;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 57px;
  }

  &__button {
    padding-left: 25px;
    padding-right: 25px;
    height: 49px;
  }
}
</style>