var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-header" }, [
    _c("div", { staticClass: "_container" }, [
      _c("div", { staticClass: "main__title" }, [_vm._v(_vm._s(_vm.name))]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page__status" },
        [
          _vm.status == "in_stock"
            ? _c(
                "div",
                { staticClass: "selected-status" },
                [
                  _c("VSelect", {
                    attrs: {
                      items: _vm.$options.statuses,
                      translates: _vm.statusesTranslates,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.setStatus()
                      },
                    },
                    model: {
                      value: _vm.currentStatus,
                      callback: function ($$v) {
                        _vm.currentStatus = $$v
                      },
                      expression: "currentStatus",
                    },
                  }),
                ],
                1
              )
            : _c("div", { staticClass: "status" }, [
                _c("div", { staticClass: "dot dot_bold" }),
                _vm._v(" "),
                _c("div", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.headerTranslate.pageStatus1.text)),
                ]),
              ]),
          _vm._v(" "),
          _c("div", { staticClass: "code" }, [
            _vm._v(
              _vm._s(_vm.headerTranslate.pageProductCode.text) +
                " " +
                _vm._s(_vm.id)
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dot dot_gray" }),
          _vm._v(" "),
          _c("div", { staticClass: "tour-name" }, [
            _vm._v("\n        " + _vm._s(_vm.slug) + "\n      "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "dot dot_gray" }),
          _vm._v(" "),
          _vm.status !== "in_stock"
            ? _c(
                "nuxt-link",
                {
                  attrs: {
                    to: _vm.localePath(`/product/${_vm.slug}/preview`),
                    tag: "a",
                  },
                },
                [
                  _c("div", { staticClass: "tour-view" }, [
                    _vm._v(_vm._s(_vm.headerTranslate.textPreview.text)),
                  ]),
                ]
              )
            : _c(
                "a",
                {
                  staticClass: "tour-view",
                  attrs: {
                    href: `https://test.vidodoguide.com/catalog/${_vm.slug}`,
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.headerTranslate.btnView.text) +
                      "\n      "
                  ),
                ]
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "page__nav" }, [
        _c(
          "div",
          { staticClass: "nav" },
          [
            _c(
              "nuxt-link",
              {
                staticClass: "nav__item",
                attrs: {
                  to: _vm.localePath(`/product/${_vm.slug}/product-content`),
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.headerTranslate.pageNav1.text) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                staticClass: "nav__item",
                attrs: {
                  to: _vm.localePath(
                    `/product/${_vm.slug}/schedules-and-prices`
                  ),
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.headerTranslate.pageNav3.text) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                staticClass: "nav__item",
                attrs: {
                  to: _vm.localePath(`/product/${_vm.slug}/booking-details`),
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.headerTranslate.pageNav2.text) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                staticClass: "nav__item",
                attrs: { to: _vm.localePath(`/product/${_vm.slug}/tickets`) },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.headerTranslate.pageNav4.text) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                staticClass: "nav__item",
                attrs: {
                  to: _vm.localePath(`/product/${_vm.slug}/translations`),
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.headerTranslate.pageNav6.text) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                staticClass: "nav__item",
                attrs: {
                  to: _vm.localePath(`/product/${_vm.slug}/special-offers`),
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.headerTranslate.pageNav5.text) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                staticClass: "nav__item",
                attrs: {
                  to: _vm.localePath(`/product/${_vm.slug}/product-connection`),
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.headerTranslate.textConnection.text) +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }