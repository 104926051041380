<template>
  <div class="v-file-input">
    <label
      v-if="!previewUrl"
      class="v-file-input__container"
      :class="{ 'v-file-input__container--error': error, 'v-file-input__container--full': full }"
      tabindex="0"
    >
      <img src="@/assets/img/svg/add-plus.svg" width="19" height="19" alt="Add license" />
      <slot />
      <input ref="fileInput" class="v-file-input__input" v-bind="$attrs" type="file" @change="handleFileChange" />
    </label>

    <template v-else>
      <div
        class="v-file-input__container v-file-input__container--preview"
        :class="{ 'v-file-input__container--error': error, 'v-file-input__container--full': full }"
        tabindex="0"
      >
        <slot name="preview-append" />
        <img :src="previewUrl" class="v-file-input__preview" />
      </div>
      <label v-if="changeText" class="v-file-input__change">
        + {{ changeText }}
        <input ref="fileInput" class="v-file-input__input" v-bind="$attrs" type="file" @change="handleFileChange" />
      </label>
    </template>
  </div>
</template>

<script>
export default {
  name: 'VFileInput',

  inheritAttrs: false,

  props: {
    previewUrl: { type: String, default: '' },
    full: { type: Boolean, default: false },
    changeText: { type: String, default: '' },
    error: { type: [Boolean, String], default: false }
  },

  methods: {
    handleFileChange(event) {
      this.$emit('change', event.target.files?.[0])
      this.$refs.fileInput.value = ''
    }
  }
}
</script>

<style lang="scss">
.v-file-input {
  &__container {
    width: 165px;
    height: 114px;
    border: 1px dashed #6e6e6e;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    transition: all 0.3s;
    text-align: center;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      color: $primary;
      border-color: $primary;
    }

    &--error {
      color: $red;
      border-color: $red;
    }

    &--preview {
      cursor: default;

      &:hover {
        border-color: #6e6e6e;
      }
    }
  }

  &__preview {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &__label {
    font-size: 1.25rem;
    line-height: 1.6;
    color: inherit;
    user-select: none;
    transition: color 0.2s;
  }

  &__input {
    display: none;
  }

  &__change {
    display: block;
    margin-top: 32px;
    color: $primary;
    font-size: 18px;
    cursor: pointer;
  }
}
</style>
