var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "container__holder" }, [
        _c("div", { staticClass: "container__title" }, [
          _vm._v(_vm._s(_vm.translations.informationDisplayedOnVoucher.text)),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container__text" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.translations.importantInformation.text) +
              "\n      "
          ),
          _c("span", { staticClass: "pre-wrap" }, [
            _vm._v(_vm._s(_vm.description)),
          ]),
          _vm._v(" "),
          _vm.isDescriptionLarge
            ? _c(
                "div",
                {
                  staticClass: "text-collapse-toggle-button",
                  on: {
                    click: function ($event) {
                      _vm.isCollapsedDescription = !_vm.isCollapsedDescription
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.isCollapsedDescription ? "Show all" : "Show less"
                      ) +
                      "\n        "
                  ),
                  _c(
                    "div",
                    { staticClass: "text-collapse-toggle-button__show-icon" },
                    [
                      _vm.isCollapsedDescription
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/img/svg/show-all-icon.svg"),
                              alt: "Show all",
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@/assets/img/svg/show-less-icon.svg"),
                              alt: "Show less",
                            },
                          }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "container__text" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.translations.leavingInformation.text) +
              "\n      "
          ),
          _c("span", { staticClass: "pre-wrap" }, [
            _vm._v(_vm._s(_vm.leaving)),
          ]),
          _vm._v(" "),
          _vm.isLeavingLarge
            ? _c(
                "div",
                {
                  staticClass: "text-collapse-toggle-button",
                  on: {
                    click: function ($event) {
                      _vm.isCollapsedLeaving = !_vm.isCollapsedLeaving
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.isCollapsedLeaving ? "Show all" : "Show less"
                      ) +
                      "\n        "
                  ),
                  _c(
                    "div",
                    { staticClass: "text-collapse-toggle-button__show-icon" },
                    [
                      _vm.isCollapsedLeaving
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/img/svg/show-all-icon.svg"),
                              alt: "Show all",
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@/assets/img/svg/show-less-icon.svg"),
                              alt: "Show less",
                            },
                          }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "nuxt-link",
        {
          staticClass: "container__edit",
          attrs: {
            to: _vm.localePath(
              `/product/${_vm.tour.slug}/tickets/information-on-voucher`
            ),
          },
        },
        [
          _c("VBtn", {
            attrs: { outline: "", name: _vm.translations.editBtn.text },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }