<template>
  <VDialog :visible="isOpen" class="v-confirm-dialog">
    <template #document>
      <div class="v-confirm-dialog">
        <h4 class="v-confirm-dialog__title">
          {{ title }}
        </h4>
        <p v-if="text" class="v-confirm-dialog__text">
          {{ text }}
        </p>

        <div class="v-confirm-dialog__footer">
          <VButton outline class="v-confirm-dialog__button" @click="cancel">
            {{ cancelText }}
          </VButton>

          <VButton base class="v-confirm-dialog__button" @click="confirm">
            {{ confirmText }}
          </VButton>
        </div>
      </div>
    </template>
  </VDialog>
</template>

<script>
import VButton from '@/components/controls/VButton.vue'
import VDialog from '@/components/common/VDialog.vue'

export default {
  name: 'VConfirmDialog',
  components: { VButton, VDialog },

  props: {
    title: { type: String, required: true },
    text: { type: String, default: '' },
    cancelText: { type: String, default: 'No' },
    confirmText: { type: String, default: 'Yes' },
    large: { type: Boolean, default: false }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    open() {
      return new Promise((resolve, reject) => {
        if (this.$options.reject) {
          this.$options.reject()
          this.cancelPromise()
        }

        this.$options.resolve = resolve
        this.$options.reject = reject

        this.isOpen = true
      })
    },

    confirm() {
      this.closeAndResolveValue(true)
    },

    cancel() {
      this.closeAndResolveValue(false)
    },
    closeAndResolveValue(value) {
      this.$options.resolve(value)
      this.cancelPromise()

      this.isOpen = false
    },

    cancelPromise() {
      this.$options.resolve = null
      this.$options.reject = null
    }
  },
  resolve: null,
  reject: null
}
</script>
<style lang="scss">
.v-confirm-dialog {
  padding: 30px 42px 20px;
  background-color: #fff;
  border-radius: 12px;
  max-width: 475px;

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: $text-primary;
    margin-bottom: 25px;
    text-align: center;
  }

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $text-primary;
    margin-bottom: 32px;
    text-align: center;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__button {
    text-transform: uppercase;
  }
}
</style>
