<template>
  <label :class="containerClasses" class="v-radio">
    <input
      v-model="modelValue"
      type="radio"
      v-bind="$attrs"
      :value="value"
      :disabled="disabled"
      class="v-radio__input"
      :class="classes"
    >
    <span class="v-radio__mark" />
    <span class="v-radio__label">
      <slot>{{ label }}</slot>
    </span>
  </label>
</template>

<script>
export default {
  name: 'VRadio',
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    checked: { type: [Boolean, String, Number], default: null },
    value: { type: [Boolean, String, Number], default: null },
    disabled: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    label: { type: String, default: '' }
  },
  computed: {
    modelValue: {
      get () {
        return this.checked
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    containerClasses () {
      return { 'radio--disabled': this.disabled }
    },
    classes () {
      return { 'radio__input--error': this.error }
    }
  }
}
</script>

<style lang="scss">
.v-radio {
  $block: &;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  display: flex;
  align-items: center;

  &--disabled {
    color: grey;
    cursor: not-allowed;
  }

  &__input {
    position: absolute;
    height: 1px;
    width: 1px;
    padding: 0;
    margin: -1px;
    clip: rect(0 0 0 0);

    &:checked ~ #{$block}__mark {
      border-color: $primary;

      &:before {
        display: block;
      }
    }

    &:disabled {
      ~ #{$block}__mark {
        background: lightgray;
      }
      ~ #{$block}__label {
        color: gray;
      }
      &:checked ~ #{$block}__mark {
        &:before {
          visibility: visible;
          background: gray;
        }
      }
    }
  }

  &__mark {
    appearance: none;
    margin: 0;
    font: inherit;
    width: 20px;
    height: 20px;
    border: 1px solid $gray;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    &:not(:last-child) {
      margin-right: 1ch;
    }

    &:disabled {
      color: grey;
      cursor: not-allowed;
    }

    &:before {
      content: '';
      display: none;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: $primary;
    }
  }
}
</style>
