<template>
  <button class="button-more" v-bind="$attrs" v-on="$listeners">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'ButtonMore'
}
</script>

<style lang="scss">
.button-more {
  display: block;
  max-width: fit-content;
  font-size: 20px;
  color: #3287f7;
  background: none;
  border: none;
  cursor: pointer;
}
</style>
