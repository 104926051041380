<template>
  <div v-if="importantInfo && importantInfo !== ''" id="important-info" class="preview__section">
    <div class="preview__title">
      {{ translations.textImportant.text }}
    </div>
    <div v-for="(line, key) in lines" :key="key" class="preview__include">
      <div class="preview__dot preview__dot--orange" />
      <span class="preview__include-text">{{ line }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImportantInfo',
  props: {
    importantInfo: { type: String, default: '' },
    translations: { type: Object, default: () => ({}) }
  },
  computed: {
    lines() {
      return this.importantInfo.split('\r\n').filter(el => el.trim().length > 0);
    }
  }
}
</script>

<style lang="scss">
</style>
