<template>
  <div class="age-groups">
    <div class="age-groups__title">
      {{ translates.subtitle.text }}
    </div>
    <ErrorBanner
      v-if="$v.$anyDirty && $v.$invalid && errorMessages.length"
      :errors="errorMessages"
      class="age-groups__errors"
    />
    <div class="age-groups__content">
      <div v-for="(member, index) in localMembers" :key="`member-${member.id}`" class="age-groups__age-item">
        <div class="age-groups__checkbox">
          <VCheckbox v-model="member.checked" :label="member.name" :disabled="member.in_use && !isGroupPricing" />
          <div v-if="member.in_use && !isGroupPricing" class="age-groups__question-mark">
            <img src="@/assets/img/svg/question-info.svg" alt="question-mark" />
            <div class="age-groups__question-mark-description">
              {{ translates.questionDescription.text }}
            </div>
          </div>
        </div>
        <div class="age-groups__min-age">
          <div v-if="index == 0" class="age-groups__age-description">
            {{ translates.minAge.text }}
          </div>
          <InputFields
            v-model="$v.localMembers.$each.$iter[index].min_age.$model"
            :class="{
              'input-error':
                $v.localMembers.$each.$iter[index].min_age.$dirty &&
                $v.localMembers.$each.$iter[index].min_age.$invalid,
            }"
            :read-only="member.in_use && !isGroupPricing"
            default
            type="number"
            min="0"
            max="120"
            step="1"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            onpaste="return false;"
          />
        </div>
        <div class="age-groups__dash">-</div>
        <div class="age-groups__max-age">
          <div v-if="index == 0" class="age-groups__age-description">
            {{ translates.maxAge.text }}
          </div>
          <InputFields
            v-model="$v.localMembers.$each.$iter[index].max_age.$model"
            :class="{
              'input-error':
                $v.localMembers.$each.$iter[index].max_age.$dirty &&
                $v.localMembers.$each.$iter[index].max_age.$invalid,
            }"
            default
            :read-only="member.in_use && !isGroupPricing"
            type="number"
            min="0"
            max="120"
            step="1"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            onpaste="return false;"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { requiredIf, minValue, maxValue } from 'vuelidate/lib/validators';

import InputFields from '@/components/controls/Input-fields.vue';
import VCheckbox from '@/components/controls/VCheckbox.vue';
import ErrorBanner from '@/components/ErrorBanner.vue';

import { parseTranslate } from '@/utils/translations';

const ADULT_MEMBER = 'adult';
const SENIOR_MEMBER = 'senior';
const YOUTH_MEMBER = 'youth';
const CHILD_MEMBER = 'child';
const INFANT_MEMBER = 'infant';

const MEMBERS_TYPES = {
  1: ADULT_MEMBER,
  2: SENIOR_MEMBER,
  3: YOUTH_MEMBER,
  4: CHILD_MEMBER,
  5: INFANT_MEMBER,
};

const ORDER_GROUP_IDS = [2, 1, 3, 4, 5];

export default {
  name: 'AgeForm',
  components: { InputFields, VCheckbox, ErrorBanner },
  props: {
    members: { type: Array, default: () => [] },
    isGroupPricing: { type: Boolean, default: false },
    translates: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      localMembers: [],
    };
  },

  computed: {
    checkedMembers() {
      return this.localMembers.filter(({ checked }) => checked).map(({ id }) => id);
    },

    errorMessages() {
      const errors = [];
      Object.values(this.$v.localMembers.$each.$iter).forEach((v) => {
        if (v.max_age.$dirty) {
          const {
            required,
            minValue,
            lessThanSeniorMinAge,
            lessThanAdultMinAge,
            lessThanYouthMinAge,
            lessThanChildMinAge,
            lessThanInfantMinAge,
          } = v.max_age;

          if (!required) {
            errors.push(parseTranslate(this.translates.errorTemplate2.text, v.$model.name));
          }

          if (!minValue) {
            errors.push(parseTranslate(this.translates.errorTemplate3.text, v.$model.name));
          }

          if (!lessThanSeniorMinAge) {
            console.log('cool: ', this.translates);
            const message = parseTranslate(
              this.translates.errorTemplate5.text,
              v.$model.name,
              this.getGroupName(SENIOR_MEMBER)
            );
            errors.push(message);
          }

          if (!lessThanAdultMinAge) {
            const message = parseTranslate(
              this.translates.errorTemplate5.text,
              v.$model.name,
              this.getGroupName(ADULT_MEMBER)
            );
            errors.push(message);
          }

          if (!lessThanYouthMinAge) {
            const message = parseTranslate(
              this.translates.errorTemplate5.text,
              v.$model.name,
              this.getGroupName(YOUTH_MEMBER)
            );
            errors.push(message);
          }

          if (!lessThanChildMinAge) {
            const message = parseTranslate(
              this.translates.errorTemplate5.text,
              v.$model.name,
              this.getGroupName(CHILD_MEMBER)
            );
            errors.push(message);
          }

          if (!lessThanInfantMinAge) {
            const message = parseTranslate(
              this.translates.errorTemplate5.text,
              v.$model.name,
              this.getGroupName(INFANT_MEMBER)
            );
            errors.push(message);
          }
        }

        if (v.min_age.$dirty) {
          const {
            required,
            maxValue,
            moreThanSeniorMaxAge,
            moreThanAdultMaxAge,
            moreThanYouthMaxAge,
            moreThanChildMaxAge,
            moreThanInfantMaxAge,
          } = v.min_age;

          if (!required) {
            errors.push(parseTranslate(this.translates.errorTemplate1.text, v.$model.name));
          }

          if (!maxValue) {
            errors.push(parseTranslate(this.translates.errorTemplate4.text, v.$model.name));
          }

          if (!moreThanSeniorMaxAge) {
            const message = parseTranslate(
              this.translates.errorTemplate6.text,
              v.$model.name,
              this.getGroupName(SENIOR_MEMBER)
            );
            errors.push(message);
          }

          if (!moreThanAdultMaxAge) {
            const message = parseTranslate(
              this.translates.errorTemplate6.text,
              v.$model.name,
              this.getGroupName(ADULT_MEMBER)
            );
            errors.push(message);
          }

          if (!moreThanYouthMaxAge) {
            const message = parseTranslate(
              this.translates.errorTemplate6.text,
              v.$model.name,
              this.getGroupName(YOUTH_MEMBER)
            );
            errors.push(message);
          }

          if (!moreThanChildMaxAge) {
            const message = parseTranslate(
              this.translates.errorTemplate6.text,
              v.$model.name,
              this.getGroupName(CHILD_MEMBER)
            );
            errors.push(message);
          }
          if (!moreThanInfantMaxAge) {
            const message = parseTranslate(
              this.translates.errorTemplate6.text,
              v.$model.name,
              this.getGroupName(INFANT_MEMBER)
            );
            errors.push(message);
          }
        }
      });

      if (this.$v.localMembers.$each.$dirty && this.checkedMembers.length === 0) {
        errors.push(this.translates.errorSelection.text);
      }

      return errors;
    },

    isFormValid() {
      return !this.$v.$invalid && this.errorMessages.length === 0;
    },
  },

  validations() {
    return {
      localMembers: {
        $each: {
          max_age: {
            required: requiredIf((group) => group.checked),
            maxValue: (value, group) => (!group.checked ? true : maxValue(120)(Number(value))),
            minValue: (value, group) => (!group.checked ? true : minValue(Number(group.min_age) || 0)(Number(value))),
            lessThanSeniorMinAge: this.isLessThanMinAge(SENIOR_MEMBER),
            lessThanAdultMinAge: this.isLessThanMinAge(ADULT_MEMBER),
            lessThanYouthMinAge: this.isLessThanMinAge(YOUTH_MEMBER),
            lessThanChildMinAge: this.isLessThanMinAge(CHILD_MEMBER),
            lessThanInfantMinAge: this.isLessThanMinAge(INFANT_MEMBER),
          },
          min_age: {
            required: requiredIf((group) => group.checked),
            maxValue: (value, group) => (!group.checked ? true : maxValue(Number(group.max_age) || 120)(Number(value))),
            minValue: (value, group) => (!group.checked ? true : minValue(0)(Number(value))),
            moreThanSeniorMaxAge: this.isGreaterThanMaxAge(SENIOR_MEMBER),
            moreThanAdultMaxAge: this.isGreaterThanMaxAge(ADULT_MEMBER),
            moreThanYouthMaxAge: this.isGreaterThanMaxAge(YOUTH_MEMBER),
            moreThanChildMaxAge: this.isGreaterThanMaxAge(CHILD_MEMBER),
            moreThanInfantMaxAge: this.isGreaterThanMaxAge(INFANT_MEMBER),
          },
          checked: {
            required: requiredIf(() => this.checkedMembers.length === 0),
          },
        },
      },
    };
  },

  watch: {
    isFormValid() {
      this.$emit('update-valid', this.isFormValid);
    },

    localMembers: {
      deep: true,
      handler() {
        this.$emit('update:members', this.localMembers);
      },
    },
  },

  created() {
    this.localMembers = JSON.parse(JSON.stringify(this.members));
  },

  methods: {
    isLessThanMinAge(groupName) {
      return (value, currentGroup) => {
        const comparableGroup = this.getGroupByType(groupName);
        const comparableGroupIsCurrent = this.getMemberType(currentGroup.id) === groupName;
        const olderGroupId = this.getGroupIdOlder(currentGroup);

        if (!currentGroup.checked || comparableGroupIsCurrent || olderGroupId !== comparableGroup.id) {
          return true;
        }

        return Number(value) < Number(comparableGroup.min_age);
      };
    },

    isGreaterThanMaxAge(groupName) {
      return (value, currentGroup) => {
        const comparableGroup = this.getGroupByType(groupName);
        const comparableGroupIsCurrent = this.getMemberType(currentGroup.id) === groupName;
        const youngerGroupId = this.getGroupIdYounger(currentGroup);

        if (!currentGroup.checked || comparableGroupIsCurrent || youngerGroupId !== comparableGroup.id) {
          return true;
        }

        return Number(value) > Number(comparableGroup.max_age);
      };
    },

    getGroupIdOlder(group) {
      const orderGroupReversed = [...ORDER_GROUP_IDS].reverse();
      const index = orderGroupReversed.findIndex((id) => id === group.id);
      const olderGroupIds = orderGroupReversed.slice(index + 1);

      return olderGroupIds.find((id) => this.checkedMembers.includes(id));
    },

    getGroupIdYounger(group) {
      const index = ORDER_GROUP_IDS.findIndex((id) => id === group.id);
      const youngerGroupIds = ORDER_GROUP_IDS.slice(index + 1);

      return youngerGroupIds.find((id) => this.checkedMembers.includes(id));
    },

    getMemberType(id) {
      return MEMBERS_TYPES[id];
    },

    getGroupByType(type) {
      return this.localMembers.find(({ id }) => this.getMemberType(id) === type);
    },

    getGroupName(type) {
      return this.getGroupByType(type).name;
    },

    validate() {
      this.$v.localMembers.$each.$touch();

      return !this.$v.localMembers.$each.$invalid && this.checkedMembers.length;
    },
  },
};
</script>

<style lang="scss">
.age-groups {
  margin-bottom: 29px;

  &__title {
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__errors {
    margin-bottom: 16px;
  }

  &__content {
    padding-top: 30px;
  }

  &__age-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    width: 126px;
    margin-right: 10px;
  }

  &__question-mark {
    flex-shrink: 0;
    position: relative;
    width: 18px;
    height: 18px;
    margin-left: 8px;

    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }

    &:hover {
      .age-groups__question-mark-description {
        display: block;
      }
    }

    &-description {
      display: none;
      position: absolute;
      top: 10px;
      left: -8px;
      z-index: 4;
      width: 250px;
      padding: 10px 10px;
      position: relative;
      background: #eee;
      border-radius: 3px;
      font-size: 18px;
      line-height: 21px;
      &::before {
        content: '';
        border: solid transparent;
        position: absolute;
        left: 8px;
        bottom: 100%;
        border-bottom-color: #eee;
        border-width: 9px;
        margin-left: 0;
      }
    }
  }

  &__min-age {
    position: relative;
  }

  &__max-age {
    position: relative;
  }

  &__age-description {
    left: 0;
    right: 0;
    margin: auto;
    top: -27px;
    position: absolute;
    text-align: center;
    color: #737373;
    font-style: normal;
    font-weight: normal;
    width: 87px;
    font-size: smaller;
    text-align: left;
  }

  &__dash {
    margin-right: 8px;
    margin-left: 8px;
  }

  .input {
    width: 75px;
    height: 48px;
  }
}
</style>
