export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
    reader.readAsDataURL(file)
  })
}

export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[arr.length - 1])

  let bstrLength = bstr.length
  const u8arr = new Uint8Array(bstrLength)
  while (bstrLength--) {
    u8arr[bstrLength] = bstr.charCodeAt(bstrLength)
  }
  return new File([u8arr], filename, { type: mime })
}
