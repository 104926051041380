<template>
  <div class="v-radio-group">
    <VField :label="label" :error="error" :error-message="errorMessage" :description="description">
    <div v-if="subLabel" class="v-radio-group__description" v-text="subLabel" />
    <div class="v-radio-group__list">
      <slot :on="on" :attrs="attrs" />
    </div>
  </VField>
  </div>
</template>

<script>
import VField from '@/components/controls/VField.vue'

export default {
  name: 'VRadioGroup',
  components: { VField },

  props: {
    name: { type: String, required: true },
    label: { type: String, default: '' },
    subLabel: { type: String, default: '' } ,
    description: { type: String, default: '' },
    value: { type: [String, Boolean, Number], default: null },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' }
  },

  data () {
    return {
      uniqueId: null
    }
  },

  computed: {
    modelWrapper: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },

    on () {
      return {
        change: this.onChange
      }
    },

    attrs () {
      return {
        name: this.name,
        checked: this.value,
        error: Boolean(this.error)
      }
    }
  },

  methods: {
    onChange (value) {
      this.modelWrapper = value
    }
  }
}
</script>

<style lang="scss">
.v-radio-group {
 
  .v-field__info {
    margin-bottom: 0;
  }

  &__list {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
  }

  & .v-radio {
    margin: 4px 0;
  }

  &__label {
    display: inline-block;
    margin-bottom: 5px;
    color: #1E2843;
    font-size: 18px;
    font-weight: 700;
  }

  &__error {
    font-size: 18px;
    color: $red;
    line-height: 16px;
    margin-top: 14px;
    font-weight: 500;
  }

  &__description {
    font-size: 16px;
  }
}
</style>
