var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "breadcrumbs" },
    [
      _c("img", {
        attrs: {
          src: require("@/assets/img/svg/breadcrumbs-arrow.svg"),
          alt: "small-arrow",
        },
      }),
      _vm._v(" "),
      _vm.path
        ? _c(
            "nuxt-link",
            { staticClass: "breadcrumbs__text", attrs: { to: _vm.path } },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          )
        : _c(
            "button",
            {
              staticClass: "breadcrumbs__text",
              on: { click: _vm.handleClick },
            },
            [_vm._v(_vm._s(_vm.title))]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }