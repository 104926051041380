export function pluralizeNumeral(count, one, other) {
  const countNumber = Number(count)

  if (isNaN(countNumber)) {
    return ''
  }

  if (countNumber === 1) {
    return one
  }

  return other
}
