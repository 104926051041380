<template>
  <div v-if="isDisplay" id="drop-off" class="preview__section">
    <div class="preview__title">
      Drop-off points
    </div>
    <VExpansionList :items="getDropOffs" blue />
  </div>
</template>

<styled lang="scss">
#drop-off {
  .v-list {
    &__item {
      * {
        font-size: 16px;
        line-height: 1.5em;
      }
    }
  }
}
</styled>

<script>
import VExpansionList from '~/components/common/VExpansionList.vue'

export default {
  name: 'FullDescription',
  components: {
    VExpansionList
  },
  props: {
    tour: { type: Object, default: () => ({}) },
    translations: { type: Object, default: () => ({}) }
  },
  computed: {
    isDisplay() {
      return this.isTravelServices && this.isPickUpAll && this.getDropOffs.length;
    },
    getDropOffs() {
      return this.tour.drop_offs === undefined ? [] : this.tour.drop_offs
    },
    isPickUpOrMeet() {
      return (
        this.tour.pick_ups.length !== 0 &&
        this.tour.meeting_point != null &&
        !(!this.tour.meeting_point || Object.keys(this.tour.meeting_point).length === 0)
      )
    },
    isPickUpAll() {
      return !this.isPickUpOrMeet && this.tour.pick_ups.length !== 0
    },
    isTravelServices() {
      return this.tour.category.slug === 'travel-services'
    }
  }
}
</script>
