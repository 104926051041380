var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "activity-features-grid" },
    _vm._l(_vm.activeIcons, function (icon, index) {
      return _c("activity-icon", {
        key: index,
        attrs: {
          "icon-name": icon.iconName,
          title: icon.title,
          description: icon.description,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }