<template>
  <button class="v-btn" :class="setClass" :disabled="disabled" v-on="$listeners">
    <slot>
      <div v-if="loading" class="loader" />
      {{ loading ? loadingName : name }}
    </slot>
  </button>
</template>

<script>
export default {
  name: 'VBtn',
  props: {
    name: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    outline: { type: Boolean, default: false },
    rejected: { type: Boolean, default: false },
    base: { type: Boolean, default: false },
    green: { type: Boolean, default: false },
    slider: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    loadingName: { type: String, default: '' }
  },
  computed: {
    setClass() {
      const typeBtn = this.outline ? 'outline' : this.base ? 'base' : this.green ? 'green' : this.slider ? 'slider' : this.rejected ? 'rejected': ''
      return 'v-btn_' + typeBtn
    }
  }
}
</script>

<style lang="scss">
.v-btn {
  font-family: 'Cabin', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: none;
  padding: 0 10px;
  min-width: 100px;
  height: 40px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  transition: 0.2s ease;
  color: $white;
  font-size: 18px;
  cursor: pointer;
  transition: 0.2s ease;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  &:active {
    opacity: 1;
  }

  &:disabled {
    background: $disabled-primary;
  }

  &_outline {
    background: $white;
    border: 2px solid $primary;
    min-width: 70px;
    line-height: 17px;
    color: $text-primary;
    height: 50px;
    transition: all 0.3s ease-out;
    &:hover {
      background: $primary;
      color: $white;
    }
    &:disabled {
      border-color: $disabled-primary;
    }
  }

  &_rejected {
    background: $white;
    border: 1px solid $orange;
    min-width: 70px;
    line-height: 17px;
    color: $text-primary;
    height: 50px;
    transition: all 0.3s ease-out;
    &:hover {
      background: $orange;
      color: $white;
    }
    &:disabled {
      border-color: $light-orange;
    }
  }

  &_base {
    background: $primary;
    min-width: 200px;
    height: 50px;
    color: $white;
    &:disabled {
      background: $disabled-primary;
      border-color: $disabled-primary;
      cursor: not-allowed;

      &:hover {
        opacity: 1;
        background: $disabled-primary;
        border-color: $disabled-primary;
      }
    }
  }

  &_green {
    font-size: 18px;
    line-height: 22px;
    background: $light-green-gradient;
  }
  &_slider {
    min-width: 24px;
    height: 24px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background: $white;
    background-image: url('~assets/img/svg/slider-btn-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px 10px;
    &:hover {
      opacity: 1;
    }
  }

  .loader {
    width: 25px;
    height: 25px;
    border: 4px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin-right: 10px;
    position: initial;
    margin-top: -5px;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
