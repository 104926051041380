<template>
  <client-only>
    <div class="__main-wrapper">
      <header class="account-header">
        <div class="account-header__logo">
          <a :href="localePath(`/`)">
            <img src="@/assets/img/header/logo.svg" alt="logo" />
          </a>
        </div>
      </header>
      <!-- <header class="account-header" v-else>
            <div class="account-header__logo">
                <img src="@/assets/img/header/logo.svg" alt="logo">
            </div>
        </header> -->
      <Nuxt />
      <setupPassword
        :is-active="
          $cookies.get('setupAccount') && $cookies.get('setupAccount').length < 0 && !userInfo.is_password_entered
        "
        :header-translate="headerTranslate"
        @close="userInfo.is_password_entered = !userInfo.is_password_entered"
      />
      <PortalTarget name="popups" multiple></PortalTarget>
      <notification-view />
      <screenResolutionNotice />
    </div>
  </client-only>
</template>

<script>
import { mapState } from 'vuex';

export default {
  async asyncData({ $cookies, store }) {
    const userInfo = store.state.userInfo;

    return {
      userInfo,
      $cookies,
    };
  },

  components: {
    setupPassword: () => import('@/components/pop-up/setupPassword.vue'),
    NotificationView: () => import('@/components/common/VNotificationsView'),
    screenResolutionNotice: () => import('@/components/pop-up/screenResolutionNotice.vue'),
  },
  head() {
    return this.$nuxtI18nHead();
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
    },
    ...mapState(['userInfo', 'headerTranslate']),
  },
};
</script>

<style lang="scss">
.account-header {
  z-index: 500;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 12px 0;
  padding-bottom: 7px;
  background: #fff;
  border-bottom: 1px solid hsl(0, 0%, 85%);

  &__logo {
    margin-left: 20px;
    cursor: pointer;
    width: 80px;
    height: 25px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
