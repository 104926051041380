var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "age-groups" },
    [
      _c("div", { staticClass: "age-groups__title" }, [
        _vm._v("\n    " + _vm._s(_vm.translates.subtitle.text) + "\n  "),
      ]),
      _vm._v(" "),
      _vm.$v.$anyDirty && _vm.$v.$invalid && _vm.errorMessages.length
        ? _c("ErrorBanner", {
            staticClass: "age-groups__errors",
            attrs: { errors: _vm.errorMessages },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "age-groups__content" },
        _vm._l(_vm.localMembers, function (member, index) {
          return _c(
            "div",
            { key: `member-${member.id}`, staticClass: "age-groups__age-item" },
            [
              _c(
                "div",
                { staticClass: "age-groups__checkbox" },
                [
                  _c("VCheckbox", {
                    attrs: {
                      label: member.name,
                      disabled: member.in_use && !_vm.isGroupPricing,
                    },
                    model: {
                      value: member.checked,
                      callback: function ($$v) {
                        _vm.$set(member, "checked", $$v)
                      },
                      expression: "member.checked",
                    },
                  }),
                  _vm._v(" "),
                  member.in_use && !_vm.isGroupPricing
                    ? _c("div", { staticClass: "age-groups__question-mark" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/svg/question-info.svg"),
                            alt: "question-mark",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "age-groups__question-mark-description",
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.translates.questionDescription.text
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "age-groups__min-age" },
                [
                  index == 0
                    ? _c(
                        "div",
                        { staticClass: "age-groups__age-description" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.translates.minAge.text) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("InputFields", {
                    class: {
                      "input-error":
                        _vm.$v.localMembers.$each.$iter[index].min_age.$dirty &&
                        _vm.$v.localMembers.$each.$iter[index].min_age.$invalid,
                    },
                    attrs: {
                      "read-only": member.in_use && !_vm.isGroupPricing,
                      default: "",
                      type: "number",
                      min: "0",
                      max: "120",
                      step: "1",
                      onkeypress:
                        "return event.charCode >= 48 && event.charCode <= 57",
                      onpaste: "return false;",
                    },
                    model: {
                      value:
                        _vm.$v.localMembers.$each.$iter[index].min_age.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.localMembers.$each.$iter[index].min_age,
                          "$model",
                          $$v
                        )
                      },
                      expression:
                        "$v.localMembers.$each.$iter[index].min_age.$model",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "age-groups__dash" }, [_vm._v("-")]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "age-groups__max-age" },
                [
                  index == 0
                    ? _c(
                        "div",
                        { staticClass: "age-groups__age-description" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.translates.maxAge.text) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("InputFields", {
                    class: {
                      "input-error":
                        _vm.$v.localMembers.$each.$iter[index].max_age.$dirty &&
                        _vm.$v.localMembers.$each.$iter[index].max_age.$invalid,
                    },
                    attrs: {
                      default: "",
                      "read-only": member.in_use && !_vm.isGroupPricing,
                      type: "number",
                      min: "0",
                      max: "120",
                      step: "1",
                      onkeypress:
                        "return event.charCode >= 48 && event.charCode <= 57",
                      onpaste: "return false;",
                    },
                    model: {
                      value:
                        _vm.$v.localMembers.$each.$iter[index].max_age.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.localMembers.$each.$iter[index].max_age,
                          "$model",
                          $$v
                        )
                      },
                      expression:
                        "$v.localMembers.$each.$iter[index].max_age.$model",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }