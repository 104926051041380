var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slider" }, [
    _c("div", { staticClass: "slider__content" }, [
      _vm.availableFrom
        ? _c("div", { staticClass: "slider__content__available-from" }, [
            _vm._v(
              "\n      Available from " + _vm._s(_vm.availableFrom) + "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "slider__picture" }, [
        _c("img", {
          attrs: {
            id: `SliderMainPhoto-${_vm.id}`,
            width: "1000",
            height: "500",
            src: _vm.minPhotos[_vm.MainPhotoIndex].get_image,
            alt: "Tour picture",
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "photo-button_prev" },
          [
            _c("v-btn", {
              staticClass: "slider__button slider__button_back",
              attrs: { id: `SliderBackBtn-${_vm.id}`, slider: "" },
              on: { click: _vm.prevPhoto },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "photo-button_next" },
          [
            _c("v-btn", {
              staticClass: "slider__button slider__button_next",
              attrs: { id: `SliderNextBtn-${_vm.id}`, slider: "" },
              on: { click: _vm.nextPhoto },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.oldPrice > 0
          ? _c("div", { staticClass: "badge badge__special" }, [
              _vm._v("\n        Special offer\n      "),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "slider__min-photos",
        staticStyle: { transition: "all 0.8s" },
        style: {
          "margin-top":
            "-" +
            (_vm.scroll * _vm.currentPhotoIndex + _vm.lastSlideScroll) +
            "px",
        },
      },
      [
        _vm._l(_vm.minPhotos, function (minPhoto, index) {
          return _c("div", { key: minPhoto.id, staticClass: "min-photo" }, [
            _c("img", {
              attrs: {
                id: `SliderMinPhoto-${_vm.id}`,
                width: "150",
                height: "75",
                src: minPhoto.get_image,
                alt: "tour photo",
              },
              on: {
                click: function ($event) {
                  _vm.MainPhotoIndex = index
                },
              },
            }),
          ])
        }),
        _vm._v(" "),
        _vm.minPhotos.length > 4
          ? _c(
              "div",
              { staticClass: "button-down", on: { click: _vm.nextSlide } },
              [
                _c("img", {
                  attrs: {
                    id: `SliderDownBtn-${_vm.id}`,
                    width: "15",
                    height: "10",
                    src: require("@/assets/img/svg/slider-btn-down.svg"),
                    alt: "slide down",
                  },
                }),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }