var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Selection",
    _vm._g(
      _vm._b(
        {
          attrs: {
            "default-name": _vm.value,
            items: _vm.items,
            placeholder: _vm.placeholder,
          },
          on: { name: _vm.updateModel },
        },
        "Selection",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }