import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _543cf526 = () => interopDefault(import('../pages/account-setup/index.vue' /* webpackChunkName: "pages/account-setup/index" */))
const _dc56b4f0 = () => interopDefault(import('../pages/availability/index.vue' /* webpackChunkName: "pages/availability/index" */))
const _725241a7 = () => interopDefault(import('../pages/bookings/index.vue' /* webpackChunkName: "pages/bookings/index" */))
const _56904d98 = () => interopDefault(import('../pages/code-of-conduct.vue' /* webpackChunkName: "pages/code-of-conduct" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7789cd85 = () => interopDefault(import('../pages/e-tickets.vue' /* webpackChunkName: "pages/e-tickets" */))
const _6580d463 = () => interopDefault(import('../pages/finance/index.vue' /* webpackChunkName: "pages/finance/index" */))
const _c470ac24 = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _49c57cf4 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _e1fa0b8e = () => interopDefault(import('../pages/performance/index.vue' /* webpackChunkName: "pages/performance/index" */))
const _8e968990 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _1bb7fb23 = () => interopDefault(import('../pages/registration.vue' /* webpackChunkName: "pages/registration" */))
const _bb1ec17a = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _3e7e4938 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _1223a433 = () => interopDefault(import('../pages/supplier-agreement.vue' /* webpackChunkName: "pages/supplier-agreement" */))
const _b2b47c24 = () => interopDefault(import('../pages/account/business-information/index.vue' /* webpackChunkName: "pages/account/business-information/index" */))
const _f6d289f4 = () => interopDefault(import('../pages/account/connectivity/index.vue' /* webpackChunkName: "pages/account/connectivity/index" */))
const _8f630b02 = () => interopDefault(import('../pages/account/license-and-insurance/index.vue' /* webpackChunkName: "pages/account/license-and-insurance/index" */))
const _06add1ee = () => interopDefault(import('../pages/account/private-information/index.vue' /* webpackChunkName: "pages/account/private-information/index" */))
const _c35631d4 = () => interopDefault(import('../pages/account/profile-settings/index.vue' /* webpackChunkName: "pages/account/profile-settings/index" */))
const _60228cd7 = () => interopDefault(import('../pages/account/public-information/index.vue' /* webpackChunkName: "pages/account/public-information/index" */))
const _dfadd8fc = () => interopDefault(import('../pages/account/supplier-type/index.vue' /* webpackChunkName: "pages/account/supplier-type/index" */))
const _344398a8 = () => interopDefault(import('../pages/finance/invoices/index.vue' /* webpackChunkName: "pages/finance/invoices/index" */))
const _06b40e66 = () => interopDefault(import('../pages/finance/payout-methods/index.vue' /* webpackChunkName: "pages/finance/payout-methods/index" */))
const _2242c93e = () => interopDefault(import('../pages/finance/payouts/index.vue' /* webpackChunkName: "pages/finance/payouts/index" */))
const _712610b0 = () => interopDefault(import('../pages/product/create-product.vue' /* webpackChunkName: "pages/product/create-product" */))
const _83ed5258 = () => interopDefault(import('../pages/reviews/manage-reviews/index.vue' /* webpackChunkName: "pages/reviews/manage-reviews/index" */))
const _250506b2 = () => interopDefault(import('../pages/reviews/request-reviews/index.vue' /* webpackChunkName: "pages/reviews/request-reviews/index" */))
const _284da950 = () => interopDefault(import('../pages/account-setup/private-info/address-information.vue' /* webpackChunkName: "pages/account-setup/private-info/address-information" */))
const _e689f174 = () => interopDefault(import('../pages/account-setup/private-info/agreement-review.vue' /* webpackChunkName: "pages/account-setup/private-info/agreement-review" */))
const _68fa1b7c = () => interopDefault(import('../pages/account-setup/private-info/booking-management.vue' /* webpackChunkName: "pages/account-setup/private-info/booking-management" */))
const _5ba30580 = () => interopDefault(import('../pages/account-setup/private-info/personal-details.vue' /* webpackChunkName: "pages/account-setup/private-info/personal-details" */))
const _e768df40 = () => interopDefault(import('../pages/account-setup/private-info/personal-info.vue' /* webpackChunkName: "pages/account-setup/private-info/personal-info" */))
const _45abaaa2 = () => interopDefault(import('../pages/account-setup/public-info/company-image.vue' /* webpackChunkName: "pages/account-setup/public-info/company-image" */))
const _610eda37 = () => interopDefault(import('../pages/account-setup/public-info/company-info.vue' /* webpackChunkName: "pages/account-setup/public-info/company-info" */))
const _6db840d8 = () => interopDefault(import('../pages/account-setup/public-info/experience-type.vue' /* webpackChunkName: "pages/account-setup/public-info/experience-type" */))
const _fed2ed14 = () => interopDefault(import('../pages/account-setup/public-info/office-info.vue' /* webpackChunkName: "pages/account-setup/public-info/office-info" */))
const _1a788294 = () => interopDefault(import('../pages/account-setup/public-info/office-information.vue' /* webpackChunkName: "pages/account-setup/public-info/office-information" */))
const _0f973270 = () => interopDefault(import('../pages/account-setup/public-info/your-expertise.vue' /* webpackChunkName: "pages/account-setup/public-info/your-expertise" */))
const _70d92a4b = () => interopDefault(import('../pages/account-setup/public-info/your-information.vue' /* webpackChunkName: "pages/account-setup/public-info/your-information" */))
const _02e8b90e = () => interopDefault(import('../pages/account-setup/public-info/your-profile-picture.vue' /* webpackChunkName: "pages/account-setup/public-info/your-profile-picture" */))
const _8d77d74c = () => interopDefault(import('../pages/account-setup/supplier-type/declaration.vue' /* webpackChunkName: "pages/account-setup/supplier-type/declaration" */))
const _13887d6b = () => interopDefault(import('../pages/account/private-information/address.vue' /* webpackChunkName: "pages/account/private-information/address" */))
const _b5426464 = () => interopDefault(import('../pages/account/private-information/personal-details.vue' /* webpackChunkName: "pages/account/private-information/personal-details" */))
const _7fb63c02 = () => interopDefault(import('../pages/account/public-information/experience-type.vue' /* webpackChunkName: "pages/account/public-information/experience-type" */))
const _2caaa766 = () => interopDefault(import('../pages/account/public-information/office-information.vue' /* webpackChunkName: "pages/account/public-information/office-information" */))
const _236c6213 = () => interopDefault(import('../pages/account/public-information/profile-photo.vue' /* webpackChunkName: "pages/account/public-information/profile-photo" */))
const _279edf7c = () => interopDefault(import('../pages/account/public-information/your-expertise.vue' /* webpackChunkName: "pages/account/public-information/your-expertise" */))
const _e6db48c6 = () => interopDefault(import('../pages/account/public-information/your-information.vue' /* webpackChunkName: "pages/account/public-information/your-information" */))
const _c667d3f4 = () => interopDefault(import('../pages/finance/payout-methods/bank-details.vue' /* webpackChunkName: "pages/finance/payout-methods/bank-details" */))
const _4ec6efc4 = () => interopDefault(import('../pages/finance/payout-methods/basic-details.vue' /* webpackChunkName: "pages/finance/payout-methods/basic-details" */))
const _6449c67a = () => interopDefault(import('../pages/product/build/basic/product-setup.vue' /* webpackChunkName: "pages/product/build/basic/product-setup" */))
const _52994ff9 = () => interopDefault(import('../pages/help/_category.vue' /* webpackChunkName: "pages/help/_category" */))
const _5854f7bc = () => interopDefault(import('../pages/product/_product/booking-details/index.vue' /* webpackChunkName: "pages/product/_product/booking-details/index" */))
const _250fb4f3 = () => interopDefault(import('../pages/product/_product/preview.vue' /* webpackChunkName: "pages/product/_product/preview" */))
const _8b8a5738 = () => interopDefault(import('../pages/product/_product/product-connection/index.vue' /* webpackChunkName: "pages/product/_product/product-connection/index" */))
const _006ba76e = () => interopDefault(import('../pages/product/_product/product-content/index.vue' /* webpackChunkName: "pages/product/_product/product-content/index" */))
const _b234a9ce = () => interopDefault(import('../pages/product/_product/schedules-and-prices/index.vue' /* webpackChunkName: "pages/product/_product/schedules-and-prices/index" */))
const _1f223ad3 = () => interopDefault(import('../pages/product/_product/special-offers/index.vue' /* webpackChunkName: "pages/product/_product/special-offers/index" */))
const _5be17195 = () => interopDefault(import('../pages/product/_product/tickets/index.vue' /* webpackChunkName: "pages/product/_product/tickets/index" */))
const _f91e67ec = () => interopDefault(import('../pages/product/_product/translations/index.vue' /* webpackChunkName: "pages/product/_product/translations/index" */))
const _431a34e5 = () => interopDefault(import('../pages/product/_product/booking-details/booking-process.vue' /* webpackChunkName: "pages/product/_product/booking-details/booking-process" */))
const _7f0c80b4 = () => interopDefault(import('../pages/product/_product/booking-details/cancellation-policy.vue' /* webpackChunkName: "pages/product/_product/booking-details/cancellation-policy" */))
const _1ac82e91 = () => interopDefault(import('../pages/product/_product/booking-details/information-edit.vue' /* webpackChunkName: "pages/product/_product/booking-details/information-edit" */))
const _0bd6be7b = () => interopDefault(import('../pages/product/_product/product-content/activities-details.vue' /* webpackChunkName: "pages/product/_product/product-content/activities-details" */))
const _29728ec8 = () => interopDefault(import('../pages/product/_product/product-content/categories-and-themes.vue' /* webpackChunkName: "pages/product/_product/product-content/categories-and-themes" */))
const _a80b13c8 = () => interopDefault(import('../pages/product/_product/product-content/covid.vue' /* webpackChunkName: "pages/product/_product/product-content/covid" */))
const _7b5e86b0 = () => interopDefault(import('../pages/product/_product/product-content/guides-and-languages.vue' /* webpackChunkName: "pages/product/_product/product-content/guides-and-languages" */))
const _54fd5248 = () => interopDefault(import('../pages/product/_product/product-content/inclusions-and-exclusions.vue' /* webpackChunkName: "pages/product/_product/product-content/inclusions-and-exclusions" */))
const _7c945826 = () => interopDefault(import('../pages/product/_product/product-content/information-about-ticket.vue' /* webpackChunkName: "pages/product/_product/product-content/information-about-ticket" */))
const _0abae038 = () => interopDefault(import('../pages/product/_product/product-content/information-edit.vue' /* webpackChunkName: "pages/product/_product/product-content/information-edit" */))
const _080221b2 = () => interopDefault(import('../pages/product/_product/product-content/makes-unique.vue' /* webpackChunkName: "pages/product/_product/product-content/makes-unique" */))
const _a39e59c6 = () => interopDefault(import('../pages/product/_product/product-content/meeting-and-pickup.vue' /* webpackChunkName: "pages/product/_product/product-content/meeting-and-pickup" */))
const _5fe1ee0d = () => interopDefault(import('../pages/product/_product/product-content/photos-edit.vue' /* webpackChunkName: "pages/product/_product/product-content/photos-edit" */))
const _217ab216 = () => interopDefault(import('../pages/product/_product/product-content/product-setup.vue' /* webpackChunkName: "pages/product/_product/product-content/product-setup" */))
const _082a7b6a = () => interopDefault(import('../pages/product/_product/schedules-and-prices/pricing-type.vue' /* webpackChunkName: "pages/product/_product/schedules-and-prices/pricing-type" */))
const _5f05e445 = () => interopDefault(import('../pages/product/_product/tickets/details.vue' /* webpackChunkName: "pages/product/_product/tickets/details" */))
const _6d926b64 = () => interopDefault(import('../pages/product/_product/tickets/information-on-voucher.vue' /* webpackChunkName: "pages/product/_product/tickets/information-on-voucher" */))
const _2f1885c1 = () => interopDefault(import('../pages/product/_product/tickets/paper-and-mobile-preview.vue' /* webpackChunkName: "pages/product/_product/tickets/paper-and-mobile-preview" */))
const _6015fa10 = () => interopDefault(import('../pages/product/_product/tickets/redemption.vue' /* webpackChunkName: "pages/product/_product/tickets/redemption" */))
const _6fb2dae6 = () => interopDefault(import('../pages/product/_product/translations/product-descriptions.vue' /* webpackChunkName: "pages/product/_product/translations/product-descriptions" */))
const _7adfe57a = () => interopDefault(import('../pages/product/_product/build/basic/categories-and-themes.vue' /* webpackChunkName: "pages/product/_product/build/basic/categories-and-themes" */))
const _3b10bfb0 = () => interopDefault(import('../pages/product/_product/build/basic/photos.vue' /* webpackChunkName: "pages/product/_product/build/basic/photos" */))
const _29fadac8 = () => interopDefault(import('../pages/product/_product/build/basic/product-setup.vue' /* webpackChunkName: "pages/product/_product/build/basic/product-setup" */))
const _fdc54c9a = () => interopDefault(import('../pages/product/_product/build/booking-and-tickets/booking-process.vue' /* webpackChunkName: "pages/product/_product/build/booking-and-tickets/booking-process" */))
const _4ba1cd74 = () => interopDefault(import('../pages/product/_product/build/booking-and-tickets/cancellation-policy.vue' /* webpackChunkName: "pages/product/_product/build/booking-and-tickets/cancellation-policy" */))
const _c5acb8fa = () => interopDefault(import('../pages/product/_product/build/booking-and-tickets/information-edit.vue' /* webpackChunkName: "pages/product/_product/build/booking-and-tickets/information-edit" */))
const _0c50230e = () => interopDefault(import('../pages/product/_product/build/booking-and-tickets/information-on-voucher.vue' /* webpackChunkName: "pages/product/_product/build/booking-and-tickets/information-on-voucher" */))
const _76830ef9 = () => interopDefault(import('../pages/product/_product/build/booking-and-tickets/ticket-details.vue' /* webpackChunkName: "pages/product/_product/build/booking-and-tickets/ticket-details" */))
const _ba9a6502 = () => interopDefault(import('../pages/product/_product/build/booking-and-tickets/ticket-preview.vue' /* webpackChunkName: "pages/product/_product/build/booking-and-tickets/ticket-preview" */))
const _3ec16bc4 = () => interopDefault(import('../pages/product/_product/build/booking-and-tickets/ticket-redemption.vue' /* webpackChunkName: "pages/product/_product/build/booking-and-tickets/ticket-redemption" */))
const _2aabe3f1 = () => interopDefault(import('../pages/product/_product/build/finish/special-offers.vue' /* webpackChunkName: "pages/product/_product/build/finish/special-offers" */))
const _5ef6994a = () => interopDefault(import('../pages/product/_product/build/finish/submit-for-review.vue' /* webpackChunkName: "pages/product/_product/build/finish/submit-for-review" */))
const _4f52ff7c = () => interopDefault(import('../pages/product/_product/build/product-content/activities-details.vue' /* webpackChunkName: "pages/product/_product/build/product-content/activities-details" */))
const _77f8b10a = () => interopDefault(import('../pages/product/_product/build/product-content/covid.vue' /* webpackChunkName: "pages/product/_product/build/product-content/covid" */))
const _17c0c169 = () => interopDefault(import('../pages/product/_product/build/product-content/guides-and-languages.vue' /* webpackChunkName: "pages/product/_product/build/product-content/guides-and-languages" */))
const _02471e27 = () => interopDefault(import('../pages/product/_product/build/product-content/inclusions-and-exclusions.vue' /* webpackChunkName: "pages/product/_product/build/product-content/inclusions-and-exclusions" */))
const _45fbd732 = () => interopDefault(import('../pages/product/_product/build/product-content/information-about-ticket.vue' /* webpackChunkName: "pages/product/_product/build/product-content/information-about-ticket" */))
const _e2e5c5b6 = () => interopDefault(import('../pages/product/_product/build/product-content/information-edit.vue' /* webpackChunkName: "pages/product/_product/build/product-content/information-edit" */))
const _29ff7773 = () => interopDefault(import('../pages/product/_product/build/product-content/makes-unique.vue' /* webpackChunkName: "pages/product/_product/build/product-content/makes-unique" */))
const _1ca5d7c4 = () => interopDefault(import('../pages/product/_product/build/product-content/meeting-and-pickup.vue' /* webpackChunkName: "pages/product/_product/build/product-content/meeting-and-pickup" */))
const _edc0a952 = () => interopDefault(import('../pages/product/_product/build/schedules-and-pricing/pricing-type.vue' /* webpackChunkName: "pages/product/_product/build/schedules-and-pricing/pricing-type" */))
const _46082494 = () => interopDefault(import('../pages/product/_product/build/schedules-and-pricing/schedules.vue' /* webpackChunkName: "pages/product/_product/build/schedules-and-pricing/schedules" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-setup",
    component: _543cf526,
    name: "account-setup___en"
  }, {
    path: "/availability",
    component: _dc56b4f0,
    name: "availability___en"
  }, {
    path: "/bookings",
    component: _725241a7,
    name: "bookings___en"
  }, {
    path: "/code-of-conduct",
    component: _56904d98,
    name: "code-of-conduct___en"
  }, {
    path: "/de",
    component: _2dfb1658,
    name: "index___de"
  }, {
    path: "/e-tickets",
    component: _7789cd85,
    name: "e-tickets___en"
  }, {
    path: "/es",
    component: _2dfb1658,
    name: "index___es"
  }, {
    path: "/finance",
    component: _6580d463,
    name: "finance___en"
  }, {
    path: "/help",
    component: _c470ac24,
    name: "help___en"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login___en"
  }, {
    path: "/logout",
    component: _49c57cf4,
    name: "logout___en"
  }, {
    path: "/performance",
    component: _e1fa0b8e,
    name: "performance___en"
  }, {
    path: "/product",
    component: _8e968990,
    name: "product___en"
  }, {
    path: "/registration",
    component: _1bb7fb23,
    name: "registration___en"
  }, {
    path: "/reset-password",
    component: _bb1ec17a,
    name: "reset-password___en"
  }, {
    path: "/reviews",
    component: _3e7e4938,
    name: "reviews___en"
  }, {
    path: "/supplier-agreement",
    component: _1223a433,
    name: "supplier-agreement___en"
  }, {
    path: "/account/business-information",
    component: _b2b47c24,
    name: "account-business-information___en"
  }, {
    path: "/account/connectivity",
    component: _f6d289f4,
    name: "account-connectivity___en"
  }, {
    path: "/account/license-and-insurance",
    component: _8f630b02,
    name: "account-license-and-insurance___en"
  }, {
    path: "/account/private-information",
    component: _06add1ee,
    name: "account-private-information___en"
  }, {
    path: "/account/profile-settings",
    component: _c35631d4,
    name: "account-profile-settings___en"
  }, {
    path: "/account/public-information",
    component: _60228cd7,
    name: "account-public-information___en"
  }, {
    path: "/account/supplier-type",
    component: _dfadd8fc,
    name: "account-supplier-type___en"
  }, {
    path: "/de/account-setup",
    component: _543cf526,
    name: "account-setup___de"
  }, {
    path: "/de/availability",
    component: _dc56b4f0,
    name: "availability___de"
  }, {
    path: "/de/bookings",
    component: _725241a7,
    name: "bookings___de"
  }, {
    path: "/de/code-of-conduct",
    component: _56904d98,
    name: "code-of-conduct___de"
  }, {
    path: "/de/e-tickets",
    component: _7789cd85,
    name: "e-tickets___de"
  }, {
    path: "/de/finance",
    component: _6580d463,
    name: "finance___de"
  }, {
    path: "/de/help",
    component: _c470ac24,
    name: "help___de"
  }, {
    path: "/de/login",
    component: _10ba8d22,
    name: "login___de"
  }, {
    path: "/de/logout",
    component: _49c57cf4,
    name: "logout___de"
  }, {
    path: "/de/performance",
    component: _e1fa0b8e,
    name: "performance___de"
  }, {
    path: "/de/product",
    component: _8e968990,
    name: "product___de"
  }, {
    path: "/de/registration",
    component: _1bb7fb23,
    name: "registration___de"
  }, {
    path: "/de/reset-password",
    component: _bb1ec17a,
    name: "reset-password___de"
  }, {
    path: "/de/reviews",
    component: _3e7e4938,
    name: "reviews___de"
  }, {
    path: "/de/supplier-agreement",
    component: _1223a433,
    name: "supplier-agreement___de"
  }, {
    path: "/es/account-setup",
    component: _543cf526,
    name: "account-setup___es"
  }, {
    path: "/es/availability",
    component: _dc56b4f0,
    name: "availability___es"
  }, {
    path: "/es/bookings",
    component: _725241a7,
    name: "bookings___es"
  }, {
    path: "/es/code-of-conduct",
    component: _56904d98,
    name: "code-of-conduct___es"
  }, {
    path: "/es/e-tickets",
    component: _7789cd85,
    name: "e-tickets___es"
  }, {
    path: "/es/finance",
    component: _6580d463,
    name: "finance___es"
  }, {
    path: "/es/help",
    component: _c470ac24,
    name: "help___es"
  }, {
    path: "/es/login",
    component: _10ba8d22,
    name: "login___es"
  }, {
    path: "/es/logout",
    component: _49c57cf4,
    name: "logout___es"
  }, {
    path: "/es/performance",
    component: _e1fa0b8e,
    name: "performance___es"
  }, {
    path: "/es/product",
    component: _8e968990,
    name: "product___es"
  }, {
    path: "/es/registration",
    component: _1bb7fb23,
    name: "registration___es"
  }, {
    path: "/es/reset-password",
    component: _bb1ec17a,
    name: "reset-password___es"
  }, {
    path: "/es/reviews",
    component: _3e7e4938,
    name: "reviews___es"
  }, {
    path: "/es/supplier-agreement",
    component: _1223a433,
    name: "supplier-agreement___es"
  }, {
    path: "/finance/invoices",
    component: _344398a8,
    name: "finance-invoices___en"
  }, {
    path: "/finance/payout-methods",
    component: _06b40e66,
    name: "finance-payout-methods___en"
  }, {
    path: "/finance/payouts",
    component: _2242c93e,
    name: "finance-payouts___en"
  }, {
    path: "/product/create-product",
    component: _712610b0,
    name: "product-create-product___en"
  }, {
    path: "/reviews/manage-reviews",
    component: _83ed5258,
    name: "reviews-manage-reviews___en"
  }, {
    path: "/reviews/request-reviews",
    component: _250506b2,
    name: "reviews-request-reviews___en"
  }, {
    path: "/account-setup/private-info/address-information",
    component: _284da950,
    name: "account-setup-private-info-address-information___en"
  }, {
    path: "/account-setup/private-info/agreement-review",
    component: _e689f174,
    name: "account-setup-private-info-agreement-review___en"
  }, {
    path: "/account-setup/private-info/booking-management",
    component: _68fa1b7c,
    name: "account-setup-private-info-booking-management___en"
  }, {
    path: "/account-setup/private-info/personal-details",
    component: _5ba30580,
    name: "account-setup-private-info-personal-details___en"
  }, {
    path: "/account-setup/private-info/personal-info",
    component: _e768df40,
    name: "account-setup-private-info-personal-info___en"
  }, {
    path: "/account-setup/public-info/company-image",
    component: _45abaaa2,
    name: "account-setup-public-info-company-image___en"
  }, {
    path: "/account-setup/public-info/company-info",
    component: _610eda37,
    name: "account-setup-public-info-company-info___en"
  }, {
    path: "/account-setup/public-info/experience-type",
    component: _6db840d8,
    name: "account-setup-public-info-experience-type___en"
  }, {
    path: "/account-setup/public-info/office-info",
    component: _fed2ed14,
    name: "account-setup-public-info-office-info___en"
  }, {
    path: "/account-setup/public-info/office-information",
    component: _1a788294,
    name: "account-setup-public-info-office-information___en"
  }, {
    path: "/account-setup/public-info/your-expertise",
    component: _0f973270,
    name: "account-setup-public-info-your-expertise___en"
  }, {
    path: "/account-setup/public-info/your-information",
    component: _70d92a4b,
    name: "account-setup-public-info-your-information___en"
  }, {
    path: "/account-setup/public-info/your-profile-picture",
    component: _02e8b90e,
    name: "account-setup-public-info-your-profile-picture___en"
  }, {
    path: "/account-setup/supplier-type/declaration",
    component: _8d77d74c,
    name: "account-setup-supplier-type-declaration___en"
  }, {
    path: "/account/private-information/address",
    component: _13887d6b,
    name: "account-private-information-address___en"
  }, {
    path: "/account/private-information/personal-details",
    component: _b5426464,
    name: "account-private-information-personal-details___en"
  }, {
    path: "/account/public-information/experience-type",
    component: _7fb63c02,
    name: "account-public-information-experience-type___en"
  }, {
    path: "/account/public-information/office-information",
    component: _2caaa766,
    name: "account-public-information-office-information___en"
  }, {
    path: "/account/public-information/profile-photo",
    component: _236c6213,
    name: "account-public-information-profile-photo___en"
  }, {
    path: "/account/public-information/your-expertise",
    component: _279edf7c,
    name: "account-public-information-your-expertise___en"
  }, {
    path: "/account/public-information/your-information",
    component: _e6db48c6,
    name: "account-public-information-your-information___en"
  }, {
    path: "/de/account/business-information",
    component: _b2b47c24,
    name: "account-business-information___de"
  }, {
    path: "/de/account/connectivity",
    component: _f6d289f4,
    name: "account-connectivity___de"
  }, {
    path: "/de/account/license-and-insurance",
    component: _8f630b02,
    name: "account-license-and-insurance___de"
  }, {
    path: "/de/account/private-information",
    component: _06add1ee,
    name: "account-private-information___de"
  }, {
    path: "/de/account/profile-settings",
    component: _c35631d4,
    name: "account-profile-settings___de"
  }, {
    path: "/de/account/public-information",
    component: _60228cd7,
    name: "account-public-information___de"
  }, {
    path: "/de/account/supplier-type",
    component: _dfadd8fc,
    name: "account-supplier-type___de"
  }, {
    path: "/de/finance/invoices",
    component: _344398a8,
    name: "finance-invoices___de"
  }, {
    path: "/de/finance/payout-methods",
    component: _06b40e66,
    name: "finance-payout-methods___de"
  }, {
    path: "/de/finance/payouts",
    component: _2242c93e,
    name: "finance-payouts___de"
  }, {
    path: "/de/product/create-product",
    component: _712610b0,
    name: "product-create-product___de"
  }, {
    path: "/de/reviews/manage-reviews",
    component: _83ed5258,
    name: "reviews-manage-reviews___de"
  }, {
    path: "/de/reviews/request-reviews",
    component: _250506b2,
    name: "reviews-request-reviews___de"
  }, {
    path: "/es/account/business-information",
    component: _b2b47c24,
    name: "account-business-information___es"
  }, {
    path: "/es/account/connectivity",
    component: _f6d289f4,
    name: "account-connectivity___es"
  }, {
    path: "/es/account/license-and-insurance",
    component: _8f630b02,
    name: "account-license-and-insurance___es"
  }, {
    path: "/es/account/private-information",
    component: _06add1ee,
    name: "account-private-information___es"
  }, {
    path: "/es/account/profile-settings",
    component: _c35631d4,
    name: "account-profile-settings___es"
  }, {
    path: "/es/account/public-information",
    component: _60228cd7,
    name: "account-public-information___es"
  }, {
    path: "/es/account/supplier-type",
    component: _dfadd8fc,
    name: "account-supplier-type___es"
  }, {
    path: "/es/finance/invoices",
    component: _344398a8,
    name: "finance-invoices___es"
  }, {
    path: "/es/finance/payout-methods",
    component: _06b40e66,
    name: "finance-payout-methods___es"
  }, {
    path: "/es/finance/payouts",
    component: _2242c93e,
    name: "finance-payouts___es"
  }, {
    path: "/es/product/create-product",
    component: _712610b0,
    name: "product-create-product___es"
  }, {
    path: "/es/reviews/manage-reviews",
    component: _83ed5258,
    name: "reviews-manage-reviews___es"
  }, {
    path: "/es/reviews/request-reviews",
    component: _250506b2,
    name: "reviews-request-reviews___es"
  }, {
    path: "/finance/payout-methods/bank-details",
    component: _c667d3f4,
    name: "finance-payout-methods-bank-details___en"
  }, {
    path: "/finance/payout-methods/basic-details",
    component: _4ec6efc4,
    name: "finance-payout-methods-basic-details___en"
  }, {
    path: "/de/account-setup/private-info/address-information",
    component: _284da950,
    name: "account-setup-private-info-address-information___de"
  }, {
    path: "/de/account-setup/private-info/agreement-review",
    component: _e689f174,
    name: "account-setup-private-info-agreement-review___de"
  }, {
    path: "/de/account-setup/private-info/booking-management",
    component: _68fa1b7c,
    name: "account-setup-private-info-booking-management___de"
  }, {
    path: "/de/account-setup/private-info/personal-details",
    component: _5ba30580,
    name: "account-setup-private-info-personal-details___de"
  }, {
    path: "/de/account-setup/private-info/personal-info",
    component: _e768df40,
    name: "account-setup-private-info-personal-info___de"
  }, {
    path: "/de/account-setup/public-info/company-image",
    component: _45abaaa2,
    name: "account-setup-public-info-company-image___de"
  }, {
    path: "/de/account-setup/public-info/company-info",
    component: _610eda37,
    name: "account-setup-public-info-company-info___de"
  }, {
    path: "/de/account-setup/public-info/experience-type",
    component: _6db840d8,
    name: "account-setup-public-info-experience-type___de"
  }, {
    path: "/de/account-setup/public-info/office-info",
    component: _fed2ed14,
    name: "account-setup-public-info-office-info___de"
  }, {
    path: "/de/account-setup/public-info/office-information",
    component: _1a788294,
    name: "account-setup-public-info-office-information___de"
  }, {
    path: "/de/account-setup/public-info/your-expertise",
    component: _0f973270,
    name: "account-setup-public-info-your-expertise___de"
  }, {
    path: "/de/account-setup/public-info/your-information",
    component: _70d92a4b,
    name: "account-setup-public-info-your-information___de"
  }, {
    path: "/de/account-setup/public-info/your-profile-picture",
    component: _02e8b90e,
    name: "account-setup-public-info-your-profile-picture___de"
  }, {
    path: "/de/account-setup/supplier-type/declaration",
    component: _8d77d74c,
    name: "account-setup-supplier-type-declaration___de"
  }, {
    path: "/de/account/private-information/address",
    component: _13887d6b,
    name: "account-private-information-address___de"
  }, {
    path: "/de/account/private-information/personal-details",
    component: _b5426464,
    name: "account-private-information-personal-details___de"
  }, {
    path: "/de/account/public-information/experience-type",
    component: _7fb63c02,
    name: "account-public-information-experience-type___de"
  }, {
    path: "/de/account/public-information/office-information",
    component: _2caaa766,
    name: "account-public-information-office-information___de"
  }, {
    path: "/de/account/public-information/profile-photo",
    component: _236c6213,
    name: "account-public-information-profile-photo___de"
  }, {
    path: "/de/account/public-information/your-expertise",
    component: _279edf7c,
    name: "account-public-information-your-expertise___de"
  }, {
    path: "/de/account/public-information/your-information",
    component: _e6db48c6,
    name: "account-public-information-your-information___de"
  }, {
    path: "/de/finance/payout-methods/bank-details",
    component: _c667d3f4,
    name: "finance-payout-methods-bank-details___de"
  }, {
    path: "/de/finance/payout-methods/basic-details",
    component: _4ec6efc4,
    name: "finance-payout-methods-basic-details___de"
  }, {
    path: "/es/account-setup/private-info/address-information",
    component: _284da950,
    name: "account-setup-private-info-address-information___es"
  }, {
    path: "/es/account-setup/private-info/agreement-review",
    component: _e689f174,
    name: "account-setup-private-info-agreement-review___es"
  }, {
    path: "/es/account-setup/private-info/booking-management",
    component: _68fa1b7c,
    name: "account-setup-private-info-booking-management___es"
  }, {
    path: "/es/account-setup/private-info/personal-details",
    component: _5ba30580,
    name: "account-setup-private-info-personal-details___es"
  }, {
    path: "/es/account-setup/private-info/personal-info",
    component: _e768df40,
    name: "account-setup-private-info-personal-info___es"
  }, {
    path: "/es/account-setup/public-info/company-image",
    component: _45abaaa2,
    name: "account-setup-public-info-company-image___es"
  }, {
    path: "/es/account-setup/public-info/company-info",
    component: _610eda37,
    name: "account-setup-public-info-company-info___es"
  }, {
    path: "/es/account-setup/public-info/experience-type",
    component: _6db840d8,
    name: "account-setup-public-info-experience-type___es"
  }, {
    path: "/es/account-setup/public-info/office-info",
    component: _fed2ed14,
    name: "account-setup-public-info-office-info___es"
  }, {
    path: "/es/account-setup/public-info/office-information",
    component: _1a788294,
    name: "account-setup-public-info-office-information___es"
  }, {
    path: "/es/account-setup/public-info/your-expertise",
    component: _0f973270,
    name: "account-setup-public-info-your-expertise___es"
  }, {
    path: "/es/account-setup/public-info/your-information",
    component: _70d92a4b,
    name: "account-setup-public-info-your-information___es"
  }, {
    path: "/es/account-setup/public-info/your-profile-picture",
    component: _02e8b90e,
    name: "account-setup-public-info-your-profile-picture___es"
  }, {
    path: "/es/account-setup/supplier-type/declaration",
    component: _8d77d74c,
    name: "account-setup-supplier-type-declaration___es"
  }, {
    path: "/es/account/private-information/address",
    component: _13887d6b,
    name: "account-private-information-address___es"
  }, {
    path: "/es/account/private-information/personal-details",
    component: _b5426464,
    name: "account-private-information-personal-details___es"
  }, {
    path: "/es/account/public-information/experience-type",
    component: _7fb63c02,
    name: "account-public-information-experience-type___es"
  }, {
    path: "/es/account/public-information/office-information",
    component: _2caaa766,
    name: "account-public-information-office-information___es"
  }, {
    path: "/es/account/public-information/profile-photo",
    component: _236c6213,
    name: "account-public-information-profile-photo___es"
  }, {
    path: "/es/account/public-information/your-expertise",
    component: _279edf7c,
    name: "account-public-information-your-expertise___es"
  }, {
    path: "/es/account/public-information/your-information",
    component: _e6db48c6,
    name: "account-public-information-your-information___es"
  }, {
    path: "/es/finance/payout-methods/bank-details",
    component: _c667d3f4,
    name: "finance-payout-methods-bank-details___es"
  }, {
    path: "/es/finance/payout-methods/basic-details",
    component: _4ec6efc4,
    name: "finance-payout-methods-basic-details___es"
  }, {
    path: "/product/build/basic/product-setup",
    component: _6449c67a,
    name: "product-build-basic-product-setup___en"
  }, {
    path: "/de/product/build/basic/product-setup",
    component: _6449c67a,
    name: "product-build-basic-product-setup___de"
  }, {
    path: "/es/product/build/basic/product-setup",
    component: _6449c67a,
    name: "product-build-basic-product-setup___es"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/de/help/:category",
    component: _52994ff9,
    name: "help-category___de"
  }, {
    path: "/es/help/:category",
    component: _52994ff9,
    name: "help-category___es"
  }, {
    path: "/de/product/:product/booking-details",
    component: _5854f7bc,
    name: "product-product-booking-details___de"
  }, {
    path: "/de/product/:product/preview",
    component: _250fb4f3,
    name: "product-product-preview___de"
  }, {
    path: "/de/product/:product/product-connection",
    component: _8b8a5738,
    name: "product-product-product-connection___de"
  }, {
    path: "/de/product/:product/product-content",
    component: _006ba76e,
    name: "product-product-product-content___de"
  }, {
    path: "/de/product/:product/schedules-and-prices",
    component: _b234a9ce,
    name: "product-product-schedules-and-prices___de"
  }, {
    path: "/de/product/:product/special-offers",
    component: _1f223ad3,
    name: "product-product-special-offers___de"
  }, {
    path: "/de/product/:product/tickets",
    component: _5be17195,
    name: "product-product-tickets___de"
  }, {
    path: "/de/product/:product/translations",
    component: _f91e67ec,
    name: "product-product-translations___de"
  }, {
    path: "/es/product/:product/booking-details",
    component: _5854f7bc,
    name: "product-product-booking-details___es"
  }, {
    path: "/es/product/:product/preview",
    component: _250fb4f3,
    name: "product-product-preview___es"
  }, {
    path: "/es/product/:product/product-connection",
    component: _8b8a5738,
    name: "product-product-product-connection___es"
  }, {
    path: "/es/product/:product/product-content",
    component: _006ba76e,
    name: "product-product-product-content___es"
  }, {
    path: "/es/product/:product/schedules-and-prices",
    component: _b234a9ce,
    name: "product-product-schedules-and-prices___es"
  }, {
    path: "/es/product/:product/special-offers",
    component: _1f223ad3,
    name: "product-product-special-offers___es"
  }, {
    path: "/es/product/:product/tickets",
    component: _5be17195,
    name: "product-product-tickets___es"
  }, {
    path: "/es/product/:product/translations",
    component: _f91e67ec,
    name: "product-product-translations___es"
  }, {
    path: "/de/product/:product/booking-details/booking-process",
    component: _431a34e5,
    name: "product-product-booking-details-booking-process___de"
  }, {
    path: "/de/product/:product/booking-details/cancellation-policy",
    component: _7f0c80b4,
    name: "product-product-booking-details-cancellation-policy___de"
  }, {
    path: "/de/product/:product/booking-details/information-edit",
    component: _1ac82e91,
    name: "product-product-booking-details-information-edit___de"
  }, {
    path: "/de/product/:product/product-content/activities-details",
    component: _0bd6be7b,
    name: "product-product-product-content-activities-details___de"
  }, {
    path: "/de/product/:product/product-content/categories-and-themes",
    component: _29728ec8,
    name: "product-product-product-content-categories-and-themes___de"
  }, {
    path: "/de/product/:product/product-content/covid",
    component: _a80b13c8,
    name: "product-product-product-content-covid___de"
  }, {
    path: "/de/product/:product/product-content/guides-and-languages",
    component: _7b5e86b0,
    name: "product-product-product-content-guides-and-languages___de"
  }, {
    path: "/de/product/:product/product-content/inclusions-and-exclusions",
    component: _54fd5248,
    name: "product-product-product-content-inclusions-and-exclusions___de"
  }, {
    path: "/de/product/:product/product-content/information-about-ticket",
    component: _7c945826,
    name: "product-product-product-content-information-about-ticket___de"
  }, {
    path: "/de/product/:product/product-content/information-edit",
    component: _0abae038,
    name: "product-product-product-content-information-edit___de"
  }, {
    path: "/de/product/:product/product-content/makes-unique",
    component: _080221b2,
    name: "product-product-product-content-makes-unique___de"
  }, {
    path: "/de/product/:product/product-content/meeting-and-pickup",
    component: _a39e59c6,
    name: "product-product-product-content-meeting-and-pickup___de"
  }, {
    path: "/de/product/:product/product-content/photos-edit",
    component: _5fe1ee0d,
    name: "product-product-product-content-photos-edit___de"
  }, {
    path: "/de/product/:product/product-content/product-setup",
    component: _217ab216,
    name: "product-product-product-content-product-setup___de"
  }, {
    path: "/de/product/:product/schedules-and-prices/pricing-type",
    component: _082a7b6a,
    name: "product-product-schedules-and-prices-pricing-type___de"
  }, {
    path: "/de/product/:product/tickets/details",
    component: _5f05e445,
    name: "product-product-tickets-details___de"
  }, {
    path: "/de/product/:product/tickets/information-on-voucher",
    component: _6d926b64,
    name: "product-product-tickets-information-on-voucher___de"
  }, {
    path: "/de/product/:product/tickets/paper-and-mobile-preview",
    component: _2f1885c1,
    name: "product-product-tickets-paper-and-mobile-preview___de"
  }, {
    path: "/de/product/:product/tickets/redemption",
    component: _6015fa10,
    name: "product-product-tickets-redemption___de"
  }, {
    path: "/de/product/:product/translations/product-descriptions",
    component: _6fb2dae6,
    name: "product-product-translations-product-descriptions___de"
  }, {
    path: "/es/product/:product/booking-details/booking-process",
    component: _431a34e5,
    name: "product-product-booking-details-booking-process___es"
  }, {
    path: "/es/product/:product/booking-details/cancellation-policy",
    component: _7f0c80b4,
    name: "product-product-booking-details-cancellation-policy___es"
  }, {
    path: "/es/product/:product/booking-details/information-edit",
    component: _1ac82e91,
    name: "product-product-booking-details-information-edit___es"
  }, {
    path: "/es/product/:product/product-content/activities-details",
    component: _0bd6be7b,
    name: "product-product-product-content-activities-details___es"
  }, {
    path: "/es/product/:product/product-content/categories-and-themes",
    component: _29728ec8,
    name: "product-product-product-content-categories-and-themes___es"
  }, {
    path: "/es/product/:product/product-content/covid",
    component: _a80b13c8,
    name: "product-product-product-content-covid___es"
  }, {
    path: "/es/product/:product/product-content/guides-and-languages",
    component: _7b5e86b0,
    name: "product-product-product-content-guides-and-languages___es"
  }, {
    path: "/es/product/:product/product-content/inclusions-and-exclusions",
    component: _54fd5248,
    name: "product-product-product-content-inclusions-and-exclusions___es"
  }, {
    path: "/es/product/:product/product-content/information-about-ticket",
    component: _7c945826,
    name: "product-product-product-content-information-about-ticket___es"
  }, {
    path: "/es/product/:product/product-content/information-edit",
    component: _0abae038,
    name: "product-product-product-content-information-edit___es"
  }, {
    path: "/es/product/:product/product-content/makes-unique",
    component: _080221b2,
    name: "product-product-product-content-makes-unique___es"
  }, {
    path: "/es/product/:product/product-content/meeting-and-pickup",
    component: _a39e59c6,
    name: "product-product-product-content-meeting-and-pickup___es"
  }, {
    path: "/es/product/:product/product-content/photos-edit",
    component: _5fe1ee0d,
    name: "product-product-product-content-photos-edit___es"
  }, {
    path: "/es/product/:product/product-content/product-setup",
    component: _217ab216,
    name: "product-product-product-content-product-setup___es"
  }, {
    path: "/es/product/:product/schedules-and-prices/pricing-type",
    component: _082a7b6a,
    name: "product-product-schedules-and-prices-pricing-type___es"
  }, {
    path: "/es/product/:product/tickets/details",
    component: _5f05e445,
    name: "product-product-tickets-details___es"
  }, {
    path: "/es/product/:product/tickets/information-on-voucher",
    component: _6d926b64,
    name: "product-product-tickets-information-on-voucher___es"
  }, {
    path: "/es/product/:product/tickets/paper-and-mobile-preview",
    component: _2f1885c1,
    name: "product-product-tickets-paper-and-mobile-preview___es"
  }, {
    path: "/es/product/:product/tickets/redemption",
    component: _6015fa10,
    name: "product-product-tickets-redemption___es"
  }, {
    path: "/es/product/:product/translations/product-descriptions",
    component: _6fb2dae6,
    name: "product-product-translations-product-descriptions___es"
  }, {
    path: "/de/product/:product/build/basic/categories-and-themes",
    component: _7adfe57a,
    name: "product-product-build-basic-categories-and-themes___de"
  }, {
    path: "/de/product/:product/build/basic/photos",
    component: _3b10bfb0,
    name: "product-product-build-basic-photos___de"
  }, {
    path: "/de/product/:product/build/basic/product-setup",
    component: _29fadac8,
    name: "product-product-build-basic-product-setup___de"
  }, {
    path: "/de/product/:product/build/booking-and-tickets/booking-process",
    component: _fdc54c9a,
    name: "product-product-build-booking-and-tickets-booking-process___de"
  }, {
    path: "/de/product/:product/build/booking-and-tickets/cancellation-policy",
    component: _4ba1cd74,
    name: "product-product-build-booking-and-tickets-cancellation-policy___de"
  }, {
    path: "/de/product/:product/build/booking-and-tickets/information-edit",
    component: _c5acb8fa,
    name: "product-product-build-booking-and-tickets-information-edit___de"
  }, {
    path: "/de/product/:product/build/booking-and-tickets/information-on-voucher",
    component: _0c50230e,
    name: "product-product-build-booking-and-tickets-information-on-voucher___de"
  }, {
    path: "/de/product/:product/build/booking-and-tickets/ticket-details",
    component: _76830ef9,
    name: "product-product-build-booking-and-tickets-ticket-details___de"
  }, {
    path: "/de/product/:product/build/booking-and-tickets/ticket-preview",
    component: _ba9a6502,
    name: "product-product-build-booking-and-tickets-ticket-preview___de"
  }, {
    path: "/de/product/:product/build/booking-and-tickets/ticket-redemption",
    component: _3ec16bc4,
    name: "product-product-build-booking-and-tickets-ticket-redemption___de"
  }, {
    path: "/de/product/:product/build/finish/special-offers",
    component: _2aabe3f1,
    name: "product-product-build-finish-special-offers___de"
  }, {
    path: "/de/product/:product/build/finish/submit-for-review",
    component: _5ef6994a,
    name: "product-product-build-finish-submit-for-review___de"
  }, {
    path: "/de/product/:product/build/product-content/activities-details",
    component: _4f52ff7c,
    name: "product-product-build-product-content-activities-details___de"
  }, {
    path: "/de/product/:product/build/product-content/covid",
    component: _77f8b10a,
    name: "product-product-build-product-content-covid___de"
  }, {
    path: "/de/product/:product/build/product-content/guides-and-languages",
    component: _17c0c169,
    name: "product-product-build-product-content-guides-and-languages___de"
  }, {
    path: "/de/product/:product/build/product-content/inclusions-and-exclusions",
    component: _02471e27,
    name: "product-product-build-product-content-inclusions-and-exclusions___de"
  }, {
    path: "/de/product/:product/build/product-content/information-about-ticket",
    component: _45fbd732,
    name: "product-product-build-product-content-information-about-ticket___de"
  }, {
    path: "/de/product/:product/build/product-content/information-edit",
    component: _e2e5c5b6,
    name: "product-product-build-product-content-information-edit___de"
  }, {
    path: "/de/product/:product/build/product-content/makes-unique",
    component: _29ff7773,
    name: "product-product-build-product-content-makes-unique___de"
  }, {
    path: "/de/product/:product/build/product-content/meeting-and-pickup",
    component: _1ca5d7c4,
    name: "product-product-build-product-content-meeting-and-pickup___de"
  }, {
    path: "/de/product/:product/build/schedules-and-pricing/pricing-type",
    component: _edc0a952,
    name: "product-product-build-schedules-and-pricing-pricing-type___de"
  }, {
    path: "/de/product/:product/build/schedules-and-pricing/schedules",
    component: _46082494,
    name: "product-product-build-schedules-and-pricing-schedules___de"
  }, {
    path: "/es/product/:product/build/basic/categories-and-themes",
    component: _7adfe57a,
    name: "product-product-build-basic-categories-and-themes___es"
  }, {
    path: "/es/product/:product/build/basic/photos",
    component: _3b10bfb0,
    name: "product-product-build-basic-photos___es"
  }, {
    path: "/es/product/:product/build/basic/product-setup",
    component: _29fadac8,
    name: "product-product-build-basic-product-setup___es"
  }, {
    path: "/es/product/:product/build/booking-and-tickets/booking-process",
    component: _fdc54c9a,
    name: "product-product-build-booking-and-tickets-booking-process___es"
  }, {
    path: "/es/product/:product/build/booking-and-tickets/cancellation-policy",
    component: _4ba1cd74,
    name: "product-product-build-booking-and-tickets-cancellation-policy___es"
  }, {
    path: "/es/product/:product/build/booking-and-tickets/information-edit",
    component: _c5acb8fa,
    name: "product-product-build-booking-and-tickets-information-edit___es"
  }, {
    path: "/es/product/:product/build/booking-and-tickets/information-on-voucher",
    component: _0c50230e,
    name: "product-product-build-booking-and-tickets-information-on-voucher___es"
  }, {
    path: "/es/product/:product/build/booking-and-tickets/ticket-details",
    component: _76830ef9,
    name: "product-product-build-booking-and-tickets-ticket-details___es"
  }, {
    path: "/es/product/:product/build/booking-and-tickets/ticket-preview",
    component: _ba9a6502,
    name: "product-product-build-booking-and-tickets-ticket-preview___es"
  }, {
    path: "/es/product/:product/build/booking-and-tickets/ticket-redemption",
    component: _3ec16bc4,
    name: "product-product-build-booking-and-tickets-ticket-redemption___es"
  }, {
    path: "/es/product/:product/build/finish/special-offers",
    component: _2aabe3f1,
    name: "product-product-build-finish-special-offers___es"
  }, {
    path: "/es/product/:product/build/finish/submit-for-review",
    component: _5ef6994a,
    name: "product-product-build-finish-submit-for-review___es"
  }, {
    path: "/es/product/:product/build/product-content/activities-details",
    component: _4f52ff7c,
    name: "product-product-build-product-content-activities-details___es"
  }, {
    path: "/es/product/:product/build/product-content/covid",
    component: _77f8b10a,
    name: "product-product-build-product-content-covid___es"
  }, {
    path: "/es/product/:product/build/product-content/guides-and-languages",
    component: _17c0c169,
    name: "product-product-build-product-content-guides-and-languages___es"
  }, {
    path: "/es/product/:product/build/product-content/inclusions-and-exclusions",
    component: _02471e27,
    name: "product-product-build-product-content-inclusions-and-exclusions___es"
  }, {
    path: "/es/product/:product/build/product-content/information-about-ticket",
    component: _45fbd732,
    name: "product-product-build-product-content-information-about-ticket___es"
  }, {
    path: "/es/product/:product/build/product-content/information-edit",
    component: _e2e5c5b6,
    name: "product-product-build-product-content-information-edit___es"
  }, {
    path: "/es/product/:product/build/product-content/makes-unique",
    component: _29ff7773,
    name: "product-product-build-product-content-makes-unique___es"
  }, {
    path: "/es/product/:product/build/product-content/meeting-and-pickup",
    component: _1ca5d7c4,
    name: "product-product-build-product-content-meeting-and-pickup___es"
  }, {
    path: "/es/product/:product/build/schedules-and-pricing/pricing-type",
    component: _edc0a952,
    name: "product-product-build-schedules-and-pricing-pricing-type___es"
  }, {
    path: "/es/product/:product/build/schedules-and-pricing/schedules",
    component: _46082494,
    name: "product-product-build-schedules-and-pricing-schedules___es"
  }, {
    path: "/help/:category",
    component: _52994ff9,
    name: "help-category___en"
  }, {
    path: "/product/:product/booking-details",
    component: _5854f7bc,
    name: "product-product-booking-details___en"
  }, {
    path: "/product/:product/preview",
    component: _250fb4f3,
    name: "product-product-preview___en"
  }, {
    path: "/product/:product/product-connection",
    component: _8b8a5738,
    name: "product-product-product-connection___en"
  }, {
    path: "/product/:product/product-content",
    component: _006ba76e,
    name: "product-product-product-content___en"
  }, {
    path: "/product/:product/schedules-and-prices",
    component: _b234a9ce,
    name: "product-product-schedules-and-prices___en"
  }, {
    path: "/product/:product/special-offers",
    component: _1f223ad3,
    name: "product-product-special-offers___en"
  }, {
    path: "/product/:product/tickets",
    component: _5be17195,
    name: "product-product-tickets___en"
  }, {
    path: "/product/:product/translations",
    component: _f91e67ec,
    name: "product-product-translations___en"
  }, {
    path: "/product/:product/booking-details/booking-process",
    component: _431a34e5,
    name: "product-product-booking-details-booking-process___en"
  }, {
    path: "/product/:product/booking-details/cancellation-policy",
    component: _7f0c80b4,
    name: "product-product-booking-details-cancellation-policy___en"
  }, {
    path: "/product/:product/booking-details/information-edit",
    component: _1ac82e91,
    name: "product-product-booking-details-information-edit___en"
  }, {
    path: "/product/:product/product-content/activities-details",
    component: _0bd6be7b,
    name: "product-product-product-content-activities-details___en"
  }, {
    path: "/product/:product/product-content/categories-and-themes",
    component: _29728ec8,
    name: "product-product-product-content-categories-and-themes___en"
  }, {
    path: "/product/:product/product-content/covid",
    component: _a80b13c8,
    name: "product-product-product-content-covid___en"
  }, {
    path: "/product/:product/product-content/guides-and-languages",
    component: _7b5e86b0,
    name: "product-product-product-content-guides-and-languages___en"
  }, {
    path: "/product/:product/product-content/inclusions-and-exclusions",
    component: _54fd5248,
    name: "product-product-product-content-inclusions-and-exclusions___en"
  }, {
    path: "/product/:product/product-content/information-about-ticket",
    component: _7c945826,
    name: "product-product-product-content-information-about-ticket___en"
  }, {
    path: "/product/:product/product-content/information-edit",
    component: _0abae038,
    name: "product-product-product-content-information-edit___en"
  }, {
    path: "/product/:product/product-content/makes-unique",
    component: _080221b2,
    name: "product-product-product-content-makes-unique___en"
  }, {
    path: "/product/:product/product-content/meeting-and-pickup",
    component: _a39e59c6,
    name: "product-product-product-content-meeting-and-pickup___en"
  }, {
    path: "/product/:product/product-content/photos-edit",
    component: _5fe1ee0d,
    name: "product-product-product-content-photos-edit___en"
  }, {
    path: "/product/:product/product-content/product-setup",
    component: _217ab216,
    name: "product-product-product-content-product-setup___en"
  }, {
    path: "/product/:product/schedules-and-prices/pricing-type",
    component: _082a7b6a,
    name: "product-product-schedules-and-prices-pricing-type___en"
  }, {
    path: "/product/:product/tickets/details",
    component: _5f05e445,
    name: "product-product-tickets-details___en"
  }, {
    path: "/product/:product/tickets/information-on-voucher",
    component: _6d926b64,
    name: "product-product-tickets-information-on-voucher___en"
  }, {
    path: "/product/:product/tickets/paper-and-mobile-preview",
    component: _2f1885c1,
    name: "product-product-tickets-paper-and-mobile-preview___en"
  }, {
    path: "/product/:product/tickets/redemption",
    component: _6015fa10,
    name: "product-product-tickets-redemption___en"
  }, {
    path: "/product/:product/translations/product-descriptions",
    component: _6fb2dae6,
    name: "product-product-translations-product-descriptions___en"
  }, {
    path: "/product/:product/build/basic/categories-and-themes",
    component: _7adfe57a,
    name: "product-product-build-basic-categories-and-themes___en"
  }, {
    path: "/product/:product/build/basic/photos",
    component: _3b10bfb0,
    name: "product-product-build-basic-photos___en"
  }, {
    path: "/product/:product/build/basic/product-setup",
    component: _29fadac8,
    name: "product-product-build-basic-product-setup___en"
  }, {
    path: "/product/:product/build/booking-and-tickets/booking-process",
    component: _fdc54c9a,
    name: "product-product-build-booking-and-tickets-booking-process___en"
  }, {
    path: "/product/:product/build/booking-and-tickets/cancellation-policy",
    component: _4ba1cd74,
    name: "product-product-build-booking-and-tickets-cancellation-policy___en"
  }, {
    path: "/product/:product/build/booking-and-tickets/information-edit",
    component: _c5acb8fa,
    name: "product-product-build-booking-and-tickets-information-edit___en"
  }, {
    path: "/product/:product/build/booking-and-tickets/information-on-voucher",
    component: _0c50230e,
    name: "product-product-build-booking-and-tickets-information-on-voucher___en"
  }, {
    path: "/product/:product/build/booking-and-tickets/ticket-details",
    component: _76830ef9,
    name: "product-product-build-booking-and-tickets-ticket-details___en"
  }, {
    path: "/product/:product/build/booking-and-tickets/ticket-preview",
    component: _ba9a6502,
    name: "product-product-build-booking-and-tickets-ticket-preview___en"
  }, {
    path: "/product/:product/build/booking-and-tickets/ticket-redemption",
    component: _3ec16bc4,
    name: "product-product-build-booking-and-tickets-ticket-redemption___en"
  }, {
    path: "/product/:product/build/finish/special-offers",
    component: _2aabe3f1,
    name: "product-product-build-finish-special-offers___en"
  }, {
    path: "/product/:product/build/finish/submit-for-review",
    component: _5ef6994a,
    name: "product-product-build-finish-submit-for-review___en"
  }, {
    path: "/product/:product/build/product-content/activities-details",
    component: _4f52ff7c,
    name: "product-product-build-product-content-activities-details___en"
  }, {
    path: "/product/:product/build/product-content/covid",
    component: _77f8b10a,
    name: "product-product-build-product-content-covid___en"
  }, {
    path: "/product/:product/build/product-content/guides-and-languages",
    component: _17c0c169,
    name: "product-product-build-product-content-guides-and-languages___en"
  }, {
    path: "/product/:product/build/product-content/inclusions-and-exclusions",
    component: _02471e27,
    name: "product-product-build-product-content-inclusions-and-exclusions___en"
  }, {
    path: "/product/:product/build/product-content/information-about-ticket",
    component: _45fbd732,
    name: "product-product-build-product-content-information-about-ticket___en"
  }, {
    path: "/product/:product/build/product-content/information-edit",
    component: _e2e5c5b6,
    name: "product-product-build-product-content-information-edit___en"
  }, {
    path: "/product/:product/build/product-content/makes-unique",
    component: _29ff7773,
    name: "product-product-build-product-content-makes-unique___en"
  }, {
    path: "/product/:product/build/product-content/meeting-and-pickup",
    component: _1ca5d7c4,
    name: "product-product-build-product-content-meeting-and-pickup___en"
  }, {
    path: "/product/:product/build/schedules-and-pricing/pricing-type",
    component: _edc0a952,
    name: "product-product-build-schedules-and-pricing-pricing-type___en"
  }, {
    path: "/product/:product/build/schedules-and-pricing/schedules",
    component: _46082494,
    name: "product-product-build-schedules-and-pricing-schedules___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
