<template>
  <div class="v-local-loader" />
</template>

<script>
export default {}
</script>

<style lang="scss">
.v-local-loader {
  width: 50px;
  height: 50px;
  border: 4px solid #f2f2f2;
  border-bottom-color: #0290e3;
  animation: rotation 1s linear infinite;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}
</style>
