export function formatGoogleAddress(placeAddressComponents) {
  const formats = ['route', 'street_number', 'postal_code', 'locality', 'administrative_area_level_2', 'country']

  const formattedAddressComponents = getAllAddressComponentsTypes(placeAddressComponents, formats)

  const [route, streetNumber, postalCode, locality, administrativeAreaLevel2, country] = formattedAddressComponents
  const resultsComponents = [
    route?.short_name || route?.long_name,
    streetNumber?.long_name,
    ...(postalCode && locality
      ? [`${postalCode.long_name} ${locality.long_name}`]
      : [postalCode?.long_name, locality?.long_name]),
    administrativeAreaLevel2?.long_name,
    country?.long_name
  ]

  return resultsComponents.filter(addressComponent => addressComponent).join(', ')
}

export function getAllAddressComponentsTypes(components, types) {
  return types.map(type => {
    return getAddressComponentByType(components, type)
  })
}

export function getAddressComponentByType(components, type) {
  return components.find(component => component.types.includes(type))
}
