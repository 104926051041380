<template>
  <div class="attractions-card">
    <div class="attractions-card__number">
      {{ index + 1 }}.
    </div>
    <div class="attractions-card__content">
      <div class="attractions-card__header">
        <div class="attractions-card__address">
          {{ address }}
        </div>
        <div class="attractions-card__actions">
          <button
            class="attractions-card__actions-button"
            :class="{ 'attractions-card__actions-button--disabled': disabledDown }"
            :disabled="disabledDown"
            @click="moveDown"
          >
            <IconChevronDoubleDown class="attractions-card__actions-down" />
          </button>
          <button
            class="attractions-card__actions-button"
            :class="{ 'attractions-card__actions-button--disabled': disabledUp }"
            :disbled="disabledUp"
            @click="moveUp"
          >
            <IconChevronDoubleDown class="attractions-card__actions-up" />
          </button>
          <button class="attractions-card__actions-button" @click="edit">
            <IconEdit class="attractions-card__actions-edit" />
          </button>
          <button class="attractions-card__actions-button" @click="remove">
            <IconTrash class="attractions-card__actions-trash" />
          </button>
        </div>
      </div>
      <div class="attractions-card__description">
        {{ description }}
      </div>
      <span class="attraction-card__fiiter">
        {{ durationTime }} {{ durationTranslates[durationTimeUnit] }}, {{ ticketRedemption }}{{ isSkipLine ? `, ${translates.skipTheLineInfo.text}` : '' }}
      </span>
    </div>
  </div>
</template>

<script>
import IconChevronDoubleDown from '@/components/icons/ChevronDoubleDown.vue'
import IconEdit from '@/components/icons/Edit.vue'
import IconTrash from '@/components/icons/Trash.vue'

export default {
  name: 'AttractionsCard',
  components: { IconChevronDoubleDown, IconEdit, IconTrash },
  props: {
    address: { type: String, default: '' },
    description: { type: String, default: '' },
    durationTimeUnit: { type: String, default: 'day' },
    durationTime: { type: String, default: '0' },
    ticketRedemption: { type: String, default: 'Admission is free' },
    isSkipLine: { type: Boolean, default: false },
    lon: { type: [String, Number], default: '0' },
    lat: { type: [String, Number], default: '0' },
    index: { type: Number, default: 0 },
    totalCount: { type: Number, default: 1 },
    translates: { type: Object, default: () => ({}) }
  },

  computed: {
    durationTranslates () {
      return {
        min: this.translates.DurationChoice1.text,
        hours: this.translates.DurationChoice2.text,
        days: this.translates.DurationChoice3.text
      }
    },

    disabledDown () {
      return this.index + 1 === this.totalCount
    },

    disabledUp () {
      return this.index === 0
    }
  },

  methods: {
    moveUp () {
      this.$emit('move-up')
    },

    moveDown () {
      this.$emit('move-down')
    },

    edit () {
      this.$emit('edit')
    },

    remove () {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss">
.attractions-card {
  display: flex;
  border: 1px solid #ECECEC;
  border-radius: 14px;
  max-width: 750px;

  &__content {
    padding: 18px 18px 18px 27px;
    border-left: 1px solid #ECECEC;
    width: 100%;
  }

  &__number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
  }

  &__address {
    font-size: 18px;
    font-weight: 600;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__description {
    margin-bottom: 8px;
    max-width: 488px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    box-orient: vertical;
  }

  &__actions-button {
    border: none;
    background: none;
    cursor: pointer;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-primary;

    &--disabled {
      cursor: not-allowed;
      color: #C4C4C4;
    }
  }

  &__actions-up {
    transform: rotate(180deg);
  }

   &__actions-edit {
    width: 18px;
    height: 18px;
   }

  &__actions-trash {
    width: 16px;
    height: 16px;
  }
}
</style>
