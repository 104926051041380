var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.covidSecurity.length || _vm.covidRequirements.length
    ? _c(
        "div",
        { staticClass: "preview__section", attrs: { id: "covid-info" } },
        [
          _c("div", { staticClass: "preview__title" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.translations.textCovidSI.text) + "\n  "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "preview__subtitle" }, [
            _vm._v(
              "\n    " + _vm._s(_vm.translations.textMeasures.text) + "\n  "
            ),
          ]),
          _vm._v(" "),
          _vm.tourCovidSecurity.more
            ? [
                _vm._l(_vm.tourCovidSecurity.start, function (security) {
                  return _c(
                    "div",
                    {
                      key: `covid-${security.id}`,
                      staticClass: "preview__include",
                    },
                    [
                      _c("div", {
                        staticClass: "preview__dot preview__dot--green",
                      }),
                      _vm._v("\n      " + _vm._s(security.text) + "\n    "),
                    ]
                  )
                }),
                _vm._v(" "),
                !_vm.showTourCovidSecurity
                  ? _c(
                      "span",
                      {
                        staticClass: "preview__read-more",
                        on: {
                          click: function ($event) {
                            _vm.showTourCovidSecurity = true
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.translations.textReadMore.text))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showTourCovidSecurity
                  ? [
                      _vm._l(_vm.tourCovidSecurity.more, function (security) {
                        return _c(
                          "div",
                          {
                            key: `covid-${security.id}`,
                            staticClass: "preview__include",
                          },
                          [
                            _c("div", {
                              staticClass: "preview__dot preview__dot--green",
                            }),
                            _vm._v(
                              "\n        " + _vm._s(security.text) + "\n      "
                            ),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "preview__read-more",
                          on: {
                            click: function ($event) {
                              _vm.showTourCovidSecurity = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.translations.textHide.text))]
                      ),
                    ]
                  : _vm._e(),
              ]
            : _vm._l(_vm.tourCovidSecurity, function (security) {
                return _c(
                  "div",
                  {
                    key: `covid-${security.id}`,
                    staticClass: "preview__include",
                  },
                  [
                    _c("div", {
                      staticClass: "preview__dot preview__dot--green",
                    }),
                    _vm._v("\n      " + _vm._s(security.text) + "\n    "),
                  ]
                )
              }),
          _vm._v(" "),
          _c("div", { staticClass: "preview__subtitle" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.translations.textTravellersRequirements.text) +
                "\n  "
            ),
          ]),
          _vm._v(" "),
          _vm.tourCovidRequirements.more
            ? [
                _vm._l(_vm.tourCovidRequirements.start, function (requirement) {
                  return _c(
                    "div",
                    {
                      key: `requirements-${requirement.id}`,
                      staticClass: "preview__include",
                    },
                    [
                      _c("div", {
                        staticClass: "preview__dot preview__dot--orange",
                      }),
                      _vm._v("\n      " + _vm._s(requirement.text) + "\n    "),
                    ]
                  )
                }),
                _vm._v(" "),
                !_vm.showTourCovidRequirements
                  ? _c(
                      "span",
                      {
                        staticClass: "preview__read-more",
                        on: {
                          click: function ($event) {
                            _vm.showTourCovidRequirements = true
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.translations.textReadMore.text))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showTourCovidRequirements
                  ? [
                      _vm._l(
                        _vm.tourCovidRequirements.more,
                        function (requirement) {
                          return _c(
                            "div",
                            {
                              key: `requirements-${requirement.id}`,
                              staticClass: "preview__include",
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "preview__dot preview__dot--orange",
                              }),
                              _vm._v(
                                "\n        " +
                                  _vm._s(requirement.text) +
                                  "\n      "
                              ),
                            ]
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "preview__read-more",
                          on: {
                            click: function ($event) {
                              _vm.showTourCovidRequirements = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.translations.textHide.text))]
                      ),
                    ]
                  : _vm._e(),
              ]
            : _vm._l(_vm.tourCovidRequirements, function (requirement) {
                return _c(
                  "div",
                  {
                    key: `requirements-${requirement.id}`,
                    staticClass: "preview__include",
                  },
                  [
                    _c("div", {
                      staticClass: "preview__dot preview__dot--orange",
                    }),
                    _vm._v("\n      " + _vm._s(requirement.text) + "\n    "),
                  ]
                )
              }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }