var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "expandableContainer",
      staticClass: "expandable-text",
      style: `--lines: ${_vm.lines}`,
    },
    [
      _c("span", { class: _vm.textClassState }, [
        _c(
          "span",
          { ref: "textContainer", staticClass: "expandable-text__text" },
          [_vm._v(_vm._s(_vm.localText))]
        ),
      ]),
      _vm._v(" "),
      _vm.showMoreButton
        ? _c(
            "ButtonMore",
            {
              staticClass: "expandable-text__button",
              on: { click: _vm.toggleShow },
            },
            [_vm._v(_vm._s(_vm.expandText))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }