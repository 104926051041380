var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "39",
        height: "26",
        viewBox: "0 0 39 26",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M34.4899 16.5113C33.9572 16.5113 33.4461 16.6047 32.9712 16.7751L30.7448 13.2367C32.0729 11.9292 32.8982 10.1126 32.8982 8.10613C32.8982 4.13394 29.6666 0.902344 25.6944 0.902344C21.7222 0.902344 18.4906 4.13394 18.4906 8.10613C18.4906 9.67784 18.998 11.1323 19.8555 12.3178L15.5178 16.6828C14.9473 16.3737 14.2946 16.198 13.6014 16.198C12.9171 16.198 12.2723 16.3697 11.707 16.6716L9.17183 14.012C9.93415 13.1074 10.3949 11.9406 10.3949 10.6678C10.3949 7.80198 8.0633 5.47039 5.19745 5.47039C2.33159 5.47039 0 7.80198 0 10.6678C0 13.5337 2.33146 15.8653 5.19732 15.8653C5.94987 15.8653 6.66489 15.7032 7.31126 15.4142L10.0607 18.2984C9.74492 18.8736 9.56495 19.5333 9.56495 20.2345C9.56495 22.4601 11.3756 24.2708 13.6013 24.2708C15.827 24.2708 17.6376 22.4601 17.6376 20.2345C17.6376 19.5418 17.4622 18.8895 17.1535 18.3194L21.493 13.9525C22.6766 14.8055 24.1272 15.3099 25.6941 15.3099C26.8273 15.3099 27.8997 15.0461 28.8545 14.5779L31.0653 18.0912C30.3893 18.88 29.9795 19.9034 29.9795 21.0213C29.9795 23.5082 32.0028 25.5315 34.4897 25.5315C36.9766 25.5315 38.9997 23.5082 38.9997 21.0213C38.9997 18.5345 36.9768 16.5113 34.4899 16.5113ZM20.8044 8.10613C20.8044 5.40984 22.998 3.21632 25.6943 3.21632C28.3906 3.21632 30.5841 5.40984 30.5841 8.10613C30.5841 10.8024 28.3906 12.996 25.6943 12.996C22.998 12.996 20.8044 10.8023 20.8044 8.10613ZM13.6013 21.9568C12.6515 21.9568 11.8789 21.1841 11.8789 20.2345C11.8789 19.2848 12.6517 18.5121 13.6013 18.5121C14.5509 18.5121 15.3237 19.2848 15.3237 20.2345C15.3237 21.1841 14.551 21.9568 13.6013 21.9568ZM2.31398 10.6678C2.31398 9.07788 3.60749 7.78436 5.19732 7.78436C6.78715 7.78436 8.08079 9.07788 8.08079 10.6678C8.08079 12.2578 6.78728 13.5513 5.19732 13.5513C3.60736 13.5513 2.31398 12.2578 2.31398 10.6678ZM34.4899 23.2176C33.2789 23.2176 32.2937 22.2323 32.2937 21.0213C32.2937 19.8104 33.2789 18.8251 34.4899 18.8251C35.7009 18.8251 36.686 19.8104 36.686 21.0213C36.686 22.2323 35.7008 23.2176 34.4899 23.2176Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }