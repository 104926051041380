var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tour-card" }, [
    _c("div", [
      _c("div", { staticClass: "tour-card__picture" }, [
        _vm.tour.get_slider.length != 0
          ? _c("img", {
              attrs: {
                src: _vm.tour.get_slider[0].get_image,
                alt: "Tour picture",
              },
            })
          : _c("img", {
              attrs: {
                src: require("@/assets/img/no_image.jpg"),
                alt: "no_image",
              },
            }),
        _vm._v(" "),
        _vm.tourPrice != "no price" && _vm.tourPrice > 0 && _vm.tourSale > 0
          ? _c(
              "div",
              {
                staticClass: "badge",
                style: `background: ${_vm.tour.badge_color};`,
              },
              [_vm._v("\n        " + _vm._s(_vm.getBadge) + "\n      ")]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tour-card__body" }, [
        _c("div", { staticClass: "description" }, [
          _c("div", { staticClass: "description__title" }, [
            _vm._v(_vm._s(_vm.tour.name)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "tour-footer" }, [
          _c("div", { staticClass: "price" }, [
            _c("div", { staticClass: "price_from" }, [
              _vm._v(_vm._s(_vm.headerTranslate.cardFrom.text)),
            ]),
            _vm._v(" "),
            _vm.tourPrice != "no price" && _vm.tourPrice > 0 && _vm.tourSale > 0
              ? _c("div", { staticClass: "price_old" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.formatCurrency(_vm.tourOldPrice, _vm.lang)) +
                      "\n          "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.tourPrice > 0
              ? _c(
                  "div",
                  {
                    staticClass: "price_total",
                    class: { price_total_sale: _vm.tourOldPrice > 0 },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.formatCurrency(_vm.tourPrice, _vm.lang)) +
                        "\n          "
                    ),
                  ]
                )
              : _c("div", { staticClass: "price_total" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.headerTranslate.cardNoPrice.text) +
                      "\n          "
                  ),
                ]),
            _vm._v(" "),
            _vm.tourOldPrice > 0 && _vm.tourPrice > 0
              ? _c("div", { staticClass: "price_sale" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.formatCurrency(_vm.tourSale, _vm.lang)) +
                      "\n            " +
                      _vm._s(_vm.headerTranslate.cardSavings.text) +
                      "\n          "
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }