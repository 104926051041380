<template>
  <div class="address-form">
    <VField :label="translations.placeholderTitle1.text" class="address-form__field"
      :error="$v.localAddress.country.$error">
      <client-only>
        <VSelectCountry v-model="$v.localAddress.country.$model" :country="localAddress.country"
          :error="$v.localAddress.country.$error" country-name top-country="ES" :usei18n="false"
          :placeholder="translations.placeholderText1.text" disable-placeholder />
      </client-only>
    </VField>

    <VField :label="translations.placeholderTitle2.text" class="address-form__field"
      :error="$v.localAddress.city.$error">
      <VInput v-model="$v.localAddress.city.$model" :error="$v.localAddress.city.$error"
        :placeholder="translations.placeholderText2.text" />
    </VField>

    <VField :label="translations.placeholderTitle3.text" class="address-form__field"
      :error="$v.localAddress.zip.$error">
      <VInput v-model="$v.localAddress.zip.$model" :error="$v.localAddress.zip.$error"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57" onpaste="return false;"
        :placeholder="translations.placeholderText3.text" />
    </VField>

    <VField :label="translations.placeholderTitle4.text" class="address-form__field"
      :error="$v.localAddress.address.$error">
      <VInput v-model="$v.localAddress.address.$model" :error="$v.localAddress.address.$error"
        :placeholder="translations.placeholderText4_1.text" />
      <VInput v-model="localAddress.secondAddress" :placeholder="translations.placeholderText4_2.text" />
    </VField>
    <VBtn class="address-form__button" :loading="loading" base :name="saveBtnText" @click="save" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import VInput from '@/components/controls/VInput.vue'
import VBtn from '@/components/controls/VBtn.vue'
import VSelectCountry from '@/components/controls/VSelectCountry.vue'
import VField from '@/components/controls/VField.vue'

export default {
  name: 'AddressForm',
  components: { VInput, VBtn, VSelectCountry, VField },
  props: {
    address: { type: Object, default: null },
    translations: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
    saveBtnText: { type: String, default: '' }
  },

  data() {
    return {
      localAddress: {
        country: '',
        city: '',
        zip: '',
        address: '',
        secondAddress: ''
      }
    }
  },

  validations() {
    return {
      localAddress: {
        country: { required },
        city: { required },
        zip: { required },
        address: { required }
      }
    }
  },

  watch: {
    address: {
      immediate: true,
      handler() {
        if (this.address) {
          this.localAddress = { ...this.address }
          this.$v.$reset()
        }
      }
    }
  },

  methods: {
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.$emit('save', this.localAddress)
    }
  }
}
</script>

<style lang="scss">
.address-form {
  max-width: 720px;

  &__field {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    .v-input {
      margin-top: 0px;
    }

    &:not(:first-child) {
      .v-field__info {
        margin-bottom: 0px;
      }
    }
  }

  &__button {
    margin-top: 72px;
  }
}
</style>
