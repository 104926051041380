var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "pop-up-select-language" }, [
          _c(
            "div",
            { staticClass: "pop-up__close", on: { click: _vm.closeWindow } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/close.svg"),
                  alt: "close",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pop-up-select-language__content" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.headerTranslate.popupTitle.text) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "subtitle" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.headerTranslate.popupSubtitle.text) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "language" },
              _vm._l(_vm.languages, function (language, index) {
                return _c(
                  "div",
                  {
                    key: `language-${index}`,
                    staticClass: "language__item",
                    on: {
                      click: function ($event) {
                        return _vm.activeLang(index)
                      },
                    },
                  },
                  [
                    _c("RadioButton", {
                      attrs: {
                        id: `language-${index}`,
                        default: "",
                        name: language.name,
                        "radio-name": "select-language",
                        checked: language.is_checked,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "nav" }, [
              _c(
                "div",
                { staticClass: "nav__cancel", on: { click: _vm.closeWindow } },
                [
                  _c("Btn", {
                    attrs: {
                      outline: "",
                      name: _vm.headerTranslate.cancelBtn.text,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "nav__save", on: { click: _vm.saveLang } },
                [
                  _c("Btn", {
                    attrs: { base: "", name: _vm.headerTranslate.saveBtn.text },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }