<template>
  <div>
    <header class="header">
      <div class="_container">
        <div class="header__inner">
          <div class="nav">
            <nuxt-link :to="localePath('/')" tag="div" class="logo">
              <img src="@/assets/img/header/logo.svg" alt="logo" />
            </nuxt-link>
            <nuxt-link :to="localePath('/bookings')" class="nav__item">
              {{ headerTranslate.headerBookings.text }}
            </nuxt-link>
            <nuxt-link :to="localePath('/finance')" class="nav__item">
              {{ headerTranslate.headerFinance.text }}
            </nuxt-link>
            <nuxt-link :to="localePath('/product')" class="nav__item">
              {{ headerTranslate.headerProduct.text }}
            </nuxt-link>
            <nuxt-link :to="localePath('/availability')" class="nav__item">
              {{ headerTranslate.headerAvailability.text }}
            </nuxt-link>
            <nuxt-link :to="localePath('/performance')" class="nav__item">
              {{ headerTranslate.headerPerformance.text }}
            </nuxt-link>
            <nuxt-link :to="localePath('/reviews')" class="nav__item">
              {{ headerTranslate.headerReviews.text }}
            </nuxt-link>
          </div>
          <div class="header__right">
            <div class="account" @click="showMenu = !showMenu">
              <div class="account__name">{{ getFirstName }} ({{ userInfo?.display_id ?? '' }})</div>
              <div class="arrow">
                <img src="@/assets/img/svg/small-arrow.svg" alt="small-arrow" />
              </div>
              <div v-if="showMenu" class="account__menu" @mouseleave="showMenu = false">
                <nuxt-link
                  :to="localePath(!userInfo.is_seller_company ? '/account/private-information' : '/account/business-information')"
                  class="account-edit">
                  <img class="gear" src="@/assets/img/svg/profile.svg" alt="profile" />
                  {{ headerTranslate.headerKebabAccount.text }}
                </nuxt-link>
                <div class="language" @click="selectLanguage = !selectLanguage">
                  <img class="language__image" src="@/assets/img/svg/language.svg" alt="language" />
                  {{ headerTranslate.headerKebabLanguage.text }}
                </div>
                <nuxt-link class="logout" :to="localePath('/logout')">
                  <img class="exit" src="@/assets/img/svg/logout.svg" alt="logout" />
                  {{ headerTranslate.headerKebabLogout.text }}                  
                </nuxt-link>
              </div>
            </div>
            <nuxt-link :to="localePath('/help')" class="nav__item">
              {{ headerTranslate.headerHelp.text }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </header>
    <Nuxt class="default-wrapper" />
    <selectLanguage :is-active="selectLanguage" :header-translate="headerTranslate"
      @close="selectLanguage = !selectLanguage" />
    <PortalTarget name="popups" multiple></PortalTarget>
    <notification-view />
    <screenResolutionNotice />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    selectLanguage: () => import('@/components/pop-up/selectLanguage.vue'),
    NotificationView: () => import('@/components/common/VNotificationsView'),
    screenResolutionNotice: () => import('@/components/pop-up/screenResolutionNotice.vue')
  },
  data: () => ({
    showMenu: false,
    selectLanguage: false,
    loading: false
  }),
  head() {
    return this.$nuxtI18nHead()
  },
  computed: {
    ...mapState(['userInfo']),
    ...mapState(['headerTranslate']),
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    },
    getFirstName() {
      let name = ''

      if (!this.userInfo?.is_seller_company) {
        try {
          name = this.userInfo.seller_full?.split(' ')[0] || ''
        } catch {
          name = this.userInfo?.seller_full || ''
        }
      } else name = this.userInfo?.contact_first_name || ''

      name = name || ' '

      if (name && name?.length === 0) name = this.userInfo?.email || ''

      if (name && name?.length > 40) name = name.substring(0, 40) + '...'

      return name
    }
  },
  methods: {
    ...mapActions(['logout'])
  }
}
</script>

<style lang="scss">
.header {
  margin-bottom: 22px;
  border-bottom: 1px solid #ebebeb;

  &__inner {
    padding: 12px 0;
    padding-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .nav {
      display: flex;
      align-items: center;

      &__item {
        cursor: pointer;
        margin-right: 30px;
        font-size: 16px;
        line-height: 16px;

        &:hover {
          color: $primary;
        }

        &.nuxt-link-active {
          position: relative;
          font-weight: bold;

          &::after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: -12px;
            left: 0;
            border-bottom: 2px solid $primary;
          }
        }
      }

      .logo {
        cursor: pointer;
        margin-right: 48px;
        width: 80px;
        height: 25px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .account {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;

      &__name {
        margin-right: 7px;
        font-size: 16px;
        line-height: 16px;
      }

      .arrow {
        padding-top: 2px;
        width: 10px;
        height: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &__menu {
        position: absolute;
        right: 0;
        top: 20px;
        width: 100%;
        min-width: 120px;
        padding: 12px 14px 16px 14px;
        background: $white;
        box-shadow: 0px 2px 8px rgba(30, 40, 67, 0.1);
        border-radius: 8px 0 8px 8px;
        z-index: 100;

        .account-edit {
          cursor: pointer;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 16px;

          .gear {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }

        .language {
          cursor: pointer;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 16px;

          &__image {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }

        .logout {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 16px;

          .exit {
            margin-right: 10px;
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  &__right {
    display: flex;
    gap: 25px;
  }
}
</style>
