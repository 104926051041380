<template>
  <ul class="v-list">
    <li v-for="(item, index) in itemsShow" :key="index" class="v-list__item" :class="itemClasses">
      <span class="v-list__marker-wrapper">
        <slot name="marker">
          <span class="v-list__marker" :class="{ 'v-list__marker--orange': orange }" />
        </slot>
      </span>
      <slot v-bind="item">
        {{ item.description }}
      </slot>
    </li>
    <button v-if="items.length > showCount" class="v-list__button-show" @click="toggleShow">
      {{ textShow }}
      <div class="v-list__button-arrow" :class="{ 'v-list__button-arrow--expanded': isShowHidden }">
        <img src="@/assets/img/svg/small-arrow.svg" alt="small-arrow" />
      </div>
    </button>
  </ul>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'VExpandableList',

  props: {
    items: { type: Array, default: () => [] },
    showCount: { type: Number, default: 2 },
    orange: { type: Boolean, default: false }
  },

  data() {
    return {
      isShowHidden: false
    }
  },

  computed: {
    ...mapState(['headerTranslate']),

    itemsShow() {
      if (this.isShowHidden) {
        return this.items
      }
      return this.items.slice(0, this.showCount)
    },

    textShow() {
      return this.isShowHidden ? this.headerTranslate.showLessButton.text : this.headerTranslate.showMoreButton.text
    },

    itemClasses() {
      return {
        'v-list__item--green': this.green,
        'v-list__item--blue': this.blue,
        'v-list__item--black': this.black
      }
    }
  },

  methods: {
    toggleShow() {
      this.isShowHidden = !this.isShowHidden
    }
  }
}
</script>

<style lang="scss">
.v-list {
  &__item {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    word-break: break-word;
    word-wrap: anywhere;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &__marker-wrapper {
    margin-right: 12px;
    flex-shrink: 0;
    height: 22px;
    display: flex;
    align-items: center;
    justify-items: center;
  }

  &__marker {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: $text-primary;

    &--orange {
      background: $orange;
    }
  }

  &__button-show {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    color: #989898;
    max-width: fit-content;

    &:hover {
      color: $primary;
    }
  }

  &__button-arrow {
    margin-left: 4px;

    &--expanded {
      transform: rotate(180deg);
    }
  }
}
</style>
