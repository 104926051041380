<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-examples">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close">
      </div>
      <div class="pop-up-examples__title">
        {{ examplesTranslate.examplesPopupTitle.text }}
      </div>
      <div class="pop-up-examples__content">
        <div class="item">
          <div class="dot dot_orange" />
          {{ examplesTranslate.examplesPopupItem1.text }}
        </div>
        <div class="item">
          <div class="dot dot_orange" />
          {{ examplesTranslate.examplesPopupItem2.text }}
        </div>
        <div class="item">
          <div class="dot dot_orange" />
          {{ examplesTranslate.examplesPopupItem3.text }}
        </div>
        <div class="item">
          <div class="dot dot_orange" />
          {{ examplesTranslate.examplesPopupItem4.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/controls/Btn.vue'
export default {
  components: {
    Btn
  },
  props: ['isActive', 'examplesTranslate'],
  methods: {
    closeWindow () {
      const active = this.isActive
      this.$emit('close', active)
    }
  }
}
</script>

<style lang="scss">
.pop-up-examples {
  position: relative;
  background: $white;
  padding: 35px;
  width: 666px;
  &__title {
    margin-bottom: 23px;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
  &__content {
    .item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 18px;
      }
      .dot {
        width: 7px;
        height: 7px;
        margin-right: 15px;
      }
    }
  }
}
</style>
