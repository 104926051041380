<template>
  <div class="change-lang" :class="{'opened': isOpen}">
    <div class="change-lang__select change-lang__item" @click="isOpen = !isOpen">
      <img :src="activeLang.image" alt="" />
      <svg width="19" height="10" viewBox="0 0 19 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 1L9.5 9L1 0.999999" stroke="#B2B7C6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="change-lang__items" :class="{'show': isOpen}">
      <template v-for="lang in langs">
        <div v-if="lang.id !== activeLang.id" :key="lang.id" class="change-lang__item" @click="onChange(lang)">
          <img :src="lang.image" alt="" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const langs = [
  { "id": 4, "image": "https://test.backend.vidodoguide.com/media/uploads/icons/lang_eu_CO4WemK.svg", "lang": "English", "abbreviation": "EN" },
  { "id": 5, "image": "https://test.backend.vidodoguide.com/media/uploads/icons/lang_es_gz73Udl.svg", "lang": "Español", "abbreviation": "ES" }
]

export default {
  components: {
  },
  data() {
    return {
      isOpen: false,
      langs,
      activeLang: langs[0]
    }
  },
  methods: {
    onChange(newLang) {
      this.activeLang = newLang;
      this.isOpen = false;
      this.$i18n.setLocale(newLang.abbreviation.toLowerCase());
    }
  }
}
</script>

<style lang="scss">

.change-lang {
  position: relative;
  padding: 8px;
  border-radius: 7px;
  $gap-y: 10px;
  transition: box-shadow 0.3s;

  &.opened {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
  }

  &.absolute-top {
    position: absolute;
    top: 0;
  }

  &__select {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  &__items {
    margin-top: $gap-y;
    display: flex;
    flex-direction: column;
    gap: $gap-y;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
    transition: opacity 0.3s;
    &.show {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__item {
    img {
      width: 28px;
      height: 28px;
    }
  }
}
</style>