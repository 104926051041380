<template>
  <VDialog
    id="intro-offer"
    v-model="modelVisible"
    :title="
      isFirstPage
        ? `${introOfferTranslate.setupTitle.text} ${tour.name}`
        : introOfferTranslate.discountTitle.text
    "
    :dialog-classes="isFirstPage ? 'pop-up-intro-offer__dialog' : ''"
    class="wrapper"
  >
    <div class="discount">
      <div class="discount__content">
        <div v-if="isFirstPage" class="pop-up-intro-offer">
          <div class="pop-up-intro-offer__content">
            <div class="from-till">
              <div class="from-till__title row">
                {{ introOfferTranslate.contentFromTill.text }}
                <div
                  class="question-mark"
                  @mouseover="questionDescriptionShow = true"
                  @mouseleave="questionDescriptionShow = false"
                >
                  <img src="@/assets/img/svg/question-info.svg" alt="question-info" />
                  <div v-if="questionDescriptionShow" class="question-mark__description">
                    {{ introOfferTranslate.fromTillQuestion.text }}
                  </div>
                </div>
              </div>
              <div class="from-till__date row">
                <div class="first-date">
                  <date-picker
                    v-model="time1"
                    value-type="format"
                    :disabled-date="disabledDates"
                    :placeholder="introOfferTranslate.datePickerFrom.text"
                    :editable="false"
                    @change="emitToParent"
                  />
                </div>
                <div class="dash">-</div>
                <div class="second-date">
                  <date-picker
                    v-model="time2"
                    value-type="format"
                    :disabled-date="disabledDates"
                    :placeholder="introOfferTranslate.datePickerTo.text"
                    :editable="false"
                    @change="emitToParent"
                  />
                </div>
              </div>
            </div>
            <div class="discount-days">
              <div class="discount-days__title row">
                {{ introOfferTranslate.contentDiscountDays.text }}
              </div>
              <div class="discount-days__radio" @click="changeDates">
                <div class="all-dates">
                  <RadioButton
                    id="discount-days-1"
                    default
                    :name="introOfferTranslate.discountDaysRadio1.text"
                    radio-name="discount-days"
                    :checked="!isRangeActive"
                  />
                </div>
                <div class="date-range">
                  <RadioButton
                    id="discount-days-2"
                    default
                    :name="introOfferTranslate.discountDaysRadio2.text"
                    radio-name="discount-days"
                    :checked="isRangeActive"
                  />
                </div>
              </div>
              <div v-if="isRangeActive" class="discount-days__date row">
                <div class="first-date">
                  <date-picker
                    v-model="time3"
                    value-type="format"
                    :disabled-date="disabledDates"
                    :placeholder="introOfferTranslate.datePickerFrom.text"
                    :class="{ 'input-error': time3 == null }"
                    :editable="false"
                    @change="emitToParent"
                  />
                </div>
                <div class="dash">-</div>
                <div class="second-date">
                  <date-picker
                    v-model="time4"
                    value-type="format"
                    :disabled-date="disabledDates"
                    :placeholder="introOfferTranslate.datePickerTo.text"
                    :class="{ 'input-error': time4 == null }"
                    :editable="false"
                    @change="emitToParent"
                  />
                </div>
                <div class="clear-dates" @click="clearDateRange">
                  {{ introOfferTranslate.discountDaysClearDate.text }}
                </div>
              </div>
            </div>
          </div>
          <div class="pop-up-intro-offer__nav">
            <div class="cancel" @click="closeWindow">
              <Btn outline :name="introOfferTranslate.cancelBtn.text" />
            </div>
            <img src="@/assets/img/svg/dot-page-1.svg" alt="dot-page" />
            <div class="next" @click="changePage">
              <Btn base :name="introOfferTranslate.nextBtn.text" />
            </div>
          </div>
        </div>
        <div v-if="isSecondPage" class="pop-up-set-discount">
          <div class="pop-up-set-discount__content">
            <div class="enter-discount">
              <div class="enter-discount__title">
                {{ introOfferTranslate.contentDiscount.text }}
              </div>
              <div class="enter-discount__subtitle">
                {{ introOfferTranslate.discountDescription.text }}
              </div>
            </div>
            <div class="package">
              <div class="package__name">
                {{ introOfferTranslate.contentPackage.text }}
              </div>
              <div class="package__info">
                <div class="discount">
                  <div class="discount__title">
                    {{ introOfferTranslate.packageDiscount.text }}
                  </div>
                  <div class="discount__description">
                    <input-fields
                      v-model="discount"
                      type="number"
                      min="0"
                      max="99"
                      :class="{ 'input-error': discountError }"
                      step="1"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      onpaste="return false;"
                      @input="emitToParent"
                    />
                    <div class="procent">%</div>
                  </div>
                </div>
                <div class="retail-price">
                  <div class="retail-price__title">
                    {{ introOfferTranslate.packageRetail.text }}
                  </div>
                  <div class="retail-price__price">
                    <div v-if="tour.old_price == 0" class="old-price">
                      {{ eurToFixed(tour.price) }}
                    </div>
                    <div v-else-if="tour.old_price != 0" class="old-price">
                      {{ eurToFixed(tour.old_price) }}
                    </div>
                    <div
                      v-if="tour.old_price == 0 && discount != 'NaN'"
                      class="total-price"
                    >
                      {{ eurToFixed(tourPriceDiscount) }}
                    </div>
                    <div
                      v-else-if="tour.old_price != 0 && discount != 'NaN'"
                      class="total-price"
                    >
                      {{ eurToFixed(oldTourPriceDiscount) }}
                    </div>
                    <div v-else-if="discount == 'NaN'" class="total-price">
                      {{ eurToFixed(tour.price) }}
                    </div>
                  </div>
                </div>
                <div class="get-paid">
                  <div class="get-paid__title">
                    {{ introOfferTranslate.packageGetPaid.text }}
                  </div>
                  <div class="get-paid__price">
                    <div v-if="tour.old_price == 0" class="old-price">
                      {{ eurToFixed(tourPrice) }}
                    </div>
                    <div v-else-if="tour.old_price != 0" class="old-price">
                      {{ eurToFixed(oldTourPrice) }}
                    </div>
                    <div
                      v-if="tour.old_price == 0 && discount != 'NaN'"
                      class="total-price"
                    >
                      {{ eurToFixed(total) }}
                    </div>
                    <div
                      v-else-if="tour.old_price != 0 && discount != 'NaN'"
                      class="total-price"
                    >
                      {{ eurToFixed(oldTotal) }}
                    </div>
                    <div v-else-if="discount == 'NaN'" class="total-price">
                      {{ eurToFixed(tourPrice) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="package__preview">
                <TourCard
                  v-if="tour.old_price == 0 && !isNaN(discount)"
                  :tour="tour"
                  :tour-price="Number(tourPriceDiscount)"
                  :tour-old-price="Number(tour.price)"
                  :tour-sale="getSale(tour.price, discount)"
                />
                <TourCard
                  v-else-if="tour.old_price != 0 && !isNaN(discount)"
                  :tour="tour"
                  :tour-price="Number(oldTourPriceDiscount)"
                  :tour-old-price="Number(tour.old_price)"
                  :tour-sale="getSale(tour.old_price, discount)"
                />
                <TourCard
                  v-else
                  :tour="tour"
                  :tour-price="Number(tour.price)"
                  :tour-old-price="Number(tour.old_price)"
                  :tour-sale="getSale(tour.price, discount)"
                />
              </div>
            </div>
          </div>
          <div class="pop-up-set-discount__nav">
            <div class="back" @click="previousPage">
              <Btn outline :name="introOfferTranslate.backBtn.text" />
            </div>
            <img src="@/assets/img/svg/dot-page-2.svg" alt="dot-page" />
            <div class="save" @click="saveWindow">
              <Btn base :name="introOfferTranslate.saveBtn.text" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </VDialog>
</template>

<script>
import { mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import RadioButton from "@/components/controls/RadioButton.vue";
import Btn from "@/components/controls/Btn.vue";
import InputFields from "@/components/controls/Input-fields.vue";
import TourCard from "@/components/TourCard.vue";
import VDialog from "@/components/common/VDialog.vue";
import "vue2-datepicker/index.css";
import { checkDateEnd } from "@/utils/validations";
import notificationService from "@/services/notification";
import { formatCurrency } from "@/utils/utils";

export default {
  components: {
    Btn,
    RadioButton,
    InputFields,
    TourCard,
    DatePicker,
    VDialog,
  },
  props: ["isActive", "tour", "introOfferTranslate"],
  data: () => ({
    time1: null,
    time2: null,
    time3: null,
    time4: null,
    discount: "",
    discountError: false,
    isRangeActive: false,
    isFirstPage: true,
    isSecondPage: false,
    saved: false,
    questionDescriptionShow: false,
    lang: "",
  }),
  fetch() {
    this.lang = this.$cookies.get("i18n_redirected");
    this.time1 = this.tour.discount_info[0]?.start_date;
    this.time2 = this.tour.discount_info[0]?.end_date;
    this.time3 = this.tour.discount_info[0]?.discount_start_date;
    this.time4 = this.tour.discount_info[0]?.discount_end_date;
    this.discount = parseInt(this.tour.discount_info[0]?.discount);
    this.isRangeActive = true;
    if (this.time1 == this.time3 && this.time2 == this.time4) {
      this.time3 = null;
      this.time4 = null;
      this.isRangeActive = false;
    }
    this.discount = this.discount.toString();
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapState(["headerTranslate"]),
    modelVisible: {
      get() {
        return this.isActive;
      },
      set(visible) {
        this.$emit("update:isActive", visible);
      },
    },
    tourPrice() {
      return this.getTourPrice(this.tour.price, this.userInfo.commission_amount);
    },
    tourPriceDiscount() {
      return this.getTourPrice(this.tour.price, this.discount);
    },
    oldTourPrice() {
      return this.getTourPrice(this.tour.old_price, this.userInfo.commission_amount);
    },
    oldTourPriceDiscount() {
      return this.getTourPrice(this.tour.old_price, this.discount);
    },
    total() {
      return this.getTotal(
        this.tour.price,
        this.discount,
        this.userInfo.commission_amount
      );
    },
    oldTotal() {
      return this.getTotal(
        this.tour.old_price,
        this.discount,
        this.userInfo.commission_amount
      );
    },
  },
  methods: {
    isEmptyObject(obj) {
      for (const key in obj) {
        return false;
      }
      return true;
    },
    checkDateEnd,
    changeDates() {
      const dateRange = document.getElementById("discount-days-2");
      if (dateRange.checked) {
        this.isRangeActive = true;
      } else {
        this.isRangeActive = false;
      }
    },
    changePage() {
      if (document.getElementById("discount-days-1").checked) {
        this.time3 = this.time1;
        this.time4 = this.time2;
      }
      if (this.time1 != null && this.time2 != null && this.time1 < this.time2) {
        if (document.getElementById("discount-days-2").checked) {
          if (this.time3 != null && this.time4 != null && this.time3 < this.time4) {
            this.isSecondPage = true;
            this.isFirstPage = false;
          }
        } else {
          this.isSecondPage = true;
          this.isFirstPage = false;
        }
      }
    },
    clearDateRange() {
      this.time3 = null;
      this.time4 = null;
    },
    previousPage() {
      this.isSecondPage = false;
      this.isFirstPage = true;
    },
    disabledDates(date) {
      const newDate = new Date();
      newDate.setDate(newDate.getDate() - 1);
      if (newDate <= date) {
        return false;
      }
      return true;
    },
    closeWindow() {
      this.isFirstPage = true;
      this.isSecondPage = false;
      this.$emit("close");
    },
    saveWindow() {
      this.saved = true;
      this.emitToParent();
      this.saved = false;
      if (!this.discountError) {
        this.closeWindow();
      }
    },
    emitToParent(event) {
      this.discountError = false;
      if (!this.checkDateEnd(this.time1, this.time2)) {
        this.time2 = null;
        notificationService.error({
          title: this.introOfferTranslate.endDateCannotBeEarlier.text,
        });
        this.discountError = true;
        return;
      }
      if (!this.checkDateEnd(this.time3, this.time4)) {
        this.time4 = null;
        notificationService.error({
          title: this.introOfferTranslate.endDateCannotBeEarlier.text,
        });
        this.discountError = true;
        return;
      }

      if (this.time1 != null && this.time1 === this.time2) {
        this.time2 = null;
        notificationService.error({
          title: this.introOfferTranslate.startDateCannotBeEndDate.text,
        });
        this.discountError = true;
        return;
      }

      if (this.time3 != null && this.time3 === this.time4) {
        this.time4 = null;
        notificationService.error({
          title: this.introOfferTranslate.startDateCannotBeEndDate.text,
        });
        this.discountError = true;
        return;
      }

      if (this.isSecondPage) {
        if (
          isNaN(this.discount) ||
          Number(this.discount) <= 0 ||
          Number(this.discount) > 99
        ) {
          this.discountError = true;
        } else {
          this.discountError = false;
          if (this.saved) {
            const data = {
              startDate: this.time1,
              endDate: this.time2,
              discountStart: this.time3,
              discountEnd: this.time4,
              discount: this.discount,
            };
            this.$emit("save", data);
          }
        }
      }
    },
    getTourPrice(price, discount) {
      return price - price * (discount / 100);
    },
    getSale(price, discount) {
      return Number(price) - this.getTourPrice(Number(price), discount);
    },
    getTotal(price, discount, commissionAmount) {
      return (((price * (100 - discount)) / 100) * (100 - commissionAmount)) / 100;
    },
    eurToFixed(value) {
      return formatCurrency(value, this.lang, "code");
    },
  },
};
</script>

<style lang="scss">
.pop-up-intro-offer__dialog {
  width: 800px;
  margin: auto;
}
.discount {
  &__content {
    .pop-up-intro-offer {
      background: $white;
      position: relative;
      width: 740px;
      &__subtitle {
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
      }
      &__content {
        .from-till {
          margin-bottom: 74px;
          &__title {
            border-top: 1px solid #ececec;
            padding-top: 37px;
            margin-bottom: 18px;
            margin-top: 18px;
            width: 720px;
            font-weight: bold;
            .question-mark {
              margin-left: 8px;
            }
          }
        }
        .discount-days {
          margin-bottom: 60px;
          &__title {
            margin-bottom: 15px;
            font-weight: bold;
          }
          &__radio {
            .all-dates {
              margin-bottom: 11px;
            }
            .date-range {
              margin-bottom: 11px;
            }
          }
          &__date {
            .clear-dates {
              font-weight: bold;
              cursor: pointer;
              margin-left: 19px;
              color: $orange;
            }
          }
          .input-error {
            border-radius: 5px;
          }
        }
      }
      &__nav {
        padding-top: 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #ececec;
        width: 720px;
        .btn {
          &_outline {
            border: 2px solid $primary;
            border-radius: 4px;
            min-width: 102px;
            height: 48px;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
          }
          &_base {
            border: 2px solid $primary;
            border-radius: 4px;
            min-width: 102px;
            height: 48px;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
          }
        }
      }
    }
    .pop-up-set-discount {
      background: $white;
      position: relative;
      width: 1080px;
      &__content {
        margin-bottom: 72px;
        .enter-discount {
          &__title {
            border-top: 1px solid #ececec;
            padding-top: 37px;
            margin-top: 18px;
            margin-bottom: 5px;
            font-weight: bold;
            width: 1072px;
          }
          &__subtitle {
            margin-bottom: 5px;
            color: #999999;
          }
        }
        .package {
          position: relative;
          padding: 26px 60px 35px 27px;
          background: #ffffff;
          border: 1px solid #ebebeb;
          border-radius: 4px;
          width: 730px;
          height: 174px;
          &__name {
            margin-bottom: 29px;
          }
          &__info {
            display: flex;
            .discount {
              margin-right: 67px;
              &__title {
                margin-bottom: 14px;
                font-weight: bold;
              }
              &__description {
                display: flex;
                .input {
                  width: 65px;
                  height: 35px;
                }
                .procent {
                  margin-left: -2px;
                  padding: 5px 7px;
                  border: 1px solid #ebebeb;
                  border-radius: 0px 4px 4px 0px;
                }
              }
              .input {
                width: 80px;
              }
            }
            .retail-price {
              margin-right: 72px;
              &__title {
                margin-bottom: 20px;
                font-weight: bold;
              }
              &__price {
                display: flex;
                .old-price {
                  margin-right: 18px;
                  text-decoration: line-through;
                  color: #999999;
                }
              }
            }
            .get-paid {
              &__title {
                margin-bottom: 20px;
                font-weight: bold;
              }
              &__price {
                display: flex;
                .old-price {
                  margin-right: 18px;
                  text-decoration: line-through;
                  color: #999999;
                }
              }
            }
          }
          &__preview {
            position: absolute;
            bottom: -47px;
            left: 800px;
          }
        }
      }
      &__nav {
        padding-top: 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #ececec;
        width: 1072px;
        .btn {
          &_outline {
            border: 2px solid $primary;
            border-radius: 4px;
            min-width: 102px;
            height: 48px;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
          }
          &_base {
            border: 2px solid $primary;
            border-radius: 4px;
            min-width: 102px;
            height: 48px;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
