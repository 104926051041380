<template>
  <div class="v-input">
    <label v-if="label" class="v-input__label">{{ label }} <span v-if="optional" class="v-input__label-optional">({{
      optional }})</span></label>
    <div v-if="subLabel" class="v-input__sub-label">
      {{ subLabel }}
    </div>
    <div class="v-input__row">
      <div v-if="prependText.length > 0" class="v-input__prepend-text">{{ prependText }}</div>
      <div :class="[prependText.length > 0 ? 'v-input__prepend-text-input' : '', 'v-input__field-wrapper']">
        <input :id="id" v-model="localModel" :type="type" :class="{ 'is-invalid': error }" :placeholder="placeholder"
          @blur="$emit('blur', $event)"
          :maxLength="maxLength" :disabled="isDisabled" :onkeypress="onkeypress" :onpaste="onpaste" />
        <div v-if="maxLength > 0 && !hideCounter" class="v-input__counter">{{ value.length }}/{{ maxLength }}</div>
      </div>
    </div>
    <div v-if="error && errorMessage" class="validation-error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VInput',
  props: {
    id: { type: String, default: '' },
    label: { type: String, default: '' },
    optional: { type: String, default: '' },
    subLabel: { type: String, default: '' },
    type: { type: String, default: 'text' },
    value: { type: [String, Number], default: '' },
    placeholder: { type: String, default: '' },
    maxLength: { type: Number, default: null },
    hideCounter: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' },
    onkeypress: { type: String, default: '' },
    onpaste: { type: String, default: '' },
    prependText: { type: String, default: '' },
    isDisabled: { type: Boolean, default: false }
  },

  computed: {
    localModel: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss">
.v-input {
  margin-top: 15px;

  &__label {
    font-weight: 700;
  }

  &__label-optional {
    color: #999999;
    font-size: 14px;
  }

  &__row {
    display: flex;
  }

  &__prepend-text {
    padding: 12px 7px;
    background: $white;
    border: 1px solid #ebebeb;
    border-radius: 3px 0px 0px 3px;
    width: 47px;
    margin-top: 10px;
  }

  &__prepend-text-input {
    input {
      border-radius: 0 3px 3px 0;
    }
  }

  &__sub-label {
    margin-top: 5px;
    font-size: 14px;
    line-height: 19px;
  }

  input {
    margin-top: 10px;
  }

  .v-input__field-wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    .v-input__counter {
      position: absolute;
      bottom: -3px;
      right: 12px;
      text-align: right;
      font-size: 14px;
      color: #cbcbcb;
      z-index: 3;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}
</style>
