var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "your-expertise-form" },
    [
      _c("VRadioGroup", {
        staticClass: "your-expertise-form__field",
        attrs: {
          label: _vm.translations.titleProfessional?.text,
          name: "isProfessional",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "VRadioButton",
                  _vm._g(
                    _vm._b(
                      { attrs: { value: true } },
                      "VRadioButton",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [_vm._v(" " + _vm._s(_vm.translations.optionYes?.text) + " ")]
                ),
                _vm._v(" "),
                _c(
                  "VRadioButton",
                  _vm._g(
                    _vm._b(
                      { attrs: { value: false } },
                      "VRadioButton",
                      attrs,
                      false
                    ),
                    on
                  ),
                  [_vm._v(" " + _vm._s(_vm.translations.optionNo?.text) + " ")]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.isProfessional,
          callback: function ($$v) {
            _vm.isProfessional = $$v
          },
          expression: "isProfessional",
        },
      }),
      _vm._v(" "),
      _vm.isProfessional
        ? _c("VRadioGroup", {
            staticClass: "your-expertise-form__field",
            attrs: {
              name: "hasLicense",
              label: _vm.translations.titleHaveLicense?.text,
              "sub-label": _vm.translations.descrHaveLicense?.text,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "VRadioButton",
                        _vm._g(
                          _vm._b(
                            { attrs: { value: true } },
                            "VRadioButton",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(
                            " " + _vm._s(_vm.translations.optionYes?.text) + " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "VRadioButton",
                        _vm._g(
                          _vm._b(
                            { attrs: { value: false } },
                            "VRadioButton",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(
                            " " + _vm._s(_vm.translations.optionNo?.text) + " "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              2509035380
            ),
            model: {
              value: _vm.hasLicense,
              callback: function ($$v) {
                _vm.hasLicense = $$v
              },
              expression: "hasLicense",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("VInput", {
        staticClass: "your-expertise-form__field",
        attrs: {
          error: _vm.$v.profession.$error,
          label: _vm.translations.titleYourProfession?.text,
          optional: _vm.isProfessional ? "optional" : "",
          "sub-label": _vm.translations.descriptionYourProfession?.text,
          placeholder: _vm.translations.answerYourProfession?.text,
          "error-message": _vm.translations.errorProfession?.text,
        },
        model: {
          value: _vm.$v.profession.$model,
          callback: function ($$v) {
            _vm.$set(_vm.$v.profession, "$model", $$v)
          },
          expression: "$v.profession.$model",
        },
      }),
      _vm._v(" "),
      _c("VBtn", {
        staticClass: "your-expertise-form__button",
        attrs: { loading: _vm.loading, base: "", name: _vm.saveBtnText },
        on: { click: _vm.save },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }