var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "error-banner" },
    [
      _c("base-icon", { attrs: { "icon-name": "Info" } }),
      _vm._v(" "),
      _c(
        "ul",
        _vm._l(_vm.errors, function (message) {
          return _c("li", { key: message, staticClass: "error-banner__item" }, [
            _vm._v("\n      " + _vm._s(message) + "\n    "),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }