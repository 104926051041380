<template>
  <div v-if="showPickUp" class="preview__section pick-up">
    <div class="preview__title">
      {{ translations.textPickupPoint.text }}
    </div>
    <div v-if="isPickUpOrMeet && isTravelServices" class="preview__text">You can head directly to the meeting point, or request pickup.</div>    
    <div v-if="hasOwnPickup" class="preview__text">Pickup is included. The activity operator will contact you to agree on a pickup address.</div>
    <div v-if="pickUpDescription" class="preview__text">{{ pickUpDescription }}</div>
  </div>
</template>

<script>
export default {
  name: 'PickUp',
  props: {
    pickUps: { type: Array, default: () => [] },
    pickUpDescription: { type: String, default: '' },
    translations: { type: Object, default: () => ({}) },
    tour: { type: Object, default: () => ({}) },
  },
  computed: {
    showPickUp() {
      return this.pickUpDescription || this.pickUps.length !== 0;
    },
    hasOwnPickup() {
      return this.tour.travelers_own_pick_up && this.tour.travelers_own_pick_up.length;
    },
    isPickUpOrMeet() {
      return (
        this.tour.pick_ups.length !== 0 &&
        this.tour.meeting_point != null &&
        !(!this.tour.meeting_point || Object.keys(this.tour.meeting_point).length === 0)
      )
    },
    isPickUpAll() {
      return !this.isPickUpOrMeet && this.tour.pick_ups.length !== 0
    },
    isTravelServices() {
      return this.tour.category.slug === 'travel-services'
    }
  }
}
</script>

<style lang="scss">
.preview__section.pick-up {
  .preview {
    &__text {
      margin-bottom: 1em;
    }
  }
}
</style>
