<template>
  <div class="reg-nav">
    <div class="reg-nav__complete-percent">
      <div class="title">{{ completedPercent }}% Complete</div>
      <div class="line">
        <div class="line_active" :style="`width: ${completedPercent}%;`" />
      </div>
    </div>
    <div class="reg-nav__public-info reg-nav__info-type">
      <div class="title" @click="supplierInfo.is_hiden = !supplierInfo.is_hiden">Supplier type</div>
      <div v-if="!supplierInfo.is_hiden" class="steps">
        <nuxt-link v-for="step in $options.commonSteps" :key="step.name" :to="localePath({ name: step.routeName })"
          class="steps__item">
          {{ translations[step.nameTranslate].text }}
        </nuxt-link>
      </div>
    </div>
    <div class="reg-nav__public-info reg-nav__info-type">
      <div class="title" :class="{ disabled: !enabledPublicRoute }" @click="publicInfo.is_hiden = !publicInfo.is_hiden">
        Public Information
      </div>
      <div v-if="!publicInfo.is_hiden" class="steps">
        <nuxt-link v-for="step in currentSteps.public" :key="step.name" :to="localePath({ name: step.routeName })"
          class="steps__item" :class="{ disabled: !currentSteps.enabled[step.name] }">
          {{ translations[step.nameTranslate].text }}
        </nuxt-link>
      </div>
    </div>
    <div class="reg-nav__private-info reg-nav__info-type">
      <div class="title" :class="{ disabled: !enabledPrivateRoute }"
        @click="privateInfo.is_hiden = !privateInfo.is_hiden">
        Private Information
      </div>
      <div v-if="!privateInfo.is_hiden" class="steps">
        <nuxt-link v-for="step in currentSteps.private" :key="step.name" :to="localePath({ name: step.routeName })"
          class="steps__item" :class="{ disabled: !currentSteps.enabled[step.name] }">
          {{ translations[step.nameTranslate].text }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { businessSteps, commonSteps, individualSteps } from '@/constants/profileSteps'
import { getCurrentSteps, getStepByRouteName } from '@/services/registration'
import { ACTION_UPDATE_STEP } from '@/store'

export default {
  businessSteps,
  individualSteps,
  commonSteps,
  data() {
    return {
      supplierInfo: {
        is_hiden: false
      },
      publicInfo: {
        is_hiden: true
      },
      privateInfo: {
        is_hiden: true
      }
    }
  },

  fetch() {
    this.$store.dispatch(ACTION_UPDATE_STEP, this.currentStep)

    if (this.isPublicRoute) {
      this.publicInfo.is_hiden = false
      this.privateInfo.is_hiden = true
    }

    if (this.isPrivateRoute) {
      this.publicInfo.is_hiden = true
      this.privateInfo.is_hiden = false
    }
  },

  computed: {
    ...mapState({ user: 'userInfo', translations: 'headerTranslate' }),

    isPublicRoute() {
      return Boolean(this.currentSteps.public.find(step => this.getRouteBaseName(this.$route) === step.routeName))
    },

    isPrivateRoute() {
      return Boolean(this.currentSteps.private.find(step => this.getRouteBaseName(this.$route) === step.routeName))
    },

    enabledPublicRoute() {
      const firstPublicStep = this.currentSteps.public[0]
      return this.currentSteps.enabled[firstPublicStep.name]
    },

    enabledPrivateRoute() {
      const firstPrivateStep = this.currentSteps.private[0]
      return this.currentSteps.enabled[firstPrivateStep.name]
    },

    completedPercent() {
      const enabledSteps = Object.entries(this.currentSteps.enabled).filter(([field, isEnabled]) => isEnabled)
      return Math.round(((enabledSteps.length - 1) * 100) / this.currentSteps.total)
    },

    currentStep() {
      const baseRouteName = this.getRouteBaseName(this.$route)

      return getStepByRouteName(baseRouteName)
    },

    currentSteps() {
      return getCurrentSteps(this.user)
    }
  }
}
</script>

<style lang="scss">
.reg-nav {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 45px;
  left: 0;
  background-color: hsl(0, 0%, 100%);
  width: 265px;
  border-right: 1px solid hsl(0, 0%, 85%);
  overflow-x: hidden;
  padding-bottom: 100px;

  &__info-type {
    border-bottom: 1px solid hsl(0, 0%, 85%);

    .title {
      cursor: pointer;
      padding: 20px 20px 20px 20px;
      font-size: 18px;
      font-weight: 700;

      &.disabled {
        color: $gray-two;
        pointer-events: none;
      }
    }

    .steps {
      &__item {
        padding: 15px 30px 15px 30px;
        display: block;
        font-size: 16px;
        line-height: 16px;

        &:first-child {
          padding: 10px 20px 20px 30px;
        }

        &:hover {
          color: $primary;
        }

        &.nuxt-link-active {
          padding: 15px 30px 15px 30px;
          background-color: hsl(0, 0%, 95%);
          position: relative;
          font-weight: bold;
        }

        &.disabled {
          color: $gray-two;
          pointer-events: none;
        }
      }
    }
  }

  &__complete-percent {
    border-bottom: 1px solid hsl(0, 0%, 85%);

    .title {
      padding: 20px 20px 0 20px;
      font-size: 18px;
      font-weight: 700;
    }

    .line {
      position: relative;
      width: 85%;
      height: 12px;
      border-radius: 10px;
      margin: 5px 20px 20px 20px;
      background: #e5e7ec;

      &_active {
        position: absolute;
        height: 12px;
        background: $primary;
        border-radius: 10px;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
