var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "information-on-voucher-page" }, [
    _vm.loadingPage
      ? _c(
          "div",
          [
            _c("local-loader"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "information-on-voucher-page__loading-title" },
              [_vm._v("Loading pricing type...")]
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _vm.isBuild ? _c("BackLink") : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "information-on-voucher-page__main-title" },
              [_vm._v(_vm._s(_vm.translations.title.text))]
            ),
            _vm._v(" "),
            _c(
              "VFiled",
              {
                staticClass: "information-on-voucher-page__field",
                attrs: {
                  label: _vm.translations.labelImportantInformation.text,
                  description:
                    _vm.translations.descriptionImportantInformation.text,
                },
              },
              [
                _c("VTextarea", {
                  attrs: {
                    "max-length": 400,
                    placeholder:
                      _vm.translations.placeholderImportantInformation.text,
                    rows: "4",
                  },
                  model: {
                    value: _vm.importantInfo,
                    callback: function ($$v) {
                      _vm.importantInfo = $$v
                    },
                    expression: "importantInfo",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "VFiled",
              {
                staticClass: "information-on-voucher-page__field",
                attrs: {
                  label: _vm.translations.labelAdditionlInformation.text,
                  description:
                    _vm.translations.descriptionImportantInformation.text,
                },
                scopedSlots: _vm._u([
                  {
                    key: "description",
                    fn: function () {
                      return [
                        _vm._v(
                          _vm._s(
                            _vm.translations.descriptionAdditionlInformation
                              .text
                          ) + "\n        "
                        ),
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.translations.descriptionBeforeYouGo.text)
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _vm._v(" "),
                _c("VTextarea", {
                  attrs: {
                    "max-length": 400,
                    placeholder:
                      _vm.translations.placeholderAdditionlInformation.text,
                    rows: "4",
                  },
                  model: {
                    value: _vm.leaving,
                    callback: function ($$v) {
                      _vm.leaving = $$v
                    },
                    expression: "leaving",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("VBtn", {
              staticClass: "mt-15",
              attrs: {
                base: "",
                name: _vm.translations.buttonSave.text,
                loading: _vm.loading,
              },
              on: { click: _vm.save },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }