<template>
  <div class="pricing-change-modal-group-page">
    <div class="pricing-change-modal-group-page__title">
      {{ getDateSpan(oldSchedule, translations) }}
    </div>
    <div class="pricing-change-modal-group-page__block">
      <ErrorBanner
        v-if="isHavePriceError || isHaveMaxTravelersError"
        :errors="errorList"
        class="change-pricing-type__error-banner change-pricing-type__error-banner--tiered"
      />
      <div class="pricing-change-modal-group-page__price">
        <div class="position-relative">
          <div class="pricing-change-modal-group-page__price--title">
            {{ translations.popupGroupThirdPageMaxTravelers.text }}
          </div>
          <v-input
            v-model.trim="$v.maxTravelers.$model"
            :error="$v.maxTravelers.$error"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            onpaste="return false;"
            @input="checkMaxTravelersError()"
            @blur="maxTravelersBlur"
          />
        </div>
        <div class="pricing-change-modal-group-page__retail-price">
          <div class="pricing-change-modal-group-page__price--title">
            {{ translations.popupPersonThirdPageSuggested.text }}
            <v-question-info
              :description="translations.popupPersonThirdPageSuggestedDescription.text"
            />
          </div>
          <v-input
            v-model.trim="$v.retailPrice.$model"
            :error="$v.retailPrice.$error"
            type="number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            onpaste="return false;"
            prepend-text="EUR"
            @input="changePrice()"
          />
        </div>
        <div class="pricing-change-modal-group-page__get-paid">
          <div class="pricing-change-modal-group-page__price--title">
            {{ translations.popupPersonThirdPageGetPaid.text }}
            <v-question-info
              :description="translations.popupPersonThirdPageGetPaidDescription.text"
            />
          </div>
          <v-input v-model.trim="groupFeePrice" is-disabled prepend-text="EUR" />
        </div>
      </div>
    </div>
    <div class="change-pricing-type__nav">
      <v-btn outline :name="translations.popupNavBack.text" @click="back" />
      <div class="nav__pagination row">
        <div v-for="index in pageCount" :key="`pageNum${index}`">
          <div
            class="dot dot_page"
            :class="{ dot_page_active: index - 1 == currentPageIndex }"
          />
        </div>
      </div>
      <v-btn
        base
        :name="
          isLastPage ? translations.popupNavSave.text : translations.popupNavNext.text
        "
        :disabled="isHavePriceError || isHaveMaxTravelersError"
        @click="next"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import { parseTranslate } from "@/utils/translations";
import { getDateSpan } from "@/utils/price";
import VInput from "@/components/controls/VInput.vue";
import VBtn from "@/components/controls/VBtn.vue";
import VQuestionInfo from "@/components/common/VQuestionInfo.vue";
import { formatCurrency } from "@/utils/utils";

const MIN_PRICE = 2.99;
const MAX_PRICE = 99999999999999;
const MIN_TRAVELERS = 1;
const MAX_TRAVELERS = 9999;
export default {
  name: "GroupPrice",
  components: {
    VInput,
    VBtn,
    VQuestionInfo,
  },
  props: {
    tour: { type: Object, default: () => ({}) },
    translations: { type: Object, default: () => ({}) },
    editPrices: { type: Object, default: () => ({}) },
    pageCount: { type: Number, default: 0 },
    currentPageIndex: { type: Number, default: 0 },
    oldSchedule: { type: Object, default: () => ({}) },
    isLastPage: { type: Boolean, default: false },
  },
  data() {
    return {
      maxTravelers: null,
      retailPrice: null,
      groupFeePrice: null,
      isHavePriceError: false,
      priceError: [],
      isHaveMaxTravelersError: false,
      maxTravelersError: [],
    };
  },
  fetch() {
    this.maxTravelers = this.editPrices.maxTravelers;
    this.retailPrice = this.editPrices.retailPrice;
    this.groupFeePrice = this.editPrices.groupFeePrice;
  },
  computed: {
    ...mapState(["userInfo"]),
    errorList() {
      return this.priceError.concat(this.maxTravelersError);
    },
  },
  validations() {
    return {
      maxTravelers: {
        required,
        minValue: (value) => minValue(MIN_TRAVELERS)(Number(value)),
        maxValue: (value) => maxValue(MAX_TRAVELERS)(Number(value)),
      },
      retailPrice: {
        required,
        minValue: (value) => minValue(MIN_PRICE)(Number(value)),
        maxValue: (value) => maxValue(MAX_PRICE)(Number(value)),
      },
    };
  },

  methods: {
    getDateSpan,
    maxTravelersBlur() {
      if (!this.tour.is_private && this.maxTravelers > 0) {
        this.maxTravelers = Math.min(this.maxTravelers, this.tour.max_travelers)
      }
    },
    checkRetailPrice() {
      this.priceError = [];
      this.isHavePriceError = false;

      if (this.$v.retailPrice.$anyError) {
        this.isHavePriceError = true;
        if (!this.$v.retailPrice.required || !this.$v.retailPrice.minValue)
          this.priceError.push(
            parseTranslate(
              this.translations.suggestedRetailPriceGreaterError.text,
              MIN_PRICE
            )
          );
        else if (!this.$v.retailPrice.maxValue)
          this.priceError.push(
            parseTranslate(
              this.translations.suggestedRetailPriceLessError.text,
              MAX_PRICE
            )
          );
      }
    },
    checkMaxTravelersError() {
      this.maxTravelersError = [];
      this.isHaveMaxTravelersError = false;

      if (this.$v.maxTravelers.$anyError) {
        this.isHaveMaxTravelersError = true;
        if (!this.$v.maxTravelers.required || !this.$v.maxTravelers.minValue)
          this.maxTravelersError.push(
            parseTranslate(this.translations.numberTravelersMinError.text, MIN_TRAVELERS)
          );
        else if (!this.$v.maxTravelers.maxValue)
          this.maxTravelersError.push(
            parseTranslate(this.translations.numberTravelersMaxError.text, MAX_TRAVELERS)
          );
      }
    },
    changePrice() {
      this.checkRetailPrice();
      let groupFeePrice =
        this.retailPrice - this.retailPrice * (this.userInfo.commission_amount / 100);
      this.groupFeePrice = formatCurrency(groupFeePrice, this.lang, "none");
    },
    next() {
      this.$v.$touch();
      this.checkRetailPrice();
      this.checkMaxTravelersError();
      if (!this.isHaveMaxTravelersError && !this.isHavePriceError) {
        this.$emit("next", {
          maxTravelers: this.maxTravelers,
          retailPrice: this.retailPrice,
          groupFeePrice: this.groupFeePrice,
        });
      }
    },
    back() {
      this.$emit("back", {
        maxTravelers: this.maxTravelers,
        retailPrice: this.retailPrice,
        groupFeePrice: this.groupFeePrice,
      });
    },
  },
};
</script>
<style lang="scss">
.pricing-change-modal-group-page {
  &__title {
    margin-bottom: 17px;
    font-weight: bold;
  }

  &__block {
    padding: 34px 30px 32px 21px;
    background: #f9f9f9;
    border: 1px solid #ececec;
    border-radius: 4px;
    width: 100%;
    padding: 54px 15px 22px 21px;
    margin-bottom: 57px;
  }
  &__price {
    padding-top: 25px;
    display: flex;
    margin-bottom: 15px;
    &--title {
      position: absolute;
      display: flex;
      margin-bottom: 11px;
      top: -35px;
    }
    .v-input {
      input {
        text-align: right;
      }
    }
  }

  &__retail-price {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
  }
  &__get-paid {
    position: relative;
    margin-right: 15px;
    .question-mark__description {
      margin-left: -200px;
    }
  }
}
</style>
