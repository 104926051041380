<template>
  <div v-if="excludes.length" class="preview__section">
    <div class="preview__title">
      {{ translations.textExcluded.text }}
    </div>
    <template v-if="tourExcludes.more">
      <div v-for="exclude in tourExcludes.start" :key="`exclude-${exclude.id}`" class="preview__include">
        <div class="preview__dot preview__dot--orange" />
        <span class="preview__include-text">{{ exclude.description }}</span>
      </div>
      <span v-if="!showTourExcludes" class="preview__read-more" @click="showTourExcludes = true">{{ translations.textReadMore.text }}</span>
      <template v-if="showTourExcludes">
        <div v-for="exclude in tourExcludes.more" :key="`exclude-${exclude.id}`" class="preview__include">
          <div class="preview__dot preview__dot--orange" />
          <span class="preview__include-text">{{ exclude.description }}</span>
        </div>
        <span class="preview__read-more" @click="showTourExcludes = false">{{ translations.textHide.text }}</span>
      </template>
    </template>
    <template v-else>
      <div v-for="exclude in tourExcludes" :key="`exclude-${exclude.id}`" class="preview__include">
        <div class="preview__dot preview__dot--orange" />
        <span class="preview__include-text">{{ exclude.description }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'IncludedPreview',
  props: {
    excludes: { type: Array, default: () => [] },
    translations: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      showTourExcludes: false
    }
  },
  computed: {
    tourExcludes () {
      return this.excludes?.length > 4
        ? { start: this.excludes.slice(0, 4), more: this.excludes.slice(4) }
        : this.excludes
    }
  }
}
</script>

<style lang="scss">
</style>
