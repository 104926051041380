<template>
  <div class="v-select-country">
    <country-select
      v-model="modelValue"
      :country="modelValue"
      class="v-select-country__select"
      :class="{ 'v-select-country__select--error': error, 'v-select-country__select--empty': !modelValue }"
      v-bind="$attrs"
    />
    <div v-if="errorMessage && error" class="v-select-country__error-message">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSelectCountry',

  model: {
    prop: 'country',
    event: 'input'
  },

  props: {
    error: { type: Boolean, default: false },
    country: { type: String, default: '' },
    errorMessage: { type: String, default: '' }
  },

  computed: {
    modelValue: {
      get () {
        return this.country
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss">
.v-select-country {
  &__select {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 14px;
    width: 100%;
    height: 48px;
    border: 1px solid #d9d9d9;
    border-radius: 4px 4px 4px 4px;
    background: $white;
    font-size: 17px;
    line-height: 27px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url('~/assets/img/svg/drop-down-arrow.svg');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 12px;

    &--empty {
      color: #969ba9;
    }

    &--error {
      border-color: #d80027;
      background-color: #FFD8DF;
    }
  }

  &__error-message {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: $red;
    margin-top: 8px;
  }

}
</style>
