var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personal-details-form" },
    [
      _c(
        "VField",
        {
          staticClass: "personal-details-form__field",
          attrs: {
            label: _vm.translations.birthLabel.text,
            error: _vm.$v.localPersonalDetails.birthDate.$error,
            "error-message": _vm.translations.requiredError.text,
          },
        },
        [
          _c("DatePicker", {
            staticClass: "personal-details-form__date",
            attrs: {
              editable: false,
              placeholder: _vm.translations.birthPlaceholder.text,
              "value-type": "DD-MM-YYYY",
              format: "D MMMM YYYY",
            },
            model: {
              value: _vm.$v.localPersonalDetails.birthDate.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.localPersonalDetails.birthDate, "$model", $$v)
              },
              expression: "$v.localPersonalDetails.birthDate.$model",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "VField",
        {
          staticClass: "personal-details-form__field",
          attrs: {
            label: _vm.translations.phoneLabel.text,
            description: _vm.translations.phoneDescription.text,
            error: _vm.phoneError,
            "error-message": _vm.translations.phoneError.text,
          },
        },
        [
          _c("VuePhoneNumberInput", {
            staticClass: "personal-details-form__phone",
            attrs: {
              error: _vm.phoneError,
              "default-country-code": _vm.localPersonalDetails.phoneCode || "",
            },
            on: { update: _vm.countryChanged },
            model: {
              value: _vm.localPersonalDetails.phone,
              callback: function ($$v) {
                _vm.$set(_vm.localPersonalDetails, "phone", $$v)
              },
              expression: "localPersonalDetails.phone",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("VBtn", {
        staticClass: "personal-details-form__button",
        attrs: { loading: _vm.loading, base: "", name: _vm.saveBtnText },
        on: { click: _vm.save },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }