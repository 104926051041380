<template>
  <div v-if="isActive" class="regular-intervals-wrapper wrapper">
    <div class="pop-up-regular-intervals">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close">
      </div>
      <div class="pop-up-regular-intervals__title">
        Add times at regular intervals
      </div>
      <div class="pop-up-regular-intervals__options">
        <div class="value">
          <div class="value__title">
            Every
          </div>
          <div class="value__input">
            <input-fields
              v-model="intervalTime"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              onpaste="return false;"
              type="number"
              @input="emitInterval"
            />
            <selection
              default
              :default-name="timeName"
              :items="time"
              @name="changeTime"
              @change="emitInterval"
            />
          </div>
        </div>
        <div class="date-from">
          <div class="date-from__title">
            From
          </div>
          <date-picker
            v-model="time1"
            type="time"
            :time-picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30',
            }"
            format="hh:mm A"
            placeholder="Time"
            :use12h="true"
            class="custom-detepicker"
            :editable="false"
          />
        </div>
        <div class="date-until">
          <div class="date-until__title">
            Until
          </div>
          <date-picker
            v-model="time2"
            type="time"
            :time-picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30',
            }"
            format="hh:mm A"
            placeholder="Time"
            :use12h="true"
            class="custom-detepicker"
            :editable="false"
          />
        </div>
      </div>
      <div class="pop-up-regular-intervals__nav">
        <div class="cancel" @click="closeWindow">
          <Btn outline name="Cancel" />
        </div>
        <div class="Save" @click="saveInterval">
          <Btn base name="Save" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import Btn from '@/components/controls/Btn.vue'
import Selection from '@/components/controls/Selection.vue'
import InputFields from '@/components/controls/Input-fields.vue'
import 'vue2-datepicker/index.css'
export default {
  components: {
    Btn,
    Selection,
    InputFields,
    DatePicker
  },
  props: ['isActive', 'scheduleIntervals'],
  data: () => ({
    time1: null,
    time2: null,
    intervalTime: null,
    time: [
      { is_active: true, name: 'minute(s)' },
      { is_active: false, name: 'hour(s)' }
    ],
    timeName: 'minute(s)',
    saved: false
  }),
  methods: {
    closeWindow () {
      const active = this.isActive
      this.$emit('saved', this.saved)
      this.$emit('close', active)
    },
    changeTime (defaultName) {
      this.timeName = defaultName
    },
    emitInterval () {
      this.$emit('time1', this.time1)
      this.$emit('time2', this.time2)
      this.$emit('intervalTime', this.intervalTime)
      this.$emit('timeName', this.timeName)
    },
    saveInterval () {
      this.emitInterval()
      if (
        this.time1 != null &&
        this.time1 != '' &&
        this.time2 != null &&
        this.time2 != '' &&
        this.intervalTime != null &&
        this.intervalTime != ''
      ) {
        this.scheduleIntervals.push({
          start_time: this.time1,
          end_time: this.time2,
          time_name: this.timeName,
          every: this.intervalTime
        })
        const d = new Date()
        const h = d.getHours()
        const m = d.getMinutes()
        const meridiem = ['AM', 'PM']
        for (let i = h; i < 24; ++i) {
          for (let j = i == h ? Math.ceil(m / 15) : 0; j < 4; ++j) {
            timeArray.push(
              (i % 12) + ':' + (j * 15 || '00') + ' ' + meridiem[(i / 12) | 0]
            )
          }
        }
        this.$emit('addInterval', this.scheduleIntervals)
        this.closeWindow()
      }
    }
  }
}
</script>

<style lang="scss">
.regular-intervals-wrapper {
  height: 125% !important;
  .pop-up-regular-intervals {
    margin-top: -100px;
    position: relative;
    flex-wrap: wrap;
    background: $white;
    padding-top: 35px;
    padding-left: 40px;
    padding-bottom: 25px;
    width: 750px;
    &__title {
      margin-bottom: 36px;
      font-weight: bold;
    }
    &__options {
      margin-bottom: 70px;
      display: flex;
      .value {
        margin-right: 25px;
        &__title {
          margin-bottom: 16px;
        }
        &__input {
          display: flex;
          .input {
            margin-right: -2px;
            width: 60px;
            height: 34px;
            border-radius: 0;
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type='number'] {
            -moz-appearance: textfield;
          }
          .selection {
            &__preview {
              width: 125px;
              height: 34px;
            }
            &__expand {
              margin-left: 1px;
              width: 125px;
            }
            width: 125px;
            border-radius: 0;
          }
        }
      }
      .date-from {
        margin-right: 25px;
        &__title {
          margin-bottom: 16px;
        }
      }
      .date-until {
        &__title {
          margin-bottom: 16px;
        }
      }
    }
    &__nav {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ececec;
      padding-top: 21px;
      width: 660px;
      .btn {
        &_outline {
          border: 2px solid $primary;
          border-radius: 4px;
          min-width: 102px;
          height: 48px;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
        }
        &_base {
          border: 2px solid $primary;
          border-radius: 4px;
          min-width: 102px;
          height: 48px;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
        }
      }
    }
  }
}
</style>
