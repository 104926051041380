<template>
  <div class="bank-details">
    <div class="_container">
      <VBreadcrumbs :title="translations.backStep.text" @click="backStep" />
      <div class="bank-details__content">
        <div class="bank">
          <div class="title">
            {{ translations.bankTitle.text }}
          </div>
          <div class="bank-details__subtitle subtitle">
            {{ translations.bankSubtitle.text }}
          </div>
          <VRadioGroup v-model="isCompany" class="bank-details__field" name="isCompany">
            <template #default="{ on, attrs }">
              <VRadioButton :label="translations.sellerTypeIndividual.text" :value="false" v-bind="attrs" v-on="on" />
              <VRadioButton :label="translations.sellerTypeBusiness.text" :value="true" v-bind="attrs" v-on="on" />
            </template>
          </VRadioGroup>
          <VField
            v-if="!isCompany"
            class="bank-details__field"
            :label="translations.labelFirstName.text"
            :error="$v.localBankForm.first_name.$error"
            :error-message="getErrorMessage(translations.labelFirstName.text)"
          >
            <VInput
              v-model.trim="$v.localBankForm.first_name.$model"
              :error="$v.localBankForm.first_name.$error"
              :max-length="128"
              hide-counter
            />
          </VField>
          <VField
            v-if="!isCompany"
            class="bank-details__field"
            :label="translations.labelLastName.text"
            :error="$v.localBankForm.last_name.$error"
            :error-message="getErrorMessage(translations.labelLastName.text)"
          >
            <VInput
              v-model.trim="$v.localBankForm.last_name.$model"
              :error="$v.localBankForm.last_name.$error"
              :max-length="128"
              hide-counter
            />
          </VField>
          <VField
            v-if="isCompany"
            class="bank-details__field"
            :label="translations.labelCompany.text"
            :error="$v.localBankForm.company_name.$error"
            :error-message="getErrorMessage(translations.labelCompany.text)"
          >
            <VInput
              v-model.trim="$v.localBankForm.company_name.$model"
              :error="$v.localBankForm.company_name.$error"
              :max-length="128"
              hide-counter
            />
          </VField>
          <VField
            class="bank-details__field"
            :label="translations.bankName.text"
            :error="$v.localBankForm.bank_name.$error"
            :error-message="getErrorMessage(translations.bankName.text)"
          >
            <VInput
              v-model.trim="$v.localBankForm.bank_name.$model"
              :error="$v.localBankForm.bank_name.$error"
              :placeholder="translations.inputBankNamePlaceholder.text"
              :max-length="128"
              hide-counter
            />
          </VField>
          <VField
            v-if="!isShowAccountNumber"
            class="bank-details__field"
            :label="translations.iban.text"
            :description="descriptionIban"
            :error="$v.localBankForm.iban.$error"
            :error-message="
              !$v.localBankForm.iban.format ? translations.errorIncorrect.text : getErrorMessage(translations.iban.text)
            "
          >
            <VInput
              v-model.trim="$v.localBankForm.iban.$model"
              :error="$v.localBankForm.iban.$error"
              :placeholder="`#### #### #### ### #### ##`"
              :max-length="50"
              hide-counter
              @input="replace_spaces"
            />
          </VField>
          <VField
            v-if="!isShowAccountNumber"
            class="bank-details__field"
            :label="translations.labelBicSwift.text"
            :error="$v.localBankForm.swift.$error"
            :error-message="
              !$v.localBankForm.swift.format
                ? translations.errorIncorrect.text
                : getErrorMessage(translations.labelBicSwift.text)
            "
          >
            <VInput v-model.trim="$v.localBankForm.swift.$model" :error="$v.localBankForm.swift.$error" placeholder="XXXXXX*****" />
          </VField>
          <VField
            v-if="isShowAccountNumber"
            class="bank-details__field"
            :label="translations.labelAccountNumber.text"
            :error="$v.localBankForm.account_number.$error"
            :error-message="getErrorMessage(translations.labelAccountNumber.text)"
          >
            <VInput v-model.trim="$v.localBankForm.account_number.$model" :error="$v.localBankForm.account_number.$error" />
          </VField>
          <div class="save" @click="saveStep">
            <Btn base :name="translations.btnSave.text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf, alphaNum } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'
import VField from '@/components/controls/VField.vue'
import VInput from '@/components/controls/VInput.vue'
import VRadioGroup from '@/components/controls/VRadioGroup.vue'
import VRadioButton from '@/components/controls/VRadioButton.vue'
import VBreadcrumbs from '@/components/controls/VBreadcrumbs.vue'

import { parseTranslate } from '@/utils/translations'
import { checkBic } from '@/utils/validations'

const CODE_LENGTHS = {
  AD: 24,
  AE: 23,
  AT: 20,
  AZ: 28,
  BA: 20,
  BE: 16,
  BG: 22,
  BH: 22,
  BR: 29,
  CH: 21,
  CR: 21,
  CY: 28,
  CZ: 24,
  DE: 22,
  DK: 18,
  DO: 28,
  EE: 20,
  ES: 24,
  FI: 18,
  FO: 18,
  FR: 27,
  GB: 22,
  GI: 23,
  GL: 18,
  GR: 27,
  GT: 28,
  HR: 21,
  HU: 28,
  IE: 22,
  IL: 23,
  IS: 26,
  IT: 27,
  JO: 30,
  KW: 30,
  KZ: 20,
  LB: 28,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MC: 27,
  MD: 24,
  ME: 22,
  MK: 19,
  MR: 27,
  MT: 31,
  MU: 30,
  NL: 18,
  NO: 15,
  PK: 24,
  PL: 28,
  PS: 29,
  PT: 25,
  QA: 29,
  RO: 24,
  RS: 22,
  SA: 24,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  TN: 24,
  TR: 26,
  AL: 28,
  BY: 28,
  CR: 22,
  EG: 29,
  GE: 22,
  IQ: 23,
  LC: 32,
  SC: 31,
  ST: 25,
  SV: 28,
  TL: 23,
  UA: 29,
  VA: 22,
  VG: 24,
  XK: 20
}
export function isValidIBANNumber(input, country) {
  if (input === '') {
    return true
  }

  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '') // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest
  // check syntax and length

  if (!code) {
    return false
  }

  const inputCountry = code[1]
  if (country && country !== inputCountry) {
    return false
  }

  if (!code || iban.length !== CODE_LENGTHS[inputCountry]) {
    return false
  }
  // rearrange country code and check digits, and convert chars to ints
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55
  })
  // final check
  return mod97(digits)
}
export function mod97(string) {
  let checksum = string.slice(0, 2)
  let fragment
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }
  return checksum
}

export default {
  components: {
    Btn: () => import('@/components/controls/Btn.vue'),
    VInput,
    VField,
    VRadioGroup,
    VRadioButton,
    VBreadcrumbs
  },
  props: {
    translations: { type: Object, default: () => ({})},
    country: { type: String, default: 'ES' },
    bankForm: { type: Object, default: null },
  },
  data: () => ({
    isCompany: true,
    localBankForm: {
      first_name: '',
      last_name: '',
      company_name: '',
      bank_name: '',
      iban: '',
      account_number: '',
      swift: ''
    }
  }),
  computed: {
    ...mapState(['userInfo']),

    descriptionIban() {
      return parseTranslate(this.translations.inputIbanDescription.text, this.country)
    },

    isShowAccountNumber() {
      const countries = ['US', 'GB']
      return countries.includes(this.country)
    },

    bankInfo() {
      return {
        company_name: this.isCompany ? this.localBankForm.company_name : '',
        first_name: this.isCompany ? '' : this.localBankForm.first_name,
        last_name: this.isCompany ? '' : this.localBankForm.last_name,
        bank_name: this.localBankForm.bank_name,
        iban: !this.isShowAccountNumber ? this.localBankForm.iban : '',
        swift: !this.isShowAccountNumber ? this.localBankForm.swift : '',
        account_number: this.isShowAccountNumber ? this.localBankForm.account_number : '',
      }
    }
  },
  validations() {
    return {
      localBankForm: {
        first_name: {
          required: requiredIf(() => !this.isCompany)
        },
        last_name: { required: requiredIf(() => !this.isCompany) },
        company_name: { required: requiredIf(() => this.isCompany) },
        bank_name: { required },
        iban: { required: requiredIf(() => !this.isShowAccountNumber), alphaNum, format: (value) => isValidIBANNumber(value, this.country) },
        swift: { required: requiredIf(() => !this.isShowAccountNumber), format: checkBic },
        account_number: { required: requiredIf(() => this.isShowAccountNumber) }
      }
    }
  },
  beforeMount() {
    if (this.bankForm) {
      const { country, street_address: address, city, postcode, ...localBankForm } = this.bankForm
      this.isCompany = !!localBankForm.company_name
      this.localBankForm = { ...localBankForm }
    }
  },
  methods: {
    ...mapActions(['loadUserInfo']),
    backStep() {
      this.$emit('back')
    },
    getErrorMessage(label) {
      return parseTranslate(this.translations.errorRequired.text, label)
    },

    saveStep() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.$emit('save', this.localBankForm)
    },

    replace_spaces() {
      this.localBankForm.iban = this.localBankForm.iban.replaceAll(/\s/g, '')
    }
  }
}
</script>

<style lang="scss">
.bank-details {
  margin-bottom: 25px;
  .v-input {
    margin-top: 0;
  }
  &__field {
    &:not(:last-child) {
      margin-bottom: 45px;
    }
  }

  &__subtitle {
    margin-bottom: 48px;
  }

  &__content {
    width: 718px;
    .title {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
    }
    .next {
      margin-top: 96px;
    }
  }
}
</style>
