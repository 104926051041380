var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile-picture-form" },
    [
      _c(
        "div",
        { staticClass: "profile-picture-form__content" },
        [
          _c(
            "VFIeld",
            {
              staticClass: "profile-picture-form__file-input",
              attrs: {
                error: _vm.$v.localPhotoFile.$error,
                "error-message": _vm.photoError,
              },
            },
            [
              _c(
                "VFileInput",
                {
                  attrs: {
                    "preview-url": _vm.localPhotoUrl,
                    full: "",
                    "change-text": _vm.translations.changePhoto.text,
                    error: _vm.$v.localPhotoFile.$error,
                    accept: "image/*",
                  },
                  on: { change: _vm.uploadFile },
                },
                [_vm._v(_vm._s(_vm.translations.placeholderTitle.text))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("PhotoHelp", {
            staticClass: "profile-picture-form__help",
            attrs: {
              "do-list": _vm.photoDo,
              "dont-list": _vm.photoDont,
              "do-title": _vm.translations.do.text,
              "dont-title": _vm.translations.dont.text,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("VBtn", {
        staticClass: "profile-picture-form__button",
        attrs: { loading: _vm.loading, base: "", name: _vm.saveBtnText },
        on: { click: _vm.save },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }