<template>
  <div ref="expandableContainer" class="expandable-text" :style="`--lines: ${lines}`">
    <span :class="textClassState">
      <span ref="textContainer" class="expandable-text__text">{{ localText }}</span>
    </span>

    <ButtonMore v-if="showMoreButton" class="expandable-text__button" @click="toggleShow">{{ expandText }}</ButtonMore>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ButtonMore from '@/components/ButtonMore.vue'

export default {
  name: 'VExpandableContainer',

  components: {
    ButtonMore
  },

  props: {
    text: { type: String, default: '' },
    lines: { type: Number, default: 3 },
    translations: { type: Object, default: () => ({})},
  },

  data() {
    return {
      expanded: false,
      localText: this.text,
      columnsCount: 0,
      showMoreButton: true
    }
  },

  computed: {
    ...mapState(['headerTranslate']),

    textClassState() {
      return this.expanded ? 'expandable-text__expanded' : 'expandable-text__default'
    },

    expandText() {
      if (this.expanded) {
        return this.translations.btnShowLess?.text ?? 'TRANSLATE_LESS'
      }
      return this.translations.btnShowMore?.text ?? 'TRANSLATE_MORE'
    }
  },

  watch: {
    text() {
      this.columnsCount = this.$refs.textContainer.getClientRects().length
    }
  },

  mounted() {
    this.columnsCount = this.$refs.textContainer.getClientRects().length;
    this.showMoreButton = (this.countLines() > this.lines);
  },

  methods: {
    toggleShow() {
      this.expanded = !this.expanded


      if (!this.expanded) {
        this.$nextTick(() => {
          this.$refs.expandableContainer.scrollIntoView({
            behavior: 'auto',
            block: 'center'
          })
        })
      }
    },
    
    countLines() {
      const div = this.$refs.textContainer;
      const lineHeight = parseFloat(window.getComputedStyle(div).getPropertyValue('line-height'));
      const contentHeight = div.getBoundingClientRect().height;
      const lines = Math.ceil(contentHeight / lineHeight);

      return lines;
    }
  }
}
</script>

<style lang="scss">
.expandable-text {

  $primary: #3287F7;
  font-size: 20px;

  &__default {
    font-size: inherit;
    font-weight: inherit;
    --lines: 3;

    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: var(--lines);
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5em;
  }

  &__expanded {
    font-size: inherit;
    font-weight: inherit;
    display: block;
  }

  &__text {
    word-break: break-word;
    word-wrap: anywhere;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    line-height: 1.5em;
    white-space: pre-wrap
  }

  &__button {
    font-size: 20px;
    margin-top: 8px;
    color: $primary;
    line-height: 1em;
  }
}
</style>