<template>
  <div class="add-attributes-price-pop-up">
    <div class="pop-up__close" @click="$emit('close')">
      <img src="@/assets/img/svg/close.svg" alt="close" />
    </div>
    <div class="add-attributes-price-pop-up__title">{{ translations.title.text }}</div>
    <div ref="option-name" class="add-attributes-price-pop-up__block">
      <div class="add-attributes-price-pop-up__block--title">{{ translations.contentOptionName.text }}</div>
      <div class="add-attributes-price-pop-up__block--name">
        <v-input
          v-model.trim="attributesLocal.optionName"
          :placeholder="translations.optionNamePlaceholder.text"
          :error="optionNameError"
          :max-length="250"
        />
      </div>
    </div>
    <div v-if="tour.languages.length != 0" ref="option-languages" class="add-attributes-price-pop-up__block">
      <div class="add-attributes-price-pop-up__block--title">{{ translations.contentLanguages.text }}</div>
      <div class="add-attributes-price-pop-up__swapper" @click="optionUseAllLang()">
        <Checkbox switcher :checked="attributesLocal.hideLanguages" />
        <div class="add-attributes-price-pop-up__swapper--name">{{ translations.switcher.text }}</div>
      </div>
      <div v-if="!attributesLocal.hideLanguages" class="add-attributes-price-pop-up__langs">
        <div v-if="optionLangsError" class="add-attributes-price-pop-up__langs--error">
          {{ translations.mustSelectLanguage.text }}
        </div>
        <div class="add-attributes-price-pop-up__langs--header">
          <div>
            {{ translations.headerLang.text }}
          </div>
          <div>
            {{ translations.headerPerson.text }}
          </div>
        </div>
        <div :class="{ 'error-box': optionLangsError }">
          <div
            v-for="(lang, index) in attributesLocal.tourLangs"
            :key="`lang-${index}`"
            class="add-attributes-price-pop-up__langs--item"
            @click="lang.is_active = !lang.is_active"
          >
            <div>{{ lang.language }}</div>
            <Checkbox :id="`lang-${index}`" default :checked="lang.is_active" />
          </div>
        </div>
      </div>
    </div>
    <div class="add-attributes-price-pop-up__block">
      <div class="add-attributes-price-pop-up__block--title">
        {{ translations.contentPickupIncluded.text }}
      </div>
      <VRadioGroup v-model="attributesLocal.optionPickupIncluded" name="optionPickupIncluded">
        <template #default="{ on, attrs }">
          <VRadioButton :label="translations.pickupIncludedYesRadio.text" :value="true" v-bind="attrs" v-on="on" />
          <VRadioButton :label="translations.pickupIncludedNoRadio.text" :value="false" v-bind="attrs" v-on="on" />
        </template>
      </VRadioGroup>
    </div>
    <div ref="options" class="add-attributes-price-pop-up__block">
      <div class="add-attributes-price-pop-up__block--title">
        {{ translations.contentOptionDetails.text }}
      </div>
      <div class="add-attributes-price-pop-up__block--subtitle">
        {{ translations.optionDetailsDescription.text }}
      </div>
      <div
        v-for="(option, index) in attributesLocal.options"
        :key="`option-${index}`"
        class="add-attributes-price-pop-up__option"
      >
        <div class="checked" @click="changeActiveOption(index)">
          <Checkbox default :name="option.title" :checked="option.is_active" />
        </div>
        <div v-if="option.id == 4 && option.is_active" class="add-attributes-price-pop-up__option--details">
          <div class="add-attributes-price-pop-up__option--duration">
            <v-input
              v-model="attributesLocal.durationTime"
              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              onpaste="return false;"
              :error="option.name_error"
              type="number"
              @input="checkMaxDuration"
            />
            <selection default :default-name="attributesLocal.durationName" :items="durations" @name="changeDuration" />
          </div>
          <VTextarea
            v-model="option.description"
            :max-length="250"
            :error="option.description_error"
            :placeholder="translations.optionDetailsDescriptionPlaceholder.text"
            rows="4"
            @input="checkDescriptionName(index)"
          />
        </div>
        <div v-else-if="option.is_active" class="add-attributes-price-pop-up__option--details">
          <v-input
            v-model.trim="option.name"
            :placeholder="translations.optionDetailsNamePlaceholder.text"
            :error="option.name_error"
            :max-length="100"
            @input="checkOptionName(index)"
          />
          <VTextarea
            v-model="option.description"
            :max-length="250"
            :error="option.description_error"
            :placeholder="translations.optionDetailsDescriptionPlaceholder.text"
            rows="4"
            @input="checkDescriptionName(index)"
          />
        </div>
      </div>
    </div>
    <div class="add-attributes-price-pop-up__nav">
      <v-btn outline :name="translations.cancelBtn.text" @click="$emit('back')" />
      <div v-if="step == 3" class="pagination row">
        <div class="dot dot_page dot_page_active" />
        <div class="dot dot_page" />
        <div class="dot dot_page" />
      </div>
      <v-btn base :name="textBtnSave" @click="save()" />
    </div>
  </div>
</template>

<script>
import VBtn from '@/components/controls/VBtn.vue';
import VInput from '@/components/controls/VInput.vue';
import VTextarea from '@/components/controls/VTextarea.vue';
import Checkbox from '@/components/controls/Checkbox.vue';
import VRadioGroup from '@/components/controls/VRadioGroup.vue';
import VRadioButton from '@/components/controls/VRadioButton.vue';
import Selection from '@/components/controls/Selection.vue';
export default {
  components: {
    VBtn,
    Checkbox,
    VTextarea,
    VInput,
    VRadioGroup,
    VRadioButton,
    Selection,
  },
  props: {
    attributes: { type: Object, default: () => ({}) },
    optionsProductDetails: { type: Array, default: () => [] },
    translations: { type: Object, default: () => ({}) },
    tour: { type: Object, default: () => ({}) },
    step: { type: Number, default: 0 },
    textBtnSave: { type: String, default: '' },
  },
  data: () => ({
    attributesLocal: {
      optionName: '',
      hideLanguages: true,
      options: [],
      optionPickupIncluded: true,
      durationTime: null,
      durationName: '',
      tourLangs: [],
    },
    optionNameError: false,
    optionLangsError: false,
    optionsError: false,
    durations: [
      { is_active: true, name: '' },
      { is_active: false, name: '' },
      { is_active: false, name: '' },
    ],
  }),
  fetch() {
    this.attributesLocal.optionName = this.attributes.optionName;
    this.attributesLocal.hideLanguages = this.attributes.hideLanguages;
    this.attributesLocal.optionPickupIncluded = this.attributes.optionPickupIncluded;
    this.attributesLocal.tourLangs = this.attributes.tourLangs;
    this.attributesLocal.options = this.attributes.options;
    this.durations[0].name = this.translations.durationItem1.text;
    this.durations[1].name = this.translations.durationItem2.text;
    this.durations[2].name = this.translations.durationItem3.text;
  },
  watch: {
    optionsProductDetails(data) {
      this.attributesLocal.options = data;
      if (this.attributesLocal.options[3].is_active) {
        const nameDuration = this.attributesLocal.options[3].name.split(' ');
        this.attributesLocal.durationTime = nameDuration[0];
        this.attributesLocal.durationName = nameDuration[1];
      } else this.attributesLocal.durationName = this.translations.durationItem1.text;
    },
  },
  methods: {
    optionUseAllLang() {
      if (this.attributesLocal.hideLanguages === false) {
        for (const i in this.attributesLocal.tourLangs) {
          this.attributesLocal.tourLangs[i].is_active = true;
        }
        this.attributesLocal.hideLanguages = true;
      } else {
        this.attributesLocal.hideLanguages = false;
      }
    },
    changeActiveOption(index) {
      this.attributesLocal.options[index].is_active = !this.attributesLocal.options[index].is_active;
      this.attributesLocal.options[index].name_error = true;
      this.attributesLocal.options[index].description_error = true;
    },
    checkMaxDuration() {
      if (this.attributesLocal.durationTime >= 999) {
        this.attributesLocal.durationTime = 999;
      } else if (this.attributesLocal.durationTime <= 1) {
        this.attributesLocal.durationTime = 1;
      }
    },
    checkOptionName(index) {
      if (this.attributesLocal.options[index].name.length === 0) {
        this.attributesLocal.options[index].name_error = true;
        this.optionsError = true;
      } else {
        this.attributesLocal.options[index].name_error = false;
        this.optionsError = false;
      }
    },
    checkDescriptionName(index) {
      if (this.attributesLocal.options[index].description.length === 0) {
        this.attributesLocal.options[index].description_error = true;
        this.optionsError = true;
      } else {
        this.attributesLocal.options[index].description_error = false;
        this.optionsError = false;
      }
    },
    changeDuration(defaultName) {
      this.attributesLocal.durationName = defaultName;
    },
    save() {
      this.optionNameError = false;
      this.optionLangsError = false;
      this.optionsError = false;
      let checkLanguage = false;
      if (this.attributesLocal.tourLangs.length > 0) {
        for (const i in this.attributesLocal.tourLangs) {
          if (this.attributesLocal.tourLangs[i].is_active) {
            checkLanguage = true;
            break;
          }
        }
      } else {
        checkLanguage = true;
      }
      for (const i in this.attributesLocal.options) {
        this.attributesLocal.options[i].name_error = false;
        this.attributesLocal.options[i].description_error = false;
        if (this.attributesLocal.options[i].is_active) {
          if (this.attributesLocal.options[i].id === 4) {
            if (this.attributesLocal.durationTime == null || this.durationTime === '') {
              this.attributesLocal.options[i].name_error = true;
              this.optionsError = true;
            } else {
              this.attributesLocal.options[i].name_error = false;
            }
          } else if (
            this.attributesLocal.options[i].name == null ||
            this.attributesLocal.options[i].name.length === 0
          ) {
            this.attributesLocal.options[i].name_error = true;
            this.optionsError = true;
          } else {
            this.attributesLocal.options[i].name_error = false;
          }

          if (
            this.attributesLocal.options[i].description == null ||
            this.attributesLocal.options[i].description.length === 0
          ) {
            this.attributesLocal.options[i].description_error = true;
            this.optionsError = true;
          } else {
            this.attributesLocal.options[i].description_error = false;
          }
        }
      }

      if (this.attributesLocal.optionName === '') {
        this.optionNameError = true;
        this.$refs['option-name'].scrollIntoView();
      } else if (!checkLanguage) {
        this.optionLangsError = true;
        this.$refs['option-languages'].scrollIntoView();
      } else if (this.optionsError) this.$refs.options.scrollIntoView();

      if (!this.optionsError && !this.optionNameError && !this.optionLangsError)
        this.$emit('save', this.attributesLocal);
    },
  },
};
</script>

<style lang="scss">
.add-attributes-price-pop-up {
  &__title {
    margin-bottom: 23px;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
  }
  &__block {
    margin-bottom: 50px;
    &--title {
      margin-bottom: 10px;
      font-weight: bold;
    }
    &--subtitle {
      margin-bottom: 34px;
    }
    &--name {
      .v-input {
        margin-top: 0px !important;
        input {
          margin-top: 0px;
        }
      }
    }
  }
  &__swapper {
    display: flex;
    align-items: center;
    &--name {
      margin-left: 10px;
    }
  }
  &__langs {
    margin-top: 26px;
    &--header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 19px;
      font-size: 18px;
      line-height: 21px;
      color: #888a93;
    }
    &--item {
      display: flex;
      justify-content: space-between;
      padding: 14px 20px 0 5px;
      margin-bottom: 26px;
      border-top: 1px solid #ececec;
    }
    &--error {
      color: $red;
      margin-bottom: 10px;
    }
  }
  &__option {
    &--details {
      margin-top: 9px;
      margin-left: 33px;
      display:flex;
      flex-direction: column;
      gap:16px;
    }
    &--duration {
      display: flex;
      .v-input {
        margin-top: -10px;
        input {
          border-radius: 4px 0 0 4px;
          width: 89px;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }
      }
      .selection {
        margin-left: -1px;
        &__preview {
          border-radius: 0 4px 4px 0;
          width: 130px;
        }
        &__expand {
          width: 130px;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 18px;
      padding-bottom: 13px;
      border-bottom: 1px solid #ececec;
    }
  }
  &__nav {
    border-top: 1px solid #ececec;
    padding-top: 23px;
    display: flex;
    justify-content: space-between;
    .v-btn {
      width: 150px;
      min-width: 150px;
    }
  }
}
</style>
