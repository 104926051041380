<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <g stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.89 21.14v-1.66c0-.88-.33-1.72-.91-2.34a3.02 3.02 0 0 0-2.2-.97H6.56c-.83 0-1.62.35-2.2.97a3.42 3.42 0 0 0-.92 2.34v1.66M9.67 12.87c1.71 0 3.1-1.48 3.1-3.31s-1.39-3.3-3.1-3.3c-1.72 0-3.11 1.47-3.11 3.3 0 1.83 1.39 3.3 3.1 3.3Z"/>
    <path stroke-width="1.5" d="M17.76 3.96c.38-.68.57-1.02.86-1.02.28 0 .47.34.85 1.02l.1.18c.1.19.16.29.25.35.08.06.18.09.4.14l.19.04c.73.17 1.1.25 1.19.53.09.28-.16.58-.67 1.17l-.13.15c-.14.17-.21.25-.24.35-.04.1-.02.22 0 .44l.02.2c.07.79.11 1.18-.12 1.36-.23.17-.57.01-1.27-.3l-.17-.09c-.2-.09-.3-.14-.4-.14-.1 0-.2.05-.4.14l-.18.08c-.7.32-1.04.48-1.27.3-.23-.17-.19-.56-.11-1.35l.02-.2c.02-.22.03-.33 0-.44-.03-.1-.1-.18-.25-.35l-.13-.15c-.5-.6-.75-.89-.67-1.17.1-.28.46-.36 1.2-.53l.19-.04c.21-.05.31-.08.4-.14.08-.06.14-.16.24-.35l.1-.18Z"/>
  </g>
</svg>
</template>

<script>
export default {
  name: 'TourPrivate'
}
</script>
