<template>
  <div id="canellcation-policy" class="preview__section">
    <div class="preview__title">
      {{ translations.cancellationPolicyTitle.text }}
    </div>
    <div class="two-columns">
      <div class="two-columns__left">
        <div v-for="(text, index) in cancellationPolicy.left" :key="index" class="preview__include">
          <div class="preview__dot preview__dot" />
          <span class="preview__include-text">{{ text.description }}</span>
        </div>
      </div>
      <div class="two-columns__right">
        <div v-for="(text, index) in cancellationPolicy.right" :key="index" class="preview__include">
          <div class="preview__dot preview__dot" />
          <span class="preview__include-text">{{ text.description }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export const STANDARD_CANCELLATION = 'standard'
export const MODERATE_CANCELLATION = 'moderate'
export const STRICT_CANCELLATION = 'strict'
export const NO_REFUND = 'no_refund'

export default {
  name: 'CancellationPolicy',
  props: {
    tour: { type: Object, default: () => ({}) },
    translations: { type: Object, default: () => ({}) },
  },
  computed: {
    cancellationPolicyStandart() {
      return [
        this.translations.cancellationPolicyStandart1.text,
        this.translations.cancellationPolicyStandart2.text,
        this.translations.cancellationPolicyStandart3.text,
        this.translations.cancellationPolicyStandart4.text
      ]
    },
    cancellationPolicyStrict() {
      return [
        this.translations.cancellationPolicyStrict1.text,
        this.translations.cancellationPolicyStrict2.text,
        this.translations.cancellationPolicyStrict3.text,
        this.translations.cancellationPolicyStrict4.text,
        this.translations.cancellationPolicyStrict5.text
      ]
    },
    cancellationPolicy() {
      const type = this.tour.cancellation_type
      let cancllationPolicyList = []

      if (type === STANDARD_CANCELLATION) {
        cancllationPolicyList = this.cancellationPolicyStandart
      } else if (type === NO_REFUND) {
        cancllationPolicyList = [this.translations.cancellationPolicySalesFinal1.text]
      } else {
        cancllationPolicyList = this.cancellationPolicyStrict
      }

      const isWeather = this.tour.cancellation_reasons.find(reason => reason.id === 1)
      const isMinTravalers = this.tour.cancellation_reasons.find(reason => reason.id === 2)

      if (isWeather) {
        cancllationPolicyList.push(this.translations.cancellationPolicyGoodWeather.text)
      }

      if (isMinTravalers) {
        cancllationPolicyList.push(this.translations.cancellationPolicyMinTravalers.text)
      }

      cancllationPolicyList = cancllationPolicyList.map(policyItem => ({ description: policyItem }))
      const centerArray = Math.ceil(cancllationPolicyList.length / 2)
      return {
        left: cancllationPolicyList.slice(0, centerArray),
        right: cancllationPolicyList.slice(centerArray, cancllationPolicyList.length)
      }
    },    
  }
}
</script>

<style lang="scss">
#canellcation-policy {
  .two-columns {
    display: flex;
    gap: 12px;
    & > div {
      width: 50%;
    }
  }
}
</style>
