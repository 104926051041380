var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "information-about-ticket" },
    [
      _c("h1", { staticClass: "main__title information-about-ticket__title" }, [
        _vm._v("\n    " + _vm._s(_vm.translates.title.text) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "h3",
        { staticClass: "information-about-ticket__subtitle main__subtitle" },
        [_vm._v("\n    " + _vm._s(_vm.translates.subtitle.text) + "\n  ")]
      ),
      _vm._v(" "),
      _c("label", { staticClass: "information-about-ticket__label" }, [
        _vm._v(
          "\n    " + _vm._s(_vm.translates.attractionsQuestion.text) + "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("VRadioGroup", {
        staticClass: "information-about-ticket__radio-group",
        attrs: { name: "hasAttractions" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "VRadioButton",
                  _vm._g(
                    _vm._b(
                      {
                        attrs: {
                          label: _vm.translates.yesText.text,
                          value: true,
                        },
                      },
                      "VRadioButton",
                      attrs,
                      false
                    ),
                    on
                  )
                ),
                _vm._v(" "),
                _c(
                  "VRadioButton",
                  _vm._g(
                    _vm._b(
                      {
                        attrs: {
                          label: _vm.translates.noText.text,
                          value: false,
                        },
                      },
                      "VRadioButton",
                      attrs,
                      false
                    ),
                    on
                  )
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.hasAttractions,
          callback: function ($$v) {
            _vm.hasAttractions = $$v
          },
          expression: "hasAttractions",
        },
      }),
      _vm._v(" "),
      _vm.hasAttractions
        ? [
            _c("label", { staticClass: "information-about-ticket__label" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.translates.contentAttractionsText1.text) +
                  "\n      "
              ),
              _c(
                "p",
                { staticClass: "information-about-ticket__label-description" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.translates.contentAttractionsText2.text) +
                      "\n      "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.$v.localAttractionList.$error
              ? _c(
                  "p",
                  { staticClass: "information-about-ticket__error-text" },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.translates.attractionsError.text) +
                        "\n    "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "information-about-ticket__attraction-card-list" },
              _vm._l(_vm.localAttractionList, function (attraction, index) {
                return _c(
                  "Card",
                  _vm._b(
                    {
                      key: attraction.id,
                      staticClass: "information-about-ticket__attraction-card",
                      attrs: {
                        index: index,
                        "total-count": _vm.localAttractionList.length,
                        translates: _vm.translates,
                      },
                      on: {
                        edit: function ($event) {
                          return _vm.editAttraction(attraction)
                        },
                        remove: function ($event) {
                          return _vm.removeAttraction(attraction)
                        },
                        "move-up": function ($event) {
                          return _vm.moveUp(attraction)
                        },
                        "move-down": function ($event) {
                          return _vm.moveDown(attraction)
                        },
                      },
                    },
                    "Card",
                    attraction,
                    false
                  )
                )
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "VButton",
              {
                staticClass: "information-about-ticket__attraction-create",
                attrs: { text: "" },
                on: { click: _vm.openCreateModal },
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.translates.attractionsAdd.text) +
                    "\n    "
                ),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "information-about-ticket__footer" },
        [
          _c(
            "VButton",
            {
              staticClass: "information-about-ticket__save",
              attrs: { base: "" },
              on: { click: _vm.saveAllAttractions },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.translates.saveBtn.text) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("CreateModal", {
        attrs: { attraction: _vm.activeAttraction, translates: _vm.translates },
        on: {
          "update:isActive": _vm.closeCreateModal,
          save: _vm.saveAttraction,
        },
        model: {
          value: _vm.showCreateModal,
          callback: function ($$v) {
            _vm.showCreateModal = $$v
          },
          expression: "showCreateModal",
        },
      }),
      _vm._v(" "),
      _c("VConfirmDialog", {
        ref: "deleteDialog",
        attrs: {
          title: _vm.translates.deleteAttractionTitle.text,
          text: _vm.translates.deleteAttractionSubtitle.text,
          "confirm-text": _vm.translates.yesText.text,
          "cancel-text": _vm.translates.noText.text,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }