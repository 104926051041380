<template>
  <ul class="v-list2" :style="cssVars">
    <template v-for="(item, index) in itemsShow">
      <template v-if="isShowDescription(item.description)">
        <li v-for="(line, lineIndex) in splitDescription(item.description)" :key="`${index}-${lineIndex}`" class="v-list__item" :class="itemClasses">
          <div>{{ line }}</div>
        </li>
      </template>
      <li v-else :key="`details-${index}`" class="v-list__item" :class="itemClasses">
        <div :style="item.details != null && item.details.length > 0 ? 'margin-bottom: 7px; margin-top: 4px;' : 'margin-top: 9px;'">
          <div>{{ item.address }}</div>
          <div v-if="item.details != null && item.details.length > 0">{{ item.details }}</div>
        </div>
      </li>
    </template>
    <ButtonMore v-if="items.length > showCount" class="v-list__button-show" @click="toggleShow">{{ textShow }}</ButtonMore>
  </ul>
</template>


<script>
import { mapState } from 'vuex'

import ButtonMore from '@/components/ButtonMore.vue'

export default {
  name: 'VList',
  components: {
    ButtonMore
  },

  props: {
    items: { type: Array, default: () => [] },
    green: { type: Boolean, default: false },
    blue: { type: Boolean, default: false },
    black: { type: Boolean, default: false },
    showCount: { type: Number, default: 4 }
  },

  data() {
    return {
      isShowHidden: false
    }
  },

  computed: {
    ...mapState(['headerTranslate']),

    cssVars() {
      return {
        '--item-line-height': this.isShowDescription(this.items[0]?.description) ? '30px' : '13px'
      }
    },

    itemsShow() {
      if (this.isShowHidden) {
        return this.items;
      }
      return this.items.slice(0, this.showCount);
    },

    textShow() {
      return this.isShowHidden ? this.headerTranslate.showLess.text : this.headerTranslate.showMore.text;
    },

    itemClasses() {
      return {
        'v-list__item--green': this.green,
        'v-list__item--blue': this.blue,
        'v-list__item--black': this.black
      }
    }
  },

  methods: {
    toggleShow() {
      this.isShowHidden = !this.isShowHidden;
    },

    isShowDescription(description) {
      return description !== undefined;
    },

    splitDescription(description) {
      return description.split('\r\n');
    }
  }
}
</script>

<style lang="scss">
.v-list2 {
  .v-list__item {
    display: flex;
    align-items: flex-start;
    font-size: 20px;
    line-height: var(--item-line-height);
    font-weight: 500;
    word-break: break-word;
    word-wrap: anywhere;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $orange;
      margin-right: 12px;
      margin-top: 16px;
      flex-shrink: 0;
    }

    &--green {
      &::before {
        background: $green-two;
      }
    }

    &--blue {
      &::before {
        background: $light-blue-two;
      }
    }

    &--black {
      &::before {
        background: $text-primary;
      }
    }
  }
}
</style>
