<template>
  <div class="profile-picture-form">
    <div class="profile-picture-form__content">
      <VFIeld class="profile-picture-form__file-input" :error="$v.localPhotoFile.$error" :error-message="photoError">
        <VFileInput :preview-url="localPhotoUrl" full :change-text="translations.changePhoto.text"
          :error="$v.localPhotoFile.$error" accept="image/*" @change="uploadFile">{{ translations.placeholderTitle.text
          }}</VFileInput>
      </VFIeld>

      <PhotoHelp class="profile-picture-form__help" :do-list="photoDo" :dont-list="photoDont"
        :do-title="translations.do.text" :dont-title="translations.dont.text" />
    </div>

    <VBtn class="profile-picture-form__button" :loading="loading" base :name="saveBtnText" @click="save" />
  </div>
</template>

<script>
import { requiredIf } from 'vuelidate/lib/validators'

import { maxFileSize, checkFileFormat } from '@/utils/validations'

import VFileInput from '@/components/controls/VFileInput.vue'
import VFIeld from '@/components/controls/VField.vue'
import VBtn from '@/components/controls/VBtn.vue'
import PhotoHelp from '@/components/photoHelp.vue'
import { parseTranslate } from '@/utils/translations'
import { dataURLtoFile } from '@/utils/base64'

const MEGABYTE_IN_BYTES = 1000000
const MAX_FILE_SIZE = 5 * MEGABYTE_IN_BYTES
const ACCESS_FILES = ['image/jpeg', 'image/png']

export default {
  name: 'YourProfilePictureForm',
  components: { VBtn, VFileInput, PhotoHelp, VFIeld },
  props: {
    translations: { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false },
    saveBtnText: { type: String, default: '' },
    photo: { type: String, default: '' }
  },

  data() {
    return {
      localPhotoUrl: this.photo ? this.photo : '',
      localPhotoFile: ''
    }
  },

  computed: {
    photoError() {
      if (!this.$v.localPhotoFile.maxFileSize) {
        return parseTranslate(this.translations.photoErrorSize.text, MAX_FILE_SIZE / MEGABYTE_IN_BYTES)
      }

      if (!this.$v.localPhotoFile.fileFormat) {
        return this.translations.photoFormatError.text
      }

      if (!this.$v.localPhotoFile.required) {
        return this.translations.photoErrorRequired.text
      }

      return ''
    },

    photoDo() {
      return [this.translations.doLi1.text, this.translations.doLi2.text, this.translations.doLi3.text]
    },
    photoDont() {
      return [
        this.translations.dontLi1.text,
        this.translations.dontLi2.text,
        this.translations.dontLi3.text,
        this.translations.dontLi4.text
      ]
    }
  },

  validations() {
    return {
      localPhotoFile: {
        required: requiredIf(() => !this.photo),
        maxFileSize: maxFileSize(MAX_FILE_SIZE),
        fileFormat: checkFileFormat(ACCESS_FILES)
      }
    }
  },

  mounted() {
    const fileInBase64 = localStorage.getItem('company_logo_file_base64_your_profile')
    if (fileInBase64) {
      this.localPhotoUrl = fileInBase64

      let fileName = localStorage.getItem('company_logo_file_name_your_profile')
      if (!fileName) fileName = 'File name'

      const file = dataURLtoFile(fileInBase64, fileName)

      this.localPhotoFile = file
    }
  },

  methods: {
    uploadFile(file) {
      this.localPhotoFile = file
      this.$v.localPhotoFile.$touch()
      this.localPhotoUrl = URL.createObjectURL(file)
    },
    save() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.$emit('save', this.localPhotoFile || this.localPhotoUrl)
    }
  }
}
</script>

<style lang="scss">
.profile-picture-form {
  width: 100%;

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 36px;
  }

  &__file-input {
    flex-grow: 1;

    .v-file-input__container {
      height: 350px;
      width: 100%;
    }
  }

  &__help {
    flex-shrink: 0;
  }

  &__button {
    margin-top: 72px;
  }
}
</style>
