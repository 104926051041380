<template>
  <div ref="expandableContainer" class="expandable-text" :style="`--lines: ${lines}`">
    <span :class="textClassState">
      <span ref="textContainer" class="expandable-text__text pre-wrap">{{ text }}</span>
    </span>

    <button v-if="isCollapsed" class="expandable-text__button" type="button" @click="toggleShow">
      {{ expandText }}
      <div class="expandable-text__button-arrow" :class="{ 'expandable-text__button-arrow--expanded': expanded }">
        <img src="@/assets/img/svg/small-arrow.svg" alt="small-arrow" />
      </div>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'VExpandableText',

  props: {
    text: { type: String, default: '' },
    lines: { type: Number, default: 3 }
  },

  data() {
    return {
      expanded: false,
      columnsCount: 0
    }
  },

  computed: {
    ...mapState(['headerTranslate']),

    textClassState() {
      return this.expanded ? 'expandable-text__expanded' : 'expandable-text__default'
    },

    expandText() {
      if (this.expanded) {
        return this.headerTranslate.showLessButton.text
      }

      return this.headerTranslate.showMoreButton.text
    },

    isCollapsed() {
      return this.columnsCount > this.lines
    }
  },

  watch: {
    text() {
      this.columnsCount = this.getLinesCount()
    }
  },

  mounted() {
    this.columnsCount = this.getLinesCount()
  },

  methods: {
    getLinesCount() {
      const containerHeight = this.$refs.textContainer.offsetHeight
      const lineHeight = parseInt(getComputedStyle(this.$refs.textContainer).lineHeight)

      return containerHeight / lineHeight
    },

    toggleShow() {
      this.expanded = !this.expanded

      if (!this.expanded) {
        this.$nextTick(() => {
          this.$refs.expandableContainer.scrollIntoView({
            behavior: 'auto',
            block: 'center'
          })
        })
      }
    }
  }
}
</script>

<style lang="scss">
.expandable-text {
  --lines: 3;

  &__default {
    font-size: inherit;
    font-weight: inherit;

    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: var(--lines);
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__expanded {
    font-size: inherit;
    font-weight: inherit;
    display: block;
  }

  &__text {
    word-break: break-word;
    word-wrap: anywhere;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }

  &__button {
    background: none;
    border: none;
    margin-top: 9px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    color: #989898;
    max-width: fit-content;

    &:hover {
      color: $primary;
    }
  }

  &__button-arrow {
    margin-left: 4px;

    &--expanded {
      transform: rotate(180deg);
    }
  }
}
</style>
