<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.9998 29.3346C23.3636 29.3346 29.3332 23.3651 29.3332 16.0013C29.3332 8.63751 23.3636 2.66797 15.9998 2.66797C8.63604 2.66797 2.6665 8.63751 2.6665 16.0013C2.6665 23.3651 8.63604 29.3346 15.9998 29.3346Z" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 21.3333V16" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M16 10.668H16.0133" stroke="currentColor" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'InfoIcon'
  }
</script>
