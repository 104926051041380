var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "change-pricing-type" },
        [
          _c(
            "div",
            {
              staticClass: "change-pricing-type__close",
              on: {
                click: function ($event) {
                  _vm.modelVisible = false
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  id: `DelClosePopup`,
                  width: "20",
                  height: "20",
                  src: require("@/assets/img/svg/close.svg"),
                  alt: "close",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _vm.pages[_vm.currentPageIndex] == 0
            ? _c("first-page", {
                attrs: {
                  translations: _vm.translations,
                  "is-person": _vm.personPricing,
                  "discount-start-date":
                    _vm.tour.discount_info[0]?.discount_start_date,
                  "discount-end-date":
                    _vm.tour.discount_info[0]?.discount_end_date,
                  "current-page-index": _vm.currentPageIndex,
                  "page-count": _vm.pages.length,
                },
                on: {
                  next: _vm.nextPage,
                  close: function ($event) {
                    _vm.modelVisible = false
                  },
                },
              })
            : _vm.pages[_vm.currentPageIndex] == 1
            ? _c("second-page", {
                attrs: {
                  translations: _vm.translations,
                  "is-person": _vm.personPricing,
                  members: _vm.membersList,
                  "group-type-name": _vm.groupTypeName,
                  "selection-titles": _vm.selectionTitles,
                  "current-page-index": _vm.currentPageIndex,
                  "page-count": _vm.pages.length,
                },
                on: {
                  "update:members": function ($event) {
                    _vm.membersList = $event
                  },
                  next: _vm.goStepPrices,
                  back: _vm.prevPage,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.allPriceSchedules, function (page) {
            return _c("div", { key: page.pageName }, [
              _vm.pages[_vm.currentPageIndex] === page.pageName
                ? _c(
                    "div",
                    { staticClass: "change-pricing-type__third-page" },
                    [
                      _c("div", { staticClass: "change-pricing-type__title" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.translations.popupPersonThirdPageTitle.text
                            ) +
                            "\n          " +
                            _vm._s(page.scheduleName) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.personPricing
                        ? _c("person", {
                            attrs: {
                              members: page.members,
                              "is-tiered": page.isTiered,
                              translations: _vm.translations,
                              "old-schedule": page.scheduleOrigin,
                              "current-page-index": _vm.currentPageIndex,
                              "page-count": _vm.pages.length,
                              "is-last-page": _vm.isLastPage,
                            },
                            on: {
                              next: _vm.savePersonPrice,
                              back: _vm.backPersonPrice,
                            },
                          })
                        : _c("group", {
                            attrs: {
                              "edit-prices": page.members,
                              "old-schedule": page.scheduleOrigin,
                              translations: _vm.translations,
                              "page-count": _vm.pages.length,
                              "current-page-index": _vm.currentPageIndex,
                              "is-last-page": _vm.isLastPage,
                              tour: _vm.tour,
                            },
                            on: {
                              next: _vm.saveGroupPrice,
                              back: _vm.backGroupPrice,
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.loading ? _c("Loader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }