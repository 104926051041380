<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-enchanced-security">
      <div class="pop-up-enchanced-security__content">
        <div class="warning">
          <img
            src="@/assets/img/svg/warning.svg"
            alt="warning"
            class="warning__image"
          >
          <div class="warning__text">
            {{ translations.enhancedSecurityTitle.text }}
          </div>
        </div>
        <div class="description">
          <div class="description__item">
            {{ translations.enhancedSecurityDescription.text }}
          </div>
          <div class="description__item">
            {{  translations.enhancedSecurityRecommend.text }}
          </div>
        </div>
      </div>
      <div class="pop-up-enchanced-security__nav">
        <div class="cancel" @click="closeWindow">
          <Btn outline name="Maybe later" />
        </div>
        <div class="enable">
          <Btn base name="Enable" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/controls/Btn.vue'
export default {
  components: {
    Btn
  },
  props: {
    isActive: { type: Boolean, default: false },
    translations: { type: Object, default: () => ({}) }
  },
  data: () => ({}),
  methods: {
    closeWindow () {
      this.$router.push(
        this.localePath('/finance/payout-methods/basic-details')
      )
    }
  }
}
</script>

<style lang="scss">
.pop-up-enchanced-security {
  background: $white;
  position: relative;
  width: 600px;
  &__content {
    .warning {
      background: $orange;
      height: 209px;
      margin-bottom: 16px;
      &__image {
        margin-bottom: 29px;
        padding-top: 37px;
        padding-left: 263px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
      }
    }
    .description {
      margin-bottom: 26px;
      padding: 0 10px;
      &__item {
        margin-bottom: 9px;
      }
    }
  }
  &__nav {
    padding: 25px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #ececec;
    .btn {
      &_outline {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 124px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
      &_base {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
    }
  }
}
</style>
