var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "InputFields",
    _vm._b(
      {
        ref: "input",
        staticClass: "google-autocomplete",
        class: {
          "google-autocomplete--error": _vm.error,
          "google-autocomplete--filled": _vm.localAddress,
        },
        attrs: { error: _vm.error },
        on: { click: _vm.initAutocomplete },
        model: {
          value: _vm.localAddress,
          callback: function ($$v) {
            _vm.localAddress = $$v
          },
          expression: "localAddress",
        },
      },
      "InputFields",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }