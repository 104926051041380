var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "btn",
      class: _vm.setClass,
      attrs: { type: _vm.type, "@click": _vm.click, disabled: _vm.disabled },
    },
    [
      _vm._t("default", function () {
        return [
          _vm.loading ? _c("div", { staticClass: "loader" }) : _vm._e(),
          _vm._v(
            "\n    " + _vm._s(_vm.loading ? _vm.loadingName : _vm.name) + "\n  "
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }