var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "question-mark" }, [
    _c("img", {
      attrs: {
        src: require("@/assets/img/svg/question-info.svg"),
        alt: "question-info",
      },
      on: {
        mouseover: function ($event) {
          _vm.isShow = true
        },
        mouseleave: function ($event) {
          _vm.isShow = false
        },
      },
    }),
    _vm._v(" "),
    _vm.isShow
      ? _c("div", { staticClass: "question-mark__description" }, [
          _vm._v("\n    " + _vm._s(_vm.description) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }