var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "expandableContainer",
      staticClass: "expandable-text",
      style: `--lines: ${_vm.lines}`,
    },
    [
      _c("span", { class: _vm.textClassState }, [
        _c(
          "span",
          {
            ref: "textContainer",
            staticClass: "expandable-text__text pre-wrap",
          },
          [_vm._v(_vm._s(_vm.text))]
        ),
      ]),
      _vm._v(" "),
      _vm.isCollapsed
        ? _c(
            "button",
            {
              staticClass: "expandable-text__button",
              attrs: { type: "button" },
              on: { click: _vm.toggleShow },
            },
            [
              _vm._v("\n    " + _vm._s(_vm.expandText) + "\n    "),
              _c(
                "div",
                {
                  staticClass: "expandable-text__button-arrow",
                  class: {
                    "expandable-text__button-arrow--expanded": _vm.expanded,
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/svg/small-arrow.svg"),
                      alt: "small-arrow",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }