<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.62763 20.3491C4.22576 19.9324 4 19.3671 4 18.7778L4 5.22222C4 4.63285 4.22576 4.06762 4.62763 3.65088C5.02949 3.23413 5.57454 3 6.14286 3L13.6429 3L16.8571 3C17.4255 3 17.9705 3.23413 18.3724 3.65087C18.7742 4.06762 19 4.63285 19 5.22222L19 18.7778C19 19.3671 18.7742 19.9324 18.3724 20.3491C17.9705 20.7659 17.4255 21 16.8571 21L13.6429 21C13.6429 20.4106 13.4171 19.8454 13.0152 19.4287C12.6134 19.0119 12.0683 18.7778 11.5 18.7778C10.9317 18.7778 10.3866 19.0119 9.98477 19.4287C9.58291 19.8454 9.35714 20.4106 9.35714 21L6.14286 21C5.57454 21 5.02949 20.7659 4.62763 20.3491Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <rect fill="currentColor" x="9" y="8" width="2" height="2" />
    <rect fill="currentColor" x="12" y="8" width="2" height="2" />
    <rect fill="currentColor" x="12" y="11" width="2" height="2" />
    <rect fill="currentColor" x="9" y="11" width="2" height="2" />
  </svg>
</template>

<script>
export default {
  name: 'TourVouchers'
}
</script>
