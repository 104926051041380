<template>
  <v-dialog v-model="modelActive" :title="getTitle" dialog-classes="connect-reservation-system">
    <div v-if="step == 1">
      <div class="connect-reservation-system__description">
        <div class="connect-reservation-system__description--item">
          {{ translations.selectDescription1.text }}
          <br />
          {{ translations.selectDescription2.text }}
        </div>

        <div>{{ translations.selectDescription3.text }}</div>
      </div>

      <div class="connect-reservation-system__label">{{ translations.yourReservationSystem.text }}</div>

      <v-select
        v-model="$v.reservationSystem.$model"
        :error="$v.reservationSystem.$error"
        error-message="This field can not be empty"
        :items="normalizedReservationSystemTypes"
        :translates="reservationSystemTypes"
        placeholder="Select one"
      />

      <v-input
        v-if="isBokun || isOther"
        v-model.trim="$v.info.$model"
        :label="isBokun ? 'Account ID' : 'Other'"
        :error="$v.info.$error"
        error-message="This field can not be empty"
        :placeholder="isBokun ? 'Introduce your Bokun Account ID' : 'Introduce the system that you work with'"
      />
    </div>

    <div v-else>
      <div class="bokun_root" v-if="isBokun">
        <div class="connect-reservation-system__bokun--text">
          As a supplier, you provide us with your contract terms when inviting us to become your reseller. Before you
          can send a Marketplace contract, you need to make sure you have your Contract terms in place.
        </div>

        <div class="connect-reservation-system__bokun--title">
          Follow the steps below to send a contract proposal to Vidodo Guide:
        </div>

        <ul>
          <li>In your Bokun Dashboard, click on Sales tools → Marketplace</li>
          <li>Go to the Discover Partners page</li>
          <li>Click on the business card</li>
          <li>Click Offer my products</li>
          <li>Complete the marketplace contract sections</li>
          <li>Select Send proposal</li>
        </ul>
      </div>
      <div v-else>
        We will establish communication with your reservation system in order to integrate it with Vidodo Guide.
      </div>
    </div>

    <div class="connect-reservation-system__footer">
      <v-btn outline @click="cancel">{{ translations.cancelButton.text }}</v-btn>
      <v-btn base :loading="loading" loading-name="Connects..." @click="next">
        {{ step === 1 ? translations.nextButton?.text : translations.saveButton.text }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';

import notificationService from '@/services/notification';
import VDialog from '@/components/common/VDialog.vue';
import VSelect from '@/components/controls/VSelect.vue';
import VInput from '@/components/controls/VInput.vue';
import VBtn from '@/components/controls/VBtn.vue';

export default {
  name: 'ConnectReservationSystem',
  components: { VDialog, VSelect, VInput, VBtn },

  props: {
    selected: { type: String, optional: true, default: undefined },
    isVisible: { type: Boolean, default: false },
    reservationSystemTypes: { type: Object, default: () => {} },
    translations: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      loading: false,
      step: 1,
      reservationSystem: '',
      info: '',
    };
  },

  computed: {
    modelActive: {
      get() {
        return this.isVisible;
      },
      set(value) {
        this.$emit('update:isVisible', value);
      },
    },

    normalizedReservationSystemTypes() {
      return Object.entries(this.reservationSystemTypes).map(([name]) => ({ name }));
    },

    isBokun() {
      return this.reservationSystem === 'bokun';
    },
    isOther() {
      return this.reservationSystem === 'other';
    },

    getTitle() {
      return this.step === 1
        ? this.translations.connectReservationSystem.text
        : this.isBokun
        ? 'Bokun Connection Instructions'
        : 'Connection';
    },
  },

  watch: {
    modelActive() {
      if (this.modelActive) {
        this.step = 1;
        this.reservationSystem = this.selected ?? '';
        this.info = '';
        this.$v.$reset();
      }
    },
  },

  validations() {
    return {
      reservationSystem: { required },
      info: { required: requiredIf(() => this.isBokun || this.isOther) },
    };
  },

  methods: {
    next() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.step === 1) this.step++;
        else {
          this.loading = true;
          const data = {
            reservation_system: this.reservationSystem,
          };

          if (this.isBokun) data.reservation_system_account_id = this.info;
          else if (this.isOther) data.reservation_system_other = this.info;

          this.$axios
            .$put(`api/v1/seller-setting/`, null, {
              params: data,
            })
            .catch(() => {
              notificationService.error({
                text: 'There was an error connecting to the reservation system',
              });
            })
            .finally(() => {
              this.loading = false;
              this.modelActive = false;
              location.reload();
            });
        }
      }
    },

    cancel() {
      if (this.step === 1) this.modelActive = false;
      else this.step--;
    },
  },
};
</script>

<style lang="scss">
.connect-reservation-system {
  width: 800px;

  &__description {
    & > * {
      line-height: 24px;
    }
    margin-bottom: 53px;
    font-size: 18px;
    font-weight: 500;
    width: 637;
    &--item {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  .v-dialog {
    &__title {
      font-weight: 600;
      padding-top: 63px;
      padding-bottom: 19px;
      width: 100%;
    }

    &__header {
      border-bottom: 1px solid $border;
      padding: 0px;
      margin: 0 35px;
    }

    &__close {
      height: 24px;
    }

    &__content {
      padding-top: 22px;
    }
  }

  .selection {
    &__preview {
      width: 100%;
    }

    &__expand {
      width: 100%;
      max-height: 120px;
    }
  }

  &__label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  &__bokun {
    max-width: 644px;

    &--text {
      margin-bottom: 49px;
    }

    &--title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 40px;
    }
  }

  .bokun_root {
    ul {
      list-style-type: disc;
      margin-left: 20px;
      line-height: 28px;
      li {
        line-height: inherit;
      }
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    padding-top: 38px;
    border-top: 1px solid $border;
    margin-top: 122px;

    .v-btn_outline {
      margin-right: 25px;
    }

    .v-btn_base {
      min-width: 70px;
    }
  }
}
</style>
