var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "pop-up-translate-select" }, [
          _c(
            "div",
            { staticClass: "pop-up__close", on: { click: _vm.closeWindow } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/close.svg"),
                  alt: "close",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pop-up-translate-select__title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.selectLanguageTranslate.title.text) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pop-up-translate-select__selection" },
            [
              _c("selection", {
                attrs: {
                  default: "",
                  "default-name": _vm.languagesName,
                  items: _vm.languages,
                },
                on: { name: _vm.changeLanguageName },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pop-up-translate-select__nav" }, [
            _c(
              "div",
              { staticClass: "cancel", on: { click: _vm.closeWindow } },
              [
                _c("Btn", {
                  attrs: {
                    outline: "",
                    name: _vm.selectLanguageTranslate.cancelBtn.text,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "continue", on: { click: _vm.goToPage } },
              [
                _c("Btn", {
                  attrs: {
                    base: "",
                    name: _vm.selectLanguageTranslate.continueBtn.text,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }