<template>
  <div class="pricing-change-modal-second-page">
    <div v-if="isPerson" class="person">
      <div class="pricing-change-modal-second-page__title">
        {{ translations.popupPersonSecondPageTitle.text }}
      </div>
      <age-form
        ref="ageForm"
        :is-group-pricing="isPerson"
        :translates="translations"
        :members="members"
        @update:members="updateMembers"
      />
    </div>
    <div v-else class="group">
      <div class="pricing-change-modal-second-page__title">
        {{ translations.popupGroupSecondPageTitle.text }}
      </div>
      <div class="pricing-change-modal-second-page__subtitle">
        {{ translations.popupGroupSecondPageSubtitle.text }}
      </div>
      <div class="pricing-change-modal-second-page__package">
        <v-select v-model="$v.typeName.$model" :error="$v.typeName.$error" :items="groupTypes" :translates="groupTypesTranslations" :placeholder="translations.groupTypePlaceholder.text" />
      </div>
    </div>
    <div class="change-pricing-type__nav">
      <v-btn outline :name="translations.popupNavBack.text" @click="$emit('back')" />
      <div class="nav__pagination row">
        <div v-for="index in pageCount" :key="`pageNum${index}`">
          <div class="dot dot_page" :class="{ dot_page_active: index - 1 == currentPageIndex }" />
        </div>
      </div>
      <v-btn base :name="translations.popupNavNext.text" @click="next" />
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import VBtn from '@/components/controls/VBtn.vue'
import AgeForm from '@/components/price/AgeForm.vue'
import VSelect from '@/components/controls/VSelect.vue'

export default {
  components: {
    VBtn,
    AgeForm,
    VSelect
  },
  props: {
    translations: { type: Object, default: () => ({}) },
    isPerson: { type: Boolean, default: false },
    pageCount: { type: Number, default: 0 },
    currentPageIndex: { type: Number, default: 0 },
    groupTypeName: { type: [String, Number], default: '' },
    members: { type: Array, default: () => [] },
    selectionTitles: { type: Array, default: () => [] }
  },
  data() {
    return {
      typeName: null,
      editMembersGroup: []
    }
  },
  fetch() {
    this.typeName = this.groupTypeName
  },

  computed: {
    groupTypes() {
      return this.selectionTitles.map(type => {
        return { ...type, name: type.id }
      })
    },

    groupTypesTranslations() {
      return this.selectionTitles.reduce((translations, { id, name }) => {
        return {
          ...translations,
          [id]: name
        }
      }, {})
    }
  },

  validations() {
    return {
      typeName: { required }
    }
  },

  methods: {
    updateMembers(newMembers) {
      this.$emit('update:members', newMembers)
    },

    next() {
      if (!this.isPerson) {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          this.$emit('next', this.typeName)
        }
      } else if (this.$refs.ageForm.validate()) {
        this.$emit('next', this.members)
      }
    }
  }
}
</script>
<style lang="scss">
.pricing-change-modal-second-page {
  &__title {
    margin-bottom: 44px;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
  &__package {
    margin-bottom: 56px;
    margin-top: 7px;
    .selection {
      &__preview {
        width: 258px;
      }
      &__expand {
        width: 258px;
      }
    }
  }
}
</style>
