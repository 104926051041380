var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "__main-wrapper" },
    [
      _c("Nuxt", { staticClass: "empty-main" }),
      _vm._v(" "),
      _c("footer", { staticClass: "footer" }, [
        _c("div", { staticClass: "footer__wave" }, [
          _c(
            "svg",
            {
              staticClass: "editorial",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                viewBox: "0 24 150 28",
                preserveAspectRatio: "none",
                width: "100%",
              },
            },
            [
              _c("defs", [
                _c("path", {
                  attrs: {
                    id: "gentle-wave",
                    d: "M-160 44c30 0\n                  58-18 88-18s\n                  58 18 88 18\n                  58-18 88-18\n                  58 18 88 18\n                  v44h-352z",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("g", { staticClass: "parallax" }, [
                _c("use", {
                  attrs: {
                    "xlink:href": "#gentle-wave",
                    x: "50",
                    y: "6",
                    fill: "#D1EBFD",
                  },
                }),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "footer__content" }, [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "footer__inner" }, [
              _c("div", { staticClass: "rights" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.footerTranslate.fields.footerAllRights.text) +
                    "\n            "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("PortalTarget", { attrs: { name: "popups", multiple: "" } }),
      _vm._v(" "),
      _c("notification-view"),
      _vm._v(" "),
      _c("screenResolutionNotice"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }