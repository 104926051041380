<template>
  <div class="bank-details">
    <div class="_container">
      <VBreadcrumbs v-if="hasBackButton" :title="translations.backStep.text" @click="backStep" />
      <div class="bank-details__content">
        <div class="billing-address">
          <div class="title">
            {{ translations.billingTitle.text }}
          </div>
          <VField class="bank-details__field" :label="translations.selectionCountry.text" :error="countryError">
            <client-only>
              <VSelectCountry v-model="country" :country="country" :country-name="false" :usei18n="false" :error="countryError" />
            </client-only>
          </VField>
          <VField
            class="bank-details__field"
            :label="translations.streetAddress.text"
            :error="$v.localAddressForm.address.$error"
            :error-message="getErrorMessage(translations.streetAddress.text)"
          >
            <VInput
              v-model.trim="$v.localAddressForm.address.$model"
              :error="$v.localAddressForm.address.$error"
              :placeholder="translations.inputAddressPlaceholder1.text"
              :max-length="128"
              hide-counter
            />
          </VField>
          <VField
            class="bank-details__field"
            :label="translations.city.text"
            :error="$v.localAddressForm.city.$error"
            :error-message="getErrorMessage(translations.city.text)"
          >
            <VInput
              v-model.trim="$v.localAddressForm.city.$model"
              :error="$v.localAddressForm.city.$error"
              :placeholder="translations.inputCityPlaceholder.text"
              :max-length="128"
              hide-counter
            />
          </VField>
          <VField
            class="bank-details__field"
            :label="translations.zipCode.text"
            :error="$v.localAddressForm.postcode.$error"
            :error-message="getErrorMessage(translations.zipCode.text)"
          >
            <VInput
              v-model.trim="$v.localAddressForm.postcode.$model"
              :error="$v.localAddressForm.postcode.$error"
              :placeholder="translations.inputZipCodePlaceholder.text"
              :max-length="128"
              hide-counter
            />
          </VField>
          <div class="next" @click="saveStep">
            <Btn base :name="translations.btnNext.text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapState } from 'vuex'
import VSelectCountry from '@/components/controls/VSelectCountry.vue'
import VField from '@/components/controls/VField.vue'
import VInput from '@/components/controls/VInput.vue'
import VBreadcrumbs from '@/components/controls/VBreadcrumbs.vue'

import { parseTranslate } from '@/utils/translations'

export default {
  components: {
    Btn: () => import('@/components/controls/Btn.vue'),
    VSelectCountry,
    VInput,
    VField,
    VBreadcrumbs
  },
  props: {
    translations: { type: Object, default: () => ({})},
    addressForm: { type: Object, default: null },
    hasBackButton: { type: Boolean, default: true },
  },
  data: () => ({
    countryError: false,
    country: 'ES',
    localAddressForm: {
      address: '',
      city: '',
      postcode: ''
    }
  }),

  computed: {
    ...mapState(['userInfo']),

  },
  validations() {
    return {
      localAddressForm: {
        address: { required },
        city: { required },
        postcode: { required }
      }
    }
  },
  beforeMount() {
    if (this.addressForm) {
      const { country, address, city, postcode } = this.addressForm

      this.country = country
      this.localAddressForm = { address, city, postcode }
    }
  },
  methods: {
    backStep() {
      this.$emit('back')
    },
    getErrorMessage(label) {
      return parseTranslate(this.translations.errorRequired.text, label)
    },
    saveStep() {
      this.$v.$touch()
      this.countryError = !this.country

      if (this.$v.$invalid || this.countryError) {
        return
      }

      this.$emit('save', { ...this.localAddressForm, country: this.country })
    },
  }
}
</script>

<style lang="scss">
.bank-details {
  margin-bottom: 25px;
  .v-input {
    margin-top: 0;
  }
  &__field {
    &:not(:last-child) {
      margin-bottom: 45px;
    }
  }

  &__subtitle {
    margin-bottom: 48px;
  }

  &__content {
    width: 718px;
    .title {
      margin-bottom: 8px;
      font-weight: bold;
      font-size: 32px;
      line-height: 39px;
    }
    .next {
      margin-top: 96px;
    }
  }
}
</style>
