var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M14.668 5.33398H5.33464C4.62739 5.33398 3.94911 5.61494 3.44902 6.11503C2.94892 6.61513 2.66797 7.29341 2.66797 8.00065V26.6673C2.66797 27.3746 2.94892 28.0528 3.44902 28.5529C3.94911 29.053 4.62739 29.334 5.33464 29.334H24.0013C24.7085 29.334 25.3868 29.053 25.8869 28.5529C26.387 28.0528 26.668 27.3746 26.668 26.6673V17.334",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M24.668 3.33233C25.1984 2.8019 25.9178 2.50391 26.668 2.50391C27.4181 2.50391 28.1375 2.8019 28.668 3.33233C29.1984 3.86277 29.4964 4.58219 29.4964 5.33233C29.4964 6.08248 29.1984 6.8019 28.668 7.33233L16.0013 19.999L10.668 21.3323L12.0013 15.999L24.668 3.33233Z",
          stroke: "currentColor",
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }