var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "pop-up-learn-more" }, [
          _c(
            "div",
            { staticClass: "pop-up__close", on: { click: _vm.closeWindow } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/close.svg"),
                  alt: "close",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pop-up-learn-more__title" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.cutoffTranslate.title.text) + "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pop-up-learn-more__subtitle" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.cutoffTranslate.description.text) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pop-up-learn-more__content" }, [
            _c("div", { staticClass: "start-time" }, [
              _c("div", { staticClass: "start-time__title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.cutoffTranslate.relativeTitle.text) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "start-time__description" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.cutoffTranslate.relativeDescription.text) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "start-time__example" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.cutoffTranslate.relativeExample.text) +
                    "\n          "
                ),
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/relative-to-start-time.jpg"),
                    alt: "relative-to-start-time",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "one-set-time" }, [
              _c("div", { staticClass: "one-set-time__title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.cutoffTranslate.setTimeTitle.text) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "one-set-time__description" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.cutoffTranslate.setTimeDescription.text) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "one-set-time__example" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.cutoffTranslate.setTimeExample.text) +
                    "\n          "
                ),
                _c("img", {
                  attrs: {
                    src: require("@/assets/img/one-set-time.jpg"),
                    alt: "one-set-time",
                  },
                }),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "pop-up-learn-more__nav",
              on: { click: _vm.closeWindow },
            },
            [
              _c("Btn", {
                attrs: {
                  base: "",
                  name: _vm.cutoffTranslate.understoodBtn.text,
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }