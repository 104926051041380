<template>
  <nuxt-link :to="fullLink" class="back-link">
    <svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 1L1 4L4 7" stroke="currentColor"/>
    </svg>
    <span v-if="!product" class="back-link__text">{{ commonTranslate.linkBackProductList?.text }}</span>
    <span v-if="product" class="back-link__text" >{{ commonTranslate.linkBackProductContent?.text }}</span>
  </nuxt-link>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BackLink',
  props: {
    product: {
      type: String,
      default: () => undefined,
    },
  },
  computed: {
    ...mapState(['commonTranslate']),
    fullLink() {
      if (this.product) return this.localePath(`/product/${this.product}/product-content`);
      return this.localePath(`/product`);
    }
  }
}
</script>

<style lang="scss">
.back-link {
  color: rgb(175, 177, 184);
  margin-bottom: 9px;
  display: block;  

  &__text {
    font-size: 12px;
    font-weight: 500;
  }

  & > svg {
    margin-right: 5px;
  }
}
</style>