var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "your-information-form" },
    [
      _c("VInput", {
        staticClass: "your-information-form__field",
        attrs: {
          error: _vm.$v.fullName.$error,
          label: _vm.translations.placeholderTitle1.text,
          placeholder: _vm.translations.placeholderText1.text,
        },
        model: {
          value: _vm.$v.fullName.$model,
          callback: function ($$v) {
            _vm.$set(_vm.$v.fullName, "$model", $$v)
          },
          expression: "$v.fullName.$model",
        },
      }),
      _vm._v(" "),
      _c("VInput", {
        staticClass: "your-information-form__field",
        attrs: {
          error: _vm.$v.publicName.$error,
          label: _vm.translations.placeholderTitle2.text,
          "sub-label": _vm.translations.placeholderSubtitle2.text,
          placeholder: _vm.translations.placeholderText2.text,
          "max-length": 24,
        },
        model: {
          value: _vm.$v.publicName.$model,
          callback: function ($$v) {
            _vm.$set(_vm.$v.publicName, "$model", $$v)
          },
          expression: "$v.publicName.$model",
        },
      }),
      _vm._v(" "),
      _c("VInput", {
        staticClass: "your-information-form__field",
        attrs: {
          error: _vm.$v.tagline.$error,
          label: _vm.translations.placeholderTitle3.text,
          "sub-label": _vm.translations.placeholderSubtitle3.text,
          placeholder: _vm.translations.placeholderText3.text,
          "max-length": 100,
        },
        model: {
          value: _vm.$v.tagline.$model,
          callback: function ($$v) {
            _vm.$set(_vm.$v.tagline, "$model", $$v)
          },
          expression: "$v.tagline.$model",
        },
      }),
      _vm._v(" "),
      _c("VTextarea", {
        staticClass: "your-information-form__field",
        attrs: {
          error: _vm.$v.bio.$error,
          rows: "3",
          label: _vm.translations.placeholderTitle4.text,
          "sub-label": _vm.translations.placeholderSubtitle4.text,
          placeholder: _vm.translations.placeholderText4.text,
          "max-length": 500,
        },
        model: {
          value: _vm.$v.bio.$model,
          callback: function ($$v) {
            _vm.$set(_vm.$v.bio, "$model", $$v)
          },
          expression: "$v.bio.$model",
        },
      }),
      _vm._v(" "),
      _c("VInput", {
        staticClass: "your-information-form__field",
        attrs: {
          error: _vm.$v.website.$error,
          label: _vm.translations.placeholderTitle5.text,
          optional: _vm.translations.placeholderTitleInfo5.text,
          placeholder: _vm.translations.placeholderText5.text,
        },
        model: {
          value: _vm.$v.website.$model,
          callback: function ($$v) {
            _vm.$set(_vm.$v.website, "$model", $$v)
          },
          expression: "$v.website.$model",
        },
      }),
      _vm._v(" "),
      _c("VBtn", {
        staticClass: "your-information-form__button",
        attrs: { loading: _vm.loading, base: "", name: _vm.saveBtnText },
        on: { click: _vm.save },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }