<template>
  <VDialog v-model="modelActive" size="extra-large" class="attractions-create-modal" :title="translates.attractionTitle.text">
    <form class="attractions-create-modal">
      <div class="attractions-create-modal__field">
        <p class="attractions-create-modal__label">
          {{ translates.locationText.text }}
        </p>
        <GoogleAutocomplete
          v-model="$v.address.$model"
          class="attractions-create-modal__input attractions-create-modal__input--search"
          :error="addressError"
          placeholder="e.g. Timanfaya National Park, 35570 Yaiza, Lanzarote, Spain"
          @update:point="handlePlaceChanged"
        />
        <p v-if="addressError" class="validation-error">
          {{ translates.locationError.text }}
        </p>
      </div>

      <VTextarea
        v-model="$v.localAttraction.description.$model"
        :max-length="1000"
        :label="translates.describeText.text"
        :error="$v.localAttraction.description.$error"
        :error-message="translates.describeError.text"
        class="attractions-create-modal__description attractions-create-modal__field"
      />

      <div class="attractions-create-modal__field">
        <p class="attractions-create-modal__label">
          {{ translates.durationText.text }}
        </p>
        <VRadioGroup v-model="isFlexTime" name="isFlexTime">
          <template #default="{ on, attrs }">
            <VRadioButton
              :label="translates.SetDurationText.text"
              :value="false"
              v-bind="attrs"
              class="attractions-create-modal__radio"
              v-on="on"
            >
              <template v-if="!isFlexTime">
                {{ translates.SetDurationText.text }}
                <div class="attractions-create-modal__duration">
                  <VInput
                    v-model="$v.localAttraction.durationTime.$model"
                    :max-length="3"
                    hide-counter
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    onpaste="return false;"
                    class="attractions-create-modal__duration-input attractions-create-modal__duration-input--right"
                    :error="$v.localAttraction.durationTime.$error"
                  />
                  <VSelect
                    v-model="localAttraction.durationTimeUnit"
                    :items="$options.durations"
                    :translates="durationTranslates"
                    class="attractions-create-modal__duration-select"
                    :class="{ 'attractions-create-modal__select--error': $v.localAttraction.durationTime.$error }"
                  />
                </div>
                <p v-if="$v.localAttraction.durationTime.$error" class="validation-error">
                  {{ translates.SetDurationError.text }}
                </p>
              </template>
            </VRadioButton>
            <VRadioButton
              :label="translates.FlexibleDurationText.text"
              :value="true"
              v-bind="attrs"
              class="attractions-create-modal__radio"
              v-on="on"
            >
              <template v-if="isFlexTime">
                {{ translates.FlexibleDurationText.text }}
                <div class="attractions-create-modal__duration">
                  <VInput
                    v-model="$v.localAttraction.durationTimeFrom.$model"
                    :max-length="3"
                    hide-counter
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    onpaste="return false;"
                    class="attractions-create-modal__duration-input"
                    :error="$v.localAttraction.durationTimeFrom.$error"
                  />
                  <span class="attractions-create-modal__duration-label">{{ translates.toText.text }}</span>
                  <VInput
                    v-model="$v.localAttraction.durationTimeTo.$model"
                    :max-length="3"
                    hide-counter
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    onpaste="return false;"
                    :error="$v.localAttraction.durationTimeTo.$error"
                    class="attractions-create-modal__duration-input attractions-create-modal__duration-input--right"
                  />
                  <VSelect
                    v-model="localAttraction.durationTimeUnit"
                    :items="$options.durations"
                    :translates="durationTranslates"
                    class="attractions-create-modal__duration-select"
                    :class="{ 'attractions-create-modal__select--error': $v.localAttraction.durationTimeTo.$error }"
                  />
                </div>
                <p v-if="durationIntervalError" class="validation-error">
                  {{ durationIntervalError }}
                </p>
              </template>
            </VRadioButton>
          </template>
        </VRadioGroup>
      </div>

      <div class="attractions-create-modal__field">
        <p class="attractions-create-modal__label">
          {{ translates.AdmissionText.text }}
        </p>
        <VRadioGroup v-model="$v.localAttraction.ticketRedemption.$model" name="ticketRedemption">
          <template #default="{ on, attrs }">
            <VRadioButton :label="translates.AdmissionChoice1.text" value="Admission is free" v-bind="attrs" v-on="on" />
            <VRadioButton
              :label="translates.AdmissionChoice2.text"
              value="Admission ticket is included"
              v-bind="attrs"
              v-on="on"
            />
            <VRadioButton
              :label="translates.AdmissionChoice3.text"
              value="Admission ticket is not included"
              v-bind="attrs"
              v-on="on"
            />
          </template>
        </VRadioGroup>
      </div>

      <div class="attractions-create-modal__field">
        <p class="attractions-create-modal__label">
          {{ translates.skipTheLineText.text }}
        </p>
        <VRadioGroup v-model="localAttraction.isSkipLine" name="isSkipLine">
          <template #default="{ on, attrs }">
            <VRadioButton :label="translates.yesText.text" :value="true" v-bind="attrs" v-on="on" />
            <VRadioButton :label="translates.noText.text" :value="false" v-bind="attrs" v-on="on" />
          </template>
        </VRadioGroup>
      </div>

      <div class="attractions-create-modal__footer">
        <VButton outline @click="close">
          {{ translates.cancelAttractionBtn.text }}
        </VButton>
        <VButton base @click="save">
          {{ translates.saveAttractionBtn.text }}
        </VButton>
      </div>
    </form>
  </VDialog>
</template>

<script>
import { required, requiredIf, minValue } from 'vuelidate/lib/validators'
import VRadioGroup from '@/components/controls/VRadioGroup.vue'
import VRadioButton from '@/components/controls/VRadioButton.vue'
import VInput from '@/components/controls/VInput.vue'
import VSelect from '@/components/controls/VSelect.vue'
import VTextarea from '@/components/controls/VTextarea.vue'
import VButton from '@/components/controls/VButton.vue'
import VDialog from '@/components/common/VDialog.vue'
import GoogleAutocomplete from '@/components/GoogleAutocomplete.vue'

const getBasicAttraction = () => {
  return {
    id: +Date.now(),
    address: '',
    description: '',
    durationTimeUnit: 'days',
    durationTime: '',
    durationTimeFrom: '',
    durationTimeTo: '',
    ticketRedemption: 'Admission is free',
    isSkipLine: true,
    lon: '',
    lat: ''
  }
}

const durations = [
  { is_active: true, name: 'min' },
  { is_active: false, name: 'hours' },
  { is_active: false, name: 'days' }
]

export default {
  name: 'AttractionsCreateModal',
  components: {
    VRadioGroup,
    VRadioButton,
    VInput,
    VSelect,
    VTextarea,
    VButton,
    VDialog,
    GoogleAutocomplete
  },
  model: {
    prop: 'isActive',
    event: 'update:isActive'
  },
  durations,

  props: {
    isActive: { type: Boolean, default: false },
    attraction: { type: Object, default: null },
    translates: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      localAttraction: getBasicAttraction(),
      isFlexTime: false,
      address: '',
      place: {
        lat: '',
        lon: ''
      }
    }
  },

  computed: {
    modelActive: {
      get() {
        return this.isActive
      },
      set(value) {
        this.$emit('update:isActive', value)
      }
    },
    durationTranslates() {
      return {
        min: this.translates.DurationChoice1.text,
        hours: this.translates.DurationChoice2.text,
        days: this.translates.DurationChoice3.text
      }
    },

    durationInterval() {
      return `${this.localAttraction.durationTimeFrom}-${this.localAttraction.durationTimeTo}`
    },

    durationIntervalError() {
      const { durationTimeTo, durationTimeFrom } = this.$v.localAttraction
      const invalid = durationTimeTo.$error || durationTimeFrom.$error

      if (invalid && (!durationTimeFrom.required || !durationTimeTo.required)) {
        return this.translates.SetDurationError.text
      }

      if (invalid && (!durationTimeFrom.maxError || !durationTimeTo.minError)) {
        return this.translates.FlexibleDurationError.text
      }

      return ''
    },

    addressError() {
      return this.$v.address.$error || this.$v.place.$error || this.localAttraction.address !== this.address
    }
  },

  validations() {
    return {
      localAttraction: {
        description: { required },
        ticketRedemption: { required },
        durationTime: { required: requiredIf(() => !this.isFlexTime) },
        durationTimeFrom: {
          required: requiredIf(() => this.isFlexTime)
        },
        durationTimeTo: {
          required: requiredIf(() => this.isFlexTime),
          minValue: value => (this.isFlexTime ? minValue(this.localAttraction.durationTimeFrom)(value) : true)
        },
        isSkipLine: { required },
      },
      place: {
        lat: { required },
        lon: { required }
      },
      address: { required }
    }
  },

  watch: {
    isActive() {
      if (this.isActive) {
        this.$v.$reset()
        this.isFlexTime = false
        this.localAttraction = this.attraction ? this.formatAttraction(this.attraction) : getBasicAttraction()
        this.address = this.localAttraction.address
        this.place = {
          lat: this.localAttraction.lat,
          lon: this.localAttraction.lon
        }
      }
    }
  },

  methods: {
    formatAttraction(attraction) {
      // TODO: JSON.stringify -> deepClone
      const formattedAttraction = JSON.parse(JSON.stringify(attraction))
      const durationInterval = formattedAttraction.durationTime.split('-')

      if (durationInterval.length > 1) {
        this.isFlexTime = true
      } else {
        this.isFlexTime = false
      }

      return {
        ...formattedAttraction,
        durationTime: durationInterval[0],
        durationTimeFrom: durationInterval[0],
        durationTimeTo: durationInterval[1]
      }
    },

    close() {
      this.$emit('update:isActive', false)
    },

    save() {
      this.$v.$touch()

      if (!this.$v.$invalid && !this.addressError) {
        const newAttraction = {
          ...this.localAttraction,
          address: this.address,
          lat: this.place.lat,
          lon: this.place.lon
        }
        if (this.isFlexTime) {
          newAttraction.durationTime = this.durationInterval
        }
        this.$emit('save', newAttraction)
      }
    },

    handlePlaceChanged(point) {
      this.$v.address.$model = point.address
      this.localAttraction.address = this.address
      this.place.lat = point.lat
      this.place.lon = point.lon
      this.$v.address.$touch()
      this.$v.place.$touch()
    }
  }
}
</script>

<style lang="scss">
.attractions-create-modal {
  width: 100%;
  padding-top: 32px;
  position: relative;
  background: #fff;

  &__radio {
    align-items: flex-start;
    margin-bottom: 14px !important;
  }

  &__input {
    width: 100% !important;

    &--search {
      background-image: url('~/assets/img/svg/search.svg');
      background-repeat: no-repeat;
      background-position: right 15px center;
      background-size: 20px;
    }
  }

  &__select--error {
    .selection__preview {
      border-color: $red !important;
      background-color: $red-light !important;
    }
  }

  &__description {
    height: 170px;
  }

  &__label {
    margin-bottom: 5px;
    color: #1e2843;
    font-size: 18px;
    font-weight: 600;
  }

  &__field {
    margin-bottom: 44px;
    max-width: 750px;
  }

  &__duration {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }

  &__duration-label {
    margin: 0 14px;
  }

  &__duration-input {
    margin-top: 0 !important;
    width: 64px !important;

    input {
      margin-top: 0 !important;
    }

    &--right {
      input {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }

  &__duration-select {
    width: 115px;

    .selection__preview {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      margin-left: -1px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}
</style>
