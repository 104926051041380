var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "edit-price-pop-up" },
        [
          _c("price-option", {
            attrs: {
              "is-edit": true,
              "price-option": _vm.priceOption,
              translations: _vm.translations,
              tour: _vm.tour,
              step: 0,
            },
            on: { back: _vm.close, save: _vm.save, close: _vm.close },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("no-members", {
        attrs: {
          translations: _vm.translations,
          "no-members": _vm.noMembers,
          slug: _vm.tour.slug,
        },
        on: { close: _vm.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }