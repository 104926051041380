var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c(
        "div",
        { staticClass: "edit-option-pop-up" },
        [
          _c("AddAttributes", {
            attrs: {
              attributes: _vm.attributes,
              "options-product-details": _vm.options,
              translations: _vm.translations,
              tour: _vm.tour,
              "text-btn-save": _vm.translations.saveBtn.text,
            },
            on: { save: _vm.save, close: _vm.close, back: _vm.close },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }