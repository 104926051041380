<template>
  <VDialog v-model="modelVisible">
    <div class="add-schedule">
      <div class="add-schedule__content">
        <div class="add-schedule__step">{{ parseTranslate(pricesTranslate.stepOf.text, step, '3') }}</div>
        <AddAttributes
          v-if="step == 1"
          :attributes="attributes"
          :options-product-details="options"
          :translations="translations"
          :tour="tour"
          :step="3"
          :text-btn-save="translations.nextBtn.text"
          @back="close"
          @save="saveAttributes"
          @close="close"
        />
        <pricing-schedule
          v-else-if="step == 2"
          :translations="scheduleTranslate"
          :tour="tour"
          :tour-lang="tourLang"
          :improve-options-translate="improveOptionsTranslate"
          :step="3"
          :schedule-pricing="schedule"
          @back="backSchedule"
          @save="saveSchedule"
          @close="close"
        />
        <price-option
          v-else-if="step == 3"
          :price-option="priceOption"
          :translations="pricesTranslate"
          :tour="tour"
          :step="3"
          @back="prevStep"
          @save="save"
          @close="close"
        />
      </div>
    </div>
    <no-members :translations="pricesTranslate" :no-members="noMembers" :slug="tour.slug" @close="close" />
  </VDialog>
</template>

<script>
import { mapState } from 'vuex';
import { mapMembers, getAvailableDays, getAvailableOpeningTimes } from '@/utils/price';
import { parseTranslate } from '@/utils/translations';
import { format } from '@/utils/date';
import VDialog from '@/components/common/VDialog.vue';
import AddAttributes from '@/components/price/AddAttributes.vue';
import PricingSchedule from '@/components/price/PricingSchedule.vue';
import PriceOption from '@/components/price/PriceOption.vue';
import NoMembers from '@/components/price/NoMembers.vue';

export default {
  components: {
    VDialog,
    AddAttributes,
    PricingSchedule,
    PriceOption,
    NoMembers,
  },
  props: {
    isActive: { type: Boolean, default: false },
    tour: { type: Object, default: () => ({}) },
    tourLang: { type: String, default: '' },
    translations: { type: Object, default: () => ({}) },
    scheduleTranslate: { type: Object, default: () => ({}) },
    pricesTranslate: { type: Object, default: () => ({}) },
    improveOptionsTranslate: { type: Object, default: () => ({}) },
  },
  data: () => ({
    step: 1,

    attributes: {
      optionName: '',
      hideLanguages: true,
      durationName: '',
      optionPickupIncluded: true,
      tourLangs: [],
    },
    options: [],

    schedule: {
      selectedScheduleTypeId: null,
      time1: new Date().toISOString(),
      time2: null,
      endDate: false,
    },

    priceOption: {
      members: [],
      addTimes: false,
      openingHours: false,
      anotherTimes: [{ time: null, is_active: false }],
      openingScheduleTimes: [{ startTime: null, endTime: null }],
      days: [
        { name: 'Mon', day: '1', is_active: false },
        { name: 'Tue', day: '2', is_active: false },
        { name: 'Wed', day: '3', is_active: false },
        { name: 'Thu', day: '4', is_active: false },
        { name: 'Fri', day: '5', is_active: false },
        { name: 'Sat', day: '6', is_active: false },
        { name: 'Sun', day: '7', is_active: false },
      ],
      activeDaysCount: 0,
      allDaysChecked: false,
      tieredPricing: false,
    },

    noMembers: false,
    lang: '',
  }),
  async fetch() {
    this.attributes.tourLangs = this.tour.languages;
    for (const i in this.attributes.tourLangs) {
      this.attributes.tourLangs[i].is_active = true;
    }
    this.lang = this.$cookies.get('i18n_redirected');
    const productDetails = await this.$axios.$get(
      `/api/v1/get-option-additional-info-types/?language=${this.tourLang}`
    );

    this.options = productDetails.map((productDetail) => {
      return {
        id: productDetail.id,
        title: productDetail.title,
        is_active: false,
        name: '',
        description: '',
      };
    });

    this.priceOption.members = await this.$axios.$get(
      `/api/v1/get-tour-members/?tour_id=${this.tour.id}&language=${this.tourLang}`
    );
    this.priceOption.members.sort(function (a, b) {
      return b.max_age - a.max_age;
    });
    if (this.priceOption.members.length !== 0) {
      this.priceOption.members = mapMembers(
        this.priceOption.members,
        this.tour.is_group_pricing,
        this.tour.max_travelers,
        await this.$axios.$get(`/api/v1/pages/lk-popup-prices?language=${this.tourLang}`),
      )
    } else {
      this.noMembers = true;
    }
  },
  computed: {
    ...mapState(['userInfo']),

    modelVisible: {
      get() {
        return this.isActive;
      },
      set(visible) {
        this.$emit('update:isActive', visible);
      },
    },
  },

  methods: {
    getAvailableDays,
    getAvailableOpeningTimes,
    format,
    parseTranslate,

    close() {
      this.modelVisible = false;
    },

    prevStep() {
      this.step -= 1;
    },

    nextStep() {
      this.step += 1;
    },

    saveAttributes(data) {
      this.attributes = data;
      this.nextStep();
    },
    saveSchedule(data) {
      this.schedule = data.schedule;
      this.priceOption.openingHours = data.openingHours;
      this.priceOption.addTimes = data.addTimes;
      this.nextStep();
    },
    backSchedule(data) {
      this.prevStep();
      this.schedule = data;
    },
    async save(data) {
      this.priceOption = data;
      this.$emit('loading');

      let activeOptionLang = '';
      let tourLangs = await this.$axios.$get(`/api/v1/catalog-filter?language=${this.lang}`);
      tourLangs = tourLangs.languages;
      for (const k in this.tour.languages) {
        for (const i in tourLangs) {
          if (this.tour.languages[k].is_active) {
            if (this.tour.languages[k].language === tourLangs[i].language) {
              activeOptionLang += `${tourLangs[i].id},`;
            }
          }
        }
      }
      activeOptionLang = activeOptionLang.substring(0, activeOptionLang.length - 1);

      const newOption = await this.$axios.$post(`/api/v1/add-tour-schedule-option/`, null, {
        params: {
          name: this.attributes.optionName,
          tour_id: this.tour.id,
          languages: activeOptionLang,
          language: this.tour.input_language.abbreviation.toLowerCase(),
          is_pick_up_included: this.attributes.optionPickupIncluded,
        },
      });
      for (const i in this.options) {
        if (this.options[i].is_active) {
          await this.$axios.$post(`/api/v1/act-option-additional-info/`, null, {
            params: {
              option_id: newOption.id,
              type_id: this.options[i].id,
              name:
                this.options[i].id === 4
                  ? `${this.attributes.durationTime.toString()} ${this.attributes.durationName}`
                  : this.options[i].name,
              description: this.options[i].description,
            },
          });
        }
      }

      const newTourSchedule = await this.$axios.$post(`/api/v1/create-tour-schedule/`, null, {
        params: {
          language: this.tour.input_language.abbreviation.toLowerCase(),
          tour_id: this.tour.id,
          option_id: newOption.id,
          'start-date': this.format(this.schedule.time1, 'dd.MM.yyyy'),
          'end-date':
            this.schedule.time2 == null || !this.schedule.endDate
              ? '02.02.2222'
              : this.format(this.schedule.time2, 'dd.MM.yyyy'),
          'schedule-type-id': this.schedule.selectedScheduleTypeId,
        },
      });
      // edit schedule (add title and max travelers)
      await this.$axios.$put(`/api/v1/edit-tour-schedule/`, null, {
        params: {
          schedule_id: newTourSchedule.id,
          title: newOption.name,
        },
      });
      // Week
      const weekSchedule = await this.$axios.$put(`/api/v1/set-week-schedule/`, null, {
        params: {
          schedule_id: newTourSchedule.id,
          available_time: this.getAvailableOpeningTimes(
            this.priceOption.openingHours,
            this.priceOption.addTimes,
            this.priceOption.openingScheduleTimes,
            this.priceOption.anotherTimes
          ),
          weekdays: this.getAvailableDays(this.priceOption.days),
        },
      });
      if (this.tour.is_group_pricing) {
        // Grope price
        await this.$axios.$put(`/api/v1/set-week-schedule/`, null, {
          params: {
            week_schedule_id: weekSchedule.id,
            max_travelers: this.priceOption.members[0].groupCount,
          },
        });
        if (this.tour.travelers.length === 0) {
          const getGroupMembers = await this.$axios.$get(
            `/api/v1/get-members/?tour_id=${this.tour.id}&language=${this.lang}`
          );
          await this.$axios.$post(`/api/v1/add-tour-member/`, null, {
            params: {
              tour_id: this.tour.id,
              age_category_id: getGroupMembers[0].id,
              min_age: 1,
              max_age: 100,
            },
          });
        }
        await this.$axios.$post(`/api/v1/add-tour-schedule-member/`, null, {
          params: {
            week_schedule_id: weekSchedule.id,
            member_id: this.priceOption.members[0].id,
            price: this.priceOption.members[0].price,
          },
        });
      } else if (!this.priceOption.tieredPricing) {
        // Person price
        for (const i in this.priceOption.members) {
          if (this.priceOption.members[i].is_active) {
            this.$axios.$post(`/api/v1/add-tour-schedule-member/`, null, {
              params: {
                week_schedule_id: weekSchedule.id,
                member_id: this.priceOption.members[i].id,
                price: this.priceOption.members[i].price,
              },
            });
          }
        }
      } else {
        // Person price tried
        for (const i in this.priceOption.members) {
          if (this.priceOption.members[i].is_active) {
            for (const ti in this.priceOption.members[i].ageTieredGroups) {
              this.$axios.$post(`/api/v1/add-tour-schedule-member/`, null, {
                params: {
                  week_schedule_id: weekSchedule.id,
                  member_id: this.priceOption.members[i].id,
                  price: this.priceOption.members[i].ageTieredGroups[ti].price,
                  count_to: this.priceOption.members[i].ageTieredGroups[ti].maxAge,
                  count_from: this.priceOption.members[i].ageTieredGroups[ti].minAge,
                },
              });
            }
          }
        }
      }
      this.$router.go(this.localePath('/'));
    },
  },
};
</script>

<style lang="scss">
.add-schedule {
  position: relative;
  background: $white;
  width: 800px;
  &__step {
    margin-bottom: 2px;
  }
  &__title {
    margin-bottom: 23px;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
  }
  &__content-title {
    margin-bottom: 10px;
    font-weight: bold;
  }
  .error-box {
    border: 1px solid #d80007 !important;
    background: #ffe2e3 !important;
    border-radius: 6px;
    padding: 10px;
  }
}
</style>
