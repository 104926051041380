<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" fill="none">
    <path
      fill="currentColor"
      stroke="#fff"
      d="M6.158 10.636 2.694 7.17a.979.979 0 1 0-1.386 1.386l4.158 4.158a.978.978 0 0 0 1.385 0l4.158-4.158A.979.979 0 1 0 9.624 7.17l-3.466 3.466Z"
    />
    <path
      fill="currentColor"
      stroke="#fff"
      d="M1.308 1.287a.977.977 0 0 0 0 1.386l4.158 4.158a.977.977 0 0 0 1.385 0l4.158-4.158a.979.979 0 1 0-1.385-1.386L6.158 4.753 2.694 1.287a.98.98 0 0 0-1.386 0Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconChevronDoubleDown'
}
</script>
