var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "pop-up-improve-options" }, [
          _c(
            "div",
            { staticClass: "pop-up__close", on: { click: _vm.closeWindow } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/close.svg"),
                  alt: "close",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pop-up-improve-options__content" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.translations.title.text) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "description" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.translations.subtitle.text) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("VTextarea", {
                attrs: { "max-length": 500, rows: "3" },
                model: {
                  value: _vm.message,
                  callback: function ($$v) {
                    _vm.message = $$v
                  },
                  expression: "message",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pop-up-improve-options__nav" },
            [
              _c("v-btn", {
                attrs: { outline: "", name: _vm.translations.backBtn.text },
                on: { click: _vm.closeWindow },
              }),
              _vm._v(" "),
              _c("v-btn", {
                attrs: {
                  base: "",
                  name: _vm.translations.sendBtn.text,
                  loading: _vm.loading,
                  "loading-name": "Saved",
                },
                on: {
                  click: function ($event) {
                    return _vm.sendFeedback()
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }