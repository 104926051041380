<template>
  <VDialog v-if="shortform" v-model="modelVisible" class="popup-cancel-booking" :title="translations.bookingConfirmOrCancelConfirmedTitle.text" size="large">
    <div class="popup-cancel-booking__content">
      <div class="popup-cancel-booking__field">
          {{ translations.bookingConfirmOrCancelConfirmedText.text }}
        </div>
    </div>
    <template #footer>
      <div class="popup-cancel-booking__footer">
        <VButton orange-outline @click="emptySave">{{ translations.bookingConfirmationCancel.text }}</VButton>
      </div>
    </template>
  </VDialog>
  <VDialog v-else v-model="modelVisible" class="popup-cancel-booking" :title="translations.titleCancelBooking.text" size="extra-large">
    <div class="popup-cancel-booking__content">
      <div class="popup-cancel-booking__field">
        <label class="popup-cancel-booking__label">{{ translations.labelReasonForCancelling.text }}</label>
        <VSelect
          v-model="$v.selectedReason.$model"
          :error="$v.selectedReason.$error"
          class="popup-cancel-booking__select"
          :items="reasons"
          :translates="reasonsTranslates"
          :placeholder="translations.placeholderReason.text"
        />
        <span v-if="$v.selectedReason.$error && !$v.selectedReason.required" class="error-text">{{ translations.errorRequired.text}}</span>
      </div>

      <div class="popup-cancel-booking__field">
        <label class="popup-cancel-booking__label">{{ translations.labelCancellationRequested.text }}</label>
        <VRadioGroup v-model="isRequested" name="refundRequested">
          <template #default="{ on, attrs }">
            <VRadioButton v-bind="attrs" :label="translations.refoundPopUpYes.text" :value="true" v-on="on" />
            <VRadioButton v-bind="attrs" :label="translations.refoundPopUpNo.text" :value="false" v-on="on" />
          </template>
        </VRadioGroup>
      </div>

      <div class="popup-cancel-booking__field">
        <label class="popup-cancel-booking__label">{{ translations.labelWhoCancelled.text }}</label>
        <VInput
          v-model="$v.name.$model"
          :error="$v.name.$error"
          class="popup-cancel-booking__input"
          :placeholder="translations.placeholderName.text"
        />
        <span v-if="$v.name.$error && !$v.name.required" class="error-text">{{ translations.errorRequired.text}}</span>
      </div>

      <p class="popup-cancel-booking__note">
        {{ translations.noteCancellation.text }}
      </p>
    </div>

    <template #footer>
      <div class="popup-cancel-booking__footer">
        <VButton outline @click="cancel">{{ translations.buttonCancel.text }}</VButton>
        <VButton base @click="save">{{ translations.buttonCancelBooking.text }}</VButton>
      </div>
    </template>
  </VDialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import VDialog from '@/components/common/VDialog.vue'
import VSelect from '@/components/controls/VSelect.vue'
import VRadioButton from '@/components/controls/VRadioButton.vue'
import VRadioGroup from '@/components/controls/VRadioGroup.vue'
import VInput from '@/components/controls/VInput.vue'
import VButton from '@/components/controls/VButton.vue'

export default {
  name: 'PopupCancelBooking',
  components: { VDialog, VSelect, VRadioButton, VRadioGroup, VInput, VButton },

  model: {
    prop: 'visible',
    event: 'update:visible'
  },

  props: {
    visible: { type: Boolean, default: false },
    translations: { type: Object, default: () => ({}) },
    shortform: {type: Boolean, default: false}
  },

  data() {
    return {
      selectedReason: '',
      isRequested: false,
      name: '',
      reasons: [],
      reasonsTranslates: {}
    }
  },

  validations() {
    return {
      selectedReason: { required },
      name: { required }
    }
  },

  computed: {
    modelVisible: {
      get() {
        return this.visible
      },
      set(visible) {
        this.$emit('update:visible', visible)
      }
    }
  },

  watch: {
    modelVisible() {
      if (this.modelVisible) {
        this.resetModal()
      }
    }
  },

  mounted() {
    this.fetchReasons()
  },

  methods: {
    cancel() {
      this.modelVisible = false
    },

    save() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.$emit('save', { reason: this.selectedReason, name: this.name, isRequested: this.isRequested })
    },
    emptySave() {
      this.$emit('save', { reason: this.selectedReason, name: this.name, isRequested: this.isRequested })
    },

    resetModal() {
      this.name = ''
      this.selectedReason = ''
      this.isRequested = false
      this.$v.$reset()
    },

    fetchReasons() {
      const language = this.$cookies.get('i18n_redirected')
      return this.$axios.$get('/api/v1/show-cancellation-reasons/', { params: { language, role: 'seller' }})
        .then(reasons => {

          this.reasons = reasons.map(reason => {
            this.reasonsTranslates[reason.id] = reason.name
            return {
              ...reason,
              name: reason.id,
              transation: reason.name
            }
          })
        })
    }
  }
}
</script>

<style lang="scss">
.popup-cancel-booking {
  &__content {
    max-width: 617px;
    padding-top: 30px;
  }

  &__input {
    margin: 0;
  }

  &__select {
    .selection__preview {
      width: 100%;
    }
  }

  &__label {
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    margin-bottom: 15px;
    display: block;
  }

  &__field {
    margin-bottom: 30px;
  }

  &__note {
    font-size: 14px;
    line-height: 16px;
    color: #999999;
    font-weight: 600;
    margin-bottom: 30px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}
</style>
