var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-pagination" }, [
    _c(
      "button",
      {
        staticClass: "v-pagination__button",
        attrs: { disabled: _vm.disablePrev },
        on: { click: _vm.prevPage },
      },
      [
        _c("IconArrowLeft", { staticClass: "v-pagination__icon" }),
        _vm._v(_vm._s(_vm.translates.previousButton.text) + "\n  "),
      ],
      1
    ),
    _vm._v(" "),
    _c("span", { staticClass: "v-pagination__page" }, [
      _vm._v(_vm._s(_vm.value)),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "v-pagination__button",
        attrs: { disabled: _vm.disableNext },
        on: { click: _vm.nextPage },
      },
      [
        _vm._v("\n    " + _vm._s(_vm.translates.nextButton.text) + " "),
        _c("IconArrowLeft", {
          staticClass: "v-pagination__icon v-pagination__icon--next",
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }