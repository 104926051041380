<template>
  <div v-if="covidSecurity.length || covidRequirements.length" id="covid-info" class="preview__section">
    <div class="preview__title">
      {{ translations.textCovidSI.text }}
    </div>
    <div class="preview__subtitle">
      {{ translations.textMeasures.text }}
    </div>
    <template v-if="tourCovidSecurity.more">
      <div v-for="security in tourCovidSecurity.start" :key="`covid-${security.id}`" class="preview__include">
        <div class="preview__dot preview__dot--green" />
        {{ security.text }}
      </div>
      <span v-if="!showTourCovidSecurity" class="preview__read-more" @click="showTourCovidSecurity = true">{{ translations.textReadMore.text }}</span>
      <template v-if="showTourCovidSecurity">
        <div v-for="security in tourCovidSecurity.more" :key="`covid-${security.id}`" class="preview__include">
          <div class="preview__dot preview__dot--green" />
          {{ security.text }}
        </div>
        <span class="preview__read-more" @click="showTourCovidSecurity = false">{{ translations.textHide.text }}</span>
      </template>
    </template>
    <template v-else>
      <div v-for="security in tourCovidSecurity" :key="`covid-${security.id}`" class="preview__include">
        <div class="preview__dot preview__dot--green" />
        {{ security.text }}
      </div>
    </template>
    <div class="preview__subtitle">
      {{ translations.textTravellersRequirements.text }}
    </div>
    <template v-if="tourCovidRequirements.more">
      <div v-for="requirement in tourCovidRequirements.start" :key="`requirements-${requirement.id}`" class="preview__include">
        <div class="preview__dot preview__dot--orange" />
        {{ requirement.text }}
      </div>
      <span v-if="!showTourCovidRequirements" class="preview__read-more" @click="showTourCovidRequirements = true">{{ translations.textReadMore.text }}</span>
      <template v-if="showTourCovidRequirements">
        <div v-for="requirement in tourCovidRequirements.more" :key="`requirements-${requirement.id}`" class="preview__include">
          <div class="preview__dot preview__dot--orange" />
          {{ requirement.text }}
        </div>
        <span class="preview__read-more" @click="showTourCovidRequirements = false">{{ translations.textHide.text }}</span>
      </template>
    </template>
    <template v-else>
      <div v-for="requirement in tourCovidRequirements" :key="`requirements-${requirement.id}`" class="preview__include">
        <div class="preview__dot preview__dot--orange" />
        {{ requirement.text }}
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CovidPreview',
  props: {
    covidSecurity: { type: Array, default: () => [] },
    covidRequirements: { type: Array, default: () => [] },
    translations: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      showTourCovidSecurity: false,
      showTourCovidRequirements: false
    }
  },
  computed: {
    tourCovidSecurity () {
      return this.covidSecurity?.length > 4
        ? { start: this.covidSecurity.slice(0, 4), more: this.covidSecurity.slice(4) }
        : this.covidSecurity
    },
    tourCovidRequirements () {
      return this.covidRequirements?.length > 4
        ? { start: this.covidRequirements.slice(0, 4), more: this.covidRequirements.slice(4) }
        : this.covidRequirements
    }
  }
}
</script>

<style lang="scss">
</style>
