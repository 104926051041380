export function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]'
}

export function snakeToCamel(str) {
  return str.replace(/([-_][a-z])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', '')
  )
}

export function snakeCaseKeysToCamelCase(value) {
  if (Array.isArray(value)) {
    return value.map(snakeCaseKeysToCamelCase)
  }

  if (isObject(value)) {
    return Object.entries(value).reduce((result, [key, keyValue]) => {
      const newKey = snakeToCamel(key)
      const newKeyValue = snakeCaseKeysToCamelCase(keyValue)
      result[newKey] = newKeyValue
      return result
    }, {})
  }

  return value
}

export function camelToSnake(str) {
  return str?.replace(/([A-Z])/g, function ($1) {
    return '_' + $1.toLowerCase()
  })
}

export function camelCaseKeysToSnakeCase(value) {
  if (Array.isArray(value)) {
    return value.map(camelCaseKeysToSnakeCase)
  }

  if (isObject(value)) {
    return Object.entries(value).reduce((result, [key, keyValue]) => {
      const newKey = camelToSnake(key)
      const newKeyValue = camelCaseKeysToSnakeCase(keyValue)
      result[newKey] = newKeyValue
      return result
    }, {})
  }

  return value
}

export function middleTruncate(string, { left = 4, right = 6, separator } = {}) {
  if (!string || string.length <= left + right) {
    return string
  }

  const originSeparator = separator || '...'

  return string.substring(0, left) + originSeparator + string.substring(right)
}

export function addSpaces(string) {
  const r = string.match(/.{1,4}/g);
  return r.join(' ');
}
