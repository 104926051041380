<template>
  <nuxt-link
    v-if="to"
    :to="to"
    class="v-button"
    :class="setClass"
    :disabled="loading || disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div v-if="loading" class="v-button__loader" />
    <slot v-else />
  </nuxt-link>
  <button
    v-else
    class="v-button"
    :class="setClass"
    :type="type"
    :disabled="loading || disabled"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div v-if="loading" class="v-button__loader" />
    <slot v-else />
  </button>
</template>

<script>
export default {
  name: 'VButton',
  props: {
    to: { type: [String, Object], default: '' },
    type: { type: String, default: 'button' },
    disabled: { type: Boolean, default: false },
    outline: { type: Boolean, default: false },
    base: { type: Boolean, default: false },
    green: { type: Boolean, default: false },
    text: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    orangeOutline: { type: Boolean, default: false }
  },
  computed: {
    setClass() {
      return {
        'v-button--base': this.base,
        'v-button--outline': this.outline,
        'v-button--green': this.green,
        'v-button--text': this.text,
        'v-button--orange-outline': this.orangeOutline
      }
    }
  }
}
</script>

<style lang="scss">
.v-button {
  position: relative;
  font-family: 'Cabin', sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  border: none;
  padding: 0 10px;
  min-width: 100px;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 21px;
  transition: 0.2s ease;
  color: $white;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  &:active {
    opacity: 1;
  }

  &--outline {
    background: $white;
    border: 2px solid $primary;
    color: $text-primary;
    &:hover {
      background: $primary;
      color: $white;
    }
  }

  &--orange-outline {
    background: $white;
    border: 2px solid $orange;
    color: $text-primary;
    &:hover {
      background: $orange;
      color: $white;
    }
  }

  &--base {
    background: $primary;
    color: $white;
  }

  &--green {
    background: $light-green-gradient;
  }

  &--text {
    background: transparent;
    border: none;
    color: $orange;
  }

  &__loader {
    width: 25px;
    height: 25px;
    border: 4px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    animation: rotation 1s linear infinite;
  }
}
</style>
