<template>
  <VDialog v-model="modelVisible" :title="fields.title.text">
    <div class="pop-up-license-information">
      <div class="form">
        <div class="form__have-license">
          <div class="pop-up-license-information__subtitles">
            {{ fields.contentHaveLicense.text }}
          </div>
          <div class="have-license__radioButtons">
            <div
              class="have-license__radioButtons__radio"
              @click="changeHaveLicense(true)"
            >
              <RadioButton
                default
                radio-name="have-license"
                :name="fields.yesBtn.text"
                :checked="newLicenseInformation.haveLicense"
              />
            </div>
            <div
              class="have-license__radioButtons__radio"
              @click="changeHaveLicense(false)"
            >
              <RadioButton
                default
                radio-name="have-license"
                :name="fields.noBtn.text"
                :checked="!newLicenseInformation.haveLicense"
              />
            </div>
          </div>
        </div>
        <div
          v-if="newLicenseInformation.haveLicense"
          class="form__registration-number"
        >
          <div class="pop-up-license-information__subtitles">
            {{ fields.contentNumber.text }}
          </div>
          <div class="form__registration-number__hint">
            {{ fields.contentNumberSubtitle.text }}
          </div>
          <VInput
            v-model.trim="$v.newLicenseInformation.registrationNumber.$model"
            class="form__registration-number__input"
            :error="$v.newLicenseInformation.registrationNumber.$error"
            error-message="This field can not be empty"
            :placeholder="fields.contentNumberPlaceholder.text"
            :max-length="40"
            hide-counter
          />
        </div>
        <div
          v-if="newLicenseInformation.haveLicense"
          class="form__expire"
        >
          <div
            class="pop-up-license-information__subtitles"
          >
            {{ fields.contentHaveLicenseExpire.text }}
          </div>
          <div class="form__expire__radioButtons">
            <div
              class="form__expire__radioButtons__radio"
            >
              <RadioButton
                default
                radio-name="expire"
                :name="fields.yesBtn.text"
              />
            </div>
            <div
              class="form__expire__radioButtons__radio"
            >
              <RadioButton
                default
                radio-name="expire"
                :name="fields.noBtn.text"
                checked
              />
            </div>
          </div>
        </div>
        <div
          v-if="newLicenseInformation.haveLicense"
          class="form__upload"
        >
          <div class="pop-up-license-information__subtitles">
            {{ fields.contentLicense.text }}
          </div>
          <VFileInput
            v-if="!licenseFile || !licenseFile.name"
            :accept="$options.INPUT_ACCESS_FILES"
            :error="$v.licenseFile.$error"
            @change="uploadFile"
          >
            <div>
              <div class="form__upload__file__text">
                {{ fields.uploadLicense.text }}
              </div>
              <div class="form__upload__file__hint">
                {{ fields.contentInsurancePlaceholderSize.text }}
              </div>
            </div>
          </VFileInput>
          <div v-else class="form__upload__file form__upload__file--with-file">
            <div class="form__upload__file__name">
              {{ licenseFile.name }}
            </div>
            <div class="form__upload__file__delete-icon" @click="deleteLicense">
              <img src="@/assets/img/svg/delete-icon.svg" alt="Delete insurance" />
            </div>
          </div>
          <div v-if="$v.licenseFile.$error" class="error-text" style="padding: 6px; margin-top: -4px">
            <template v-if="!$v.licenseFile.required">{{ fields.errorFileEmpty.text }}</template>
            <template v-else-if="!$v.licenseFile.maxFileSize">{{ fields.errorFileSize.text }}</template>
            <template v-else-if="!$v.licenseFile.fileFormat">{{
              `${fields.errorFileFormat.text} (${$options.INPUT_ACCESS_FILES})`
            }}</template>
          </div>
        </div>
      </div>
      <div class="actions">
        <div @click="closePopup">
          <Btn outline :name="fields.cancelBtn.text" class="actions__button" />
        </div>
        <div @click="saveLicense">
          <Btn base :name="fields.saveBtn.text" class="actions__button" />
        </div>
      </div>
    </div>
  </VDialog>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import Btn from "@/components/controls/Btn"
import RadioButton from '@/components/controls/RadioButton'
import VInput from '@/components/controls/VInput'
import VFileInput from '@/components/controls/VFileInput.vue'
import VDialog from '@/components/common/VDialog.vue'

const MEGABYTE_IN_BYTES = 1000000
const MAX_FILE_SIZE = 5 * MEGABYTE_IN_BYTES
const ACCESS_FILES = ['image/jpeg', 'image/png', 'application/pdf']
const INPUT_ACCESS_FILES = '.jfif, .jpj, .jpg, .jpeg, .pjpeg, .pdf, .png'

export default {
  components: {
    VInput,
    RadioButton,
    Btn,
    VFileInput,
    VDialog
  },
  props: {
    open: { type: Boolean, default: false },
    fields: { type: Object, default: () => ({}) },
    license: { type: Object, default: null }
  },
  INPUT_ACCESS_FILES,

  data() {
    return {
      newLicenseInformation: {
        haveLicense: null,
        copyOfLicense: null,
        registrationNumber: null,
      },
      licenseFile: null,
    }
  },

  computed: {
    modelVisible: {
      get() {
        return this.open
      },
      set(value) {
        this.$emit('update:open', value)
      }
    }
  },

  validations() {
    return {
      newLicenseInformation: {
        registrationNumber: { required }
      },
      licenseFile: {
        required: requiredIf(() => !this.newLicenseInformation.copyOfLicense),
        maxFileSize: this.maxFileSize(MAX_FILE_SIZE),
        fileFormat: this.isFileFormat(ACCESS_FILES)
      }
    }
  },

  watch: {
    open() {
      if (this.open) {
        this.newLicenseInformation = {
          ...this.license,
          copyOfLicense: this.license.copyOfLicense?.split('uploads/')[1],
        }

        if (this.newLicenseInformation.copyOfLicense) {
          this.licenseFile = { name: this.newLicenseInformation.copyOfLicense }
        }

      }
    }
  },

  methods: {
    uploadFile(file) {
      this.licenseFile = file
      this.$v.licenseFile.$touch()
    },
    deleteLicense() {
      this.licenseFile = ''
      this.newLicenseInformation.copyOfLicense = ''
      this.$v.licenseFile.$touch()
    },
    maxFileSize(maxSize) {
      return value => (value?.size ? value?.size < maxSize : true)
    },
    isFileFormat(accessFileTypes) {
      return value => (value?.type ? accessFileTypes.includes(value?.type) : true)
    },
    closePopup() {
      this.$emit('close')
      this.$v.$reset()

      this.newLicenseInformation.haveLicense = this.license.haveLicense
      this.newLicenseInformation.registrationNumber = this.license.registrationNumber
      this.licenseFile = this.license.copyOfLicense?.split('uploads/')[1]
    },
    changeHaveLicense(newValue) {
      this.newLicenseInformation.haveLicense = newValue
    },

    checkValidate () {
      if (!this.newLicenseInformation.haveLicense) {
        return true
      }

      this.$v.$touch()
      return !this.$v.$invalid
    },

    async saveLicense() {
      if (this.checkValidate()) {
        const bodyFormData = new FormData()
        if (this.newLicenseInformation.haveLicense) {
          bodyFormData.append('license', this.licenseFile || '')

          await this.$axios.$put('/api/v1/seller-setting/', bodyFormData, {
            params: {
              have_license: true,
              registration_number: this.newLicenseInformation.registrationNumber
            }
          })
        } else {
          bodyFormData.append('license', '')

          await this.$axios.$put('/api/v1/seller-setting/', bodyFormData, {
            params: {
              have_license: false,
              registration_number: '',
            }
          })
        }

        this.$emit('close')
        this.$router.go(this.localePath())
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .pop-up-license-information {
    position: relative;
    background: $white;
    padding-top: 48px;
    width: 830px;

    &__subtitles {
      font-size: 18px;
      font-weight: bold;
    }

    & .form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &__have-license,
      .have-license {

        &__radioButtons {
          margin-top: 20px;

          &__radio:not(:first-child) {
            margin-top: 12px;
          }
        }
      }

      &__registration-number {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &__hint {
          font-size: 13px;
        }

        &__input {
          width: 100%;
          margin-top: 0;

          &::v-deep input {
            width: 100%;
            margin-top: 0;
          }
        }
      }

      &__expire {

        &__radioButtons {
          margin-top: 20px;

          &__radio:not(:first-child) {
            margin-top: 12px;
          }
        }
      }

      &__upload {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &__file {

          &--without-file {
            width: 165px;
            height: 114px;
            border: 1px dashed #6E6E6E;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            transition: all .3s;
            text-align: center;

            &:hover,
            &:hover &__text {
              color: $primary;
              cursor: pointer;
              border-color: $primary;
            }
          }

          &--with-file {
            display: flex;
            justify-content: flex-start;
            gap: 5px;
            align-items: center;
          }

          &__text {
            font-weight: bold;
          }

          &__hint {
            font-size: 12px;
          }

          &__name {
            color: $orange;
            word-break: break-word;
            overflow-wrap: anywhere;
          }

          &__delete-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 26px;
            cursor: pointer;
          }
        }
      }
    }

    .actions {
      margin-top: 56px;
      display: flex;
      justify-content: space-between;
      gap: 25px;

      &__button {
        width: 70px;
        height: 40px;
        min-width: 100px;
      }
    }
  }
</style>
