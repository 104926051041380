var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "16",
        height: "17",
        viewBox: "0 0 16 17",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M2 4.64453H3.33333H14",
          stroke: "currentColor",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M5.33398 4.64323V3.3099C5.33398 2.95627 5.47446 2.61714 5.72451 2.36709C5.97456 2.11704 6.3137 1.97656 6.66732 1.97656H9.33398C9.68761 1.97656 10.0267 2.11704 10.2768 2.36709C10.5268 2.61714 10.6673 2.95627 10.6673 3.3099V4.64323M12.6673 4.64323V13.9766C12.6673 14.3302 12.5268 14.6693 12.2768 14.9194C12.0267 15.1694 11.6876 15.3099 11.334 15.3099H4.66732C4.3137 15.3099 3.97456 15.1694 3.72451 14.9194C3.47446 14.6693 3.33398 14.3302 3.33398 13.9766V4.64323H12.6673Z",
          stroke: "currentColor",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M6.66602 7.97656V11.9766",
          stroke: "currentColor",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M9.33398 7.97656V11.9766",
          stroke: "currentColor",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }