var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    { staticClass: "v-checkbox", class: _vm.className },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checkedModel,
            expression: "checkedModel",
          },
        ],
        staticClass: "v-checkbox__input",
        attrs: { type: "checkbox", disabled: _vm.disabled },
        domProps: {
          value: _vm.value,
          checked: Array.isArray(_vm.checkedModel)
            ? _vm._i(_vm.checkedModel, _vm.value) > -1
            : _vm.checkedModel,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.checkedModel,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = _vm.value,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checkedModel = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checkedModel = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checkedModel = $$c
            }
          },
        },
      }),
      _vm._v(" "),
      _vm._t(
        "default",
        function () {
          return [
            _c("span", { staticClass: "v-checkbox__mark" }),
            _vm._v(" "),
            _vm._t("label", function () {
              return [
                _c("span", { staticClass: "v-checkbox__label" }, [
                  _vm._v(_vm._s(_vm.label)),
                ]),
              ]
            }),
          ]
        },
        null,
        { active: _vm.checked, value: _vm.value }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }