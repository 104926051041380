<template>
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0779 4.14589C12.2368 3.98694 12.4255 3.86085 12.6332 3.77483C12.8409 3.68881 13.0635 3.64453 13.2883 3.64453C13.513 3.64453 13.7356 3.68881 13.9433 3.77483C14.151 3.86085 14.3397 3.98694 14.4986 4.14589C14.6576 4.30484 14.7837 4.49354 14.8697 4.70122C14.9557 4.9089 15 5.13148 15 5.35627C15 5.58106 14.9557 5.80365 14.8697 6.01133C14.7837 6.219 14.6576 6.40771 14.4986 6.56666L6.32855 14.7367L3 15.6445L3.90779 12.316L12.0779 4.14589Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconEdit'
}
</script>
