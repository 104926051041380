<template>
    <VDialog v-model="modelActive" :title="translations.bookingAcknowledgeTitle.text" dialog-classes="booking-acknowledge">
      <VField 
        class="booking-acknowledge__field pre-formatted"
        :label="translations.bookingAcknowledgedText.text.trim()">
      </VField>
      <div class="booking-acknowledge__footer">
        <VButton class="booking-acknowledge__button" outline @click="close">{{ translations.buttonCancel.text }}</VButton>
        <VButton class="booking-acknowledge__button" base @click="confirm">{{ translations.buttonSave.text }}</VButton>
      </div>
    </VDialog>
  </template>
    
  <script>
  import VDialog from '@/components/common/VDialog.vue'
  import VButton from '@/components/controls/VButton.vue'
  import VField from '@/components/controls/VField.vue'
  
  export default {
    name: 'BookingAcknowledgeSave',
    components: { VDialog, VButton, VField },
    props: {
      isVisible: { type: Boolean, default: false },
      translations: { type: Object, required: true },
      acknowledge: { type: String, default: '' } 
    },

    emits: ['confirm', 'update:isVisible'],
  
    computed: {
      modelActive: {
        get() {
          return this.isVisible
        },
        set(value) {
          this.$emit('update:isVisible', value)
        }
      }
    },
  
    methods: {
      close() {
        this.modelActive = false
      },
  
      confirm() {
        this.$emit('confirm', this.acknowledge)
      }
    }
  }
  </script>
    
  <style lang="scss">
  .booking-acknowledge {
    width: 100%;
    max-width: 603px;
  
    &__field {
      margin-top: 30px;
    }
  
    .v-input {
      margin-top: 0 !important;
  
      input {
        margin-top: 0 !important;
      }
    }
  
    &__footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 57px;
    }
  
    &__button {
      padding-left: 25px;
      padding-right: 25px;
      height: 49px;
    }
  }
  
  .pre-formatted {
    white-space: pre-line;
  }
  </style>