<template>
  <VDialog v-model="modelVisible">
    <div class="change-pricing-type">
      <div class="change-pricing-type__close" @click="modelVisible = false">
        <img :id="`DelClosePopup`" width="20" height="20" src="@/assets/img/svg/close.svg" alt="close" />
      </div>
      <first-page
        v-if="pages[currentPageIndex] == 0"
        :translations="translations"
        :is-person="personPricing"
        :discount-start-date="tour.discount_info[0]?.discount_start_date"
        :discount-end-date="tour.discount_info[0]?.discount_end_date"
        :current-page-index="currentPageIndex"
        :page-count="pages.length"
        @next="nextPage"
        @close="modelVisible = false"
      />
      <second-page
        v-else-if="pages[currentPageIndex] == 1"
        :translations="translations"
        :is-person="personPricing"
        :members.sync="membersList"
        :group-type-name="groupTypeName"
        :selection-titles="selectionTitles"
        :current-page-index="currentPageIndex"
        :page-count="pages.length"
        @next="goStepPrices"
        @back="prevPage"
      />
      <div v-for="page in allPriceSchedules" :key="page.pageName">
        <div v-if="pages[currentPageIndex] === page.pageName" class="change-pricing-type__third-page">
          <div class="change-pricing-type__title">
            {{ translations.popupPersonThirdPageTitle.text }}
            {{ page.scheduleName }}
          </div>
          <person
            v-if="personPricing"
            :members="page.members"
            :is-tiered="page.isTiered"
            :translations="translations"
            :old-schedule="page.scheduleOrigin"
            :current-page-index="currentPageIndex"
            :page-count="pages.length"
            :is-last-page="isLastPage"
            @next="savePersonPrice"
            @back="backPersonPrice"
          />
          <group
            v-else
            :edit-prices="page.members"
            :old-schedule="page.scheduleOrigin"
            :translations="translations"
            :page-count="pages.length"
            :current-page-index="currentPageIndex"
            :is-last-page="isLastPage"
            :tour="tour"
            @next="saveGroupPrice"
            @back="backGroupPrice"
          />
        </div>
      </div>
    </div>
    <Loader v-if="loading" />
  </VDialog>
</template>

<script>
import { mapState } from 'vuex';
import VDialog from '@/components/common/VDialog.vue';
import FirstPage from '@/components/price/pricingChange/FirstPage.vue';
import SecondPage from '@/components/price/pricingChange/SecondPage.vue';
import Group from '@/components/price/pricingChange/Group.vue';
import Person from '@/components/price/pricingChange/Person.vue';

export default {
  components: {
    VDialog,
    FirstPage,
    SecondPage,
    Group,
    Person,
  },
  props: {
    isActive: { type: Boolean, default: false },
    members: { type: Array, default: () => [] },
    translations: { type: Object, default: () => ({}) },
    tour: { type: Object, default: () => ({}) },
    personPricing: { type: Boolean, default: false },
    selectionTitles: { type: Array, default: () => [] },
    oldSchedule: { type: Array, default: () => [] },
    lang: { type: String, default: '' },
  },
  data: () => ({
    loading: false,
    activePage: 1,
    pageCount: 1,
    editPages: [],
    editGroup: [],
    editPerson: [],
    groupTypeName: '',
    membersPricing: [],
    tieredPricing: [],
    membersList: [],
    allPriceSchedules: [],
    currentPageIndex: 0,
    activeMembers: [],
  }),
  fetch() {
    this.groupTypeName = '';
    this.membersList = this.members;
    this.pageCount = 0;

    this.allPriceSchedules = this.oldSchedule.reduce((schedules, schedule) => {
      return [
        ...schedules,
        ...schedule.price_schedules.map((priceSchedule) => {
          return {
            scheduleName: schedule.title,
            pageName: `${schedule.id}-${priceSchedule.id}`,
            scheduleOrigin: schedule,
            members: [],
            isTiered: false,
            ...priceSchedule,
          };
        }),
      ];
    }, []);
  },
  computed: {
    ...mapState(['userInfo']),

    pages() {
      return [0, 1, ...this.allPriceSchedules.map((price) => price.pageName)];
    },

    isLastPage() {
      return this.currentPageIndex >= this.pages.length - 1;
    },

    modelVisible: {
      get() {
        return this.isActive;
      },
      set(visible) {
        if (!visible) {
          this.resetPages();
        }
        this.$emit('update:isActive', visible);
      },
    },

    needSaveMemberIds() {
      return this.membersList.filter(({ checked }) => checked);
    },
  },

  methods: {
    nextPage() {
      if (this.isLastPage) {
        return;
      }

      this.currentPageIndex += 1;
    },

    prevPage() {
      if (this.currentPageIndex) {
        this.currentPageIndex -= 1;
      }
    },

    goStepPrices(data) {
      if (this.personPricing) {
        const checkedMembers = data.filter((member) => member.checked);

        this.activeMembers = checkedMembers.map((member) => {
          return {
            member,
            is_active: true,
            price: '0',
            feePrice: '0',
            ageTieredGroups: [
              {
                minAge: null,
                maxAge: null,
                price: '0',
                feePrice: '0',
                ageCategoryId: member.id,
              },
            ],
          };
        });

        this.allPriceSchedules = this.allPriceSchedules.map((priceSchedule) => ({
          ...priceSchedule,
          // TODO: save prev members price
          members: this.activeMembers,
        }));
      } else {
        this.allPriceSchedules = this.allPriceSchedules.map((priceSchedule) => ({
          ...priceSchedule,
          // TODO: save prev members price
          members: {
            maxTravelers: 0,
            retailPrice: 0,
            groupFeePrice: 0,
          },
        }));
        this.groupTypeName = data;
      }

      this.nextPage();
    },

    resetPages() {
      this.currentPageIndex = 0;
    },

    backGroupPrice() {
      this.prevPage();
    },

    async saveGroupPrice(groupData) {
      const currentPageName = this.pages[this.currentPageIndex];

      this.allPriceSchedules = this.allPriceSchedules.map((priceSchedule) => {
        if (currentPageName === priceSchedule.pageName) {
          return {
            ...priceSchedule,
            members: groupData,
          };
        }

        return priceSchedule;
      });

      if (!this.isLastPage) {
        this.nextPage();
      } else {
        this.loading = true;
        await this.editTour({ isGroupPricing: true, groupTypeId: this.groupTypeName });

        this.membersPricing = await this.getTourMembers();

        for (let m = 0; m < this.membersPricing.length; m++) {
          if (m !== 0) {
            await this.deleteTourMember({ ageCategoryId: this.membersPricing[m].age_category.id });
          }
        }
        for (const priceSchedule of this.allPriceSchedules) {
          await this.addTourMember({
            tourId: this.tour.id,
            ageCategoryId: this.membersPricing[0].age_category.id,
            minAge: 1,
            maxAge: 100,
          });
          await this.addScheduleMember({
            weekScheduleId: priceSchedule.id,
            memberId: this.membersPricing[0].id,
            price: priceSchedule.members.retailPrice,
          });
          await this.setTourSchedule({
            scheduleId: priceSchedule.id,
            maxTravelers: priceSchedule.members.maxTravelers,
          });
        }
        this.loading = false;
        this.$router.go(this.localePath('/'));
      }
    },

    backPersonPrice(data) {
      this.prevPage();
    },

    async savePersonPrice(data) {
      const currentPageName = this.pages[this.currentPageIndex];
      this.allPriceSchedules = this.allPriceSchedules.map((priceSchedule) => {
        if (currentPageName === priceSchedule.pageName) {
          return {
            ...priceSchedule,
            members: data.prices,
            isTiered: data.tiered,
          };
        }

        return priceSchedule;
      });

      if (!this.isLastPage) {
        this.nextPage();
      } else {
        this.loading = true;
        await this.editTour({ isGroupPricing: false });

        await Promise.all(
          this.needSaveMemberIds.map((member) => {
            return this.addTourMember({
              tourId: this.tour.id,
              ageCategoryId: member.id,
              minAge: member.min_age,
              maxAge: member.max_age,
            });
          })
        );

        const serverMembers = await this.getTourMembers();

        for (const priceSchedule of this.allPriceSchedules) {
          const activeMembers = priceSchedule.members.filter((member) => member.is_active);
          for (const scheduleMember of activeMembers) {
            const savedMember = serverMembers.find((member) => member.age_category.name === scheduleMember.member.name);
            if (savedMember) {
              if (priceSchedule.isTiered) {
                for (const tieredMemberItem of scheduleMember.ageTieredGroups) {
                  await this.addScheduleMember({
                    weekScheduleId: priceSchedule.id,
                    memberId: savedMember.id,
                    price: tieredMemberItem.price,
                    countTo: tieredMemberItem.maxAge,
                    countFrom: tieredMemberItem.minAge,
                  });
                }
              } else {
                await this.addScheduleMember({
                  weekScheduleId: priceSchedule.id,
                  memberId: savedMember.id,
                  price: scheduleMember.price,
                });
              }
            }
          }
        }

        this.$router.go(this.localePath('/'));
        this.loading = false;
      }
    },

    editTour({ isGroupPricing, groupTypeId }) {
      return this.$axios.$put('/api/v1/edit-tour/', null, {
        params: {
          tour_id: this.tour.id,
          group_type_id: groupTypeId,
          is_group_pricing: isGroupPricing,
        },
      });
    },

    getTourMembers() {
      return this.$axios.$get('/api/v1/get-tour-members/', {
        params: {
          tour_id: this.tour.id,
          language: this.lang,
        },
      });
    },

    addTourMember({ tourId, ageCategoryId, minAge, maxAge }) {
      return this.$axios.$post('/api/v1/add-tour-member/', null, {
        params: {
          tour_id: tourId,
          age_category_id: ageCategoryId,
          min_age: minAge,
          max_age: maxAge,
        },
      });
    },

    deleteTourMember({ ageCategoryId }) {
      return this.$axios.$delete(`/api/v1/delete-tour-member/`, {
        params: {
          tour_id: this.tour.id,
          age_category_id: ageCategoryId,
        },
      });
    },

    addScheduleMember({ memberId, weekScheduleId, price, countFrom, countTo }) {
      this.$axios.$post(`/api/v1/add-tour-schedule-member/`, null, {
        params: {
          week_schedule_id: weekScheduleId,
          member_id: memberId,
          count_from: countFrom,
          count_to: countTo,
          price,
        },
      });
    },

    setTourSchedule({ scheduleId, maxTravelers }) {
      return this.$axios.$put('/api/v1/set-week-schedule/', null, {
        params: {
          week_schedule_id: scheduleId,
          max_travelers: maxTravelers,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.change-pricing-type {
  width: 795px;
  position: relative;
  background: $white;
  &__close {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1100;
    filter: brightness(0);
    img {
      width: 24px;
      height: 24px;
    }
  }
  .dot {
    width: 8px;
    height: 8px;
    &__orange {
      width: 8px;
      height: 8px;
    }
  }
  &__nav {
    border-top: 1px solid #ececec;
    padding-top: 23px;
    display: flex;
    justify-content: space-between;
    .v-btn {
      width: 150px;
      min-width: 150px;
    }
  }
  &__error-banner {
    margin-top: 0;
    margin-bottom: 10px;

    &--tiered {
      margin-bottom: 40px;
    }
  }
  &__title {
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
  }
}
</style>
