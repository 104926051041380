<template>
  <VDialog
    v-model="modelVisible"
    size="large-plus"
    :title="translation.verifyEmail.text"
    dialog-classes="pop-up-send-email-code-title"
  >
    <div class="pop-up-send-email-code">
      <div class="pop-up-send-email-code__content">
        <div class="pop-up-send-email-code__title">{{ translation.verifySend.text }}</div>
        <div class="pop-up-send-email-code__title">
          {{ translation.verifyWillBeSent.text }} <b>{{ hiddenEmail }}</b>
          <div class="pop-up-send-email-code__subtitle">{{ translation.verifyThisEmail.text }}</div>
        </div>
      </div>
      <v-btn
        base
        :name="translation.verifySendCode.text"
        :loading="loading"
        loading-name="Sending"
        class="pop-up-send-email-code__send"
        @click="sendEmailCode()"
      />
    </div>
  </VDialog>
</template>

<script>
import { mapState } from 'vuex';
import VDialog from '@/components/common/VDialog.vue';
import VBtn from '@/components/controls/VBtn.vue';

export default {
  components: { VDialog, VBtn },

  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: { type: Boolean, default: false },
    email: { type: String, default: '' },
    translation: { type: Object },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState(['userInfo']),
    modelVisible: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit('update:visible', visible);
      },
    },
    hiddenEmail() {
      return this.email.slice(0, 3) + '******' + this.email.split('@')[1];
    },
  },
  watch: {},
  methods: {
    sendEmailCode() {
      this.$emit('sendEmailCode');
    },
  },
};
</script>

<style lang="scss">
.pop-up-send-email-code-title {
  .v-dialog__title {
    font-size: 32px;
    line-height: 37.5px;
    text-align: start;
  }
}
.pop-up-send-email-code {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 28px 0;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  &__title {
    font-size: 18px;
    line-height: normal;
  }

  &__subtitle {
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    color: #868686;
  }

  &__send {
    margin-left: auto;
  }
}
</style>
