var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { staticClass: "v-radio", class: _vm.containerClasses }, [
    _c(
      "input",
      _vm._b(
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.modelValue,
              expression: "modelValue",
            },
          ],
          staticClass: "v-radio__input",
          class: _vm.classes,
          attrs: { type: "radio", disabled: _vm.disabled },
          domProps: {
            value: _vm.value,
            checked: _vm._q(_vm.modelValue, _vm.value),
          },
          on: {
            change: function ($event) {
              _vm.modelValue = _vm.value
            },
          },
        },
        "input",
        _vm.$attrs,
        false
      )
    ),
    _vm._v(" "),
    _c("span", { staticClass: "v-radio__mark" }),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "v-radio__label" },
      [
        _vm._t("default", function () {
          return [_vm._v(_vm._s(_vm.label))]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }