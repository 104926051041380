<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-office-information">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close">
      </div>
      <div class="pop-up-office-information__title">
        {{ officeTranslate.title.text }}
      </div>
      <div class="pop-up-office-information__content">
        <div class="country">
          <div class="country__title">
            {{ officeTranslate.contentCountry.text }}
          </div>
          <VSelectCountry
            v-model="$v.country.$model"
            :error="$v.country.$error"
            :country="country"
            :country-name="true"
            top-country="ES"
            :usei18n="false"
            :error-message="officeTranslate.сountryInputError.text"
          />
        </div>
        <div class="city">
          <div class="city__title">
            {{ officeTranslate.contentCity.text }}
          </div>
          <input-fields
            v-model.trim="$v.form.city.$model"
            :class="{ 'input-error': $v.form.city.$error }"
            :placeholder="officeTranslate.cityInputPlaceholder.text"
          />
          <div v-if="$v.form.city.$error" class="city_error error-text">
            {{ officeTranslate.cityInputError.text }}
          </div>
        </div>
        <div class="zip">
          <div class="zip__title">
            {{ officeTranslate.contentZip.text }}
          </div>
          <input-fields
            v-model.trim="$v.form.zipCode.$model"
            :class="{ 'input-error': $v.form.zipCode.$error }"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            onpaste="return false;"
            :placeholder="officeTranslate.zipInputPlaceholder.text"
          />
          <div v-if="$v.form.zipCode.$error" class="zip_error error-text">
            {{ officeTranslate.zipInputError.text }}
          </div>
        </div>
        <div class="street-address">
          <div class="street-address__title">
            {{ officeTranslate.contentAddress.text }}
          </div>
          <div class="street-address__input-fields">
            <div class="first-address">
              <input-fields
                v-model.trim="$v.form.address.$model"
                :class="{ 'input-error': $v.form.address.$error }"
                :placeholder="officeTranslate.addressInputPlaceholder1.text"
              />
              <div
                v-if="$v.form.address.$error"
                class="first-address_error error-text"
              >
                {{ officeTranslate.addressInputError.text }}
              </div>
            </div>
            <div class="second-address">
              <input-fields
                v-model.trim="form.secondAddress"
                :placeholder="officeTranslate.addressInputPlaceholder2.text"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="pop-up-office-information__nav">
        <div class="cancel" @click="closeWindow">
          <Btn outline :name="officeTranslate.cancelBtn.text" />
        </div>
        <div class="save" @click="saveOfficeSettings">
          <Btn base :name="officeTranslate.saveBtn.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Btn from '@/components/controls/Btn.vue'
import InputFields from '@/components/controls/Input-fields.vue'
import VSelectCountry from '@/components/controls/VSelectCountry.vue'

export default {
  components: {
    Btn,
    InputFields,
    VSelectCountry
  },
  props: [
    'isActive',
    'nowOfficeCountry',
    'nowOfficeCity',
    'nowOfficeZip',
    'nowOfficeAddress',
    'nowOfficeSecondAddress',
    'officeTranslate'
  ],
  data: () => ({
    country: '',
    form: {
      address: '',
      secondAddress: '',
      city: '',
      zipCode: ''
    }
  }),
  async fetch () {
    this.country = this.nowOfficeCountry
    this.form.secondAddress = this.nowOfficeSecondAddress
    this.form.address = this.nowOfficeAddress
    this.form.city = this.nowOfficeCity
    this.form.zipCode = this.nowOfficeZip
  },
  validations: {
    form: {
      address: { required },
      city: { required },
      zipCode: { required }
    },
    country: { required }
  },
  methods: {
    closeWindow () {
      const active = this.isActive
      this.isFirstPage = true
      this.isSecondPage = false
      this.$emit('close', active)
    },
    saveOfficeSettings () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$axios.$put(
          `/api/v1/seller-setting/?office_country=${this.country}&office_second_address=${this.form.secondAddress}&office_city=${this.form.city}&office_address=${this.form.address}&office_address_code=${this.form.zipCode}`,)
        this.closeWindow()
        this.$router.go(this.localePath())
      }
    }
  }
}
</script>

<style lang="scss">
.pop-up-office-information {
  position: relative;
  background: $white;
  padding: 35px;
  &__title {
    margin-bottom: 22px;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
  &__content {
    margin-bottom: 40px;
    width: 718px;
    .country {
      margin-bottom: 45px;
      &__title {
        margin-bottom: 15px;
        font-weight: bold;
      }
    }
    .zip {
      margin-bottom: 35px;
      &__title {
        margin-bottom: 5px;
        font-weight: bold;
      }
      &__subtitle {
        margin-bottom: 10px;
      }
      .input {
        width: 100%;
      }
      &_error {
        margin-top: 8px;
      }
    }
    .city {
      margin-bottom: 35px;
      &__title {
        margin-bottom: 15px;
        font-weight: bold;
      }
      .input {
        width: 100%;
      }
      &_error {
        margin-top: 8px;
      }
    }
    .street-address {
      &__title {
        margin-bottom: 15px;
        font-weight: bold;
      }
      &__input-fields {
        .first-address {
          margin-bottom: 25px;
          .input {
            width: 100%;
          }
          &_error {
            margin-top: 8px;
          }
        }
        .second-address {
          .input {
            width: 100%;
          }
        }
      }
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ececec;
    padding-top: 21px;
    .btn {
      &_outline {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
      &_base {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
    }
  }
}
</style>
