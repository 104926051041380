var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ticket-paper-preview", style: _vm.cssVars },
    [
      _c("div", { staticClass: "ticket-paper-preview__content" }, [
        _c("div", { staticClass: "voucher" }, [
          _c("div", { staticClass: "ticket" }, [
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "column__content" }, [
                _c("img", {
                  staticClass: "logo",
                  attrs: {
                    src: require("@/assets/img/header/logo.svg"),
                    alt: "logo",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "tour-name" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.tour.name) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _vm.tour.get_tour_schedule.length != 0
                  ? _c("div", { staticClass: "info-list" }, [
                      _c("div", { staticClass: "ticket-paper-preview__info" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/svg/tour-date-gray.svg"),
                            alt: "tour-date",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "ticket-paper-preview__info-text" },
                          [_vm._v(_vm._s(_vm.formattedDate))]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "ticket-paper-preview__info" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/svg/tour-time-gray.svg"),
                            alt: "tour-start-time",
                          },
                        }),
                        _vm._v(" "),
                        _vm.schedule.schedule_type.option ==
                        "coordinated_start_times"
                          ? _c(
                              "div",
                              {
                                staticClass: "ticket-paper-preview__info-text",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.translations.coordinatedStart.text
                                    ) +
                                    ":\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.schedule.schedule_type.option == "opening_hours"
                          ? _c(
                              "div",
                              {
                                staticClass: "ticket-paper-preview__info-text",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.translations.openingHours.text) +
                                    ":\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.formatTime({ time: _vm.time, lang: _vm.lang })
                            ) +
                            "\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "ticket-paper-preview__info" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/svg/tour-duration-gray.svg"),
                            alt: "tour-duration",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "ticket-paper-preview__info-text" },
                          [_vm._v(_vm._s(_vm.durationTitle))]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.language
                        ? _c(
                            "div",
                            { staticClass: "ticket-paper-preview__info" },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/svg/tour-language-gray.svg"),
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "ticket-paper-preview__info-text",
                                },
                                [_vm._v(_vm._s(_vm.language))]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "ticket-paper-preview__info" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/svg/tour-amount-gray.svg"),
                            alt: "tour-members-amount",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "ticket-paper-preview__info-text" },
                          [_vm._v("1 " + _vm._s(_vm.memberAgeCategory))]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "lead-name" }, [
                  _vm._v(_vm._s(_vm.translations.mobileBuyer.text)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "column__content" }, [
                _vm.tour.paper_logo
                  ? _c("img", {
                      staticClass: "supplier-logo",
                      attrs: { src: _vm.tour.paper_logo, alt: "supp-logo" },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "activity-operator" }, [
                  _c("div", { staticClass: "activity-operator__title" }, [
                    _vm._v("Activity operator:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "activity-operator__description" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.tour.seller.company_name) +
                        "\n              "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "column__content--qrcode-box" }, [
                  _vm.tour.ticket_redemption == "direct"
                    ? _c("div", { staticClass: "qrcode" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/svg/qr.svg"),
                            alt: "tour-qrcode",
                          },
                        }),
                      ])
                    : _c("div", { staticClass: "important-info" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "important-info__text important-info__text_important",
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.translations.isNotEntryVoucher.text
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.tour.ticket_redemption === "delivery"
                          ? _c("div", { staticClass: "important-info__text" }, [
                              _vm._v(
                                "\n                  Please present your voucher at " +
                                  _vm._s(_vm.tour.ticket_redemption_address) +
                                  " to receive your entry ticket\n                "
                              ),
                            ])
                          : _c("div", { staticClass: "important-info__text" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.translations.presentVoucher.text) +
                                  "\n                "
                              ),
                            ]),
                      ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column__content--reference-code" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.tour.reference_code) +
                          "\n              "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "booking-code" }, [
                  _c("div", { staticClass: "booking-code__title" }, [
                    _vm._v(_vm._s(_vm.translations.paperCode.text)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "booking-code__description" }, [
                    _vm._v("VD123456789"),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "notification" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.translations.operatorWillCall.text) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "main-content" }, [
            _c("div", { staticClass: "main-content__column" }, [
              _c("div", { staticClass: "info" }, [
                _vm.tour.pick_ups.length > 0
                  ? _c("div", { staticClass: "info__item" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.translations.pickupTime.text)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.parseTranslate(
                                _vm.translations.operatorPickUpTime.text,
                                _vm.betweenDateStart,
                                _vm.tour.get_tour_schedule[0].price_schedules[0]?.available_time[0]?.slice(
                                  0,
                                  5
                                )
                              )
                            ) +
                            "\n              "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.tour.pick_ups.length > 0
                  ? _c("div", { staticClass: "info__item" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.translations.pickupPoint.text)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.tour.pick_ups[0].address) +
                            "\n              "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.tour.meeting_point
                  ? _c("div", { staticClass: "info__item" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.translations.meetingPoint.text)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.tour.meeting_point.address) +
                            "\n              "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.tour.meeting_point
                  ? _c("div", { staticClass: "info__item" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Meeting point details"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.tour.meeting_point.description) +
                            "\n              "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.tour.meeting_point && !_vm.tour.meeting_point.is_end_point
                  ? _c("div", { staticClass: "info__item" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.translations.finalDestination.text)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.tour.end_point.address) +
                            "\n              "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "info__item" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("Final destination details"),
                  ]),
                  _vm._v(" "),
                  _vm.tour.meeting_point == null ||
                  _vm.tour.meeting_point.is_end_point
                    ? _c("div", { staticClass: "description" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.translations.droppedOffSamePlace.text) +
                            "\n              "
                        ),
                      ])
                    : _c("div", { staticClass: "description" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.tour.end_point.description) +
                            "\n              "
                        ),
                      ]),
                ]),
                _vm._v(" "),
                _vm.tour.get_tour_includes.length > 0 ||
                _vm.tour.get_tour_excludes.length > 0
                  ? _c("div", { staticClass: "info__item" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(_vm.translations.includedExcluded.text)),
                      ]),
                      _vm._v(" "),
                      _vm.tour.get_tour_includes.length > 0
                        ? _c("div", { staticClass: "description" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.translations.whatsIncluded.text) +
                                "\n              "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tour.get_tour_includes.length > 0
                        ? _c(
                            "div",
                            { staticClass: "list" },
                            _vm._l(
                              _vm.tour.get_tour_includes,
                              function (include, index) {
                                return _c(
                                  "div",
                                  {
                                    key: `include-${index}`,
                                    staticClass: "list__item",
                                  },
                                  [
                                    _c("div", { staticClass: "dot" }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(include.description) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tour.get_tour_excludes.length > 0
                        ? _c("div", { staticClass: "description" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.translations.whatsExcluded.text) +
                                "\n              "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tour.get_tour_excludes.length > 0
                        ? _c(
                            "div",
                            { staticClass: "list" },
                            _vm._l(
                              _vm.tour.get_tour_excludes,
                              function (exclude, index) {
                                return _c(
                                  "div",
                                  {
                                    key: `exclude-${index}`,
                                    staticClass: "list__item",
                                  },
                                  [
                                    _c("div", { staticClass: "dot" }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(exclude.description) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.tour.tour_leaving && _vm.tour.tour_leaving.length > 0
                  ? _c("div", { staticClass: "info__item" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Before you go"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "description" }, [
                        _c("div", { staticClass: "description__item" }, [
                          _vm._v(_vm._s(_vm.tour.tour_leaving)),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.tour.covid_security.length > 0 ||
                _vm.tour.covid_requirements.length > 0
                  ? _c("div", { staticClass: "info__item" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("COVID-19 Special information"),
                      ]),
                      _vm._v(" "),
                      _vm.tour.covid_security.length > 0
                        ? _c("div", { staticClass: "description" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.translations.securityMeasuresAreTaken.text
                                ) +
                                "\n              "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tour.covid_security.length > 0
                        ? _c(
                            "div",
                            { staticClass: "list" },
                            _vm._l(
                              _vm.tour.covid_security,
                              function (security, index) {
                                return _c(
                                  "div",
                                  {
                                    key: `security-${index}`,
                                    staticClass: "list__item",
                                  },
                                  [
                                    _c("div", { staticClass: "dot" }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(security.text) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tour.covid_requirements.length > 0
                        ? _c("div", { staticClass: "description" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.translations.requirementsForParticipants
                                    .text
                                ) +
                                "\n              "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tour.covid_requirements.length > 0
                        ? _c(
                            "div",
                            { staticClass: "list" },
                            _vm._l(
                              _vm.tour.covid_requirements,
                              function (requirements, index) {
                                return _c(
                                  "div",
                                  {
                                    key: `requirements-${index}`,
                                    staticClass: "list__item",
                                  },
                                  [
                                    _c("div", { staticClass: "dot" }),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(requirements.text) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.getDropOffs.length > 0
                  ? _c("div", { staticClass: "info__item" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Drop off:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "list" },
                        _vm._l(
                          _vm.getDropOffs,
                          function (dropOff, dropOffIndex) {
                            return _c(
                              "div",
                              {
                                key: `drop-off${dropOffIndex}`,
                                staticClass: "list__item",
                              },
                              [
                                _c("div", { staticClass: "dot" }),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "list__item--address" },
                                    [_vm._v(_vm._s(dropOff.address))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "list__item--details" },
                                    [_vm._v(_vm._s(dropOff.details))]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "main-content__column" }, [
              _c("div", { staticClass: "info" }, [
                _c("div", { staticClass: "info__item" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.translations.paperImportant.text)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description" }, [
                    _c("div", { staticClass: "description__item" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.getDownloadVoucher[0]) +
                          "\n\n                  "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.vidodoguide.com/app",
                            target: "_blank",
                          },
                        },
                        [_vm._v("vidodoguide.com/app")]
                      ),
                      _vm._v(
                        "\n\n                  " +
                          _vm._s(_vm.getDownloadVoucher[1]) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description__item" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.translations.emailInfo.text) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description__item" }, [
                      _vm._v(_vm._s(_vm.tour.tour_important_info)),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info__item" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("Activity operator information"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description__item" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v(
                        "This activity is provided by: " +
                          _vm._s(_vm.tour.seller.company_name) +
                          "."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "title" }, [
                      _vm._v(
                        "Contact your activity operator for very urgent or last-minute questions."
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [
                    _c("div", { staticClass: "description__item" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.translations.paperPhone.text) +
                          "\n\n                  "
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: `tel:+${_vm.userInfo.contact_phone}` },
                        },
                        [_vm._v(_vm._s(_vm.userInfo.contact_phone))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description__item" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.translations.paperEmail.text) +
                          "\n\n                  "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `mailTo:${_vm.userInfo.contact_email}`,
                          },
                        },
                        [_vm._v(_vm._s(_vm.userInfo.contact_email))]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info__item" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.translations.bookingManagment.text)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "description" }, [
                    _c("div", { staticClass: "description__item" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.refundData) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "description__item" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.translations.helpPage.text) +
                          " (\n                  "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "https://www.vidodoguide.com/help",
                            target: "_blank",
                          },
                        },
                        [_vm._v(" vidodoguide.com/help ")]
                      ),
                      _vm._v(
                        "\n                  ) -\n                  " +
                          _vm._s(
                            `“${_vm.translations.bookingManagment.text}”`
                          ) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "description__item description__item_booking",
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.translations.paperCode.text) +
                            " "
                        ),
                        _c("b", [_vm._v("VD123456789")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "description__item description__item_pin",
                      },
                      [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.translations.paperPin.text) +
                            " "
                        ),
                        _c("b", [_vm._v("!FD=jsHdb")]),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info__item" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.translations.cancellationPolicy.text)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "list" },
                    _vm._l(_vm.cancellationPolicy, function (policy, index) {
                      return _c(
                        "div",
                        { key: `policy-${index}`, staticClass: "list__item" },
                        [
                          _c("div", { staticClass: "dot" }),
                          _vm._v(
                            "\n                  " +
                              _vm._s(policy) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "info__item" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.translations.contactUs.text)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "title" }, [
                    _c("div", { staticClass: "description__item" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.translations.forGeneralInquiries.text) +
                          "\n                  "
                      ),
                      _c("a", [_vm._v("+34 928 299 630")]),
                      _vm._v(
                        " - " +
                          _vm._s(
                            _vm.translations.duringBusinessHours.text.slice(
                              0,
                              -1
                            )
                          ) +
                          ", email us on\n                  help@vidodoguide.com or message us via WhatsApp +34 676 717 130\n                "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "gift" }, [
                  _c("div", { staticClass: "info" }, [
                    _c("div", { staticClass: "info__name" }, [
                      _vm._v(_vm._s(_vm.translations.yourGift.text) + " "),
                      _c("b", [_vm._v("South Tour")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info__description" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.translations.receiveGiftCode.text) +
                          "\n                "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "info__description" }, [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.translations.downloadAppQr.text) +
                          "\n                "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ticket-paper-preview__map" }, [
            _c("div", { staticClass: "ticket-paper-preview__map--marker" }),
            _vm._v(" "),
            _vm.tour.meeting_point
              ? _c(
                  "div",
                  { staticClass: "ticket-paper-preview__map-address-card" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "ticket-paper-preview__map-address-card--title",
                      },
                      [_vm._v("Meeting point address")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ticket-paper-preview__map-address-card--address",
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.tour.meeting_point.address) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "qrcode" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/svg/qr-site.svg"), alt: "qr-site" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }