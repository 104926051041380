var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: { title: _vm.fields.title.text },
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c("div", { staticClass: "pop-up-insurance-information" }, [
        _c("div", { staticClass: "form" }, [
          _c("div", { staticClass: "form__have-insurance" }, [
            _c(
              "div",
              { staticClass: "pop-up-insurance-information__subtitles" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.fields.contentNavePolicy.text) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "have-insurance__radioButtons" },
              [
                _c("VRadioGroup", {
                  attrs: { name: "havePolicy" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "VRadioButton",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    label: _vm.fields.yesBtn.text,
                                    value: true,
                                  },
                                },
                                "VRadioButton",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                          _vm._v(" "),
                          _c(
                            "VRadioButton",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    label: _vm.fields.noBtn.text,
                                    value: false,
                                  },
                                },
                                "VRadioButton",
                                attrs,
                                false
                              ),
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.newPolicyInformation.havePolicy,
                    callback: function ($$v) {
                      _vm.$set(_vm.newPolicyInformation, "havePolicy", $$v)
                    },
                    expression: "newPolicyInformation.havePolicy",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.newPolicyInformation.havePolicy
            ? _c("div", { staticClass: "form__policy-number" }, [
                _c(
                  "div",
                  { staticClass: "pop-up-insurance-information__subtitles" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.fields.contentNumber.text) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form__policy-number__hint" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.fields.contentNumberSubtitle.text) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form__policy-number__input" },
                  [
                    _c("InputFields", {
                      class: {
                        "input-error":
                          _vm.$v.newPolicyInformation.policyNumber.$error,
                      },
                      attrs: {
                        maxlength: 40,
                        placeholder: _vm.fields.contentNumberPlaceholder.text,
                      },
                      model: {
                        value: _vm.$v.newPolicyInformation.policyNumber.$model,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.$v.newPolicyInformation.policyNumber,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression:
                          "$v.newPolicyInformation.policyNumber.$model",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.newPolicyInformation.policyNumber.$error
                      ? _c(
                          "div",
                          {
                            staticClass: "error-text",
                            staticStyle: {
                              padding: "6px",
                              "margin-top": "-4px",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.fields.errorFieldEmpty.text) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.newPolicyInformation.havePolicy
            ? _c(
                "div",
                { staticClass: "form__select-currency" },
                [
                  _c(
                    "div",
                    { staticClass: "pop-up-insurance-information__subtitles" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.fields.contentCurrency.text) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("VSelect", {
                    staticClass: "form__select-currency__selection",
                    attrs: { items: _vm.normalizedCurrencies },
                    model: {
                      value: _vm.newPolicyInformation.currency,
                      callback: function ($$v) {
                        _vm.$set(_vm.newPolicyInformation, "currency", $$v)
                      },
                      expression: "newPolicyInformation.currency",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.newPolicyInformation.havePolicy
            ? _c("div", { staticClass: "form__amount" }, [
                _c(
                  "div",
                  { staticClass: "pop-up-insurance-information__subtitles" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.fields.contentAmountCovered.text) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form__amount__input" },
                  [
                    _c("InputFields", {
                      class: {
                        "input-error":
                          _vm.$v.newPolicyInformation.amountCovered.$error,
                      },
                      attrs: {
                        maxlength: 14,
                        placeholder:
                          _vm.fields.contentAmountCoveredPlaceholder.text,
                      },
                      model: {
                        value: _vm.$v.newPolicyInformation.amountCovered.$model,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.$v.newPolicyInformation.amountCovered,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression:
                          "$v.newPolicyInformation.amountCovered.$model",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.newPolicyInformation.amountCovered.$error
                      ? _c(
                          "div",
                          {
                            staticClass: "error-text",
                            staticStyle: {
                              padding: "6px",
                              "margin-top": "-4px",
                            },
                          },
                          [
                            !_vm.$v.newPolicyInformation.amountCovered.numeric
                              ? [
                                  _vm._v(
                                    _vm._s(_vm.fields.errorOnlyNumbers.text)
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    _vm._s(_vm.fields.errorFieldEmpty.text)
                                  ),
                                ],
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.newPolicyInformation.havePolicy
            ? _c("div", { staticClass: "form__amount-occurrence" }, [
                _c(
                  "div",
                  { staticClass: "pop-up-insurance-information__subtitles" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.fields.contentAmountOccurrence.text) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form__amount-occurrence__input" },
                  [
                    _c("InputFields", {
                      class: {
                        "input-error":
                          _vm.$v.newPolicyInformation.amountPerOccurrence
                            .$error,
                      },
                      attrs: {
                        maxlength: 14,
                        placeholder:
                          _vm.fields.contentAmountOccurrencePlaceholder.text,
                      },
                      model: {
                        value:
                          _vm.$v.newPolicyInformation.amountPerOccurrence
                            .$model,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.$v.newPolicyInformation.amountPerOccurrence,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression:
                          "$v.newPolicyInformation.amountPerOccurrence.$model",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.newPolicyInformation.amountPerOccurrence.$error
                      ? _c(
                          "div",
                          {
                            staticClass: "error-text",
                            staticStyle: {
                              padding: "6px",
                              "margin-top": "-4px",
                            },
                          },
                          [
                            !_vm.$v.newPolicyInformation.amountPerOccurrence
                              .numeric
                              ? [
                                  _vm._v(
                                    _vm._s(_vm.fields.errorOnlyNumbers.text)
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    _vm._s(_vm.fields.errorFieldEmpty.text)
                                  ),
                                ],
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.newPolicyInformation.havePolicy
            ? _c("div", { staticClass: "form__expire-date" }, [
                _c(
                  "div",
                  { staticClass: "pop-up-insurance-information__subtitles" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.fields.contentPolicyExpire.text) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form__expire-date__date-picker" },
                  [
                    _c("date-picker", {
                      attrs: {
                        placeholder:
                          _vm.fields.contentPolicyExpirePlaceholder.text,
                        "value-type": "format",
                        format: "YYYY.MM.DD",
                        editable: false,
                        "disabled-date": _vm.disabledBedoreToday,
                      },
                      model: {
                        value:
                          _vm.$v.newPolicyInformation.expirationDate.$model,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.$v.newPolicyInformation.expirationDate,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression:
                          "$v.newPolicyInformation.expirationDate.$model",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.newPolicyInformation.expirationDate.$error
                      ? _c(
                          "div",
                          {
                            staticClass: "error-text",
                            staticStyle: {
                              padding: "6px",
                              "margin-top": "-4px",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.fields.errorFieldEmpty.text) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.newPolicyInformation.havePolicy
            ? _c(
                "div",
                { staticClass: "form__upload" },
                [
                  _c(
                    "div",
                    { staticClass: "pop-up-insurance-information__subtitles" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.fields.contentInsurance.text) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.insuranceFile || !_vm.insuranceFile.name
                    ? _c(
                        "VFileInput",
                        {
                          attrs: {
                            accept: _vm.$options.INPUT_ACCESS_FILES,
                            error: _vm.$v.insuranceFile.$error,
                          },
                          on: { change: _vm.uploadFile },
                        },
                        [
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "form__upload__file__text" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.fields.contentInsurancePlaceholder
                                        .text
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form__upload__file__hint" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.fields.contentInsurancePlaceholderSize
                                        .text
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "form__upload__file form__upload__file--with-file",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form__upload__file__name" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.insuranceFile.name) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form__upload__file__delete-icon",
                              on: { click: _vm.deleteInsurance },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/svg/delete-icon.svg"),
                                  alt: "Delete insurance",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                  _vm._v(" "),
                  _vm.$v.insuranceFile.$error
                    ? _c(
                        "div",
                        {
                          staticClass: "error-text",
                          staticStyle: { padding: "6px", "margin-top": "-4px" },
                        },
                        [
                          !_vm.$v.insuranceFile.required
                            ? [_vm._v(_vm._s(_vm.fields.errorFileEmpty.text))]
                            : !_vm.$v.insuranceFile.maxFileSize
                            ? [_vm._v(_vm._s(_vm.fields.errorFileSize.text))]
                            : !_vm.$v.insuranceFile.fileFormat
                            ? [
                                _vm._v(
                                  _vm._s(
                                    `${_vm.fields.errorFileFormat.text} (${_vm.$options.INPUT_ACCESS_FILES})`
                                  )
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "actions" }, [
          _c(
            "div",
            { on: { click: _vm.closePopup } },
            [
              _c("Btn", {
                staticClass: "actions__button",
                attrs: { outline: "", name: _vm.fields.cancelBtn.text },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { on: { click: _vm.saveInsurance } },
            [
              _c("Btn", {
                staticClass: "actions__button",
                attrs: { base: "", name: _vm.fields.saveBtn.text },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }