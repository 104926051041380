var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "index-price-type-page" }, [
    _vm.loadingPage
      ? _c(
          "div",
          [
            _c("local-loader"),
            _vm._v(" "),
            _c("div", { staticClass: "index-price-type-page__loading-title" }, [
              _vm._v(_vm._s(_vm.translations.loader.text)),
            ]),
          ],
          1
        )
      : _c(
          "div",
          [
            _vm.isBuild ? _c("BackLink") : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "index-price-type-page__title" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.translations.title.text) + "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "index-price-type-page__content-title" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.translations.howPriceTitle.text) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "index-price-type-page__how-price" }, [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.checkPersonType()
                    },
                  },
                },
                [
                  _c("RadioButton", {
                    attrs: {
                      default: "",
                      name: _vm.translations.howPriceRadio1.text,
                      "radio-name": "how-price",
                      checked: _vm.isPersonType,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "index-price-type-page__how-price--description",
                    },
                    [_vm._v(_vm._s(_vm.translations.infoPrice.text))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.checkPersonType()
                    },
                  },
                },
                [
                  _c("RadioButton", {
                    attrs: {
                      default: "",
                      name: _vm.translations.howPriceRadio2.text,
                      "radio-name": "how-price",
                      checked: !_vm.isPersonType,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "index-price-type-page__how-price--description",
                    },
                    [_vm._v(_vm._s(_vm.translations.infoPriceGroup.text))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.isPersonType
                ? _c(
                    "div",
                    { staticClass: "index-price-type-page__package" },
                    [
                      _c("selection", {
                        attrs: {
                          default: "",
                          "default-name": _vm.groupTypeName,
                          items: _vm.selectionTitles,
                        },
                        on: { name: _vm.changeGroupName },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.isPersonType
              ? _c("AgeForm", {
                  ref: "ageForm",
                  attrs: {
                    "is-group-pricing": _vm.tour.is_group_pricing,
                    translates: _vm.translations,
                    members: _vm.members,
                    "tour-members": _vm.tourMembers,
                  },
                  on: {
                    "update:members": function ($event) {
                      _vm.members = $event
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.isPersonType && _vm.isSelectedOneChildrenGroups
              ? _c(
                  "div",
                  {
                    staticClass: "index-price-type-page__child-accompanied",
                    on: {
                      click: function ($event) {
                        _vm.accompanied = !_vm.accompanied
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "index-price-type-page__child-accompanied--title",
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.translations.accompanied.text) +
                            "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("RadioButton", {
                      attrs: {
                        default: "",
                        "radio-name": "child-accompanied",
                        name: _vm.translations.accompaniedYesRadio.text,
                        checked: _vm.accompanied,
                      },
                    }),
                    _vm._v(" "),
                    _c("RadioButton", {
                      attrs: {
                        default: "",
                        "radio-name": "child-accompanied",
                        name: _vm.translations.accompaniedNoRadio.text,
                        checked: !_vm.accompanied,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isPersonType
              ? _c(
                  "div",
                  { staticClass: "index-price-page__max-per-booking" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "index-price-type-page__child-accompanied--title",
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.translations.maxTravelersPerBooking.text
                            ) +
                            "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("v-input", {
                      attrs: {
                        onkeypress:
                          "return event.charCode >= 48 && event.charCode <= 57",
                        onpaste: "return false;",
                        error: _vm.errorMaxTravelersPerBooking,
                        type: "number",
                      },
                      on: { blur: _vm.blurMaxTravelers },
                      model: {
                        value: _vm.maxTravelersPerBooking,
                        callback: function ($$v) {
                          _vm.maxTravelersPerBooking = $$v
                        },
                        expression: "maxTravelersPerBooking",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("v-btn", {
              attrs: {
                base: "",
                name: _vm.isBuild
                  ? _vm.translations.btnSaveAndCont.text
                  : _vm.translations.saveBtn.text,
              },
              on: { click: _vm.savePricing },
            }),
            _vm._v(" "),
            _c("pricing-change", {
              attrs: {
                lang: _vm.lang,
                "is-active": _vm.pricingChange,
                "is-saving": _vm.savingPricing,
                "selection-titles": _vm.selectionTitles,
                "person-pricing": _vm.isPersonType,
                tour: _vm.tour,
                "group-pricing": _vm.groupPricing,
                "tour-members": _vm.tourMembers,
                translations: _vm.translations,
                members: _vm.members,
                "old-schedule": _vm.oldSchedule,
              },
              on: {
                "update:isActive": function ($event) {
                  return _vm.closePricingChange()
                },
                save: function ($event) {
                  return _vm.savingPricingChange()
                },
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }