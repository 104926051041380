var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VDialog", {
    staticClass: "v-confirm-dialog",
    attrs: { visible: _vm.isOpen },
    scopedSlots: _vm._u([
      {
        key: "document",
        fn: function () {
          return [
            _c("div", { staticClass: "v-confirm-dialog" }, [
              _c("h4", { staticClass: "v-confirm-dialog__title" }, [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
              ]),
              _vm._v(" "),
              _vm.text
                ? _c("p", { staticClass: "v-confirm-dialog__text" }, [
                    _vm._v("\n        " + _vm._s(_vm.text) + "\n      "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "v-confirm-dialog__footer" },
                [
                  _c(
                    "VButton",
                    {
                      staticClass: "v-confirm-dialog__button",
                      attrs: { outline: "" },
                      on: { click: _vm.cancel },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.cancelText) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "VButton",
                    {
                      staticClass: "v-confirm-dialog__button",
                      attrs: { base: "" },
                      on: { click: _vm.confirm },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.confirmText) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }