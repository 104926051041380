var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isActive
    ? _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "pop-up-review-report" }, [
          _c(
            "div",
            { staticClass: "pop-up__close", on: { click: _vm.closeWindow } },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/svg/close.svg"),
                  alt: "close",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "pop-up-review-report__content" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.reportTranslate.title.text) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "description" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.reportTranslate.subtitle.text) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.$v.form.text.$model,
                  expression: "$v.form.text.$model",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "textarea",
              class: { "input-error": _vm.$v.form.text.$error },
              domProps: { value: _vm.$v.form.text.$model },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.$v.form.text,
                    "$model",
                    $event.target.value.trim()
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "pop-up-review-report__nav" }, [
            _c(
              "div",
              {
                staticClass: "back",
                on: {
                  click: function ($event) {
                    return _vm.closeWindow()
                  },
                },
              },
              [
                _c("Btn", {
                  attrs: {
                    outline: "",
                    name: _vm.reportTranslate.backBtn.text,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "report",
                on: {
                  click: function ($event) {
                    return _vm.reviewReport()
                  },
                },
              },
              [
                _c("Btn", {
                  attrs: { base: "", name: _vm.reportTranslate.sendBtn.text },
                }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }