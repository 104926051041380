import { format as _format } from 'date-fns';
import { formatTime } from './utils';
const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};
export function formatDate(date, locale) {
  return new Date(date).toLocaleDateString(locale, options);
}
export function formatTimeLocale(date, locale) {
  return new Date(date).toLocaleTimeString(locale, options);
}

export function format(date, formatString = 'MMM d, YYYY', options) {
  return _format(new Date(date), formatString, options);
}

export function daysToSeconds(days) {
  return days * 24 * 60 * 60;
}
