import { daysToSeconds } from '@/utils/date';

export default function ({ $axios, $cookies, store }) {
  $axios.interceptors.request.use((request) => {
    console.log(request.method.toUpperCase(), request.url, request.params ? request.params : '');
    return request;
  });

  $axios.onRequest(async ({ _withoutAuthHeader = false, checkToken = false, ...config }) => {
    let token = $cookies.get('ulkid');

    if (_withoutAuthHeader || !token) {
      return config;
    }

    // add JWT token
    let prefix = 'Token';
    if (token != undefined && token.split('.').length === 3) prefix = 'JWT';

    const additionalHeaders = {
      Authorization: `${prefix} ${token}`,
    };

    return {
      ...config,
      headers: {
        ...additionalHeaders,
        ...config.headers,
      },
    };
  });

  $axios.onError(async (error) => {
    // если unauthorized - обновляем ключ и повторяем запрос
    if (error.response.status == 401) {
      const newToken = await $axios.$post('/api/v1/jwt/refresh', { refresh: $cookies.get('ulkidRefresh'), _withoutAuthHeader: true });
      $cookies.set('ulkid', newToken.access, { maxAge: daysToSeconds(30) });
      $axios.setToken(newToken.access, 'JWT');
      error.config.headers['Authorization'] = 'JWT ' + newToken.access
      return $axios.request(error.config);
    }


    console.error(error?.response?.data);
    return Promise.reject(error);
  });
}
