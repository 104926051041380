<template>
  <table class="v-table" v-bind="$attrs">
    <thead>
      <tr class="v-table__headers">
        <th
          v-for="header in headers"
          :key="header.value"
          class="v-table__header v-table__cell"
          :style="{ width: header.width, minWidth: header.width }"
        >
          <slot :name="`header:${header.value}`" v-bind="header">
            {{ header.text }}
          </slot>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in items" :key="`item-${index}`" class="v-table__items">
        <td v-for="(header, headerIndex) in headers" :key="`key-${headerIndex}`" class="v-table__cell v-table__item" :align="`${header.align || ''}`">
          <slot :name="`item:${header.value}`" v-bind="{ item, header }">
            {{ item[header.value] }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'VTable',

  props: {
    headers: { type: Array, required: true },
    items: { type: Array, default: () => [] }
  }
}
</script>

<style lang="scss">
.v-table {
  width: 100%;
  color: $dark-blue;

  &__headers {
    text-align: left;
    border-bottom: 1px solid #D9D9D9;
  }

  &__items {
    border-bottom: 1px solid #D9D9D9;
  }

  &__cell {
    padding: 14px;
    font-weight: 500;

    &:first-child {
      padding-left: 0;
    }
  }

  &__item {
    padding: 20px 14px;
    height: 62px;
  }

  &__header {
    font-weight: 600;
  }
}
</style>
