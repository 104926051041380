<template>
  <div class="tour-card">
    <div>
      <div class="tour-card__picture">
        <img
          v-if="tour.get_slider.length != 0"
          :src="tour.get_slider[0].get_image"
          alt="Tour picture"
        />
        <img v-else src="@/assets/img/no_image.jpg" alt="no_image" />
        <div
          v-if="tourPrice != 'no price' && tourPrice > 0 && tourSale > 0"
          class="badge"
          :style="`background: ${tour.badge_color};`"
        >
          {{ getBadge }}
        </div>
      </div>
      <div class="tour-card__body">
        <div class="description">
          <div class="description__title">{{ tour.name }}</div>
        </div>
        <div class="tour-footer">
          <div class="price">
            <div class="price_from">{{ headerTranslate.cardFrom.text }}</div>
            <div
              v-if="tourPrice != 'no price' && tourPrice > 0 && tourSale > 0"
              class="price_old"
            >
              {{ formatCurrency(tourOldPrice, lang) }}
            </div>
            <div
              v-if="tourPrice > 0"
              class="price_total"
              :class="{ price_total_sale: tourOldPrice > 0 }"
            >
              {{ formatCurrency(tourPrice, lang) }}
            </div>
            <div v-else class="price_total">
              {{ headerTranslate.cardNoPrice.text }}
            </div>
            <div v-if="tourOldPrice > 0 && tourPrice > 0" class="price_sale">
              {{ formatCurrency(tourSale, lang) }}
              {{ headerTranslate.cardSavings.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatCurrency } from "@/utils/utils";

export default {
  props: {
    tour: { type: Object, default: () => ({}) },
    tourPrice: { type: Number, default: 0 },
    tourOldPrice: { type: Number, default: 0 },
    tourSale: { type: Number, default: 0 },
    discount: { type: Number, default: 0 },
  },
  data: () => ({
    lang: "",
  }),
  fetch() {
    this.lang = this.$cookies.get("i18n_redirected");
  },
  methods: {
    formatCurrency,
  },
  computed: {
    ...mapState(["headerTranslate"]),

    getBadge() {
      return (
        this.headerTranslate.cardSpecialOffer.text[0].toUpperCase() +
        this.headerTranslate.cardSpecialOffer.text.slice(1)
      );
    },
  },
};
</script>

<style lang="scss">
.tour-card {
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  max-width: 262px;
  min-width: 262px;
  max-height: 293px;
  min-height: 293px;
  width: 100%;
  height: 100%;
  position: relative;
  &__picture {
    position: relative;
    height: 144px;
    border-radius: 12px 12px;
    overflow: hidden;
    z-index: -1;
    .badge {
      position: absolute;
      bottom: 24px;
      left: 16px;
      padding: 3px 8px;
      background: $orange;
      border-radius: 4px;
      color: $white;
      font-size: 12px;
      line-height: 14px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 156px;
    margin-top: -12px;
    padding: 12px 16px;
    background: $white;
    box-shadow: 0px 1px 4px rgba(30, 40, 67, 0.12);
    border-radius: 8px;
    z-index: 10;
    .description {
      margin-bottom: 12px;
      overflow: hidden;
      &__title {
        word-wrap: break-word;
        flex-wrap: wrap;
        overflow: hidden;
        max-height: 54px;
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
      }
      &__text {
        font-size: 16px;
        max-height: 43px;
      }
    }
    .tour-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .price {
        margin-bottom: 10px;
        position: relative;
        &_total {
          font-size: 22px;
          line-height: 26px;
          text-align: left;
          font-weight: bold;
          &_sale {
            color: $orange;
          }
        }
        &_old {
          text-decoration: line-through;
          font-size: 16px;
          line-height: 19px;
          &_no {
            bottom: 25px;
            left: 2px;
            font-size: 12px;
            line-height: 14px;
            color: $light-gray;
          }
        }
        &_from {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: $light-gray;
        }
        &_sale {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: $orange;
        }
      }
    }
  }
}
</style>
