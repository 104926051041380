<template>
  <div class="v-field">
    <div v-if="label || description" class="v-field__info">
      <label v-if="label" class="v-field__label">{{ label }} <span v-if="optional" class="v-field__label-optional">({{ optional }})</span></label>
      <p v-if="description" class="v-field__description">
        <slot name="description">{{ description }}</slot>
      </p>
    </div>
    <slot />
    <p v-if="error" class="v-field__error error-text">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: 'VField',
  props: {
    label: { type: String, default: '' },
    description: { type: String, default: '' },
    optional: { type: String, default: '' },
    error: { type: Boolean, default: false },
    errorMessage: { type: String, default: '' }
  }
}
</script>

<style lang="scss">
.v-field {
  &__label {
    display: block;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }

  &__description {
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
  }

  &__info {
    margin-bottom: 8px;
  }

  &__label-optional {
    color: #999999;
    font-size: 14px;
  }

  &__error {
    margin-top: 6px;
  }
}
</style>
