<template>
  <VDialog v-model="modelVisible">
    <div class="add-schedule-pop-up">
      <div class="add-schedule-pop-up__step">{{ parseTranslate(pricesTranslate.stepOf.text, step, '2')}}</div>
      <pricing-schedule
        v-if="step == 1"
        :translations="scheduleTranslate"
        :tour="tour"
        :tour-lang="tourLang"
        :improve-options-translate="improveOptionsTranslate"
        :step="2"
        :schedule-pricing="schedule"
        @save="saveSchedule"
        @close="close"
        @back="close"
      />
      <price-option
        v-if="step == 2"
        :price-option="priceOption"
        :translations="pricesTranslate"
        :tour="tour"
        :step="2"
        @back="step = 1"
        @close="close"
        @save="save"
      />
    </div>
    <no-members :translations="pricesTranslate" :no-members="noMembers" :slug="tour.slug" @close="close" />
  </VDialog>
</template>

<script>
import { mapState } from 'vuex'
import { mapMembers, getAvailableDays, getAvailableOpeningTimes } from '@/utils/price'
import { parseTranslate } from '@/utils/translations'
import { format } from '@/utils/date'
import VDialog from '@/components/common/VDialog.vue'
import PricingSchedule from '@/components/price/PricingSchedule.vue'
import NoMembers from '@/components/price/NoMembers.vue'

export default {
  components: {
    VDialog,
    PricingSchedule,
    NoMembers
  },
  props: {
    isActive: { type: Boolean, default: false },
    tour: { type: Object, default: () => ({}) },
    tourLang: { type: String, default: '' },
    translations: { type: Object, default: () => ({}) },
    optionId: { type: Number, default: 0 },
    optionTitle: { type: String, default: '' },
    scheduleTranslate: { type: Object, default: () => ({}) },
    pricesTranslate: { type: Object, default: () => ({}) },
    improveOptionsTranslate: { type: Object, default: () => ({}) }
  },
  data: () => ({
    step: 1,
    schedule: {
      selectedScheduleTypeId: null,
      time1: new Date().toISOString(),
      time2: null,
      endDate: false
    },

    priceOption: {
      members: [],
      addTimes: false,
      openingHours: false,
      anotherTimes: [{ time: null, is_active: false }],
      openingScheduleTimes: [{ startTime: null, endTime: null }],
      days: [
        { name: 'Mon', day: '1', is_active: false },
        { name: 'Tue', day: '2', is_active: false },
        { name: 'Wed', day: '3', is_active: false },
        { name: 'Thu', day: '4', is_active: false },
        { name: 'Fri', day: '5', is_active: false },
        { name: 'Sat', day: '6', is_active: false },
        { name: 'Sun', day: '7', is_active: false }
      ],
      activeDaysCount: 0,
      allDaysChecked: false,
      tieredPricing: false
    },

    noMembers: false,
    lang: ''
  }),

  async fetch() {
    this.lang = this.$cookies.get('i18n_redirected')

    this.priceOption.members = await this.$axios.$get(
      `/api/v1/get-tour-members/?tour_id=${this.tour.id}&language=${this.tourLang}`
    )

    this.priceOption.members.sort(function (a, b) {
      return b.max_age - a.max_age
    })
    if (this.priceOption.members.length !== 0) {
      this.priceOption.members = mapMembers(
        this.priceOption.members,
        this.tour.is_group_pricing,
        this.tour.max_travelers,
        await this.$axios.$get(`/api/v1/pages/lk-popup-prices?language=${this.tourLang}`),
      )
    } else {
      this.noMembers = true
    }
  },
  computed: {
    ...mapState(['userInfo']),

    modelVisible: {
      get() {
        return this.isActive
      },
      set(visible) {
        this.$emit('update:isActive', visible)
      }
    }
  },

  methods: {
    getAvailableDays,
    getAvailableOpeningTimes,
    format,
    parseTranslate,

    close() {
      this.modelVisible = false
    },

    saveSchedule(data) {
      this.schedule = data.schedule
      this.priceOption.openingHours = data.openingHours
      this.priceOption.addTimes = data.addTimes
      this.step++
    },
    async save(data) {
      this.priceOption = data
      this.$emit('loading')

      const newTourSchedule = await this.$axios.$post(`/api/v1/create-tour-schedule/`, null, {
        params: {
          language: this.tour.input_language.abbreviation.toLowerCase(),
          tour_id: this.tour.id,
          option_id: this.optionId,
          'start-date': this.format(this.schedule.time1, 'dd.MM.yyyy'),
          'end-date':
            this.schedule.time2 == null || !this.schedule.endDate ? '02.02.2222' : this.format(this.schedule.time2, 'dd.MM.yyyy'),
          'schedule-type-id': this.schedule.selectedScheduleTypeId
        }
      })
      // edit schedule (add title and max travelers)
      await this.$axios.$put(`/api/v1/edit-tour-schedule/`, null, {
        params: {
          schedule_id: newTourSchedule.id,
          edit_language: this.tour.input_language.abbreviation.toLowerCase(),
          title: this.optionTitle
        }
      })
      // Week
      const weekSchedule = await this.$axios.$put(`/api/v1/set-week-schedule/`, null, {
        params: {
          schedule_id: newTourSchedule.id,
          available_time: this.getAvailableOpeningTimes(
            this.priceOption.openingHours,
            this.priceOption.addTimes,
            this.priceOption.openingScheduleTimes,
            this.priceOption.anotherTimes
          ),
          weekdays: this.getAvailableDays(this.priceOption.days)
        }
      })
      if (this.tour.is_group_pricing) {
        // Grope price
        await this.$axios.$put(`/api/v1/set-week-schedule/`, null, {
          params: {
            week_schedule_id: weekSchedule.id,
            max_travelers: this.priceOption.members[0].groupCount
          }
        })
        if (this.tour.travelers.length === 0) {
          const getGroupMembers = await this.$axios.$get(`/api/v1/get-members/?tour_id=${this.tour.id}&language=${this.lang}`)
          await this.$axios.$post(`/api/v1/add-tour-member/`, null, {
            params: {
              tour_id: this.tour.id,
              age_category_id: getGroupMembers[0].id,
              min_age: 1,
              max_age: 100
            }
          })
        }
        await this.$axios.$post(`/api/v1/add-tour-schedule-member/`, null, {
          params: {
            week_schedule_id: weekSchedule.id,
            member_id: this.priceOption.members[0].id,
            price: this.priceOption.members[0].price
          }
        })
      } else if (!this.tieredPricing) {
        // Person price
        for (const i in this.priceOption.members) {
          if (this.priceOption.members[i].is_active) {
            this.$axios.$post(`/api/v1/add-tour-schedule-member/`, null, {
              params: {
                week_schedule_id: weekSchedule.id,
                member_id: this.priceOption.members[i].id,
                price: this.priceOption.members[i].price
              }
            })
          }
        }
      } else {
        // Person price tried
        for (const i in this.priceOption.members) {
          if (this.priceOption.members[i].is_active) {
            for (const ti in this.priceOption.members[i].ageTieredGroups) {
              this.$axios.$post(`/api/v1/add-tour-schedule-member/`, null, {
                params: {
                  week_schedule_id: weekSchedule.id,
                  member_id: this.priceOption.members[i].id,
                  price: this.priceOption.members[i].ageTieredGroups[ti].price,
                  count_to: this.priceOption.members[i].ageTieredGroups[ti].maxAge,
                  count_from: this.priceOption.members[i].ageTieredGroups[ti].minAge
                }
              })
            }
          }
        }
      }
      this.$router.go(this.localePath('/'))
    }
  }
}
</script>

<style lang="scss">
.add-schedule-pop-up {
  position: relative;
  background: $white;
  width: 800px;
  &__step {
    margin-bottom: 2px;
  }
}
</style>
