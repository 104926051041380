export function parseTranslate(translateString, ...params) {
  let errorMessage = translateString
  const matches = translateString.match(/{\d}/g)

  if (matches == null) return translateString;

  matches.forEach((matchParam, index) => {
    errorMessage = errorMessage.replace(matchParam, params[index])
  })

  return errorMessage
}
