<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-company-information">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close" />
      </div>
      <div class="pop-up-company-information__title">
        {{ companyTranslate.title.text }}
      </div>
      <div class="pop-up-company-information__content">
        <div class="business-name">
          <v-input v-model="$v.form.businessName.$model" :label="companyTranslate.contentBusinessName.text"
            :placeholder="companyTranslate.businessNameInputPlaceholder.text" :error="$v.form.businessName.$error"
            :error-message="companyTranslate.businessNameInputError.text" :max-length="255" />
        </div>
        <div v-if="isSellerCompany" class="business-trading-name">
          <div class="business-trading-name__title">
            {{ companyTranslate.contentBusinessTradingName.text }}
          </div>
          <div class="business-trading-name__subtitle">
            {{ companyTranslate.businessTradingNameDescription.text }}
          </div>
          <v-input v-model="$v.form.businessTradingName.$model"
            :placeholder="companyTranslate.businessTradingNameInputPlaceholder.text"
            :error="$v.form.businessTradingName.$error"
            :error-message="companyTranslate.businessTradingNameInputError.text" :max-length="24" />
        </div>
        <div class="company-website">
          <div class="company-website__title">
            {{ companyTranslate.contentWebsite.text }}
          </div>
          <input-fields v-model="$v.form.website.$model" :class="{ 'input-error': $v.form.website.$error }"
            placeholder=" http://www.example.com " />
          <div v-if="$v.form.website.$error" class="company-website_error error-text">
            {{ companyTranslate.contentWebsiteInputError.text }}
          </div>
        </div>
        <div v-if="isSellerCompany" class="contact-email">
          <div class="contact-email__title">
            {{ companyTranslate.contactEmail.text }}
          </div>
          <input-fields v-model="$v.form.contactEmail.$model"
            :class="{ 'input-error': $v.form.contactEmail.$error }" placeholder="yourContactEmail@gmail.com" />
          <div v-if="$v.form.contactEmail.$error" class="company-website_error error-text">
            {{ companyTranslate.contactEmailError.text }}
          </div>
        </div>
        <div class="contact-phone">
          <div class="contact-phone__title">
            {{ companyTranslate.contactPhone.text }}
          </div>
          <VuePhoneNumberInput v-if="userInfo.contact_phone_code" v-model="contactPhone" :error="!countryError"
            :default-country-code="contactPhoneCode" @update="countryChanged" />
          <VuePhoneNumberInput v-else v-model="contactPhone" :default-country-code="contactPhoneCode"
            :error="!countryError" @update="countryChanged" />
        </div>
        <!-- Logo -->
        <VField class="company-image__field" :label="companyTranslate.contentLogo.text"
          :error="$v.localPhotoFile.$error" :error-message="photoError">
          <VFileInput class="company-image__file-input" :preview-url="localPhotoUrl" full
            :error="$v.localPhotoFile.$error" accept="image/*" @change="uploadFile">
            <template #preview-append>
              <button class="company-image__delete-icon" @click="deleteLogoImage">
                <img src="@/assets/img/svg/add-logo-trash-icon.svg" alt="Delete Logo" />
              </button>
            </template>
          </VFileInput>
        </VField>
        <div class="company-information">
          <div class="company-information__title">
            {{ companyTranslate.contentInformation.text }}
          </div>
          <div class="company-information__description">
            {{ companyTranslate.contentInformationDescription.text }}
          </div>
          <div class="company-information__textarea-wrapper">
            <VTextarea 
              v-model="$v.form.companyInformation.$model" 
              :error="$v.form.companyInformation.$error"
              :error-message="companyTranslate.errorComDescLength.text"
              rows="6" :max-length="companyInformation.maxLength"
              min-length="100" />
          </div>
        </div>
      </div>
      <div class="pop-up-company-information__nav">
        <div class="cancel" @click="closeWindow">
          <Btn outline :name="companyTranslate.cancelBtn.text" />
        </div>
        <div class="save" @click="saveCompanySettings">
          <Btn base :name="companyTranslate.saveBtn.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required, email, requiredIf, minLength } from 'vuelidate/lib/validators'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VuePhoneNumberInput from 'vue-phone-number-input'
import Btn from '@/components/controls/Btn.vue'
import InputFields from '@/components/controls/Input-fields.vue'
import VTextarea from '@/components/controls/VTextarea.vue'
import VFileInput from '@/components/controls/VFileInput.vue'
import VField from '@/components/controls/VField.vue'
import VInput from '@/components/controls/VInput.vue'
import { website } from '@/utils/validations'

import { maxFileSize } from '@/utils/validations'

const MEGABYTE_IN_BYTES = 1000000
const MAX_FILE_SIZE = 5 * MEGABYTE_IN_BYTES

export default {
  components: { Btn, InputFields, VuePhoneNumberInput, VTextarea, VFileInput, VField, VInput },
  props: [
    'isActive',
    'nowWebsite',
    'nowBusinessName',
    'nowTradingName',
    'companyTranslate',
    'nowContactEmail',
    'nowContactPhone',
    'nowContactPhoneCode',
    'nowCompanyLogo',
    'nowCompanyInformation',
    'isSellerCompany'
  ],
  data: () => ({
    localPhotoUrl: '',
    localPhotoFile: null,
    form: {
      businessName: '',
      businessTradingName: '',
      website: '',
      contactEmail: '',
      companyInformation: ''
    },
    contactPhone: '',
    contactPhoneCode: 'ES',
    companyInformation: {
      maxLength: 750,
      currentLength: 0
    },
    country: null,
    isValidPhone: false,
    countryError: false
  }),
  validations() {
    return {
      form: {
        businessName: { required },
        businessTradingName: { required: required(() => this.isSellerCompany) },
        companyInformation: { required, minLength: minLength(100) },
        website: { required, valid: website },
        contactEmail: { required: required(() => this.isSellerCompany), email }
      },
      localPhotoFile: {
        required: requiredIf(() => !this.localPhotoUrl),
        maxFileSize: maxFileSize(MAX_FILE_SIZE),
        fileFormat: value => (value?.type ? value.type.includes('image') : true)
      }
    }
  },
  fetch() {
    this.form.website = this.nowWebsite
    this.form.businessName = this.nowBusinessName
    this.form.businessTradingName = this.nowTradingName
    this.form.contactEmail = this.nowContactEmail
    this.contactPhone = this.nowContactPhone
    if (this.nowContactPhoneCode) {
      this.contactPhoneCode = this.nowContactPhoneCode
    }
    this.form.companyInformation = this.nowCompanyInformation

    if (this.nowCompanyLogo) {
      this.localPhotoUrl = `${this.nowCompanyLogo}`
    }
  },
  computed: {
    ...mapState(['userInfo']),

    photoError() {
      if (!this.$v.localPhotoFile.fileFormat) {
        return this.companyTranslate.descriptionFile.text
      }
      if (!this.$v.localPhotoFile.maxFileSize) {
        return this.companyTranslate.descriptionPhotoSize.text
      }

      return ''
    }
  },
  beforeUpdate() {
    if (!this.isValidPhone && this.nowContactPhone) {
      try {
        this.contactPhone = this.country.nationalNumber
        this.isValidPhone = true
      } catch { }
    }
  },
  methods: {
    uploadFile(file) {
      this.localPhotoFile = file
      this.$v.localPhotoFile.$touch()
      this.localPhotoUrl = URL.createObjectURL(file)
    },
    deleteLogoImage() {
      this.$v.localPhotoFile.$touch()
      this.localPhotoUrl = ''
      this.localPhotoFile = null
    },
    closeWindow() {
      const active = this.isActive
      this.$emit('close', active)
    },
    countryChanged(country) {
      this.country = country
      if (country.isValid !== undefined) {
        this.countryError = country.isValid
      }
    },
    saveCompanySettings() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.country != null) {
          if (this.country.isValid && this.country.formattedNumber) {
            this.contactPhoneCode = this.country.countryCode

            const bodyFormData = new FormData()
            let params = {}

            if (this.localPhotoFile) {
              bodyFormData.append('company_logo', this.localPhotoFile)
            }
            if (this.isSellerCompany) {
              bodyFormData.append('company_info', this.form.companyInformation)

              params = {
                company_name: this.form.businessName,
                company_trading_name: this.form.businessTradingName,
                company_site_link: this.form.website,
                contact_email: this.form.contactEmail,
                contact_phone: this.country.formattedNumber,
                contact_phone_code: this.country.countryCode
              }
            } else {
              bodyFormData.append('personal_phone', this.country.formattedNumber)
              bodyFormData.append('personal_phone_code', this.country.countryCode)

              params = {
                company_name: this.form.businessName,
                company_site_link: this.form.website,
                bio: this.form.companyInformation
              }
            }

            this.$axios.$put('/api/v1/seller-setting/', bodyFormData, { params }).then(() => {
              window.location.href = this.localePath('/account/business-information')
            })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
.pop-up-company-information {
  position: relative;
  background: $white;
  padding: 35px;

  &__title {
    margin-bottom: 22px;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }

  &__content {
    margin-bottom: 40px;

    .business-name {
      margin-bottom: 35px;

      &__title {
        margin-bottom: 15px;
        font-weight: bold;
      }

      .input {
        width: 100%;
      }

      &_error {
        margin-top: 8px;
      }
    }

    .business-trading-name {
      margin-bottom: 35px;

      &__title {
        margin-bottom: 5px;
        font-weight: bold;
      }

      &__subtitle {
        margin-bottom: 10px;
      }

      .input {
        width: 100%;
      }

      &_error {
        margin-top: 8px;
      }
    }

    .company-website {
      margin-bottom: 35px;

      &__title {
        margin-bottom: 15px;
        font-weight: bold;
      }

      .input {
        width: 100%;
      }

      &_error {
        margin-top: 8px;
      }
    }

    .contact-email {
      margin-bottom: 35px;

      &__title {
        margin-bottom: 15px;
        font-weight: bold;
      }

      .input {
        width: 100%;
      }

      &_error {
        margin-top: 8px;
      }
    }

    .contact-phone {
      margin-bottom: 35px;

      &__title {
        margin-bottom: 15px;
        font-weight: bold;
      }

      .input {
        width: 100%;
      }

      &_error {
        margin-top: 8px;
      }
    }

    .company-information {
      &__title {
        font-weight: bold;
        margin-bottom: 16px;
      }

      &__description {
        margin-bottom: 20px;
      }

      &__textarea-wrapper {
        width: 550px;
        margin: 0;

        .v-textarea__textarea--error {
          border-color: #ff5134;
          background-color: #ffbdb3;
          & ~ .v-textarea__counter {
            color: black;
          }
        }
        .validation-error {
          color: #ff5134;
          font-size: 12px;
          margin-left: 0;
        }
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ececec;
    padding-top: 21px;

    .btn {
      &_outline {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }

      &_base {
        border: 2px solid $primary;
        border-radius: 4px;
        min-width: 102px;
        height: 48px;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
      }
    }
  }

  .company-image {
    &__field {
      margin-bottom: 32px;

      .v-field__label {
        font-weight: 700;
      }
    }

    &__file-input {
      .v-file-input__container {
        position: relative;
      }
    }

    &__delete-icon {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 3%;
      right: 3%;
      width: 30px;
      height: 26px;
      background: #000;
      border: none;
      opacity: 0.5;
      border-radius: 4px;
      transition: all 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
}
</style>
