<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
    <path
      fill="currentColor"
      stroke="currentColor"
      stroke-width=".4"
      d="M6.25056 14.7165h-.00002c-.1658.1632-.38376.2661-.61802.2912l-2.96822.318c-.13779.0148-.246-.0989-.23216-.2239l.32328-2.9197c.02524-.228.12894-.4413.29468-.6043h.00001l9.01799-8.87013c.4028-.39619 1.0572-.39619 1.46 0l1.7404 1.71195.1403-.14259-.1403.14259c.401.39437.401 1.03239 0 1.42677L6.25056 14.7165ZM1.13438 12.007l.19878.022-.19878-.022-.32327 2.9197c-.12808 1.1566.86375 2.1249 2.02904 2.0001l2.96822-.318c.60191-.0645 1.16396-.3292 1.59291-.7511h.00001l9.01801-8.87017c1.0409-1.02385 1.0409-2.68522 0-3.70906l-1.7405-1.71194-.1402.14258.1402-.14258c-1.0391-1.02204-2.7224-1.02204-3.7615 0L1.89937 10.4367l.1398.1421-.1398-.1421c-.42901.422-.69917.9759-.76499 1.5703Z"
    />
    <path
      fill="currentColor"
      stroke="currentColor"
      stroke-width=".4"
      d="M15.268 4.41962c.401.39437.401 1.03239 0 1.42677l.1403.14259-.1403-.14259-.904.88922-3.1958-3.14336.8994-.88458c.4028-.39619 1.0571-.39619 1.4599 0l1.7405 1.71195Zm-1.0443 4.45107.1403.13794.1402-.13794 1.9146-1.88316c1.0409-1.02384 1.0409-2.68522 0-3.70906l-1.7405-1.71194-.1402.14258.1402-.14258c-1.0391-1.02204-2.7224-1.02204-3.7615 0L9.00233 3.44967l-.14496.14258.14496.14259 5.22137 5.13585Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TourCustomize'
}
</script>
