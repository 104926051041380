<template>
  <div v-if="includes.length" id="whats-included" class="preview__section">
    <div class="preview__title">
      {{ translations.textIncluded.text }}
    </div>
    <template v-if="tourIncludes.more">
      <div v-for="include in tourIncludes.start" :key="`include-${include.id}`" class="preview__include">
        <div class="preview__dot preview__dot--green" />
        <span class="preview__include-text">{{ include.description }}</span>
      </div>
      <span v-if="!showTourIncludes" class="preview__read-more" @click="showTourIncludes = true">{{ translations.textReadMore.text }}</span>
      <template v-if="showTourIncludes">
        <div v-for="include in tourIncludes.more" :key="`include-${include.id}`" class="preview__include">
          <div class="preview__dot preview__dot--green" />
          <span class="preview__include-text">{{ include.description }}</span>
        </div>
        <span class="preview__read-more" @click="showTourIncludes = false">{{ translations.textHide.text }}</span>
      </template>
    </template>
    <template v-else>
      <div v-for="include in tourIncludes" :key="`include-${include.id}`" class="preview__include">
        <div class="preview__dot preview__dot--green" />
        <span class="preview__include-text">{{ include.description }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'IncludedPreview',
  props: {
    includes: { type: Array, default: () => [] },
    translations: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      showTourIncludes: false
    }
  },
  computed: {
    tourIncludes () {
      return this.includes?.length > 4
        ? { start: this.includes.slice(0, 4), more: this.includes.slice(4) }
        : this.includes
    }
  }
}
</script>

<style lang="scss">
</style>
