var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notification-view" },
    [
      _c(
        "transition-group",
        {
          staticClass: "notification-view__items",
          attrs: { name: "notification-list", tag: "div" },
        },
        _vm._l(_vm.notificationPool, function (notification) {
          return _c("NotificationItem", {
            key: notification.id,
            staticClass: "notification-view__item",
            attrs: {
              type: notification.type,
              title: notification.title,
              text: notification.text,
            },
            on: {
              close: function ($event) {
                return _vm.removeNotification(notification)
              },
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }