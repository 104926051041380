var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M6.10187 10.5507C6.21017 10.4428 6.32764 10.3517 6.45124 10.2725L14.7193 2.00479C15.5259 1.19856 16.8345 1.19818 17.6415 2.00516C18.4485 2.81178 18.4485 4.12002 17.6415 4.9274L10.521 12.0479L17.6783 19.2056C18.4853 20.0122 18.4853 21.3201 17.6783 22.1278C17.2746 22.5319 16.7454 22.7332 16.2173 22.7332C15.6893 22.7332 15.1597 22.5319 14.7564 22.1278L6.45124 13.8219C6.32764 13.7427 6.20939 13.6516 6.10187 13.5437C5.68939 13.1312 5.49041 12.5879 5.50036 12.0472C5.49041 11.5065 5.68939 10.9635 6.10187 10.5507Z",
          fill: "currentColor",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }