var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("header", { staticClass: "header" }, [
        _c("div", { staticClass: "_container" }, [
          _c("div", { staticClass: "header__inner" }, [
            _c(
              "div",
              { staticClass: "nav" },
              [
                _vm.toLogin == "anonymous"
                  ? _c(
                      "a",
                      {
                        staticClass: "logo",
                        attrs: { href: _vm.localePath("/login"), tag: "div" },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/header/logo.svg"),
                            alt: "logo",
                          },
                        }),
                      ]
                    )
                  : !_vm.userInfo.is_password_entered
                  ? _c(
                      "nuxt-link",
                      {
                        staticClass: "logo",
                        attrs: { to: _vm.localePath("/"), tag: "div" },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/header/logo.svg"),
                            alt: "logo",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("Nuxt", { staticClass: "default-wrapper" }),
      _vm._v(" "),
      _c("notification-view"),
      _vm._v(" "),
      _c("screenResolutionNotice"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }