<template>
  <div v-if="tourTo != null && tourFrom != null">
    <!-- Block First impression  -->
    <div class="translation-block">
      <div class="translation-block__title">{{ translations.firstImpression.text }}</div>
      <!--  Short description -->
      <div
        v-if="tour.short_description != null && tour.short_description != ''"
        class="translation-row translation-block__container"
      >
        <div class="translation-row__english">
          <div>{{ translations.shortDescription.text }}</div>
          <VTextarea v-model="tourFrom.short_description" disabled rows="2" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ translations.shortDescription.text }}</div>
          <VTextarea
            v-model="$v.tourTo.short_description.$model"
            :error="$v.tourTo.short_description.$error"
            :max-length="250"
            rows="2"
          />
        </div>
      </div>
    </div>

    <!-- Block Information travelers need from you  -->
    <div v-if="tour.important_info != null && tour.important_info != ''" class="translation-block">
      <div class="translation-block__title">{{ translations.informationTravelersNeed.text }}</div>
      <!-- Please note -->
      <div class="translation-row translation-block__container">
        <div class="translation-row__english">
          <div>{{ translations.pleaseNote.text }}</div>
          <VTextarea v-model="tourFrom.important_info" disabled rows="2" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ translations.pleaseNote.text }}</div>
          <VTextarea
            v-model="$v.tourTo.important_info.$model"
            :error="$v.tourTo.important_info.$error"
            :max-length="250"
            rows="2"
          />
        </div>
      </div>
    </div>

    <!-- Block activity details -->
    <div class="translation-block">
      <div class="translation-block__title">{{ translations.activityDetails.text }}</div>
      <!-- Full description -->
      <div
        v-if="tour.description != null && tour.description != ''"
        class="translation-row translation-block__container"
      >
        <div class="translation-row__english">
          <div>{{ translations.fullDescription.text }}</div>
          <VTextarea v-model="tourFrom.description" disabled rows="6" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ translations.fullDescription.text }}</div>
          <VTextarea
            v-model="$v.tourTo.description.$model"
            :error="$v.tourTo.description.$error"
            :max-length="1000"
            rows="6"
          />
        </div>
      </div>
      <!-- Additional information -->
      <div
        v-for="(item, index) in tour.additional_info"
        :key="'tour-additional-info' + index"
        class="translation-row translation-block__container"
      >
        <div class="translation-row__english">
          <div>{{ translations.additionalInformation.text }}</div>
          <VTextarea v-model="tourFrom.additional_info[index].content" disabled rows="2" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ translations.additionalInformation.text }}</div>
          <VTextarea v-model="tourTo.additional_info[index].content" :max-length="250" rows="2" />
        </div>
      </div>
      <!-- Highlights -->
      <div
        v-for="(item, index) in tour.get_tour_highlights"
        :key="'tour-highlights' + index"
        class="translation-row translation-block__container"
      >
        <div class="translation-row__english">
          <div>{{ translations.highlights.text }}</div>
          <VTextarea v-model="tourFrom.get_tour_highlights[index].description" disabled rows="2" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ translations.highlights.text }}</div>
          <VTextarea v-model="tourTo.get_tour_highlights[index].description" :max-length="250" rows="2" />
        </div>
      </div>
    </div>

    <!-- Information about the ticket -->
    <div v-if="tour.get_tour_attraction.length > 0" class="translation-block">
      <div class="translation-block__title">{{ translations.informationAboutTheTicket.text }}</div>
      <div
        v-for="(attraction, index) in tour.get_tour_attraction"
        :key="'tour-attraction' + index"
        class="translation-row translation-block__container"
      >
        <div class="translation-row__english">
          <div>{{ translations.attractionsIncluded.text }}</div>
          <VTextarea v-model="tourFrom.get_tour_attraction[index].description" disabled rows="4" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ translations.attractionsIncluded.text }}</div>
          <VTextarea v-model="tourTo.get_tour_attraction[index].description" :max-length="1000" rows="4" />
        </div>
      </div>
    </div>

    <!-- Block inclusions & exclusions -->
    <div class="translation-block">
      <div v-if="tourIncludes.length > 0" class="translation-block__title">
        {{ translations.inclusionsExclusions.text }}
      </div>
      <!-- Inclusions -->
      <div
        v-for="(item, index) in tourIncludes"
        :key="'tour-yncludes' + index"
        class="translation-row translation-block__container"
      >
        <div class="translation-row__english">
          <div>{{ translations.inclusions.text }}</div>
          <VTextarea v-model="tourIncludes[index].description" disabled rows="2" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ translations.inclusions.text }}</div>
          <VTextarea v-model="tourIncludesTranslate[index].description" rows="2" :max-length="250" />
        </div>
      </div>
      <!-- Exclusions -->
      <div
        v-for="(item, index) in tourExcludes"
        :key="'tour-excludes' + index"
        class="translation-row translation-block__container"
      >
        <div class="translation-row__english">
          <div>{{ translations.exclusions.text }}</div>
          <VTextarea v-model="tourExcludes[index].description" disabled rows="2" />
        </div>
        <div class="translation-row__new-lang">
          <div>{{ translations.exclusions.text }}</div>
          <VTextarea v-model="tourExcludesTranslate[index].description" rows="2" :max-length="250" />
        </div>
      </div>
    </div>

    <v-btn base name="Save & continue" :loading="loading" loading-name="Saved.." @click="saveTranslate" />
    <loader v-if="loading" />
  </div>
</template>

<script>
import VTextarea from '@/components/controls/VTextarea.vue';
import VBtn from '@/components/controls/VBtn.vue';
import Loader from '@/components/Loader';
import { required, requiredIf } from 'vuelidate/lib/validators';

export default {
  name: 'StepActivity',
  components: {
    VTextarea,
    VBtn,
    Loader,
  },
  props: {
    tour: { type: Object, default: () => ({}) },
    tourTranslate: { type: Object, default: () => ({}) },
    translations: { type: Object, default: () => ({}) },
    newQuery: { type: Object, default: () => ({}) },
  },
  data: () => ({
    loading: false,
    tourFrom: null,
    tourTo: null,
    tourIncludes: [],
    tourIncludesTranslate: [],
    tourExcludes: [],
    tourExcludesTranslate: [],
    lastIdInCategories: 23,
  }),
  validations: {
    tourTo: {
      short_description: { required },

      important_info: {
        required: requiredIf(function (model) {
          return this.tour.important_info != null && this.tour.important_info != '';
        }),
      },

      description: { required },
    },
  },
  mounted() {
    this.tourFrom = JSON.parse(JSON.stringify(this.tour));
    this.tourTo = JSON.parse(JSON.stringify(this.tourTranslate));
    if (this.tourTo.description == null) this.tourTo.description = '';

    const tourFilteredIncludes = this.tour.get_tour_includes.filter(
      (tourInclude) => tourInclude.id > this.lastIdInCategories
    );
    const tourFilteredIncludesTranslate = this.tourTranslate.get_tour_includes.filter(
      (tourInclude) => tourInclude.id > this.lastIdInCategories
    );
    this.tourIncludes = tourFilteredIncludes;
    this.tourIncludesTranslate = tourFilteredIncludesTranslate;

    const tourFilteredExcludes = this.tour.get_tour_excludes.filter(
      (tourExclude) => tourExclude.id > this.lastIdInCategories
    );
    const tourFilteredExcludesTranslate = this.tourTranslate.get_tour_excludes.filter(
      (tourExclude) => tourExclude.id > this.lastIdInCategories
    );
    this.tourExcludes = tourFilteredExcludes;
    this.tourExcludesTranslate = tourFilteredExcludesTranslate;
  },
  methods: {
    async saveTranslate() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      for (const item of this.tourIncludesTranslate) {
        const includes = new FormData();
        includes.append('include_id', item.id);
        includes.append('edit_language', this.newQuery.lang);
        includes.append('description', item.description);
        await this.$axios.$put('/api/v1/translate-tour-include/', includes);
      }
      for (const item of this.tourExcludesTranslate) {
        const exclude = new FormData();
        exclude.append('exclude_id', item.id);
        exclude.append('edit_language', this.newQuery.lang);
        exclude.append('description', item.description);
        await this.$axios.$put('/api/v1/translate-tour-exclude/', exclude);
      }

      for (const item of this.tourTo.additional_info) {
        const additional = new FormData();
        additional.append('content', item.content);
        await this.$axios.$put(`/api/v1/additional-info/${item.id}`, additional, {
          params: {
            tour_id: this.tour.id,
            edit_language: this.newQuery.lang,
          },
        });
      }

      for (const item of this.tourTo.get_tour_highlights) {
        const highlight = new FormData();
        highlight.append('highlight_id', item.id);
        highlight.append('edit_language', this.newQuery.lang);
        highlight.append('description', item.description);
        await this.$axios.$put('/api/v1/translate-tour-highlight/', highlight);
      }

      for (const item of this.tourTo.get_tour_attraction) {
        const attractions = new FormData();
        attractions.append('attraction_id', item.id);
        attractions.append('edit_language', this.newQuery.lang);
        attractions.append('description', item.description);
        await this.$axios.$put('/api/v1/translate-tour-attraction/', attractions);
      }

      const bodyFormData = new FormData();
      bodyFormData.append('name', this.tourTo.name);
      bodyFormData.append('short_description', this.tourTo.short_description);
      bodyFormData.append('important_info', this.tourTo.important_info);
      bodyFormData.append('description', this.tourTo.description);
      await this.$axios.$put(`/api/v1/edit-tour/`, bodyFormData, {
        params: {
          tour_id: this.tour.id,
          edit_language: this.newQuery.lang,
        },
      });

      this.loading = false;
      this.$emit('next-step');
    },
  },
};
</script>
