var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: { title: _vm.fields.title.text },
      model: {
        value: _vm.modelVisible,
        callback: function ($$v) {
          _vm.modelVisible = $$v
        },
        expression: "modelVisible",
      },
    },
    [
      _c("div", { staticClass: "pop-up-license-information" }, [
        _c("div", { staticClass: "form" }, [
          _c("div", { staticClass: "form__have-license" }, [
            _c(
              "div",
              { staticClass: "pop-up-license-information__subtitles" },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.fields.contentHaveLicense.text) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "have-license__radioButtons" }, [
              _c(
                "div",
                {
                  staticClass: "have-license__radioButtons__radio",
                  on: {
                    click: function ($event) {
                      return _vm.changeHaveLicense(true)
                    },
                  },
                },
                [
                  _c("RadioButton", {
                    attrs: {
                      default: "",
                      "radio-name": "have-license",
                      name: _vm.fields.yesBtn.text,
                      checked: _vm.newLicenseInformation.haveLicense,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "have-license__radioButtons__radio",
                  on: {
                    click: function ($event) {
                      return _vm.changeHaveLicense(false)
                    },
                  },
                },
                [
                  _c("RadioButton", {
                    attrs: {
                      default: "",
                      "radio-name": "have-license",
                      name: _vm.fields.noBtn.text,
                      checked: !_vm.newLicenseInformation.haveLicense,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.newLicenseInformation.haveLicense
            ? _c(
                "div",
                { staticClass: "form__registration-number" },
                [
                  _c(
                    "div",
                    { staticClass: "pop-up-license-information__subtitles" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.fields.contentNumber.text) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form__registration-number__hint" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.fields.contentNumberSubtitle.text) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("VInput", {
                    staticClass: "form__registration-number__input",
                    attrs: {
                      error:
                        _vm.$v.newLicenseInformation.registrationNumber.$error,
                      "error-message": "This field can not be empty",
                      placeholder: _vm.fields.contentNumberPlaceholder.text,
                      "max-length": 40,
                      "hide-counter": "",
                    },
                    model: {
                      value:
                        _vm.$v.newLicenseInformation.registrationNumber.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.newLicenseInformation.registrationNumber,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression:
                        "$v.newLicenseInformation.registrationNumber.$model",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.newLicenseInformation.haveLicense
            ? _c("div", { staticClass: "form__expire" }, [
                _c(
                  "div",
                  { staticClass: "pop-up-license-information__subtitles" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.fields.contentHaveLicenseExpire.text) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form__expire__radioButtons" }, [
                  _c(
                    "div",
                    { staticClass: "form__expire__radioButtons__radio" },
                    [
                      _c("RadioButton", {
                        attrs: {
                          default: "",
                          "radio-name": "expire",
                          name: _vm.fields.yesBtn.text,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form__expire__radioButtons__radio" },
                    [
                      _c("RadioButton", {
                        attrs: {
                          default: "",
                          "radio-name": "expire",
                          name: _vm.fields.noBtn.text,
                          checked: "",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.newLicenseInformation.haveLicense
            ? _c(
                "div",
                { staticClass: "form__upload" },
                [
                  _c(
                    "div",
                    { staticClass: "pop-up-license-information__subtitles" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.fields.contentLicense.text) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.licenseFile || !_vm.licenseFile.name
                    ? _c(
                        "VFileInput",
                        {
                          attrs: {
                            accept: _vm.$options.INPUT_ACCESS_FILES,
                            error: _vm.$v.licenseFile.$error,
                          },
                          on: { change: _vm.uploadFile },
                        },
                        [
                          _c("div", [
                            _c(
                              "div",
                              { staticClass: "form__upload__file__text" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.fields.uploadLicense.text) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form__upload__file__hint" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.fields.contentInsurancePlaceholderSize
                                        .text
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "form__upload__file form__upload__file--with-file",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form__upload__file__name" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.licenseFile.name) +
                                  "\n          "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "form__upload__file__delete-icon",
                              on: { click: _vm.deleteLicense },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/img/svg/delete-icon.svg"),
                                  alt: "Delete insurance",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                  _vm._v(" "),
                  _vm.$v.licenseFile.$error
                    ? _c(
                        "div",
                        {
                          staticClass: "error-text",
                          staticStyle: { padding: "6px", "margin-top": "-4px" },
                        },
                        [
                          !_vm.$v.licenseFile.required
                            ? [_vm._v(_vm._s(_vm.fields.errorFileEmpty.text))]
                            : !_vm.$v.licenseFile.maxFileSize
                            ? [_vm._v(_vm._s(_vm.fields.errorFileSize.text))]
                            : !_vm.$v.licenseFile.fileFormat
                            ? [
                                _vm._v(
                                  _vm._s(
                                    `${_vm.fields.errorFileFormat.text} (${_vm.$options.INPUT_ACCESS_FILES})`
                                  )
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "actions" }, [
          _c(
            "div",
            { on: { click: _vm.closePopup } },
            [
              _c("Btn", {
                staticClass: "actions__button",
                attrs: { outline: "", name: _vm.fields.cancelBtn.text },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { on: { click: _vm.saveLicense } },
            [
              _c("Btn", {
                staticClass: "actions__button",
                attrs: { base: "", name: _vm.fields.saveBtn.text },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }