var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "photo-help" }, [
    _c("img", {
      staticClass: "photo-help__image",
      attrs: { src: require("@/assets/img/svg/camera.svg"), alt: "camera" },
    }),
    _vm._v(" "),
    _c("p", { staticClass: "photo-help__title" }, [
      _vm._v(_vm._s(_vm.doTitle)),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "photo-help__list" },
      _vm._l(_vm.doList, function (doItem) {
        return _c(
          "li",
          { key: doItem, staticClass: "photo-help__item" },
          [
            _c("BaseIcon", {
              staticClass: "photo-help__icon",
              attrs: { "icon-name": "Checkmark" },
            }),
            _vm._v(" " + _vm._s(doItem) + "\n    "),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("p", { staticClass: "photo-help__title" }, [
      _vm._v(_vm._s(_vm.dontTitle)),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "photo-help__list" },
      _vm._l(_vm.dontList, function (dontItem) {
        return _c(
          "li",
          { key: dontItem, staticClass: "photo-help__item" },
          [
            _c("BaseIcon", {
              staticClass: "photo-help__icon photo-help__icon--dont",
              attrs: { "icon-name": "Close" },
            }),
            _vm._v("\n      " + _vm._s(dontItem) + "\n    "),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }