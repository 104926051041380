<template>
  <div v-if="isActive" class="wrapper">
    <div class="pop-up-map">
      <div class="pop-up__close" @click="closeWindow">
        <img src="@/assets/img/svg/close.svg" alt="close">
      </div>
      <div class="map">
        <base-map
          :id="id"
          :location-map="locationMap"
          :circle-list="circleList"
          @setPoint="setPoint"
          @moveMarker="moveMarker"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseMap from '@/components/common/BaseMap.vue'
export default {
  components: { BaseMap },
  props: {
    isActive: { type: Boolean, default: false },
    id: { type: String, default: 'map' },
    locationMap: { type: Array, default: () => [28.99685, -13.70702] },
    circleList: { type: Array, default: () => [] }
  },
  methods: {
    setPoint (index) {
      this.$emit('setPoint', index)
    },
    moveMarker (event, index) {
      this.$emit('moveMarker', event, index)
    },
    closeWindow () {
      this.$emit('close', this.isActive)
    }
  }
}
</script>

<style lang="scss">
.pop-up-map {
  position: relative;
  flex-wrap: wrap;
  background: $white;
  padding: 45px;
  padding-bottom: 0px;
  width: 90%;
  z-index: 9999;
  .map{
    width: 100%;
    height: 600px;
    .map-wrap {
      width: 100%;
      height: 600px;
    }
  }
}
</style>
