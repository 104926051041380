var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "23",
        height: "24",
        fill: "none",
        viewBox: "0 0 23 24",
      },
    },
    [
      _c("path", {
        attrs: {
          fill: "currentColor",
          "fill-rule": "evenodd",
          d: "M8.97 2.17c-.87.18-1.72 1-1.94 1.86-.04.16-.07.5-.08 1.02l-.02.77-2.2.04-2.18.03-.13.09c-.18.13-.25.28-.25.6 0 .33.08.48.32.6.16.08.22.09.87.09h.7v6.22c0 3.9.02 6.34.04 6.55a2.6 2.6 0 0 0 1.76 2.15l.33.12h10.62l.33-.12c.66-.23 1.2-.7 1.5-1.31.1-.2.2-.43.22-.53.04-.15.06-1.58.07-6.64V7.27h.7c.66 0 .72-.01.88-.09.24-.12.31-.27.31-.6 0-.32-.06-.47-.24-.6l-.13-.09-2.19-.03-2.19-.04-.02-.79c-.02-.86-.06-1.07-.26-1.47a2.7 2.7 0 0 0-1.78-1.4c-.38-.07-4.66-.07-5.04 0m4.94 1.45c.25.11.55.43.63.67.05.14.07.33.07.87v.68H8.39l.01-.74c.01-.7.02-.75.1-.91.12-.21.29-.39.49-.52.3-.15.34-.15 2.57-.15l2.14.01.2.09m3.58 10.03V20l-.1.19a1.15 1.15 0 0 1-.88.7c-.35.06-9.99.03-10.18-.04a1.36 1.36 0 0 1-.76-.79c-.03-.09-.04-2.27-.04-6.47V7.27h11.96v6.37Zm-8.28-3c-.2.1-.3.28-.32.55-.02.13-.03 1.53-.02 3.12.01 2.81.02 2.88.1 3 .12.21.29.3.62.3.35 0 .51-.09.63-.31.08-.15.08-.18.08-3.22 0-3.41.02-3.2-.26-3.4-.17-.11-.63-.14-.83-.04m3.83 0c-.2.1-.3.27-.32.59-.02 1.03-.02 2.06-.01 3.1 0 2.78 0 2.82.09 2.97.12.22.28.3.63.3.33 0 .5-.08.63-.28.07-.13.08-.18.08-3.22 0-3.02 0-3.1-.08-3.22-.13-.22-.26-.28-.59-.3-.22 0-.32.01-.43.06",
          "clip-rule": "evenodd",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }