<template>
  <div class="pricing-change-modal-first-page">
    <div class="pricing-change-modal-first-page__title">
      {{ isPerson ? translations.popupPersonFirstPageTitle.text : translations.popupGroupFirstPageTitle.text }}
    </div>
    <div class="pricing-change-modal-first-page__subtitle">
      {{ isPerson ? translations.popupPersonFirstPageSubtitle.text : translations.popupGroupFirstPageSubtitle.text }}
    </div>
    <div class="pricing-change-modal-first-page__content">
      <div class="pricing-change-modal-first-page__item">
        <div class="dot dot_orange" />
        <div class="pricing-change-modal-first-page__item--description">
          {{ isPerson ? translations.popupPersonFirstPageItem1.text : translations.popupGroupFirstPageItem1.text }}
        </div>
      </div>
      <div class="pricing-change-modal-first-page__item">
        <div class="dot dot_orange" />
        <div class="pricing-change-modal-first-page__item--description">
          {{ isPerson ? translations.popupPersonFirstPageItem2.text : translations.popupGroupFirstPageItem2.text }}
        </div>
      </div>
    </div>
    <div v-if="isSpacialOfferNow" class="pricing-change-modal-first-page__error">
      {{ translations.errorPricesChange.text }}
    </div>
    <div class="change-pricing-type__nav">
      <v-btn outline :name="translations.popupNavCancel.text" @click="$emit('close')" />
      <div class="nav__pagination row">
        <div v-for="index in pageCount" :key="`pageNum${index}`">
          <div class="dot dot_page" :class="{ 'dot_page_active': index - 1 == currentPageIndex}" />
        </div>
      </div>
      <v-btn class="nav__next" base :name="translations.popupNavNext.text" :disabled="isSpacialOfferNow" @click="$emit('next')" />
    </div>
  </div>
</template>

<script>
import { dateIsInTheInterval } from '@/utils/validations'
import VBtn from '@/components/controls/VBtn.vue'

export default {
  components: {
    VBtn
  },
  props: {
    translations: { type: Object, default: () => ({}) },
    isPerson: { type: Boolean, default: false },
    pageCount: { type: Number, default: 0 },
    currentPageIndex: { type: Number, default: 0 },
    discountStartDate: { type: String, default: '' },
    discountEndDate: { type: String, default: '' }
  },
  computed: {
    isSpacialOfferNow() {
      const dateNow = new Date()
      dateNow.setHours(0, 0, 0, 0)
      const endDate = new Date(this.discountEndDate)
      return dateNow <= endDate
    }
  },
  methods: {
    dateIsInTheInterval
  }
}
</script>
<style lang="scss">
.pricing-change-modal-first-page {
  &__title {
    width: 683px;
    margin-bottom: 22px;
    font-weight: bold;
    font-size: 32px;
    line-height: 39px;
  }
  &__subtitle {
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }
  &__content {
    margin-bottom: 62px;
  }
  &__item {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    &--description {
      margin-left: 14px;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
    }
  }
  &__error {
    color: $red;
  }
}
</style>
